/* eslint-disable react/prop-types */
/* eslint-disable no-console */
import React, { Component } from 'react';
import { BASE_URL_V4, API_URL, FEED_BACK_FORMS } from '../../../../../api/api-config';
//import { constants } from '../../../../../utility/constants';
import Loader from '../../../../shared-ui/Loader';
import { withTranslation } from 'react-i18next';
import ErrorPopup from '../../../errorPopup';
import { axiosInstance } from '../../../../../api/coreApi';
import { windowOpenHandler } from '../../../../../utility/common';

class ContactUs extends Component {

  constructor (props) {
    super(props);
    this.onNameChange=this.onNameChange.bind(this);
    this.onEmailChange=this.onEmailChange.bind(this);
    this.onPhoneChange=this.onPhoneChange.bind(this);
    this.onDescriptionChange=this.onDescriptionChange.bind(this);
    this.onSubmit=this.onSubmit.bind(this);
    this.isNumeric=this.isNumeric.bind(this);
    this.endUserFeedBackForm=this.endUserFeedBackForm.bind(this);
    this.updatePopUpStatus=this.updatePopUpStatus.bind(this);
    this.state = {
      name: '',
      email: '',
      PhoneNumber: '',
      description: '',
      errorMessage: '',
      errorMessageSecond: '',
      allReq: false,
      emailError: false,
      phoneError: false,
      isLoading: false,
      showErrorPopUp: false,
    };
  }

  isNumeric (value) {
    //return value.isInteger();
    return /^-?\d+$/.test(value);
  }

  endUserFeedBackForm () {
    windowOpenHandler(FEED_BACK_FORMS.end_user_form);
    this.setState({
      showErrorPopUp:false
    });
  }

  updatePopUpStatus () {
    this.setState({
      showErrorPopUp:false
    });
  }

  onNameChange (e) {
    this.setState({
      name: e && e.target && e.target.value,
    });
  }

  onEmailChange (e) {
    this.setState({
      email: e && e.target && e.target.value,
      emailError: false
    });
  }

  onPhoneChange (e) {
    this.setState({
      PhoneNumber: e && e.target && e.target.value,
      phoneError: false
    });
  }

  onDescriptionChange (e) {
    this.setState({
      description: e && e.target && e.target.value
    });
  }

  onSubmit () {
    const { t } = this.props;
    const { name, email, PhoneNumber, description } = this.state;

    if(name == '' || email == '' || PhoneNumber == '' || description == '') {
      this.setState({ allReq:true });
      return;
    }else{
      this.setState({ allReq:false });
    }
    
    const isEmailValid = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email) ? true : false;
    const isPhoneValid =this.isNumeric(PhoneNumber) && PhoneNumber && PhoneNumber.length == 10 ? true : false;
    const url = BASE_URL_V4 + API_URL.contactUs;
    const obj = {
      full_name: name,
      email: email,
      mobile_number: PhoneNumber,
      message_content: description
    };

    if (isEmailValid == false && isPhoneValid == false) {
      this.setState({
        emailError: true,
        phoneError: true
      });
    } else if (isEmailValid == false) {
      this.setState({
        emailError: true,
      });
    } else if (isPhoneValid == false) {
      this.setState({
        phoneError: true,
      });
    } else {
      this.setState({
        isLoading: true
      });
      axiosInstance.post(url, obj).then((res) => {
        const data = res && res.data;
        if (data && data.status && data.status.toLowerCase() == 'success') {
          this.setState({
            name: '',
            email: '',
            PhoneNumber: '',
            description: '',
            emailError: false,
            phoneError: false,
            isLoading: false,
            showErrorPopUp: true,
            errorMessage: t('form_submit_thank_you'),
            errorMessageSecond: t('form_submit_thank_you_second_para')
          });
        }
      }).catch((err) => {
        this.setState({
          isLoading: false
        });
        console.log(err);
      });
    }
  }

  render () {
    // eslint-disable-next-line react/prop-types
    const { t }=this.props;
    const { name, email, PhoneNumber, description, emailError, phoneError, isLoading, allReq, errorMessage, errorMessageSecond } = this.state;
    
    return (
      <div>
        <div className='contact-us-container'>
          <Loader
            isLoading={isLoading} />
          <div className='contact-us-text'>
            {t('contact_us')}
          </div>
          <div className='contact-us-form-wrapper'>
            <form className='form-wrapper'>
              {(allReq) && <div className='error-text'>{t('all_fields_required')}</div>}
              {(emailError || phoneError) && <div className='error-text'>{t('enter_valid_inputs')}</div>}
              <div className='form-group'>
                <label className='form-label'>{t('full_name')}</label>
                <input className='form-input'
                  type='text'
                  id='name'
                  placeholder={t('input_text')}
                  maxLength={250}
                  value={name}
                  onChange={(e) => this.onNameChange(e)} />
              </div>
              <div className='form-group'>
                <label className='form-label'>{t('email_address')}</label>
                <input className={`form-input ${emailError && 'error'}`}
                  type='email'
                  id='email'
                  placeholder={t('input_text')}
                  value={email}
                  onChange={(e) => this.onEmailChange(e)} />
              </div>
              <div className='form-group'>
                <label className='form-label'>{t('phone_number')}</label>
                <input className={`form-input ${phoneError && 'error'}`}
                  type='text'
                  id='phone-number'
                  placeholder={t('input_number')}
                  value={PhoneNumber}
                  onChange={(e) => this.onPhoneChange(e)} />
              </div>
              <div className='form-group'>
                <label className='form-label'>{t('description')}</label>
                <textarea className='form-input-desc'
                  rows='3'
                  type='text'
                  id='description'
                  placeholder={t('input_text')}
                  value={description}
                  onChange={(e) => this.onDescriptionChange(e)} />
              </div>
              <div className='form-submit visava-btn'
                onClick={ this.onSubmit }>{t('submit')}</div>
            </form>
          </div>
        </div>
        {this.state.showErrorPopUp &&
          <ErrorPopup shown={this.state.showErrorPopUp}
            close={() => this.setState({ showErrorPopUp:false })}
            closeOk={() => this.setState({ showErrorPopUp:false })}
            formOpen={() => this.endUserFeedBackForm()}
            formPostponded={() => this.updatePopUpStatus()}
            message={errorMessage}
            anotherMessage={errorMessageSecond}
            buttonLabel='share_feedback'
            buttonLabelSecond='maybe_later'
            showPayment={false}
            googleMaps={false}
            feedBackButton={true}
            showGoogleClose={false} />}
      </div>
    );
  }
}

export default withTranslation()(ContactUs);