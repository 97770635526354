import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import VisavaLogo from '../../../../src/asset/images/VisavaLogo.png';
import { withTranslation } from 'react-i18next';

class LogoWithText extends Component {
  render () {
    // eslint-disable-next-line react/prop-types
    const { title, t } = this.props;
    return (
      <div className='logo-with-text-container'>
        <Link to='/'
          className='logo-link'>
          <div className='image-wrapper'>
            <img className='image'
              src={VisavaLogo}
              alt='' />
          </div>
          <div className='visava-logo-text'>
            {t('app_name').toUpperCase()}
            <div className='beta-txt'>BETA</div>
          </div>
        </Link>
        <div className='title'>{title}</div>
      </div>
    );
  }
}

LogoWithText.defaultProps = {
  title: ''
};

export default withTranslation()(LogoWithText);