import React, { Component } from 'react';
import PlayStore from './playStoreButton/index.js';

class PlayStoreWrapper extends Component {
  render () {
    return (
      <div className='playStoreWrapper'>
        <PlayStore {...this.props} />
      </div>
    );
  }
}

export default PlayStoreWrapper;