/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
import React, { Fragment } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import VisavaLogo from '../../../../src/asset/images/VisavaLogo.png';
import languageLogo from '../../../../src/asset/images/vector.svg';
import SideMenuIcon from '../../../../src/asset/images/side_menu.svg';
import CloseIcon from '../../../../src/asset/images/cancel-icon.svg';
//import PlayStoreImg from '../../../../src/asset/images/playStore.png';
import GooglePlayBadgeEn from '../../../../src/asset/images/GooglePlayBadgeEn.png';
import GooglePlayBadgeHi from '../../../../src/asset/images/GooglePlayBadgeHi.png';
import GooglePlayBadgeMa from '../../../../src/asset/images/GooglePlayBadgeMa.png';
//import Offer_tag_img from '../../../asset/images/offer_tag_img.svg';
import betaOffer from '../../../asset/images/beta_offer.svg';
import Breakpoint from '../../../utility/breakPoints';
//import GetDocumentImg from '../../../../src/asset/images/499-document.png';
//import { constants, hindiConstants, marathiConstants } from '../../../utility/constants';
import { changeLang } from '../../../i18n/i18n';
import { withTranslation } from 'react-i18next';
import Loader from '../Loader';
import ErrorPopup from '../../components/errorPopup';
import ROUTES from '../../../pages/routes';
import PaymentSuccessPage from '../../components/paymentSuccessPage';
import { GOOGLE_PLAY_URL, BASE_URL_V4, API_URL, SHARE_URL, BLOG_URL, userAgent } from '../../../api/api-config';
import { axiosInstance } from '../../../api/coreApi';
import { setInitialPayment } from '../../redux/actions/initiatePayment';
import { updateBreakPoint, updateLangCode } from '../../redux/actions/headerState';
import { getBreakpoint, getLangCodeSelector } from '../../../selectors/commonSelectors';
import { windowOpenHandler } from '../../../utility/common';


class Header extends React.Component {
  constructor (props) {
    super(props);
    this.onEnter=this.onEnter.bind(this);
    this.onLeave=this.onLeave.bind(this);
    this.updateBreakPoint=this.updateBreakPoint.bind(this);
    this.renderSideMenuOptions=this.renderSideMenuOptions.bind(this);
    this.onLangClick=this.onLangClick.bind(this);
    this.selectLang=this.selectLang.bind(this);
    this.redirectToSignInLoginPage=this.redirectToSignInLoginPage.bind(this);
    this.redirectHome=this.redirectHome.bind(this);
    this.goToDMH=this.goToDMH.bind(this);
    this.showMyProfile=this.showMyProfile.bind(this);
    this.goToOurStory=this.goToOurStory.bind(this);
    this.goToBlog=this.goToBlog.bind(this);
    this.goToVisavaVendors=this.goToVisavaVendors.bind(this);
    //this.checkPaymentStatus=this.checkPaymentStatus.bind(this);
    this.fetchHouseDetails=this.fetchHouseDetails.bind(this);
    this.viewDesignDoc=this.viewDesignDoc.bind(this);
    //this.initiatePayment=this.initiatePayment.bind(this);
    this.langSelectOperation=this.langSelectOperation.bind(this);
    this.updateUserProfile=this.updateUserProfile.bind(this);
    this.paymentProcess = this.paymentProcess.bind(this);
    this.redirectPayment=this.redirectPayment.bind(this);
    this.goToHomeListing=this.goToHomeListing.bind(this);
    this.skipPayment=this.skipPayment.bind(this);
    this.continueDoc=this.continueDoc.bind(this);
    const tempLang = localStorage.getItem('lang');
    this.state = {
      didMouseEnter: false,
      showSideMenuOptions: false,
      showAvailablelang: false,
      //defaultLang: tempLang || 'en',
      showErrorPopUp: false,
      isLoading: false,
      showPayOpt: false,
      bomCost:'999',
      isPaymentCompleted: false,
      orderId: false,
      payParams:{},
      paymentURL: '',
      openHouse: false,
      paymentStarted: false,
      openHouseTotal: false,
      totalHouse: null,
      permittedHouse: null
    };
  }

  componentDidMount () {
    const tempLang = localStorage.getItem('lang');
    if(tempLang) {
      this.selectLang(tempLang);
      //this.props.history.push('/');
    }else{
      localStorage.setItem('lang', 'hi');
    }
   
    const headerDataElement = ROUTES.filter((element) => {
      // if (element && element.path && element.path.includes(location.pathname)) {
      if (location.pathname.includes(element.path) && element.path != '/') {
        //console.log('??????', location.pathname, element.path );
        return element;
      }
    });

    const headerData = headerDataElement && headerDataElement[0] && headerDataElement[0].headerData;
    const showPayment = headerData && headerData.showPayment;
    if(showPayment) {
      this.setState({
        showPayOpt:true
      });
      this.fetchHouseDetails();
    }
    
  }

  fetchHouseDetails () {

    const houseId = localStorage.getItem('houseId');
    const url = `${API_URL.house}/${houseId}`;

    axiosInstance.get(url).then((resp) => {
      const data = resp && resp.data && resp.data.data;
      if (data) {
        this.setState({
          isPaymentCompleted: data.payment,
          bomCost: data.iap_house_bom_cost,
          totalHouse: data.total_house_count,
          permittedHouse: data.maximum_house_count
        });
        // if(!data.payment) {
        //   const tempOrderId = localStorage.getItem('orderId');
        //   if(tempOrderId) {
        //     this.checkPaymentStatus(tempOrderId);
        //   }else{
        //     this.initiatePayment();
        //   }
        // }
      }
    }).catch((err) => {
      console.log('err---->>>', err);
      
    });
  }

  // initiatePayment () {
  //   const url = BASE_URL_V4 + API_URL.payment;
  //   const object = {
  //     os_type: 'WEB',
  //   };

  //   axiosInstance.post(url, object).then((response) => {
  //     if (response && response.data && response.data.data) {
  //       const data = response && response.data && response.data.data;
  //       const formData = {
  //         url: data.url,
  //         mid: data.mid,
  //         orderId: data.order_id,
  //         transactionToken: data.paytm_txn_token
  //       };
  //       this.setState({
  //         paymentURL: data.url
  //       });
  //       this.props.setInitialPayment(formData);
  //       localStorage.setItem('orderId', data.order_id);
  //     }
  //   }).catch((err) => {
  //     console.log('\n\nError in payment------>>', err && err.response && err.response.data);
  //   });
  // }

  // checkPaymentStatus (tempOrderId) {
  //   const url = BASE_URL_V4 + API_URL.paymentStatus;
  //   const object = {
  //     order_id: tempOrderId
  //   };

  //   axiosInstance.post(url, object).then((response) => {
  //     if (response && response.data) {
  //       const data = response && response.data;
  //       if(data.status=='SUCCESS') {
  //         this.setState({
  //           isPaymentCompleted: true
  //         });
  //         localStorage.removeItem('orderId');
  //         this.fetchHouseDetails();
  //       }else{
  //         //this.initiatePayment();
  //       }
  //     }
  //   }).catch((err) => {
  //     console.log('\n\nError in payment------>>', err && err.response && err.response.data);
  //   });
  // }

  viewDesignDoc () {
    //localStorage.setItem('isValid', true);
    const houseId = localStorage.getItem('houseId');
    const shareToken = localStorage.getItem('shareToken');
    const langCode = localStorage.getItem('lang');
    const { history } = this.props;
    history && history.push(`/design-document?house_id=${houseId}&share_token=${shareToken}&preferred_language=${langCode}&user_agent=${userAgent}`);
    //windowOpenHandler('/design-document');
    //windowOpenHandler(`/design-document?house_id=${houseId}&share_token=${shareToken}&preferred_language=${langCode}&user_agent=${userAgent}`);
  }

  onEnter () {
    this.setState({
      didMouseEnter: true
    });
  }

  onLeave () {
    this.setState({
      didMouseEnter: false
    });
  }

  renderSideMenuOptions () {
    this.setState({
      showSideMenuOptions: !this.state.showSideMenuOptions,
      showAvailablelang: false,
    });
  }

  onLangClick () {
    this.setState({
      showAvailablelang: true
    });
  }

  goToHomeListing () {
    //this.viewDesignDoc();
    const { history } = this.props;
    history.push('/homes');
  }

  continueDoc () {
    this.setState({
      paymentStatus: this.state.paymentStarted,
      showSuccess: false
    });
    this.goToHomeListing();
  }

  updateUserProfile () {
    const URL = BASE_URL_V4 + API_URL.getUserProfile;
    const payloadObject = {
      preferred_language: localStorage.getItem('lang'),
      first_name: '',
      last_name: ''
    };

    axiosInstance
      .patch(URL, payloadObject)
      .then(() => {
      })
      .catch((err) => {
        console.log('\n\n\nError----------->>>>', err.response);
      });
  }

  langSelectOperation (lang) {
    //this.selectLang(lang);
    const jwtToken = localStorage.getItem('jwtToken');
    if(jwtToken) {
      this.selectLang(lang);
      this.updateUserProfile();
    } else {
      this.selectLang(lang);
    }
  }

  selectLang (lang) {
    changeLang(lang);
    this.props.updateLangCode(lang);
    localStorage.setItem('lang', lang);
    this.setState({
      showAvailablelang: false
    });
  }

  redirectToSignInLoginPage (text) {
    this.setState({
      showSideMenuOptions: false,
      showAvailablelang: false
    });
    if (text && text.toLowerCase() == 'log in') {
      this.props.history.push('/login');
    } else {
      this.props.history.push('/signup');
    }
  }

  updateBreakPoint (breakpoint) {
    this.props.updateBreakPoint(breakpoint || 'lg');
  }


  redirectHome () {
    this.setState({
      showSideMenuOptions: false,
      showAvailablelang: false
    });
    this.props.history.push('/');
  }

  goToDMH () {
    const { history } = this.props;
    if (localStorage.getItem('jwtToken')) {
      this.setState({
        showSideMenuOptions: false
      }, () => history.push('/homes'));
    } else {
      history.push('/login');
    }
  }

  showMyProfile () {
    const { history } = this.props;
    this.setState({
      showSideMenuOptions: false,
      showAvailablelang: false,
    });
    history && history.push('/my-profile');
  }

  goToOurStory () {
    const { history } = this.props;
    this.setState({
      showSideMenuOptions: false,
      showAvailablelang: false,
    });
    history && history.push('/our-story');
    // window.open('/our-story');
  }

  goToVisavaVendors () {
    const { history } = this.props;
    history && history.push('/visava-vendors');
  }

  skipPayment () {
    const url = BASE_URL_V4 + API_URL.payment;
    axiosInstance.put(url).then((response) => {
      if (response && response.data && response.data.data) {
        const data = response && response.data && response.data.data;
        this.setState({
          //showSuccess: true,
          paymentStatus: 'SUCCESS'
        });
        this.viewDesignDoc();
      }
    }).catch((err) => {
      //console.log('\n\nError in payment------>>', err && err.response && err.response.data);
      if (err && err.response) {
        const errData = err.response && err.response.data;
        if (errData && errData.status && errData.status.toLowerCase() == 'failed' && errData.error_code === 5401) {
          const { t }=this.props;
          this.setState({
            errorMessage: t('cannot_make_payment_three'),
            buttonLabel: t('close_button'),
            showErrorPopUp: true,
            openHouseTotal: true
          });
          localStorage.removeItem('orderId');
        }
      }
    });
  }

  paymentProcess () {
    //this.initiatePayment();
    const { t } = this.props;
    this.setState({
      errorMessage: t('noFurtherEdit'),
      showErrorPopUp: true,
      paymentStarted: true,
      buttonLabel: t('view_design_doc')
    });
  }

  redirectPayment () {
    //const { paymentURL } = this.state;
    localStorage.setItem('isPaymentStarted', true);
    if(!this.state.openHouseTotal) {
      this.skipPayment();
    } else {
      this.setState({
        showErrorPopUp:false,
        openHouseTotal:false });
    }
    //window.open(paymentURL);
  }

  goToBlog () {
    const langCode = localStorage.getItem('lang');
    if(langCode == 'en') {
      windowOpenHandler(`${BLOG_URL}`);
    } else {
      windowOpenHandler(`${BLOG_URL}/${langCode}`);
    }
  }

  render () {
    const { t, breakpoint, langCode } = this.props;
    const { didMouseEnter, showSideMenuOptions, totalHouse, permittedHouse, showAvailablelang, isPaymentCompleted, showPayOpt, bomCost, isLoading } = this.state;
    const { payParams } = this.props;
    const { children, location } = this.props;
    const myState = location && location.state;

    const headerDataElement = ROUTES.filter((element) => {
      if (element && element.path && element.path.includes(location.pathname)) {
        return element;
      }
    });
    const headerData = headerDataElement && headerDataElement[0] && headerDataElement[0].headerData;
    const from = (headerData && headerData.page == 'otp') && (location && location.state && location.state.from);
    const showTopbtn = breakpoint == 'lg' && (headerData && headerData.btnText != undefined);
    //console.log(showTopbtn);
    const btnText = (from && from == 'signup') ? 'LOG IN' : from && from == 'login' ? 'SIGN UP' : (headerData && headerData.btnText) || 'SIGN UP';
    const buttonText = btnText.toUpperCase() == 'log in' ?
      t('login') : t('sign_up');

    const jwtToken = localStorage.getItem('jwtToken');
    const showHeader = headerData && headerData.showHeader;
    const isDMH = headerData && headerData.dmh;
    //read from props and change the name(enablehumburgerheader)>>>>>>>>>
    const showPayment = headerData && headerData.showPayment;
    
    return (
      <><Loader
        isLoading={isLoading} />
        <Fragment>
          <div>
            {showHeader && <div className={'header-container-1'}>
              <div className='logoWrapper'
                style={isDMH ? { width: '100%' } : undefined}>
                <div>
                  <div className='logoContainer'
                    onClick={this.redirectHome}>
                    <img
                      className='logo'
                      src={VisavaLogo}
                      alt={'VisavaLogo'} />
                  </div>
                  {breakpoint == 'lg' && <div className='brand_name'>
                    {t('app_name').toUpperCase()}
                    <div className='beta-txt'>BETA</div>
                  </div>}
                </div>
                {(isDMH && breakpoint == 'lg') && <div className={`${'design-house-text'} ${showPayment && 'design-header-center'}`}>{t('design_my_house').toUpperCase()}</div>}
              </div>
              <div className='headerLinksContainer'>
                {breakpoint == 'lg' && !isDMH && <div className='header-link-wrapper'>
                  <span className='headerLinks'
                    onClick={this.goToDMH}>
                    {t('design_my_house')}
                  </span>
                  <span className='headerLinks'
                    onClick={this.goToOurStory}>
                    {t('our_story')}
                  </span>
                  <span className='headerLinks'
                    onClick={this.goToBlog}>
                    {t('blog')}
                  </span>
                  {jwtToken &&
                    <span className='headerLinks'
                      onClick={this.showMyProfile}>
                      {t('my_profile')}
                    </span> }
                  <span className='headerLinks'
                    onClick={this.goToVisavaVendors}>
                    {t('visava_vendor')}
                  </span>
                  <span className='headerLinks'
                    onClick={this.onLangClick}>
                    <img className='langLogo'
                      src={languageLogo}
                      alt='languageLogo' />
                    {t('selected_lang')}
                  </span>
                </div>}
                {/*breakPoint_L && showPayment && <div className='header-link-wrapper'>
                  <img className='image'
                    src={GetDocumentImg}
                    alt='' />   
                  </div> */}
                {breakpoint == 'lg' && (showPayOpt || showPayment) ?
                  // !isPaymentCompleted || totalHouse < 3 ?
                  totalHouse < permittedHouse ?
                    <div style={{ position: 'relative' }}>
                      {/* <div className='offer_tag_img'><img src={Offer_tag_img}
                      alt='offer' /></div> */}
                      {langCode == 'en' ?
                        <><div className='offer_tag_img' /><div className='offer_tag_img'>
                          <img src={betaOffer}
                            alt='offer' />
                        </div></> : <><div className='offer_text' /><div className='offer_tag_img_hi_mi'>
                          <img src={betaOffer}
                            alt='offer' />
                        </div></> }
                      <div className='get-document-header'
                        onClick={this.paymentProcess}>
                        {langCode == 'en' ?
                          <button className='get-document-submit button-pointer'
                            onClick={this.paymentProcess}>{ t('get_document_starting')} <span className='original_price'>₹{bomCost}</span></button > :
                          <button className='get-document-submit button-pointer'
                            onClick={this.paymentProcess}><span className='original_price'>₹{bomCost}</span> { t('get_document_starting')} </button >
                        }
                      </div>
                    </div> : null
                  // <div className='get-document-header button-pointer'
                  //   onClick={this.viewDesignDoc}>
                  //   {t('view_design_doc')}
                  // </div>
                  : null }
                {jwtToken && !isDMH ?
                  <div className='playStore-container'>
                    <a href={GOOGLE_PLAY_URL}
                      target='_blank'
                      rel='noopener noreferrer'><img className='playStoreImg'
                        src={langCode == 'hi' ? GooglePlayBadgeHi : langCode == 'mr' ? GooglePlayBadgeMa : GooglePlayBadgeEn}
                        alt='' /></a>
                  </div> : !isDMH &&
                    <div
                      className={`${'loginButton'} ${didMouseEnter && 'loginButtonHover'} ${(showTopbtn) && 'hidecomponent'}`}
                      onMouseEnter={this.onEnter}
                      onMouseLeave={this.onLeave}
                      onClick={() => this.redirectToSignInLoginPage(btnText)}
                      role='button'>
                      {buttonText}
                    </div>}
                {(!isDMH && breakpoint !== 'lg') && <div className='icon-wrapper opt-link'
                  onClick={this.renderSideMenuOptions}>
                  <img className={`${showSideMenuOptions ? 'close-icon' : 'side-menu'}`}
                    src={showSideMenuOptions ? CloseIcon : SideMenuIcon}
                    alt='' />
                </div>}
                {((isDMH && breakpoint == 'sm') || (isDMH && breakpoint == 'lg')) && <div className='icon-wrapper opt-link'
                  onClick={this.renderSideMenuOptions}>
                  <img className={`${showSideMenuOptions ? 'close-icon' : 'side-menu'}`}
                    src={showSideMenuOptions ? CloseIcon : SideMenuIcon}
                    alt='' />
                </div>}
              </div>
            </div>}
            {showSideMenuOptions && !showAvailablelang && showHeader ?
              <div className='sideMenu-options'>
                <div className='options opt-link'
                  onClick={this.goToDMH}>{t('design_my_house')}</div>
                <div className='options opt-link'
                  onClick={this.goToOurStory}>{t('our_story')}</div>
                <div className='options'
                  onClick={this.goToBlog}>{t('blog')}
                </div>
                {jwtToken && <div className='options opt-link'
                  onClick={this.showMyProfile}>{t('my_profile')}</div>}
                <div className='options opt-link'
                  onClick={this.goToVisavaVendors}>{t('visava_vendor')}</div>
                <div onClick={this.onLangClick}
                  style={{
                    display: 'flex',
                    alignItems: 'center'
                  }}>
                  <div className='lang_icon'>
                    <img
                      src={languageLogo}
                      alt='languageLogo' />
                  </div>
                  <div style={{
                    marginBottom: '0',
                    lineHeight: 'normal'
                  }}
                    className='options opt-link'>{t('selected_lang')}</div>
                </div>
              </div> :
              showAvailablelang && showHeader ?
                <div className='sideMenu-options'>
                  <div className='options'><div>{t('select_lang')}</div><div onClick={this.redirectHome}
                    className='close_icon' /></div>
                  <div className='languages'>
                    <div onClick={() => this.langSelectOperation('hi')}
                      className='options opt-link'
                      style={langCode.toLowerCase() == 'hi' ? {
                        color: '#00B0A6',
                        fontWeight: '600'
                      } : {}}>हिन्दी</div>
                    <div onClick={() => this.langSelectOperation('en')}
                      className='options opt-link'
                      style={langCode.toLowerCase() == 'en' ? {
                        color: '#00B0A6',
                        fontWeight: '600'
                      } : {}}>English</div>
                    <div onClick={() => this.langSelectOperation('mr')}
                      className='options opt-link'
                      style={langCode.toLowerCase() == 'mr' ? {
                        color: '#00B0A6',
                        fontWeight: '600'
                      } : {}}>मराठी</div>
                  </div>
                </div> :
                <div className='dummy-class'>
                  {children}
                </div>}
          </div>
          <Breakpoint onBreakpointChange={this.updateBreakPoint} />
        </Fragment>
        <div>
          {this.state.showSuccess &&
            <PaymentSuccessPage continueDoc={this.continueDoc} /> }
          {this.state.paymentStarted &&
            <ErrorPopup shown={this.state.showErrorPopUp}
              paymentStatus={'FAILURE'}
              close={() => this.setState({ showErrorPopUp:false })}
              closeOk={() => this.setState({ showErrorPopUp:false }, this.redirectPayment)}
              message={this.state.errorMessage}
              showPayment={false}
              googleMaps={false}
              feedBackButton={false}
              buttonLabel={this.state.buttonLabel}
              showGoogleClose={false} />}
        </div>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  setInitialPayment: (data) => {
    dispatch(setInitialPayment(data));
  },
  updateBreakPoint: (breakpoint) => {
    dispatch(updateBreakPoint(breakpoint));
  },
  updateLangCode: (lang) => {
    dispatch(updateLangCode(lang));
  }
});

const mapStateToProps = (state) => ({
  payParams: (state.homeDetailsState && state.homeDetailsState.payParams) || {},
  breakpoint : getBreakpoint(state),
  langCode: getLangCodeSelector(state)
});


export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withRouter(Header)));
