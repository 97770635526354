/* eslint-disable no-constant-condition */
/* eslint-disable no-undef */
/* eslint-disable prefer-const */
/* eslint-disable react/prop-types */
/* eslint-disable no-console */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { getLangCodeSelector } from '../../../selectors/commonSelectors';
import EditImg from '../../../../src/asset/images/pencil.svg';
import CameraImg from '../../../../src/asset/images/camera.svg';
import YoutubeIcon from '../../../../src/asset/images/youtube_icon.svg';
import HandShakeIcon from '../../../../src/asset/images/hand-shake.svg';
import HelpImg from '../../../../src/asset/images/help.svg';
import EmailImg from '../../../../src/asset/images/open-email.svg';
import LogoutImg from '../../../../src/asset/images/logout.svg';
import FeedbackForm from '../../../../src/asset/images/shareFeedbackProfile.svg';
//import UserImg from '../../../../src/asset/images/user-image.svg';
import DefaultUserImg from '../../../../src/asset/images/default_profile_img.svg';
import { BASE_URL_V4, API_URL, SHARE_URL, YOUTUBE_LINKS, CONTACT_DETAILS, FEED_BACK_FORMS } from '../../../api/api-config';
import Loader from '../../shared-ui/Loader';
import { windowOpenHandler } from '../../../utility/common';
import { axiosInstance } from '../../../api/coreApi';
import { withTranslation } from 'react-i18next';
//import YouTubeModal from '../youTubeModal';
import SharePopUp from '../sharePopup';
import ErrorPopup from '../errorPopup';

class MyProfile extends Component {
  constructor (props) {
    super(props);
    this.mailTo = this.mailTo.bind(this);
    this.logout = this.logout.bind(this);
    this.getHelp = this.getHelp.bind(this);
    this.goToVideo = this.goToVideo.bind(this);
    this.verifyEmailPhone = this.verifyEmailPhone.bind(this);
    this.verifyEmailPhone1 = this.verifyEmailPhone1.bind(this);
    this.inludeBoth = this.inludeBoth.bind(this);
    this.changeFirstname = this.changeFirstname.bind(this);
    this.changeLastname = this.changeLastname.bind(this);
    this.updateUserProfile = this.updateUserProfile.bind(this);
    this.updatProfileImage = this.updatProfileImage.bind(this);
    this.fetchProfileDetails = this.fetchProfileDetails.bind(this);
    this.fetchProfilePicture = this.fetchProfilePicture.bind(this);
    this.closeSharePopUp=this.closeSharePopUp.bind(this);
    this.viewSharePopUp=this.viewSharePopUp.bind(this);
    this.logoutAgree=this.logoutAgree.bind(this);
    this.capitalize=this.capitalize.bind(this);
    this.endUserFeedBackForm=this.endUserFeedBackForm.bind(this);
    this.state = {
      firstName: null,
      lastName: null,
      email: null,
      mobile: null,
      image: null,
      loading: false,
      both: false,
      editName: false,
      countryCode: null,
      //preferredLanguage: null,
      mobile_verifed:'',
      email_verifed:'',
      showModal: false,
      showSharePopUp: false,
      isLoading: false,
      logOutPopUp: false,
      pictureasFile:'',
      showCropModal:false,
      profileImage: DefaultUserImg,
      crop:{ aspect: 1/1,
        width: 200,
        height: 200,
      }
    };
  }

  viewSharePopUp () {
    this.setState({
      showSharePopUp: true
    });
  }

  closeSharePopUp () {
    this.setState({
      showSharePopUp: false
    });
  }

  endUserFeedBackForm () {
    windowOpenHandler(FEED_BACK_FORMS.end_user_form);
  }

  /*********    function for formatting the string to have one letter in upper case and all other letters in lower case  **********/
  capitalize (str) {
    str = str.toLowerCase().split(' ');
    for (let i = 0; i < str.length; i++) {
      str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
    }
    return str.join(' ');
  }
  
  componentDidMount () {
    this.fetchProfileDetails();
    this.fetchProfilePicture();
  }

  fetchProfilePicture () {
    const { history } = this.props;
    const jwtToken = localStorage.getItem('jwtToken');

    if (!jwtToken) {
      history.push('/login');
    } else {
      this.setState({
        loading: true,
      });
      const URL = BASE_URL_V4 + API_URL.getUserProfileImage;
      const axiosConfig = {
        headers:  { 'Authorization':   `Bearer  ${jwtToken}`,
          'content-type':'multipart/form-data' },
        responseType: 'blob'
      };
      axiosInstance
        .get(
          URL,
          axiosConfig)
        .then((res) => {
          const reader = new FileReader();
          reader.onloadend = () => {
            this.setState({
              profileImage: reader.result,
              loading: false,
              showCropModal: false
            });
          };
          reader.readAsDataURL(res.data);
        })
        .catch((err) => {
          this.setState({
            loading: false,
            showCropModal: false
          });
          console.log(err);
          if(err.response.data.status == 'Token is Expired' || err.response.data.status == 'Token is Invalid' || err.response.data.status == 'Authorization Token not found') {
            localStorage.clear();
            this.props.history.push('/login', { expiredError: true });
          }
        });
    }
  }

  fetchProfileDetails () {
    const { history } = this.props;
    const jwtToken = localStorage.getItem('jwtToken');

    if (!jwtToken) {
      history.push('/');
    } else {
      this.setState({
        loading: true,
      });
      const URL = BASE_URL_V4 + API_URL.getUserProfile;
      axiosInstance
        .get(URL)
        .then((resp) => {
          const profileData = resp && resp.data && resp.data.data;
          if (profileData) {
            this.setState({
              loading: false,
              firstName: profileData.first_name,
              lastName: profileData.last_name,
              email: profileData.email,
              mobile: profileData.mobile_number,
              countryCode: profileData.country_code,
              //preferredLanguage: profileData.preferred_language,
              email_verifed:profileData.email_verifed,
              mobile_verifed:profileData.mobile_verifed,
            });
          }
        })
        .catch((err) => {
          this.setState({
            loading: false,
          });
          if(err.response.data.status == 'Token is Expired' || err.response.data.status == 'Token is Invalid' || err.response.data.status == 'Authorization Token not found') {
            localStorage.clear();
            this.props.history.push('/login', { expiredError: true });
          }
        });
    }
  }

  updateUserProfile () {
    const URL = BASE_URL_V4 + API_URL.getUserProfile;
    const payloadObject = {
      // iso: 'IN',
      // country_code: this.state.countryCode,
      first_name: this.state.firstName,
      last_name: this.state.lastName,
      preferred_language: this.props.langCode,
    };

    axiosInstance
      .patch(URL, payloadObject)
      .then(() => {
        this.setState({
          editName: false,
        });
      })
      .catch((err) => {
        console.log('\n\n\nError----------->>>>', err.response);
        if (err.response.data.first_name) {
          alert(err.response.data.first_name);
        }
        if (err.response.data.last_name) {
          alert(err.response.data.last_name);
        }
      });
  }

  changeFirstname (e) {
    this.setState({
      firstName: e.target.value,
    });
  }

  changeLastname (e) {
    this.setState({
      lastName: e.target.value,
    });
  }

  mailTo () {
    window.open('mailto:enquiry@visava.in');
  }

  logoutAgree () {
    this.setState({
      logOutPopUp: true
    });
  }

  logout () {

    const URL = BASE_URL_V4 + API_URL.logout;

    axiosInstance
      .get(URL)
      .then(() => {
        localStorage.clear();
        this.props.history.push('/');
      })
      .catch((err) => {
        console.log(err);
      });
  }

  getHelp () {
    this.props.history.push('/faq');
  }

  goToVideo () {
    const langCode = localStorage.getItem('lang');
    if( langCode === 'en') {
      windowOpenHandler(YOUTUBE_LINKS.how_to_use_visava_english);
    } else {
      windowOpenHandler(YOUTUBE_LINKS.how_to_use_visava_hindi);
    }
  }

  verifyEmailPhone () {
    const { history } = this.props;
    const { mobile_verifed }=this.state;
    history.push('/my-profile/verify-email-phone', { emailorphone:mobile_verifed });
  }

  verifyEmailPhone1 () {
    const { history } = this.props;
    const { email_verifed }=this.state;
    history.push('/my-profile/verify-email-phone', { emailorphone:!email_verifed });
  }

  inludeBoth () {
    this.setState({
      both: true,
    });
  }

  getCroppedImg () {
    const canvas = document.createElement('canvas');
    const scaleX = this.state.image.naturalWidth / this.state.image.width;
    const scaleY = this.state.image.naturalHeight / this.state.image.height;
    canvas.width = this.state.crop.width;
    canvas.height = this.state.crop.height;
    const ctx = canvas.getContext('2d');

    if(this.state.crop.width==0 &&
      this.state.crop.height==0) {
      this.setState({ pictureasFile:'' });
      return 'NULL';
    }
    ctx.drawImage(
      this.state.image,
      this.state.crop.x * scaleX,
      this.state.crop.y * scaleY,
      this.state.crop.width * scaleX,
      this.state.crop.height * scaleY,
      0,
      0,
      this.state.crop.width,
      this.state.crop.height
    );
    return canvas.toDataURL('image/jpeg');
  }

  saveImage (event) {
    if (event.target.files && event.target.files[0]) {
      // console.log('>>>>>.', event.target.files[0].size);
      this.setState({ ...this.state,
        pictureasFile:URL.createObjectURL(event.target.files[0]),
        showCropModal: true
      });
      //this.fetchProfileImage();
    }
  }

  updatProfileImage () {
    const jwtToken = localStorage.getItem('jwtToken');
    //const userId = localStorage.getItem('userId');
    // const url2 = `${BASE_URL_V4 + API_URL.getUserProfileImage}/${userId}` ;
    const URL = `${BASE_URL_V4 + API_URL.getUserProfileImage}`;
    const imageData =this.getCroppedImg();

    // if(imageData=='NULL') {
    //   this.fetchProfilePicture();
    //   return;
    // }

    function dataURLtoFile (dataurl, filename) {

      // eslint-disable-next-line prefer-const
      let arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

      // eslint-disable-next-line no-const-assign
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }

      return new File([u8arr], filename, { type:mime });
    }

    const file = dataURLtoFile(imageData, 'hello.txt');
    
    const formdata = new FormData();
    formdata.append(
      'profile_image_original',
      //event.target.files[0],
      file
    );

    const axiosConfig = {
      headers:  { 'Authorization':   `Bearer  ${jwtToken}`,
        'content-type':'multipart/form-data' }
    };

    axiosInstance.post(
      URL,
      formdata,
      axiosConfig ).then(() => {
      //this.setState({ pictureasFile:'' });
      this.setState({
        profileImage: this.state.profileImage,
      });
      this.fetchProfilePicture();
    })
      .catch(() => {
        // console.log('\n\n\nError----------->>>>', err.response);
      });
  }

  render () {
    const { t, langCode } = this.props;
    const { loading, firstName, logOutPopUp, lastName, email, mobile, email_verifed, mobile_verifed } = this.state;
    let setValueA = '';
    let setValueB = '';
    if (email_verifed ==1 && mobile_verifed==1) {
      setValueA = mobile;
      setValueB = email;
    }
    // else {
    //   if (mobile_verifed==0 && email_verifed==0) {
    //     setValueA = t('add_phone_number');
    //     setValueB = t('add_email_id');
    //   } else if(mobile_verifed==1 && email_verifed==0) {
    //     setValueA = mobile;
    //     setValueB = t('add_email_id');
    //   } else if(mobile_verifed==0 && email_verifed==1) {
    //     setValueA = t('add_phone_number');
    //     setValueB = email;
    //   }
    // }
    return loading ? (
      <Loader isLoading={loading} />
    ) : (
      <div className='banner-container-top'>
        <div className='user-profile'>
          <div className='image-container'>
            {!this.state.editName ? (
              <div
                className='edit-wrapper'>
                <div className='edit-image-wrapper button-pointer'
                  onClick={() => this.setState({ editName: true })}>
                  <img className='edit-img'
                    src={EditImg}
                    alt='' />
                </div>
                <div className='edit-text button-pointer'
                  onClick={() => this.setState({ editName: true })}>{t('edit')}</div>
              </div>
            ) : (
              <div className='edit-wrapper'
                onClick={() => this.updateUserProfile()}>
                <div className='edit-image-wrapper button-pointer'>
                  <img className='edit-img'
                    src={EditImg}
                    alt='' />
                </div>
                <div className='edit-text button-pointer'>{t('save_button')}</div>
              </div>
            )}
            
            <div className='profile-image-container'>
              <div className='profile-image-wrapper'>
                <div className='user-image'>
                  {/* <img src={DefaultUserImg} /> */}
                  <img src={this.state.profileImage}
                    alt='' />
                </div>
                <div className='camera-image-wrapper'>
                  {/*<img className='camera-image'
                    src={CameraImg}
                    alt='' />*/}
                  <input type='file'
                    id='img'
                    accept='image/*'
                    style={{ display:'none' }}
                    onChange={(e) => {
                      this.saveImage(e);
                    }} />
                  <label htmlFor='img'>
                    <img className='camera-image button-pointer'
                      src={CameraImg}
                      alt='' />
                  </label>
                </div>
              </div>
              <div className='profile-name-number'>{!this.state.editName ? (
                `${firstName} ${lastName}`
              ) : (
                <div className='edit-name-input'>
                  <input
                    type='text'
                    value={this.state.firstName}
                    onChange={this.changeFirstname}
                    className='update-name' />
                  {/* onKeyPress="this.style.width = ((this.value.length + 1) * 8) + 'px';"  */}
                  <input
                    type='text'
                    value={this.state.lastName}
                    onChange={this.changeLastname}
                    className='update-name' />
                </div>
              )}</div>
              <div className='profile-name-number spacing'>{email || mobile}</div>
              {/* <div className='profile-name-number spacing2'
                onClick={this.verifyEmailPhone}>{`${setValueA}`}</div>
              <div
                className='profile-name-number spacing2'
                onClick={this.verifyEmailPhone1}>{`${setValueB}`}</div> */}
              {mobile_verifed ?
                <div className='profile-name-number spacing1'>{`${setValueA}`}</div>
                : <div className='profile-name-number spacing2 button-pointer'>{`${setValueA}`}</div>
                    //onClick={this.verifyEmailPhone}>{`${setValueA}`}</div>
            }
              {email_verifed ?
                <div className='profile-name-number spacing1'>{`${setValueB}`}</div>
                : <div className='profile-name-number spacing2 button-pointer'>{`${setValueB}`}</div>
                    //onClick={this.verifyEmailPhone1}>{`${setValueB}`}</div>
            }
              {/* {both ?
                <div>
                  <div className='profile-name-number spacing1'>{`${setValueA}`}</div>
                  <div className='profile-name-number spacing1'>{`${setValueB}`}</div></div>
                :
                // <div className='profile-name-number spacing2'
                //   onClick={this.verifyEmailPhone}>{`${setValueB}`}</div>
                <div>
                  <div className='profile-name-number spacing2'
                    onClick={this.verifyEmailPhone}>{`${setValueA}`}</div>
                  <div
                    className='profile-name-number spacing2'
                    onClick={this.verifyEmailPhone1}>{`${setValueB}`}</div>
                </div>
             } */}
            </div>
          </div>
          <div className='link-container button-pointer'
            // onClick={() => this.setState({ showModal: false })}
            onClick={this.goToVideo}>
            {/********* set showModel true once youtube video are updated******/}
            <div className='icon-wrapper'
              onClick={this.goToVideo}>
              <img className='icon'
                src={YoutubeIcon}
                alt='' />
            </div>
            <div className='description'>{t('watch_how_visava_works')}</div>
          </div>
          <div className='bottom-line' />
          <div className='link-container button-pointer'
            onClick={this.viewSharePopUp}>
            <div className='icon-wrapper'>
              <img className='icon'
                src={HandShakeIcon}
                alt='' />
            </div>
            <div className='description'>{t('invite_friend')}</div>
          </div>
          <div className='bottom-line' />
          <div className='link-container button-pointer'
            onClick={this.getHelp}>
            <div className='icon-wrapper'>
              <img className='icon'
                src={HelpImg}
                alt='' />
            </div>
            <div className='description'>{t('help')}</div>
          </div>
          <div className='bottom-line' />
          <div className='link-container button-pointer'>
            <div className='icon-wrapper'>
              <img className='icon'
                src={EmailImg}
                alt='' />
            </div>
            <div className='description'>
              {langCode == 'en' ?
                <><div>{t('write_us_to_at')}{' '}</div><span className='email'
                  onClick={this.mailTo}>
                  {CONTACT_DETAILS.email}
                </span></> : <><span className='email'
                  onClick={this.mailTo}>
                  {CONTACT_DETAILS.email}
                </span><div>{t('write_us_to_at')}{' '}</div></>}
            </div>
          </div>
          <div className='bottom-line' />
          <div className='link-container button-pointer'
            onClick={this.endUserFeedBackForm}>
            <div className='icon-wrapper'>
              <img className='icon'
                src={FeedbackForm}
                alt='' />
            </div>
            {langCode === 'en' ?
              <div className='description'>{this.capitalize('SHARE FEEDBACK')}</div> :
              <div className='description'>{t('share_feedback')}</div> }
          </div>
          <div className='bottom-line' />
          <div className='link-container button-pointer'
            onClick={this.logoutAgree}>
            <div className='icon-wrapper'>
              <img className='icon'
                src={LogoutImg}
                alt='' />
            </div>
            <div className='description'>{t('logout')}</div>
            <div>{logOutPopUp &&
              <ErrorPopup shown={logOutPopUp}
                paymentStatus={'FAILURE'}
                close={() => this.setState({ logOutPopUp:false })}
                closeOk={() => this.setState({ logOutPopUp:false }), this.logout}
                message={t('logout_statement')}
                googleMaps={false}
                buttonLabel={t('logout')}
                feedBackButton={false} />}
            </div>
          </div>
        </div>
        {/* {this.state.showModal ? (
          <YouTubeModal
            shown={this.state.showModal}
            close={() =>
              this.setState({
                showModal: false,
              })
            }
            youTubeLink={YOUTUBE_LINKS.how_visava_works} />
        ) : null} */}
        {this.state.showSharePopUp &&
          <SharePopUp closeSharePopUp={this.closeSharePopUp}
            text={t('string_invite_friend_desc')}
            shareUrl={SHARE_URL} />}
        {this.state.showCropModal && <div className='crop-container'>
          <div className='inner_wrapper'>
            <div className='crop-inner-container'>
              {this.state.showCropModal &&
                <ReactCrop src={this.state.pictureasFile}
                  crop={this.state.crop}
                  onChange={(crop) => this.setState({ crop })}
                  onImageLoaded={(image) => this.setState({ image })} />}
            </div>
            <div className='btn-area'>
              <button className='visava-btn crop-button button-pointer'
                onClick={() => this.updatProfileImage()}>
                {t('apply')}
              </button>
              <button className='visava-btn cancel-button button-pointer'
                onClick={() =>
                  this.setState({
                    showModal: false,
                    showCropModal: false
                  })} >
                {t('cancel')}
              </button>
            </div>
          </div>
        </div>}
      </div>
    );
  }
}

/***** reading value of breakpoint from redux ****/
const mapStateToProps = (state) => ({
  langCode: getLangCodeSelector(state)
});

export default connect(mapStateToProps)(withTranslation()(withRouter(MyProfile)));
