/* eslint-disable no-console */
/* eslint-disable react/prop-types */
//import { BASE_URL_V4, API_URL, SHARE_URL } from '../../../api/api-config';
import { BASE_URL_V4, API_URL } from '../../../api/api-config';
import { connect } from 'react-redux';
import { setInitialPayment } from '../../redux/actions/initiatePayment';
import { axiosInstance } from '../../../api/coreApi';
import { setDMHRedirection } from '../../../utility/common';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import BlueTickIcon from '../../../asset/images/blue_tick_icon.svg';
import NotifyIcon from '../../../asset/images/notify_me_icon.svg';
// import LocationIcon from '../../../asset/images/location_icon.svg';

class ErrorPopup extends Component {
  constructor (props) {
    super(props);
    //this.checkPaymentStatus=this.checkPaymentStatus.bind(this);
    //this.initiatePayment=this.initiatePayment.bind(this);
    this.state = {
      data: {},
      showPayment: this.props.showPayment,
      paymentDone: this.props.paymentDone,
      feedBackButton: this.props.feedBackButton
    };
  }

  componentDidMount () {
    // if(this.state.showPayment && !this.state.paymentDone) {
    //   const tempOrderId = localStorage.getItem('orderId');
    //   if(tempOrderId) {
    //     this.checkPaymentStatus(tempOrderId);
    //   }else{
    //     this.initiatePayment();
    //   }
    // }
  }


  initiatePayment () {
    const url = BASE_URL_V4 + API_URL.payment;
    const object = {
      os_type: 'WEB',
      //call_back_url : `${SHARE_URL}homes`,
    };

    axiosInstance.post(url, object).then((response) => {
      if (response && response.data && response.data.data) {
        const data = response && response.data && response.data.data;
        const formData = {
          url: data.url,
          mid: data.mid,
          orderId: data.order_id,
          transactionToken: data.paytm_txn_token
        };
        localStorage.setItem('orderId', data.order_id);
        this.setState({
          data: formData
        });
      }
    }).catch((err) => {
      console.log('\n\nError in payment------>>', err && err.response && err.response.data);
    });
  }

  checkPaymentStatus (tempOrderId) {
    const url = BASE_URL_V4 + API_URL.paymentStatus;
    const object = {
      order_id: tempOrderId
    };

    axiosInstance.post(url, object).then((response) => {
      if (response && response.data) {
        const data = response && response.data;
        if(data.status=='SUCCESS') {
          this.setState({
            paymentDone: true
          });
          localStorage.removeItem('orderId');
          setDMHRedirection();
          this.props.history.push('/design-my-house');
        }else{
          //this.initiatePayment();
        }
      }
    }).catch((err) => {
      console.log('\n\nError in payment------>>', err && err.response && err.response.data);
    });
  }

  render () {
    const { t } = this.props;
    const { data, showPayment, feedBackButton } = this.state;
    return this.props.shown ? (
      <div>
        <div
          className='error-popup-container'
          onClick={() => {
            this.props.close();
          }}>
          <div
            className='error-popup-inner-container'
            onClick={(e) => {
              e.stopPropagation();
            }}>
            <div className='close-icon'
              onClick={() => {
                this.props.close();
              }} />
            {this.props.paymentStatus === 'FAILURE' || this.props.paymentStatus === 'PENDING' ? '' :
            <div className='icon'>
              <img src={BlueTickIcon}
                alt='error icon' />
            </div>}
            {this.props.googleMaps === false ? '' :
            <div className='icon'>
              <img src={NotifyIcon}
                alt='error icon' />
            </div>}
            <div className='error-text'>{this.props.message}</div>
            <div className='error-text'>{this.props.anotherMessage}</div>
            {showPayment ?
              <form method='post'
                action={data?.url}
                name='paytm'>
                <table border='1'>
                  <tbody>
                    <tr>
                      <td>
                        <input type='hidden'
                          name='mid'
                          value={data?.mid} /></td>
                      <td><input type='hidden'
                        name='orderId'
                        value={data?.orderId} /></td>
                      <td><input type='hidden'
                        name='txnToken'
                        value={data?.transactionToken} /></td>
                    </tr>
                  </tbody>
                </table>
                <input className='pay-now-button'
                  type='submit'
                  value={t('pay_now_button')} />
              </form> :
              <div>
                {!feedBackButton ?
                  <div className='ok-button'
                    onClick={() => {
                      this.props.closeOk();
                    } }>{t(this.props.buttonLabel)}
                  </div> :
                  <>
                    <div className='ok-button'
                      onClick={() => {
                        this.props.formOpen();
                      } }>{t(this.props.buttonLabel)}
                    </div>
                    <div className='ok-button-feed-back'
                      onClick={() => {
                        this.props.formPostponded();
                      } }>{t(this.props.buttonLabelSecond)}
                    </div>
                  </> }
              </div>}
          </div>
        </div>
      </div>) : null;
  }
}


const mapDispatchToProps = (dispatch) => ({
  setInitialPayment: (data) => {
    dispatch(setInitialPayment(data));
  },
});

const mapStateToProps = (state) => ({
  payParams: (state.homeDetailsState && state.homeDetailsState.payParams) || {},
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ErrorPopup));
