export const UPDATE_BREAKPOINT = 'UPDATE_BREAKPOINT';
export const UPDATE_LANG_CODE = 'UPDATE_LANG_CODE';

export const updateBreakPoint = (data) => ({
  type: UPDATE_BREAKPOINT,
  payload: data
});

export const updateLangCode = (data) => ({
  type: UPDATE_LANG_CODE,
  payload: data
});