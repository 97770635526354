/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import './errorScreens.scss';
//import UnableToConnect from '../../../asset/images/unable_to_connect.svg';
//import ErrorImg from '../../../asset/images/error_img.svg';
import BrokenLink from '../../../asset/images/broken_link.svg';
import LogoWithText from '../logoWithText';

class ErrorScreens extends Component {
  constructor (props) {
    super(props);
  }

  render () {
    return (
      <div className='error_container'>
        <div className='logo_wrapper'><LogoWithText /></div>
        <div className='inner_wrapper'>
          <div className='error_img'>
            <img src={BrokenLink} />
          </div>
          <div className='error_title'>
            {this.props.errorTitle}
            {/* Unable to Connect */}
          </div>
          <div className='error_sub_title'>
            {this.props.sitemessage}
            {/* You have slow or no internet connection. Please check your internet connection and try again */}
          </div>
          <div className='error_cta'>
            {/* <div className='visava-btn'>RETRY</div> */}
            <div className='visava-btn'
              onClick={() => {
                this.props.closefunction();
              }}>{this.props.errorButtonLabel}</div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(withRouter(ErrorScreens));