import React, { Component } from 'react';
// eslint-disable-next-line no-unused-vars
// eslint-disable-next-line no-unused-vars
import BomPage from '../../client/components/bomPage';

class bomSampleDocument extends Component {
  render () {
    return (
      <>
        <BomPage />
      </>
    );
  }
}

export default bomSampleDocument;