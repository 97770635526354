import {
  FETCH_HEALTH_CHECK,
} from '../actions/healthCheckAction';
    
const INITIAL_STATE = {};
    
// eslint-disable-next-line default-param-last
const healthCheck = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_HEALTH_CHECK:
      return {
        ...state,
        healthCheck: action && action.data,
      };
    default:
      return { ...state };
  }
};
    
export default healthCheck;
    