import React from 'react';
import Home from './home/index.js';
import { withRouter } from 'react-router-dom';

class Dashboard extends React.Component {

  constructor (props) {
    super(props);
    this.state = {};
  }

  render () {
    return (
      <div>
        <Home {...this.props} />
      </div>
    );
  }
}

export default withRouter(Dashboard);
