/* eslint-disable no-console */
/* eslint-disable react/prop-types */
import { React, Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import './bompage.scss';
import Logo from '../../../../src/asset/images/VisavaLogo.png';
import ShareIcon from '../../../asset/images/share_icon.svg';
import PrintIcon from '../../../asset/images/print_icon.svg';
import UserIcon from '../../../asset/images/user_icon.svg';
import ContactIcon from '../../../asset/images/contact_icon.svg';
import DateIcon from '../../../asset/images/date_icon.svg';
import MailIcon from '../../../asset/images/mail_icon.svg';
import FloorPlan2D from '../../../asset/images/floor_design_2d.svg';
//import RefreshIcon from '../../../asset/images/refresh_icon.svg';
//import PlusIcon from '../../../asset/images/plus.svg';
//import MinusIcon from '../../../asset/images/minus.svg';
import InfoIcon from '../../../asset/images/info_icon.svg';
import InfoWhiteIcon from '../../../asset/images/info_icon_white.svg';
import MobileIcon from '../../../asset/images/mobile_phone.svg';
import GlobeIcon from '../../../asset/images/globe_icon.svg';
import DownArrowIcon from '../../../asset/images/down_arrow_icon.svg';
import EnvelopeIcon from '../../../asset/images/envelope.svg';
import FacebookIcon from '../../../asset/images/facebook.svg';
import TwitterIcon from '../../../asset/images/twitter.svg';
import InstagramIcon from '../../../asset/images/instagram.svg';
import LinkedinIcon from '../../../asset/images/linkedin.svg';
import GooglePlayBadgeEn from '../../../asset/images/GooglePlayBadgeEn.png';
import GooglePlayBadgeMa from '../../../asset/images/GooglePlayBadgeMa.png';
import GooglePlayBadgeHi from '../../../asset/images/GooglePlayBadgeHi.png';
import qr_code from '../../../asset/images/websiteQRCode.svg';
import { BASE_URL_V4, API_URL, CONTACT_DETAILS, BLOG_URL, GOOGLE_PLAY_URL, SOCIAL_LINKS, FEED_BACK_FORMS } from '../../../api/api-config';
import { axiosInstance } from '../../../api/coreApi';
//import floorPlan from '../../../asset/images/FloorPlan2.svg';
import floorPlanEn from '../../../asset/images/FloorPlanEn.svg';
import floorPlanHi from '../../../asset/images/FloorPlanHi.svg';
import floorPlanMr from '../../../asset/images/FloorPlanMr.svg';
import Firework from '../../../asset/images/firework.svg';
import PriceMoney from '../../../asset/images/price_money.svg';
import CopyrightIcon from '../../../asset/images/copyright_icon.svg';
import WebsiteIcon from '../../../asset/images/website_icon.svg';
import UpArrow from '../../../asset/images/up_arrow_white.svg';
//import NorthDirection from '../../../asset/images/north_icon.svg';
import SampleDocImg1 from '../../../asset/images/SampleDocImg1.svg';
import SampleDocImg2 from '../../../asset/images/SampleDocImg2.svg';
//import SampleDocImg3 from '../../../asset/images/SampleDocImg3.svg';
import BlogThreeImg from '../../../asset/images/blogThree.svg';
import FullScreenIcon from '../../../../src/asset/images/full_screen_icon.svg';
import TapToTouch from '../../../asset/images/TapToInteract.svg';
import SharePopUp from '../sharePopup';
import ErrorPopup from '../errorPopup';
import ErrorScreens from '../errorScreens';
import { connect } from 'react-redux';
import { changeLang } from '../../../i18n/i18n';
import { updateLangCode } from '../../redux/actions/headerState';
import { getLangCodeSelector } from '../../../selectors/commonSelectors';
import { windowOpenHandler } from '../../../utility/common';
import Loader from '../../shared-ui/Loader';
import { t } from 'i18next';


class BomPage extends Component {
  
  /*********   binding the functions  **********/
  constructor (props) {
    super(props);
    this.fetchProfileHouseDetails = this.fetchProfileHouseDetails.bind(this);
    this.preferToilet = this.preferToilet.bind(this);
    this.preferDirection = this.preferDirection.bind(this);
    this.unitLocalization = this.unitLocalization.bind(this);
    this.unitAreaLocalization = this.unitAreaLocalization.bind(this);
    this.viewSharePopUp = this.viewSharePopUp.bind(this);
    this.closeSharePopUp = this.closeSharePopUp.bind(this);
    this.preferFloor = this.preferFloor.bind(this);
    this.selectLang = this.selectLang.bind(this);
    this.redirectBlog1 = this.redirectBlog1.bind(this);
    this.redirectBlog2 = this.redirectBlog2.bind(this);
    this.redirectBlog3 = this.redirectBlog3.bind(this);
    //this.fetchHouseBom = this.fetchHouseBom.bind(this);
    this.getEvaluatedPrice = this.getEvaluatedPrice.bind(this);
    this.redirectHome = this.redirectHome.bind(this);
    this.costingTable = this.costingTable.bind(this);
    this.billOfMaterial = this.billOfMaterial.bind(this);
    this.showFullScreen=this.showFullScreen.bind(this);
    this.capitalize=this.capitalize.bind(this);
    this.printTo=this.printTo.bind(this);
    this.onLangClick=this.onLangClick.bind(this);
    this.goToBlog=this.goToBlog.bind(this);
    this.collapseBom=this.collapseBom.bind(this);
    this.mailTo=this.mailTo.bind(this);
    this.callTo=this.callTo.bind(this);
    this.updatePopUpStatus=this.updatePopUpStatus.bind(this);
    this.endUserFeedBackForm=this.endUserFeedBackForm.bind(this);
    this.updatePopUpStatusPaused=this.updatePopUpStatusPaused.bind(this);
    this.gotoFacebook=this.gotoFacebook.bind(this);
    this.gotoLinkedin=this.gotoLinkedin.bind(this);
    this.gotoInsta=this.gotoInsta.bind(this);
    this.gotoTwitter=this.gotoTwitter.bind(this);

    /*********   defining state variables  **********/
    this.state = {
      shareUrl: '',
      firstName: '',
      lastName: '',
      email: '',
      mobile: '',
      date: '',
      location: '',
      constructionArea: '',
      constructionArea1: '',
      budgetCurrency: '',
      unit: '',
      unitlength:'',
      budget: '',
      mainDoor: '',
      preferencesFloor: '',
      preferencesToilet: '',
      preferencesRooms: '',
      downloadTrigger: false,
      downloadCooling: '',
      scrollTrigger: false,
      scrollCooling: '',
      shareTrigger: false,
      shareCooling: '',
      shareThreeDTrigger: false,
      shareThreeDCooling: '',
      //threeDimensionModel: '',
      budgetSaved: '',
      showSharePopUpDoc: false,
      showSharePopUpModel: false,
      billOfMaterialTable: {},
      costTable: {},
      currency: '',
      //isValid: false,
      ctTotal: '',
      bomTotal: '',
      paymentStatus: '',
      showAvailablelang: false,
      houseId : '',
      share_token : '',
      preferred_language: 'en',
      language: '',
      savingAmount:'',
      sampleDocument: false,
      userAgent: '',
      brokenLink: false,
      collapse: false,
      expandedIndexes: new Set(),
      isLoading: false,
      collapseIndex: -1,
      docUrl:'',
      modelUrl:'',
      showErrorPopUp: false,
      errorMessage: '',
      errorMessageSecond: '',
      pageScrollPosition: null,
      triggerPoint: '',
      actionTaken: '',
      hideInfoTootTip: false,
      printActive: false,
      grandFinal: ''
    };
  }

  componentDidMount () {
    /*************  To render the page from top of the page everytime ***************/
    window.scrollTo({ top: 0 });

    // if(this.state.scrollTrigger) {
    //   this.setState({
    //     showErrorPopUp: true,
    //     errorMessage: t('design_doc_ready')
    //   });
    // }
    
    /*********  checking for language in local storage if null, Reading the param from query params url and setting the local storage state variables  **********/
    if(localStorage.getItem('lang') == null ) {
      localStorage.setItem('lang', 'en');
    }
    let language = '';
    let preferred_language = new URLSearchParams(window.location.search).get('preferred_language');
    if(preferred_language == 'en' || preferred_language == null) {
      language = 'ENGLISH';
      preferred_language = 'en';
      localStorage.setItem('lang', 'en');
    } else if(preferred_language == 'hi') {
      language = 'हिंदी';
      localStorage.setItem('lang', 'hi');
    } else {
      language = 'मराठी';
      localStorage.setItem('lang', 'mr');
    }
    if(localStorage.getItem('lang') == null ) {
      localStorage.setItem('lang', preferred_language);
    }

    /*********    Reading Strings and params from query params url and storing in state variables  **********/
    this.setState({
      isLoading: true,
      houseId : new URLSearchParams(window.location.search).get('house_id'),
      share_token : new URLSearchParams(window.location.search).get('share_token'),
      userAgent : new URLSearchParams(window.location.search).get('user_agent'),
      preferred_language : preferred_language,
      language : language,
      docUrl : new URLSearchParams(window.location.href),
      sampleDocument: window.location.toString().includes('sample-document'),
      pageScrollPosition: window.innerHeight,
    }, this.fetchProfileHouseDetails);

    // const isValid = localStorage.getItem('isValid');
    // if (isValid) {
    //   this.setState({ isValid: true });
    // } else {
    //   this.setState({ isValid: false });
    // }
  }

  /*********   function for rendering share component  **********/
  viewSharePopUp (isDoc) {
    if(isDoc) {
      this.setState({
        showSharePopUpDoc: true,
        showAvailablelang: false
      });
    } else {
      this.setState({
        showSharePopUpModel: true,
        showAvailablelang: false
      });
    }
  }

  /*********   Redirecting to end-user feedback form in the new tab  **********/
  endUserFeedBackForm () {
    this.setState({
      actionTaken: 'submitted',
      showErrorPopUp: false,
      showAvailablelang: false,
      showSharePopUpDoc: false,
      showSharePopUpModel: false
    }, this.updatePopUpStatusPaused);
    //setTimeout(window.print, 100);
    windowOpenHandler(FEED_BACK_FORMS.end_user_form);
  }


  /*********   Redirecting to 3D full screen  **********/
  showFullScreen () {
    if(this.state.userAgent === 'VisavaWebApp') {
      const { history } = this.props;
      history.push('/full-screen', { modelUrl : this.state.modelUrl });
    } else {
      windowOpenHandler(this.state.modelUrl);
    }
  }

  /*********  Redirecting to Visava blog with preferred lang    **********/
  goToBlog () {
    const langCode = localStorage.getItem('lang');
    if(langCode == 'en') {
      windowOpenHandler(`${BLOG_URL}`);
    } else {
      windowOpenHandler(`${BLOG_URL}/${langCode}`);
    }
  }

  /*********    function for formatting the string to have one letter in upper case and all other letters in lower case  **********/
  capitalize (str) {
    str = str.toLowerCase().split(' ');
    for (let i = 0; i < str.length; i++) {
      str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
    }
    return str.join(' ');
  }

  /*********    function for rounding the decimal to zero and formatting it into indian currency format  **********/
  costFormat (val) {
    val = !val ? 0 : val;
    val = val.toFixed(0);
    val = parseInt(val);
    return val.toLocaleString('en-IN');
  }

  /*********    Adding collapse feature to the costing table  **********/
  collapseBom (indexValue) {
    let updatedIndexex;
    if(this.state.expandedIndexes.has(indexValue)) {

      this.state.expandedIndexes.delete(indexValue);
      updatedIndexex =this.state.expandedIndexes;
    }else {
      this.state.expandedIndexes.add(indexValue);
      updatedIndexex =this.state.expandedIndexes;
    }
    this.setState({
      //collapse: !this.state.collapse,
      expandedIndexes : updatedIndexex
    });
  }


  /*********   function for setting the local Storage based on drop-down menu for language change  **********/
  selectLang (lang) {
    if(lang == 'ENGLISH') {
      localStorage.setItem('lang', 'en');
      changeLang('en');
    } else if (lang == 'हिंदी') {
      localStorage.setItem('lang', 'hi');
      changeLang('hi');
    } else{
      localStorage.setItem('lang', 'mr');
      changeLang('mr');
    }

    /*********   everytime language is set from the drop-down menu update and make all the following API calls  **********/
    // eslint-disable-next-line react/prop-types
    this.props.updateLangCode(lang);
    this.fetchProfileHouseDetails();
    this.setState({
      showAvailablelang: false,
      language:lang,
      showErrorPopUp: false
    });
  }

  /*********  function for hiding the drop-down menu  **********/
  onLangClick () {
    this.setState({
      showAvailablelang: !this.state.showAvailablelang,
    });
  }

  /*********  Redirecting to Visava web-app landing page    **********/
  redirectHome () {
    //this.props.history.push('/');
    const jwt = localStorage.getItem('jwtToken');
    if (jwt) {
      this.props.history.push('/homes');
    } else {
      this.props.history.push('/');
    }
    this.setState({
      showAvailablelang: false,
    });
  }

  /*********   function for redirect to specific blog  **********/
  redirectBlog1 () {
    const langCode = localStorage.getItem('lang');
    if(langCode == 'en') {
      windowOpenHandler(`${BLOG_URL}/the-state-of-housing-in-india/`);
    } else {
      //windowOpenHandler(`${BOM_BLOG1}`);
      windowOpenHandler(`${BLOG_URL}/${langCode}/the-state-of-housing-in-india/`); //use this url once blog specific url with lang starts working
    }
  }

  /*********   function for redirect to specific blog  **********/
  redirectBlog2 () {
    const langCode = localStorage.getItem('lang');
    if(langCode == 'en') {
      windowOpenHandler(`${BLOG_URL}/housing-schemes-in-india/`);
    } else {
      windowOpenHandler(`${BLOG_URL}/${langCode}/housing-schemes-in-india/`);
      //windowOpenHandler(`${BOM_BLOG2}/${langCode}`); //use this url once blog specific url with lang starts working
    }
  }

  /*********   function for redirect to specific blog  **********/
  redirectBlog3 () {
    const langCode = localStorage.getItem('lang');
    if(langCode == 'en') {
      windowOpenHandler(`${BLOG_URL}/the-right-to-adequate-housing/`);
    } else {
      windowOpenHandler(`${BLOG_URL}/${langCode}/the-right-to-adequate-housing/`);
      //windowOpenHandler(`${BOM_BLOG3}/${langCode}`); //use this url once blog specific url with lang starts working
    }
  }

  getEvaluatedPrice (price) {
    return price ? `${price} ${this.state.currency}` : '';
  }

  mailTo () {
    this.setState({
      showAvailablelang: false,
    });
    window.open('mailto:enquiry@visava.in');
  }

  callTo () {
    this.setState({
      showAvailablelang: false,
    });
    window.open('tel:{+918530698999}');
  }

  /*********   function for opening the default system print function  **********/
  printTo () {
    this.state.expandedIndexes.clear();
    const updatedIndexed = this.state.expandedIndexes;
    this.setState({
      showAvailablelang: false,
      expandedIndexes: updatedIndexed,
      hideInfoTootTip: true,
      printActive: true
    });
    setTimeout(window.print, 500);
    /*********   sub-state change inside the function for opening the feed-back form and setting the trigger point from where feed-back has initiated  **********/

    if(this.state.downloadTrigger && this.state.downloadCooling == null ) {
      setTimeout(() => {
        this.setState({
          triggerPoint: 'download',
          hideInfoTootTip: false,
          showErrorPopUp: true,
          errorMessage: t('design_doc_downloaded'),
          errorMessageSecond: t('share_design_doc_popup_second_para')
        }, this.fetchProfileHouseDetails);
      }, 1000);
    } else {
      setTimeout(() => {
        this.setState({
          hideInfoTootTip: false,
          printActive: false
        });
      }, 1000);
    }
  }

  /*********   function for closing the share pop-up  **********/
  closeSharePopUp (isDoc) {
    if(isDoc) {
      this.setState({
        showSharePopUpDoc: false,
        showAvailablelang: false,
      });
      /*********   sub-state change inside the function for opening the feed-back form and setting the trigger point from where feed-back has initiated for share document **********/
      if(this.state.shareTrigger && this.state.shareCooling == null) {
        this.setState({
          showErrorPopUp: true,
          triggerPoint: 'share-document',
          errorMessage: t('share_design_doc_popup'),
          errorMessageSecond: t('share_design_doc_popup_second_para')
        }, this.fetchProfileHouseDetails );
      }
    } else {
      if(this.state.shareThreeDTrigger && this.state.shareThreeDCooling == null) {
        this.setState({
          showSharePopUpModel: false,
          showAvailablelang: false,
          showErrorPopUp: true,
          triggerPoint: 'share-3d-model',
          errorMessage: t('share_design_doc_popup'),
          errorMessageSecond: t('share_design_doc_popup_second_para')
        }, this.fetchProfileHouseDetails);
      } else {
        this.setState({
          showSharePopUpModel: false,
          showAvailablelang: false,
          showSharePopUpDoc: false
        });
      }
    }
  }

  /*********   function for making house details and user details API call based on if its sample-document or design-document  **********/
  fetchProfileHouseDetails () {
    if(this.state.sampleDocument) {
      localStorage.setItem('sample-doc', 'sample-doc');
    } else {
      localStorage.removeItem('sample-doc');
    }
    //console.log(this.state.pageScrollPosition, 'position of scroll');
    let url = '';
    if(this.state.sampleDocument) {
      /*********   url for sample-document  **********/
      url = `${BASE_URL_V4 + API_URL.userHouseProfile}?house_id=1&share_token=ABC1234567890&preferred_language=${localStorage.getItem('lang')}`;
    } else{
      /*********  url for design-document   **********/
      url = `${BASE_URL_V4 + API_URL.userHouseProfile}?house_id=${this.state.houseId}&share_token=${this.state.share_token}&preferred_language=${localStorage.getItem('lang')}`;
    }
    
    axiosInstance
      .get(url)
      .then((resp) => {
        const userData = resp && resp.data && resp.data.data.user_info;
        const houseData = resp && resp.data && resp.data.data.house_info;
        //const docUrl = resp && resp.data && resp.data.data.design_document_url;
        // this.setState({
        //   docUrl:docUrl,
        // });
        if (userData) {
          this.setState({
            fullName: userData.name,
            email: userData.email || 'N/A',
            mobile: userData.mobile_number || 'N/A',
          });
        }
        if (houseData) {
          const paymentStatus = houseData.house_payment;
          this.setState({
            
            budget: houseData.budget.toLocaleString('en-IN'),
            budgetSaved: houseData.house_cost_savings,
            date: houseData.house_created_date,
            location: houseData.location,
            mainDoor: houseData.main_door_location,
            paymentStatus: houseData.house_payment,
            currency: houseData.cost_currency,
            savingAmount: houseData.minimum_house_cost_savings_amount,
            modelUrl: houseData.model_url,
            grandFinal: houseData.house_cost,
          });
          const constructionDetails = houseData.construction_area;
          this.setState({
            constructionArea: constructionDetails.length_a,
            constructionArea1: constructionDetails.length_b,
            plinthArea: constructionDetails.total_plinth_area,
            unit: constructionDetails.unit_area,
            unitlength: constructionDetails.unit_length,
          });
          const floorRoomPreferences = houseData.preferences;
          this.setState({
            preferencesFloor: floorRoomPreferences.floors,
            preferencesToilet: floorRoomPreferences.toilet,
            preferencesRooms: floorRoomPreferences.room
          });
          const userFeedBackStatus = houseData.user_feedback_popup_status;
          this.setState({
            downloadTrigger: userFeedBackStatus.download_trigger.show_popup_status,
            downloadCooling: userFeedBackStatus.download_trigger.cooling_timestamp,
            scrollTrigger: userFeedBackStatus.scroll_trigger.show_popup_status,
            scrollCooling: userFeedBackStatus.scroll_trigger.cooling_timestamp,
            shareTrigger: userFeedBackStatus.share_document_trigger.show_popup_status,
            shareCooling: userFeedBackStatus.share_document_trigger.cooling_timestamp,
            shareThreeDTrigger: userFeedBackStatus.share_3d_model_trigger.show_popup_status,
            shareThreeDCooling: userFeedBackStatus.share_3d_model_trigger.cooling_timestamp
          });
          if(paymentStatus) {
            this.costingTable();
            this.billOfMaterial();
          }
        }

        /*********   sub-state change inside the function for opening the feed-back form and setting the trigger point from where feed-back has initiated  **********/
        if(this.state.scrollTrigger && this.state.scrollCooling == null) {
          this.setState({
            showErrorPopUp: true,
            triggerPoint: 'scroll',
            errorMessage: t('design_doc_ready')
          });
        }
      })
      .catch((err) => {
        if (err && err.response) {
          const errData = err.response && err.response.data;
          if (errData && errData.status && errData.status.toLowerCase() == 'failed') {
            this.setState({
              brokenLink: true,
            });
          }
        }
      });
  }

  updatePopUpStatus () {
    if(this.state.actionTaken !== 'submitted') {
      this.setState({
        showErrorPopUp:false,
        actionTaken: 'maybe-later',
        showAvailablelang: false,
        showSharePopUpDoc: false
      }, this.updatePopUpStatusPaused);
    }
  }

  /*********  function for making an post API call for setting feed-back form status with object as triggerPoint and actionTaken  **********/
  updatePopUpStatusPaused () {
    const object = {
      trigger : this.state.triggerPoint,
      action : this.state.actionTaken
    };

    const url = BASE_URL_V4 + API_URL.feedBackStatus + this.state.houseId;
    axiosInstance.post(url, object).then((response) => {
      if (response && response.data && response.data.data) {
        //const data = response && response.data && response.data.data;
        this.setState({
          showErrorPopUp:false,
          showAvailablelang: false,
          showSharePopUpDoc: false
        }, this.fetchProfileHouseDetails);
      }
    }).catch((err) => {
      //console.log('\n\nError in payment------>>', err && err.response && err.response.data);
      if (err && err.response) {
        const errData = err.response && err.response.data;
        if (errData && errData.status && errData.status.toLowerCase() == 'failed') {
          const { t }=this.props;
          this.setState({
            errorMessage: t(''),
            buttonLabel: t('close_button'),
            showErrorPopUp: false
          });
        }
      }
    });
  }

  /*********  Adding localization to display toilet value    **********/
  preferToilet (preferencesToilet) {
    const result = preferencesToilet == 1 ? t('yes') : t('no');
    return result;
  }

  /*********  Adding localization to display unit value    **********/
  unitLocalization (unitlength) {
    const result = unitlength == 'feet' ? t('feet') : t('meter');
    return result;
  }

  /*********  Adding localization to display unit area value    **********/
  unitAreaLocalization (unit) {
    const result = unit == 'square feet' ? t('square_feet') : t('square_meter');
    return result;
  }

  /*********  Adding localization to display Direction value    **********/
  preferDirection (mainDoor) {
    const result = mainDoor == 'NORTH' ? t('north') : mainDoor == 'EAST' ? t('east') : mainDoor == 'WEST' ? t('west') : t('south');
    return result;
  }

  /*********  Adding localization to display Floor of house value    **********/
  preferFloor (preferencesFloor) {
    return preferencesFloor === 0 ? t('ground') : `${t('ground')} + ${preferencesFloor}`;
  }


  /*********   function for making costing table details API call based on if its sample-document or design-document  **********/
  costingTable () {
    let url = '';
    if(this.state.sampleDocument) {
      /*********   url for sample-document  **********/
      url = `${BASE_URL_V4 + API_URL.sampleBomCosting}?house_id=1&share_token=ABC1234567890&preferred_language=${localStorage.getItem('lang')}`;

    } else{
      /*********   url for Design-document  **********/
      url = `${BASE_URL_V4 + API_URL.houseCosting}?house_id=${this.state.houseId}&share_token=${this.state.share_token}&preferred_language=${localStorage.getItem('lang')}`;
    }
    axiosInstance.get(url).then((resp) => {
      const response = resp.data.data;
      const result = [];
      const subFeatures = [];

      if (response.features) {
        response.features.forEach((item) => {
          // eslint-disable-next-line no-prototype-builtins
          if (!result.hasOwnProperty(item.feature)) {
            result[item.feature] = [];
          }
          if (subFeatures.includes(item.sub_feature)) {
            item.sub_feature = '';
          } else {
            subFeatures.push(item.sub_feature);
          }
          result[item.feature].push(item);
        });
        this.setState({
          costTable: result,
          isLoading: false
        });
      }
      this.setState({
        ctTotal: response.total_cost,

      });
    });
  }

  /*********   function for making bill of material table details API call based on if its sample-document or design-document  **********/
  billOfMaterial () {
    let url = '';
    if(this.state.sampleDocument) {
      /*********   url for sample-document  **********/
      url = `${BASE_URL_V4 + API_URL.sampleBom}?house_id=1&share_token=ABC1234567890&preferred_language=${localStorage.getItem('lang')}`;
    } else{
      /*********   url for design-document  **********/
      url = `${BASE_URL_V4 + API_URL.houseBom}?house_id=${this.state.houseId}&share_token=${this.state.share_token}&preferred_language=${localStorage.getItem('lang')}`;
    }
    axiosInstance.get(url).then((resp) => {
      const response = resp.data.data;
      const result = [];
      if (response.features) {
        response.features.forEach((item) => {
          // eslint-disable-next-line no-prototype-builtins
          if (!result.hasOwnProperty(item.feature)) {
            result[item.feature] = [];
          }
          result[item.feature].push(item);
        });
        this.setState({
          billOfMaterialTable: result,
          isLoading: false
        });
      }
      this.setState({
        bomTotal: response.total_cost
      });
    });
  }

  gotoFacebook () {
    windowOpenHandler(`${SOCIAL_LINKS.facebook}`);
  }

  gotoInsta () {
    windowOpenHandler(`${SOCIAL_LINKS.instagram}`);
  }

  gotoLinkedin () {
    windowOpenHandler(`${SOCIAL_LINKS.linkedin}`);
  }

  gotoTwitter () {
    windowOpenHandler(`${SOCIAL_LINKS.twitter}`);
  }


  render () {
    //eslint-disable-next-line react/prop-types
    const { t, langCode } = this.props;
    const { fullName, email, mobile, userAgent, sampleDocument, date, location, ctTotal, grandFinal, bomTotal, constructionArea, constructionArea1, unit, unitlength, budget, mainDoor, preferencesFloor, budgetSaved, preferencesToilet, showAvailablelang, savingAmount, modelUrl, docUrl, preferencesRooms, expandedIndexes, isLoading, errorMessage, errorMessageSecond, hideInfoTootTip } = this.state;
    // let counter = 1;
    return (
      <div className='web_output_container'>
        <Loader
          isLoading={isLoading} />
        {sampleDocument ?
          <div className='water_mark_container'>
            <div className='water_mark'>{t('sample_doc_title')}  </div>
          </div> : null }
        {this.state.brokenLink ?
          <ErrorScreens
            errorTitle={t('broken_link')}
            sitemessage={t('broken_link_desc')}
            closefunction={this.redirectHome}
            errorButtonLabel={t('broken_link_button')} /> :
          <><div className='header'>
            {userAgent !== '' ?
              <div className='logo_area'
                onClick={this.redirectHome}>
                <div className='img_wrapper'>
                  <img src={Logo}
                    alt='visava' />
                </div>
                <div className='logo-text'>
                  {t('app_name').toUpperCase()}
                  <div className='beta-txt'>BETA</div>
                </div>
              </div> : null }
            <div>
              {userAgent !== '' ?
                <div className='btn_area'>
                  <div className='btn'
                    onClick={this.onLangClick}>
                    <div className='icon'>
                      <img src={GlobeIcon} />
                    </div>
                    <div className='dropdown_lbl'>
                      <div className='lang_lbl'>{this.state.language}</div>
                      <div className='dropdown_icon'>
                        <img src={DownArrowIcon} />
                      </div>
                    </div>
                    {showAvailablelang ?
                      <div className='dropdown_area'>
                        <div className='item'
                          onClick={() => this.selectLang('ENGLISH')}>English</div>
                        <div className='item'
                          onClick={() => this.selectLang('हिंदी')}>हिंदी</div>
                        <div className='item'
                          onClick={() => this.selectLang('मराठी')}>मराठी</div>
                      </div> :
                      null}
                  </div>
                  {userAgent === 'VisavaAndroidApp' ?
                    <div className='btn'>
                      <a href='shareDoc:'>
                        <div className='icon'>
                          <img src={ShareIcon} />
                        </div>
                      </a>
                      <div className='lbl'>{t('share_document_label')}</div>
                    </div> :
                    <div className='btn'
                      onClick={() => this.viewSharePopUp(true)}>
                      <div className='icon'>
                        <img src={ShareIcon} /></div>
                      <div className='lbl'>{t('share_document_label')}</div>
                    </div>
                  }
                  {this.state.showSharePopUpDoc &&
                    <SharePopUp closeSharePopUp={() => this.closeSharePopUp(true)}
                      shareUrl={docUrl}
                      text={t('document_share_content')}
                      //previewImg={floorPlanEn} add after client confirmation
                      shareText={true} />}
                  {userAgent === 'VisavaAndroidApp' ?
                    <div className='btn'>
                      <a href='printDoc:'>
                        <div className='icon'>
                          <img src={PrintIcon} />
                        </div>
                      </a>
                      <div className='lbl'>{t('print_document_label')}</div>
                    </div> :
                    <div className='btn'
                      onClick={this.printTo}>
                      <div className='icon'>
                        <img src={PrintIcon} /></div>
                      <div className='lbl'>{t('print_document_label')}</div>
                    </div> }
                </div> : null}
            </div>
          </div><div className='customer_details'>
            <div className='details_area'>
              <div className='detail'>
                <div className='icon'><img src={UserIcon} /></div>
                <span className='lbl'>{t('name')}:</span> {fullName}
              </div>
              <div className='detail'>
                <div className='icon'><img src={ContactIcon} /></div>
                <span className='lbl'>{t('phone_number')}:</span>
                {mobile !== 'N/A' ?
                  <a href='tel:{mobile}'
                    className='bold'>{mobile}</a>
                  : <span>{mobile}</span>}
              </div>
            </div>
            <div className='details_area'>
              <div className='detail'>
                <div className='icon'><img src={DateIcon} /></div>
                <span className='lbl'>{t('date')}:</span> {date}
              </div>
              <div className='detail'>
                <div className='icon'><img src={MailIcon} /></div>
                <span className='lbl'>{t('email_id')}:</span>
                {email !== 'N/A' ?
                  <a href={`mailto:${email}`}
                    className='bold'>{email}</a>
                  : <span>{email}</span>}
              </div>
            </div>
          </div><div className='house_details'>
            <div className='section_header'>{t('userHouseDetails')}</div>
            <div className='table_area'>
              <table className='custom_table'
                colSpan='0'>
                <tr>
                  <td>{t('location')}</td>
                  <td>{location}</td>
                </tr>
                <tr>
                  <td>{t('construction_area')}</td>
                  <td>{t('lengthA')} = {constructionArea} {this.unitLocalization(unitlength)}, {t('lengthB')} = {constructionArea1} {this.unitLocalization(unitlength)}, {t('plinth')} = {(constructionArea * constructionArea1).toFixed(2)} {this.unitAreaLocalization(unit)}</td>
                </tr>
                <tr>
                  <td>{t('budget')}</td>
                  <td> ₹{budget} </td>
                </tr>
                <tr>
                  <td>{t('main_door_direction')}</td>
                  <td> {this.preferDirection(mainDoor)} </td>
                </tr>
                <tr>
                  <td>{t('preferences')} </td>
                  <td>{t('floors')} = {this.preferFloor(preferencesFloor)}, {t('rooms')} = {preferencesRooms}, {t('toiletreq')} = {this.preferToilet(preferencesToilet)}</td>
                </tr>
              </table>
            </div>
          </div><div className='interactive_house'>
            <div className='title'>
              <div className='section_header'>{t('threeModel')}</div>
              {userAgent !== '' ?
                <div className='btn_area'>
                  {userAgent === 'VisavaAndroidApp' ?
                    <div className='btn'>
                      <a href='fullscreen3D:'>
                        <div className='icon'><img src={FullScreenIcon} /></div>
                        <div className='lbl'>{t('fullScreen')}</div>
                      </a>
                    </div>
                    :
                    <div className='btn'
                      onClick={this.showFullScreen}>
                      <div className='icon'><img src={FullScreenIcon} /></div>
                      <div className='lbl'>{t('fullScreen')}</div>
                    </div>}
                  {userAgent === 'VisavaAndroidApp' ?
                    <div className='btn'>
                      <a href='share3D:'>
                        <div className='icon'><img src={ShareIcon} /></div>
                        <div className='lbl'>{t('shareModel')}</div>
                      </a>
                    </div> :
                    <div className='btn'
                      onClick={() => this.viewSharePopUp(false)}>
                      <div className='icon'><img src={ShareIcon} /></div>
                      <div className='lbl'>{t('shareModel')}</div>
                    </div> }
                  {this.state.showSharePopUpModel &&
                    <SharePopUp closeSharePopUp={() => this.closeSharePopUp(false)}
                      shareUrl={modelUrl}
                      text={t('model_share_content')}
                      shareText={true} />}
                </div>
                : null}
            </div>
            <div className='house_3d_model_area'>
              <div className='house_3d_model_mobile'>
                <iframe src={`${modelUrl}&interactive=false`}
                  height='100%'
                  width='100%'
                  loading='lazy' />
                {userAgent === 'VisavaAndroidApp' ?
                  <div className='tap_to_touch_overlay'>
                    <a href='fullScreen3D:'>
                      <img src={TapToTouch} />
                    </a>
                  </div> :
                  <div className='tap_to_touch_overlay'
                    onClick={this.showFullScreen}>
                    <img src={TapToTouch} />
                  </div>}
              </div>
              <div className='house_3d_model'
                style={{ height: '50vh' }}>
                <iframe src={modelUrl}
                  height='100%'
                  width='100%'
                  loading='fast' />
                {/* <Sample3DModel /> */}
              </div>
              <div className='btn_area'>
                {/* <div className='refresh_btn'><img src={RefreshIcon} /></div> */}
                <div className='zoom_btn'>
                  {/* <div className='btn'><img src={PlusIcon} /></div>
                      <div className='btn'><img src={MinusIcon} /></div> */}
                </div>
              </div>
            </div>
            {sampleDocument ?
              <div className='water_mark_container'>
                <div className='water_mark'>{t('sample_doc_3D')}  </div>
              </div> : null }
          </div><div className='house_plan'>
            <div className='section_header'>{t('housePlan')}</div>
            <div className='inner_wrapper'>
              {sampleDocument ?
                <div className='house_model'><img src={langCode == 'hi' ? floorPlanHi : langCode == 'mr' ? floorPlanMr : floorPlanEn} />
                </div> :
                <div className='coming_soon_area'>
                  <div className='img'>
                    <img src={FloorPlan2D} />
                  </div>
                  <div className='lbl'>{t('2DplanTitle')}</div>
                </div>}
              {/* {sampleDocument ?
                <div className='house_plan_table'>
                  <table className='table'>
                    <tr>
                      <th>Plinth Level</th>
                      <th>Floor Height</th>
                      <th>Lintle Level</th>
                      <th>Sloping Roof Angle</th>
                      <th>North</th>
                    </tr>
                    <tr>
                      <td>450 m/1.5 ft</td>
                      <td>2.7 m/9 ft</td>
                      <td>2.1 m/7 ft</td>
                      <td>22 </td>
                      <td><img src={NorthDirection} /></td>
                    </tr>
                  </table>
                </div> : null } */}
            </div>
            {sampleDocument ?
              <div className='water_mark_container'>
                <div className='water_mark'>{t('sample_doc_2D')}</div>
              </div> : null }
          </div><div className='house_costing_sheet'>
            <div className='section_header'>{t('ctSheet')}</div>
            <div className='section_wrapper'>

              {Object.keys(this.state.costTable).map((item) => this.state.costTable[item].map((val, index) => (
                <div key={index}>
                  <div className='feature_table'>
                    <div className='feature_header'
                      onClick={ () => this.collapseBom (index)}>
                      {String.fromCharCode(65 + index)}. {val.name.toUpperCase()}
                      <div className='right_icons'>
                        {!hideInfoTootTip ?
                          <div className='info_icon'>
                            <img src={InfoWhiteIcon} />
                            <div className='tooltip'>
                              <div className='tooltiptext'>
                                <span className='title'>{val.name}:</span> {val.description}<br />
                                {/* <span className='title'>Stone Rubble Wall: </span> 450 mm walls along the periphery back filled with murum */}
                              </div>
                            </div>
                          </div> : null }
                        {/* when feature is in expanded view add expand class with arrow_icon class*/}
                        <div className={expandedIndexes.has(index) ? 'arrow_icon' : 'arrow_icon expand'}>
                          <img src={UpArrow} />
                        </div>
                      </div>
                    </div>
                    {/* to show expanded view add display block styling  feature_collapsed_area class*/}
                    {/* <div className={index == collapseIndex && !collapse ? '' :'feature_collapsed_area'} > */}
                    <div className={expandedIndexes.has(index) ? 'feature_collapsed_area' : ''} >
                      {/* sub feature starts */}
                      {
                        val.sub_features.map((value, index) => (
                          <div key={value.id}>

                            <div className='sub_feature_table'>
                              <div className='sub_feature_header'>
                                <div className='sub_feature_title'>
                                  <div className='title'>{index+1}. {value.name.toUpperCase()}</div>
                                  <div className='material'>{value.materials[0].name}</div>
                                </div>
                                {/* tooltip icon and content */}
                                {!hideInfoTootTip ?
                                  <div className='info_icon'>
                                    <img src={InfoIcon} />
                                    <div className='tooltip'>
                                      <div className='tooltiptext'>
                                        <span className='title'>{value.name}:</span> {value.description}<br /> <br />
                                        <span className='title'>{value.materials[0].name}: </span>{value.materials[0].description}
                                      </div>
                                    </div>
                                  </div> : null }
                              </div>
                              {value.materials.map((material) => (
                                <div key={material.id}>
                                  {material.material_labour_cost ?
                                    <>
                                      {/* sub feature without material and labour starts */}
                                      <div className='inner-wrapper'>
                                        <div className='sub_feature_content'>
                                          <div className='content'>
                                            <div className='title'>{t('items')}</div>
                                            <div className='details'>
                                              <div>{t('itemQuantity')}: {material.quantity} {material.uom}</div>
                                              <div>{t('itemRate')}: ₹{this.costFormat(material.material_labour_rate)} / {material.uom} {t('materialDesc')}</div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className='sub_feature_footer'>
                                        {t('totalCost')} = ₹{this.costFormat(material.total_cost)}
                                      </div>
                                    </> : <>
                                      {/* sub feature with material and labour starts */}
                                      <div className='inner_wrapper'>
                                        <div className='sub_feature_content'>
                                          <div className='content'>
                                            <div className='title'>[A] {t('material')}</div>
                                            <div className='details'>
                                              <div>{t('materialQuantity')}: {material.quantity.toFixed(2)} {material.uom}</div>
                                              <div>{t('materialRate')}: ₹{this.costFormat(material.material_rate)} {material.uom}</div>
                                            </div>
                                          </div>
                                          <div className='cost'>
                                            {t('materialCost')}: ₹{this.costFormat(material.material_cost)} {material.uom}
                                          </div>
                                        </div>
                                        <div className='sub_feature_content'>
                                          <div className='content'>
                                            <div className='title'>[B] {t('labour')}</div>
                                            <div className='details'>
                                              <div>{t('materialQuantity')}: {material.quantity.toFixed(2)} {material.uom} </div>
                                              <div>{t('labourRate')}: ₹{this.costFormat(material.labour_rate)} {material.uom}</div>
                                            </div>
                                          </div>
                                          <div className='cost'>
                                            {t('labourCost')}: ₹{this.costFormat(material.labour_cost)} {material.uom}
                                          </div>
                                        </div>
                                      </div>
                                      <div className='sub_feature_footer'>
                                        {t('totalCost')} [A] {t('material')} + [B] {t('labour')}  = ₹{this.costFormat(material.total_cost)}
                                      </div>
                                    </>}

                                </div>
                              ))}

                            </div>
                          </div>
                        ))
                      }
                    </div>
                    {/* add feature_footer_green class with feature footer when feature is in expanded view*/}
                    <div className={expandedIndexes.has(index) ? 'feature_footer' : 'feature_header'}>
                      {t('total')} {val.name.toUpperCase()} {t('cost').toUpperCase()} = ₹{this.costFormat(val.total_cost)}
                    </div>
                  </div>
                </div>
              ))
              )}
              <div className='grand_total'>
                {t('grandTotal')} ({t('grandTotalDesc')}) = ₹{this.costFormat(ctTotal)}
              </div>
              {sampleDocument ?
                <div className='water_mark_container'>
                  <div className='water_mark'>{t('sample_costing_sheet')}</div>
                </div> : null }
            </div>
          </div><div className='material_quantity_sheet'>
            <div className='section_header'>{t('materialQuantitySheet')}</div>
            <div className='inner_wrapper'>
              <div className='table_area'>
                <table className='quantity_table'>
                  <tr>
                    <th>{t('feature')}</th>
                    <th>{t('material')}</th>
                    <th>{t('unit')}</th>
                    <th>{t('quantity')}</th>
                    <th>{t('rate')}</th>
                    <th>{t('cost').toUpperCase()}</th>
                  </tr>
                  {Object.keys(this.state.billOfMaterialTable).map((item) => this.state.billOfMaterialTable[item].map((val, ind) => (
                  // eslint-disable-next-line react/jsx-key
                    <Fragment>
                      {val.sub_materials.map((material, index) => (
                        <tr key={`row-${index}`}>
                          <td className='heading'>{index == 0 ? `${String.fromCharCode(65 + ind)}.${val.name}`: ''} </td>
                          <td>{material.name}</td>
                          <td>{material.sub_material_uom}</td>
                          <td>{material.sub_material_quantity}</td>
                          {/* <td>{this.getEvaluatedPrice(material.sub_material_rate)}</td> */}
                          {/* <td>₹{(material.sub_material_rate).toLocaleString('en-IN')}</td> */}
                          <td className='heading_sub_material'>₹{this.costFormat(material.sub_material_rate)}</td>

                          {/* <td>{this.getEvaluatedPrice(material.sub_material_cost)}</td> */}
                          <td className='heading_sub_material'>₹{this.costFormat(material.sub_material_cost)}</td>
                        </tr>
                      ))}
                      <tr>
                        <td className='heading'
                          colSpan='6'> {val.name} {t('cost')}: ₹{this.costFormat(val.total_cost)}</td>
                      </tr>
                    </Fragment>
                  )))}
                </table>
              </div>
              <div className='material_total'>
                {t('materialTotal')} = ₹{(bomTotal).toLocaleString('en-IN')}
              </div>
              <div className='grand_total'>
                {t('grandTotal')} ({t('grandTotalDesc')}) = ₹{(grandFinal).toLocaleString('en-IN')}
              </div>
              {sampleDocument ?
                <div className='water_mark_container'>
                  <div className='water_mark'>{t('sample_costing_sheet')}</div>
                </div> : null }
              {budgetSaved > savingAmount && <div className='congratulate_container'>
                <div className='left_img'><img src={Firework} /></div>
                <div className='center_txt'>
                  <div className='title_area'>
                    <img className='img'
                      src={PriceMoney} />
                    <div className='title'>{t('congratulations')}</div>
                    <img className='img'
                      src={PriceMoney} />
                  </div>
                  <div className='txt'>{t('firstStringSavedAmount')}{budgetSaved.toLocaleString('en-IN')} {t('secondStringSavedAmount')} </div>
                </div>
                <div className='right_img'><img src={Firework} />
                </div>
              </div>}
            </div>
          </div><div className='blogs'>
            <div className='section_header'>{t('Visava_blogs')}</div>
            <div className='inner_wrapper'>
              <div className='blog_box'
                onClick={this.redirectBlog1}>
                <div className='img_area'>
                  <img src={SampleDocImg1} />
                </div>
                <div className='txt_area'>
                  <div className='lbl'>{t('Blog_display_title_first')}</div>
                  <div className='txt'>{t('Blog_display_desc_first')}</div>
                </div>
              </div>
              <div className='blog_box'
                onClick={this.redirectBlog2}>
                <div className='img_area'>
                  <img src={SampleDocImg2} />
                </div>
                <div className='txt_area'>
                  <div className='lbl'>{t('Blog_display_title_second')}</div>
                  <div className='txt'>{t('Blog_display_desc_second')}</div>
                </div>
              </div>
              <div className='blog_box'
                onClick={this.redirectBlog3}>
                <div className='img_area'>
                  <img src={BlogThreeImg} />
                </div>
                <div className='txt_area'>
                  <div className='lbl'>{t('Blog_display_title_third')}</div>
                  <div className='txt'>{t('Blog_display_desc_third')}</div>
                </div>
              </div>
            </div>
            <div className='btn_area'>
              <div>
                <a onClick={this.goToBlog}
                  className='btn'>
                  {t('read_more')}
                </a>
              </div>
            </div>
          </div><div className='disclaimer'>
            <div className='section_header'>{t('Disclaimer')}</div>
            <div className='inner_wrapper'>
              {/* <div className='info_box'>
                  <p>1. {t('bomdocfirstpara')}</p>
                  <p>2. {t('bomdocsecondpara')}</p>
                  <p>3. {t('bomdocthirdpara')}</p>
                  <p>4. {t('bomdocfourthpara')}</p>
                  <p>5. {t('bomdocfifthpara')}</p>
                  <p>6. {t('bomdocsixthpara')}</p>
                  <p>7. {t('bomdocseventhpara')}</p>
                </div> */}
              <div className='info_box'>
                <p>1. Sanctioning and Permissions - Visava promotes only legal construction as per the bylaws prescribed by the Government. The user shall be responsible for all the necessary permissions and sanctions for the construction of the house.</p>
                <p>2. Material Cost - The costing chart only specifies material rates and excludes labour and contractor charges. The material cost may vary as per vendor rates. Wastage is not considered in the costing chart.</p>
                <p>3. Construction - The user is expected to consult appropriate contractors or construction masons for the construction of this house.</p>
                <p>4. Structural Design and Detail - The user is expected to consult a structural engineer for design details and load calculations.</p>
                <p>5. Tax - Taxes on all costs as applicable.</p>
                <p>6. Visava - Visava is just a tool to support you in building your house. In no event will Visava be liable for any damages including, without limitation, indirect or consequential damage or any damages whatsoever arising from the use of the mobile application or during the construction of the house.</p>
                <p>7. Liability - Visava accepts no liability or responsibility for any loss or damage suffered by the recipient arising out of, or in connection with, the use or misuse of the information issued.</p>
              </div>
            </div>
          </div><div className='stay_connected'>
            <div className='section_header'>{t('stay_connected')}</div>
            <div className='inner_wrapper'>
              <div className='left_wrapper'>
                <div className='get_in_touch'>
                  <div className='lbl'>{t('get_in_touch')}</div>
                  <a href='tel:{CONTACT_DETAILS.mobile}'
                    className='txt'>
                    <span className='img'><img src={MobileIcon} /></span>
                    <span>{CONTACT_DETAILS.mobile}</span>
                  </a>
                  <a href='mailto:enquiry@visava.in'
                    className='txt'
                    onClick={this.mailTo}>
                    <span className='img'><img src={EnvelopeIcon} /></span>
                    <span>
                      {CONTACT_DETAILS.email}
                    </span>
                  </a>
                </div>
                <div className='follow_us'>
                  <div className='lbl'>{t('follow_us')}</div>
                  <div className='icon_area'>
                    <div className='icon'
                      onClick={this.gotoFacebook}>
                      <img src={FacebookIcon} />
                    </div>
                    <div className='icon'
                      onClick={this.gotoTwitter}>
                      <img src={TwitterIcon} />
                    </div>
                    <div className='icon'
                      onClick={this.gotoInsta}>
                      <img src={InstagramIcon} />
                    </div>
                    <div className='icon'
                      onClick={this.gotoLinkedin}>
                      <img src={LinkedinIcon} />
                    </div>
                    {/* <a href={SOCIAL_LINKS.facebook}
                      className='icon'>
                      <img src={FacebookIcon} />
                    </a>
                    <a href={SOCIAL_LINKS.twitter}
                      className='icon'>
                      <img src={TwitterIcon} />
                    </a>
                    <a href={SOCIAL_LINKS.instagram}
                      className='icon'>
                      <img src={InstagramIcon} />
                    </a>
                    <a href={SOCIAL_LINKS.linkedin}
                      className='icon'>
                      <img src={LinkedinIcon} />
                    </a> */}
                  </div>
                </div>
              </div>
              <div className='download_area'>
                <div className='lbl'>{t('download_visava')}</div>
                <div className='img_area'>
                  <div className='google_play_img'>
                    <a href={GOOGLE_PLAY_URL}>
                      <img src={langCode == 'hi' ? GooglePlayBadgeHi : langCode == 'mr' ? GooglePlayBadgeMa : GooglePlayBadgeEn} />
                    </a>
                  </div>
                  <div className='qr_code_img'>
                    <a href={GOOGLE_PLAY_URL}>
                      <img src={qr_code} />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
            <div className='footer'>
              <div className='link_area'>
                <div className='img'>
                  <img src={CopyrightIcon} />
                </div>
                2022. All rights reserved. Visava Labs Private Limited.
              </div>
              <div className='link_area'
                onClick={this.mailTo}>
                <div className='img'>
                  <img src={EnvelopeIcon} />
                </div>
                <a href='mailto:enquiry@visava.in'>
                  {CONTACT_DETAILS.email}
                </a>
              </div>
              <div className='link_area'>
                <div className='img'>
                  <img src={WebsiteIcon} />
                </div>
                <a href='https://www.visava.in'>
                  www.visava.in
                </a>
              </div>
              <div className='link_area'
                onClick={this.callTo}>
                <div className='img'>
                  <img src={MobileIcon} />
                </div>
                {CONTACT_DETAILS.mobile}
              </div>
            </div>
          </>}
        {this.state.showErrorPopUp && userAgent === 'VisavaWebApp' && !sampleDocument &&
          <ErrorPopup shown={this.state.showErrorPopUp}
            close={() => this.setState({ showErrorPopUp:false })}
            closeOk={() => this.setState({ showErrorPopUp:false })}
            formOpen={() => this.endUserFeedBackForm()}
            formPostponded={() => this.updatePopUpStatus()}
            message={errorMessage}
            anotherMessage={errorMessageSecond}
            paymentStatus={'SUCCESS'}
            paymentDone={this.state.paymentDone}
            buttonLabel='share_feedback'
            buttonLabelSecond='maybe_later'
            googleMaps={false}
            feedBackButton={true}
            showPayment={false} />}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  updateLangCode: (lang) => {
    dispatch(updateLangCode(lang));
  }
});


const mapStateToProps = (state) => ({
  langCode: getLangCodeSelector(state)
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withRouter(BomPage)));