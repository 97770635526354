/* eslint-disable no-console */
/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { API_URL, userAgent, House_ID, Share_Token } from '../../../api/api-config';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router';
import Loader from '../../shared-ui/Loader';
import SharePopUp from '../sharePopup';
import BackIcon from '../../../asset/images/white_back.svg';
import ShareIcon from '../../../asset/images/share_icon.svg';
import { axiosInstance } from '../../../api/coreApi';

class ViewFullScreen extends Component {

  constructor (props) {
    super(props);
    this.fetchHouseDetails=this.fetchHouseDetails.bind(this);
    this.gobacktoapp=this.gobacktoapp.bind(this);
    this.viewSharePopUp=this.viewSharePopUp.bind(this);
    this.closeSharePopUp=this.closeSharePopUp.bind(this);
    this.delayComponent=this.delayComponent.bind(this);
    this.viewDesignDoc=this.viewDesignDoc.bind(this);
    this.state = {
      modelUrl : '',
      isLoading: true,
      showSharePopUp: false,
      showComponents: false,
      paymentStatus: false
    };
  }
  
  gobacktoapp () {
    const { history } = this.props;
    const houseId = localStorage.getItem('houseId');
    if(!this.state.paymentStatus) {
      history.push(`/home-details?house-id=${houseId}`);
    } else {
      this.viewDesignDoc();
    }
  }

  viewDesignDoc () {
    const houseId = localStorage.getItem('houseId');
    const shareToken = localStorage.getItem('shareToken');
    const langCode = localStorage.getItem('lang');
    const { history } = this.props;
    const sampleDocument = localStorage.getItem('sample-doc');
    if (sampleDocument) {
      history && history.push(`/sample-document?preferred_language=${langCode}&user_agent=${userAgent}`);
    } else {
      history && history.push(`/design-document?house_id=${houseId}&share_token=${shareToken}&preferred_language=${langCode}&user_agent=${userAgent}`);
    }
  }

  viewSharePopUp () {
    this.setState({
      showSharePopUp: true
    });
  }

  delayComponent () {
    setTimeout(() => {
      this.setState({
        showComponents: true
      });
    }, 5500);
  }

  closeSharePopUp () {
    this.setState({
      showSharePopUp: false
    });
  }

  componentDidMount () {
    const sampleDocument = localStorage.getItem('sample-doc');
    const houseId = House_ID;
    const shareToken = Share_Token;
    const langCode = localStorage.getItem('lang');
    if(sampleDocument) {
      this.setState({
        modelUrl: `https://dev1.visava.in/model/?house_id=${houseId}&share_token=${shareToken}&preferred_language=${langCode}`,
        paymentStatus: true,
        isLoading: false
      }, this.delayComponent);
    } else {
      this.fetchHouseDetails();
    }
  }
  
  fetchHouseDetails () {
    this.setState({
      isLoading: true
    });
  
    const { history } = this.props;
    const houseId = localStorage.getItem('houseId');
    const url = `${API_URL.house}/${houseId}`;

    axiosInstance.get(url).then((resp) => {
      const data = resp && resp.data && resp.data.data;
      if (data) {
        this.setState({
          modelUrl: data.model_url,
          isLoading: false,
          //showComponents: true,
          paymentStatus: data.payment
        });
        this.delayComponent();
      }
    }).catch((err) => {
      console.log('err---->>>', err);
      if(err.response.data.status == 'Token is Expired' || err.response.data.status == 'Token is Invalid' || err.response.data.status == 'Authorization Token not found') {
        localStorage.clear();
        history.push('/login', { expiredError: true });
      }
      this.setState({
        isLoading: false
      });
    });
  }


  render () {
    
    const { isLoading, modelUrl, showSharePopUp, showComponents } = this.state;
    const { t } = this.props;

    return (
      <div>
        {showComponents ?
          <div className='back_btn_wrapper'
            onClick={this.gobacktoapp}>
            <div className='back_icon'>
              <img src={BackIcon} />
            </div>
            <div className='lbl'
              onClick={this.gobacktoapp}>{t('back')}
            </div>
          </div> : null }
        {showComponents ?
          <div className='share_btn_wrapper'>
            <div className='share_icon'
              onClick={this.viewSharePopUp}>
              <img src={ShareIcon} />
            </div>
            <div className='lbl'
              onClick={this.viewSharePopUp}>{t('shareModel')}</div>
            {showSharePopUp &&
              <SharePopUp closeSharePopUp={this.closeSharePopUp}
                text={t('share_design_desc')}
                shareUrl={this.state.modelUrl}
                shareText={true} />}
          </div> : null }
        <div className='show-full-screen'>
          <iframe src={modelUrl}
            height='100%'
            width='100%' />
        </div>
        <Loader
          isLoading={isLoading} />
      </div>
      
    );
  }
}

export default withTranslation()(withRouter(ViewFullScreen));