/* eslint-disable no-console */
/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import OtpInput from 'react-otp-input-visio';
import TermsConditions from '../t&c';
import PrivacyPolicydoc from '../privacyPolicy/privacyPolicy';
import { BASE_URL_V4, API_URL } from '../../../api/api-config';
import Loader from '../Loader';
import LogoWithText from '../../components/logoWithText';
//import { breakPoint_L } from '../../../utility/breakPoints';
import { withTranslation } from 'react-i18next';
import { getBreakpoint } from '../../../selectors/commonSelectors';
import { errorMsgHandler } from '../../../utility/errorHandling';
import { getLangCode } from '../../../utility/common';
import { axiosInstance } from '../../../api/coreApi';

// eslint-disable-next-line no-undef
const baseURL = process.env.REACT_APP_BASE_URL || '';
const basePath = (baseURL && baseURL !== '/') ? baseURL : '';

class OtpPage extends Component {

  componentDidMount () {
    this.gotoHome();
  }


  constructor (props) {
    super(props);
    this.inputNumber=this.inputNumber.bind(this);
    this.showTC=this.showTC.bind(this);
    this.showPP=this.showPP.bind(this);
    this.loading=this.loading.bind(this);
    this.isNumeric=this.isNumeric.bind(this);
    this.validateOtpApi=this.validateOtpApi.bind(this);
    this.resendOtp=this.resendOtp.bind(this);
    this.validateOtp=this.validateOtp.bind(this);
    this.gotoHome=this.gotoHome.bind(this);
    this.fetchProfileDetails=this.fetchProfileDetails.bind(this);
    const { t } = this.props;
    this.state = {
      otpNumber: '',
      inputError: false,
      otpValidation: true,
      tcPopUp: false,
      ppPopUp: false,
      verifyText: t('otp_verifier_resend_code'),
      showError: false,
      isLoading: false,
      enterOtpError: false,
    };
    this.gotoHome();
  }

  inputNumber (e) {
    this.setState({
      enterOtpError:false,
      otpNumber: e
    });
  }

  fetchProfileDetails () {
    const { history } = this.props;
    const jwtToken = localStorage.getItem('jwtToken');

    if (!jwtToken) {
      history.push('/');
    } else {
      this.setState({
        loading: true,
      });
      const URL = BASE_URL_V4 + API_URL.getUserProfile;
      axiosInstance
        .get(URL)
        .then((resp) => {
          const profileData = resp && resp.data && resp.data.data;
          if (profileData) {
            this.setState({
              loading: false,
              preferredLanguage: profileData.preferred_language
            });
            localStorage.setItem('lang', profileData.preferred_language);
          }
        })
        .catch(() => {
          this.setState({
            loading: false,
          });
        });
    }

  }

  gotoHome () {
    const { location } = this.props;
    const jwtTemp = localStorage.getItem('jwtToken');
    const prevPage = location && location.state && location.state.from;
    if (!prevPage || jwtTemp) {
      this.props.history.push('/');
    }
  }

  showTC () {
    this.setState({
      tcPopUp: !this.state.tcPopUp
    });
  }

  showPP () {
    this.setState({
      ppPopUp: !this.state.ppPopUp
    });
  }

  loading (value) {
    this.setState({
      isLoading: value
    });
  }

  isNumeric (value) {
    return /^-?\d+$/.test(value);
  }

  validateOtpApi (isSignUp, emailOrPhone) {
    const { t } = this.props;
    const { otpNumber } = this.state;
    if (this.isNumeric(emailOrPhone)) {
      const URL = isSignUp ? BASE_URL_V4 + API_URL.signUpMobileOtp : BASE_URL_V4 + API_URL.loginMobileOtp;
      const requestBody = {
        mobile_country_code: '91',
        mobile_number: emailOrPhone,
        mobile_otp_value: otpNumber,
      };
      axiosInstance.post(URL, requestBody).then((res) => {
        this.loading(false);
        if (res && res.data) {
          const response = res.data;
          const jwtToken = response && response.authorization && response.authorization.token;
          localStorage.setItem('jwtToken', jwtToken);
          localStorage.setItem('userId', response && response.id);
          this.setState({
            isLoading:true
          });
          // history.push(`${isSignUp ? '/welcome' : '/homes'}`, isSignUp ? { from: 'signup' }:{ from: 'login' });
          window.location.href = isSignUp ? `${basePath}/welcome` : `${basePath}/homes`;
        }
      }).catch((err) => {
        if (err && err.response) {
          const errData = err.response && err.response.data;
          if (errData.status.toLowerCase() == 'failed') {
            this.setState({
              otpValidation: false,
              showError: true,
              verifyText: t('otp_verifier_resend_code_button'),
              errorMsg: errorMsgHandler(errData.error_code, t),
              isLoading: false
            });
          }
          // console.log('err-------->>>>', errData);
        } else {
          this.setState({
            otpValidation: false,
            showError: true,
            verifyText: t('otp_verifier_resend_code_button'), // catch all
            errorMsg: errorMsgHandler('FAILED', t),
            isLoading: false
          });
        }
      });
    } else {
      const URL = isSignUp ? BASE_URL_V4 + API_URL.signUpEmailOtp : BASE_URL_V4 + API_URL.loginEmailOtp;
      const requestBody = {
        email: emailOrPhone,
        email_otp_value: otpNumber,
      };
      axiosInstance.post(URL, requestBody).then((res) => {
        this.loading(false);
        if (res && res.data) {
          const response = res.data;
          const jwtToken = response && response.authorization && response.authorization.token;
          localStorage.setItem('jwtToken', jwtToken);
          localStorage.setItem('userId', response && response.id);
          this.setState({
            isLoading:true
          });
          // history.push(`${isSignUp ? '/welcome' : '/homes'}`, isSignUp && { from: 'signup' });
          window.location.href = isSignUp ? `${basePath}/welcome` : `${basePath}/homes`;
        }
      }).catch((err) => {
        if (err && err.response) {
          const errData = err.response && err.response.data;
          if (errData.status.toLowerCase() == 'failed') {
            this.setState({
              otpValidation: false,
              showError: true,
              verifyText: t('otp_verifier_resend_code_button'),
              errorMsg: errorMsgHandler(errData.error_code, t),
              isLoading: false
            });
          }
          // console.log('err-------->>>>', errData);
        } else {
          this.setState({
            otpValidation: false,
            showError: true,
            verifyText: t('otp_verifier_resend_code_button'), // catch all
            errorMsg: errorMsgHandler('FAILED', t),
            isLoading: false
          });
        }
      });
    }
  }

  resendOtp (isSignUp, emailOrPhone) {
    const { location, t } = this.props;
    this.loading(true);
    const firstName = location && location.state && location.state.firstName;
    const lastName = location && location.state && location.state.lastName;
    if (this.isNumeric(emailOrPhone)) {
      const URL = isSignUp ? BASE_URL_V4 + API_URL.signUpMobile : BASE_URL_V4 + API_URL.loginMobile;
      const requestBody = isSignUp ? {
        preferred_language: getLangCode(localStorage.getItem('lang')) || 'en',
        first_name: firstName,
        last_name: lastName,
        country_iso: 'IN',
        mobile_country_code: '91',
        mobile_number: emailOrPhone,
      } : {
        mobile_country_code: '91',
        mobile_number: emailOrPhone,
      };
      axiosInstance.post(URL, requestBody).then(() => {
        this.setState({
          showError: false,
          verifyText: t('verify_text'),
          otpNumber: '',
          isLoading: false
        });
      }).catch((err) => {
        this.loading(false);
        if (err && err.response) {
          const errData = err.response && err.response.data;
          console.log('err-------->>>>', errData);
        }
      });
    } else {
      const URL = isSignUp ? BASE_URL_V4 + API_URL.signUpEmail : BASE_URL_V4 + API_URL.loginEmail;
      const requestBody = isSignUp ? {
        preferred_language: getLangCode(localStorage.getItem('lang')) || 'en',
        first_name: firstName,
        last_name: lastName,
        country_iso: 'IN',
        email: emailOrPhone,
      } : {
        email: emailOrPhone
      };
      axiosInstance.post(URL, requestBody).then(() => {
        this.setState({
          showError: false,
          verifyText: t('verify_text'),
          otpNumber: '',
          enterOtpError:'',
          isLoading: false
        });
      }).catch((err) => {
        this.loading(false);
        if (err && err.response) {
          const errData = err.response && err.response.data;
          console.log('err-------->>>>', errData);
        }
      });
    }
  }

  validateOtp (signup, emailOrPhone) {
    const { showError, otpNumber } = this.state;
    if (otpNumber && otpNumber.length == 4) {
      this.loading(true);
      if (showError) {
        this.resendOtp(signup, emailOrPhone);
      } else {
        this.validateOtpApi(signup, emailOrPhone);
      }
    }else {
      this.setState({
        enterOtpError:true
      });
    }
  }

  render () {
    const { location, t, breakpoint } = this.props;
    const { otpNumber, otpValidation, tcPopUp, verifyText, showError, isLoading, enterOtpError, ppPopUp, errorMsg } = this.state;
    const prevPage = location && location.state && location.state.from;
    if (!prevPage) {
      this.props.history.push('/');
    }
    const emailOrPhone = location && location.state && location.state.mobile ? location.state.mobile : location && location.state && location.state.email;
    //console.log(emailOrPhone);
    const signup = (prevPage == 'signup');
    //const disabled = !(otpNumber && otpNumber.length == 4);
    return (
      <div className='otp-container banner-container-top'>
        <Loader
          isLoading={isLoading} />
        {breakpoint && <LogoWithText title={signup ? t('sign_up') : t('login')} />}
        {tcPopUp && <TermsConditions onClose={this.showTC} />}
        {ppPopUp && <PrivacyPolicydoc onClose={this.showPP} /> }
        {!tcPopUp && <div className='inner_wrapper'>{!breakpoint == 'sm' && <div className='initial-text'>{signup ? t('sign_up') : t('login')}</div>}
          {signup ? <div className='description'>{t('otp_verifier_signup')}</div> :<div className='description'>{t('otp_verifier_sms_email_message')} <span className='bolder'>{`${emailOrPhone}`}</span></div>}
          {/* <div className='description'>{t('otp_verifier_sms_email_message')}{`${emailOrPhone}`}</div> */}
          <div className='otp-wrapper'>
            {enterOtpError ? <div className='error-text'>{t('please_enter_otp')}</div> : null}
            <OtpInput
              value={otpNumber}
              onChange={this.inputNumber}
              numInputs={4}
              isInputNum={true}
              hasErrored={showError}
              inputStyle='input-container'
              errorStyle={showError && 'input-error'} />
          </div>
          <div className={`resend-code-wrapper ${!otpValidation ? 'otp-failed' : undefined}`}

            style={!otpValidation ? { marginBottom: '18px' } : signup ? {} : { marginBottom: '60px' }}>
            <div className={!otpValidation ? 'invalid-code' : undefined}>{otpValidation ? t('didn\'t_receive_the_code') : (!otpValidation && showError) ? errorMsg : ''}</div>
            {otpValidation && <div className='resend-code button-pointer'
              onClick={() => this.resendOtp(signup, emailOrPhone)}>{t('otp_verifier_resend_code')}</div>}
          </div>

          <div className='otp-validation-wrapper'
            role='button'>
            <div
              className='otp-validation-button button-pointer'
              onClick={() => this.validateOtp(signup, emailOrPhone)}>
              {!otpValidation ? verifyText : signup ? t('otp_verifier_signup_btn') : t('login_btn')}
            </div>
          </div>
          {(signup && otpValidation) && <div className='terms-condition-wrapper'>
            <div>{t('otp_verifier_terms_message')}</div>
            <div className='inner_wrapper'>
              <div className='resend-code1 button-pointer'
                onClick={this.showTC}>{t('otp_verifier_terms_link')}</div>
              <div className='and_lbl'> {t('and')} </div>
              <div className='resend-code1 button-pointer'
                onClick={this.showPP}>{t('privacy_policy')}</div>
            </div>
          </div>}
        </div>}
        {/* {(signup && otpValidation) && <div className='terms-condition-wrapper'>
          <div>{t('otp_verifier_terms_message')}</div>
          <div className='resend-code1 button-pointer'
            onClick={this.showTC}>{t('otp_verifier_terms_link')}</div>
        </div>} */}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  breakpoint : getBreakpoint(state)
});

export default withTranslation()(withRouter(connect(mapStateToProps, null)(OtpPage)));