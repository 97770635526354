import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class PageNotFound extends Component {
  render () {
    return (
      <div
        style={{
          height: '70vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <h1>Not Found</h1>
        <Link to='/'> Go Home </Link>
      </div>
    );
  }
}

export default PageNotFound;