/* eslint-disable no-console */

/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Confirmation from '../../../../src/asset/images/confirmationImg.svg';
import { SAVING_LIMIT } from '../../../api/api-config';
import MoneyImg from '../../../../src/asset/images/money_img.svg';
import HouseImg from '../../../../src/asset/images/house_ready.svg';
// import RupeeImg from '../../../../src/asset/images/rupee.svg';
import { withTranslation } from 'react-i18next';
//import { breakPoint_L } from '../../../utility/breakPoints';
import { getBreakpoint } from '../../../selectors/commonSelectors';

class ConfirmationScreen extends Component {
  constructor (props) {
    super(props);
    this.onContinueClick=this.onContinueClick.bind(this);
    this.state = {
      langUsed: ''
    };
  }

  componentDidMount () {
    window.scrollTo({ top: 0 });
    this.setState({
      langUsed: localStorage.getItem('lang')
    });
  }

  onContinueClick () {
    this.props.continue && this.props.continue();
  }

  render () {
    //const amount = 10000; // remove the hardcoded budget, dynamic budget logic already created
    const { amount, t } = this.props;
    return (
      <div className='congratulations-container'>
        { amount > SAVING_LIMIT ?
          <><div className='banner-image-container'>
            <div className='info_container'>
              <div className='img_area'>
                <img className='congrats_img'
                  src={Confirmation}
                  alt='Congratulations Image' />
                <div className='congratulations_text_area'>
                  <div className='title'>{t('congratulations')}</div>
                  <div className='sub_title'>{t('you_have_saved_money')}</div>
                </div>
              </div>
            </div>
            <div className='money_img'>
              <img className='money_image'
                alt='Money Icon'
                src={MoneyImg} />
            </div>
          </div><div className='savings_info_container'>
            <div className='savings'>{t('total_savings')}</div>
            <div className='amount-wrapper'>
              {/* <div className='rupee-wrapper'>
                ₹
                  </div> */}
              <div className='amount'>₹{amount.toLocaleString('en-IN')}</div>
            </div>
            <div className='description'>{t('budgetScreenTitle6')} {t('budgetScreenTitle7')}</div>
            {/* <div className='inr'>{t('currency')}</div> */}
            <div className='continue-wrapper'>
              <div className='continue visava-btn'
                onClick={this.onContinueClick}>{t('budgetScreenTitle8')}</div>
            </div>
          </div></> :
          <><div className='banner-image-container'>
            <div className='info_container'>
              <div className='img_area'>
                <img className='congrats_img'
                  src={HouseImg}
                  alt='Visava house' />
                <div className='congratulations_text_area'>
                  <div className='title'>{t('congratulations')}</div>
                  <div className='sub_title'>{t('budgetScreenTitle6').toUpperCase()}</div>
                </div>
              </div>
            </div>
          </div><div className='savings_info_container'>
            <div className='description'>{t('budgetScreenTitle7')}</div>
            <div className='continue-wrapper'>
              <div className='continue visava-btn'
                onClick={this.onContinueClick}>{t('budgetScreenTitle8')}</div>
            </div>
          </div></> }
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  breakpoint : getBreakpoint(state),
});

export default withTranslation()(connect(mapStateToProps, null)(ConfirmationScreen));