/* eslint-disable react/prop-types */
/* eslint-disable no-console */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import GetInTouch from '../dashboard/home/getInTouch';
import { getBreakpoint, getLangCodeSelector } from '../../../selectors/commonSelectors';
import GooglePlayBadgeEn from '../../../asset/images/GooglePlayBadgeEn.png';
import GooglePlayBadgeMa from '../../../asset/images/GooglePlayBadgeMa.png';
import GooglePlayBadgeHi from '../../../asset/images/GooglePlayBadgeHi.png';
//import PlayStoreImg from '../../../../src/asset/images/GooglePlayBadgeEn.png';
import { GOOGLE_PLAY_URL } from '../../../api/api-config';
import ContactUs from '../dashboard/home/contactUs/index.js';
import HeroBanner from '../../../asset/images/our_story/hero_banner_new.png';
import Amay from '../../../asset/images/our_story/amay_shah.png';
import Nidhi from '../../../asset/images/our_story/nidhi_joshi.png';
import Yash from '../../../asset/images/our_story/yash_ketkar.png';
import Pratiksha from '../../../asset/images/our_story/pratiksha_nair.png';
import Mark from '../../../asset/images/our_story/mark_jacob.png';
import Anuj from '../../../asset/images/our_story/anuj_kundarap.png';
import Rucha from '../../../asset/images/our_story/rucha_ghayal.png';
import Omkar from '../../../asset/images/our_story/omkar_deshpande.png';
import Rajeev from '../../../asset/images/our_story/rajeev_shah.png';
import Roshan from '../../../asset/images/our_story/roshan_kulkarni.png';
import Sushma from '../../../asset/images/our_story/sushma_joshi.png';
import Seema from '../../../asset/images/our_story/seema_ghule.png';
import Vijaylaxmi from '../../../asset/images/our_story/vijaylaxmi.png';
import Viraj from '../../../asset/images/our_story/viraj_rane.png';
import Photo1 from '../../../asset/images/our_story/photo_1.png';
import Photo2 from '../../../asset/images/our_story/photo_2.png';
import Photo3 from '../../../asset/images/our_story/photo_3.png';
import Photo4 from '../../../asset/images/our_story/photo_4.png';
import Photo5 from '../../../asset/images/our_story/photo_5.png';
import Photo6 from '../../../asset/images/our_story/photo_6.png';
import DesktopImg from '../../../asset/images/our_story/desktop_img.png';
import '../../../asset/styles/bootstrap/bootstrap-grid.min.css';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

class OurStory extends Component {

  constructor (props) {
    super(props);
    this.gotoVisavaWeb = this.gotoVisavaWeb.bind(this);
  }

  gotoVisavaWeb () {
    //const { history } = this.props;
    const jwtToken = localStorage.getItem('jwtToken');
    if (jwtToken) {
      this.props.history.push('/homes');
    } else {
      //console.log('The history is ', this.props.history);
      this.props.history.push('/signup');
    }
  }

  componentDidMount () {
    // eslint-disable-next-line no-console
    //console.log(window.pageYOffset);
    window.scrollTo({ top: 0 });
  }

  render () {
    // eslint-disable-next-line react/prop-types
    const { breakpoint, t, langCode } = this.props;
    return (
      <div className='our_story_main_container banner-container-top'>
        <div className='hero_banner_section'>
          <img src={HeroBanner}
            alt='Visava Story Banner' />
        </div>
        <div className='our_story_content section_padding'>
          <p className='content'>{t('our_story_title')}</p>
          <p className='content'>{t('our_story_first_para')}{t('our_story_second_para')}</p>
          <p className='content'>{t('our_story_third_para')}</p>
        </div>
        <div className='photo_gallery_mobile section_padding'>
          <div className='bootstrap-wrapper'>
            <div className='row no-gutters'>
              <div className='col-md-6'>
                {langCode == 'hi' ?
                  <div className='title'>{t('our_story_forth_para')} <span className='colored'>{t('our_story_fifth_para')}</span></div> :
                  langCode == 'mr' ?
                    <div className='title'>{t('our_story_forth_para')} <span className='colored'>{t('our_story_forth_para_2')}</span> {t('our_story_forth_para_3')}</div> :
                    <div className='title'>{t('our_story_forth_para')} <span className='colored'>{t('our_story_fifth_para')}</span></div> }
                <div className='photo'>
                  <img src={Photo1} />
                </div>
                <div className='photo'>
                  <img src={Photo2} />
                </div>
              </div>
              <div className='col-md-6'>
                <div className='photo'>
                  <img src={Photo3} />
                </div>
                <div className='photo'>
                  <img src={Photo4} />
                </div>
                <div className='photo'>
                  <img src={Photo5} />
                </div>
                <div className='photo'>
                  <img src={Photo6} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='photo_gallery_desktop section_padding'>
          <div className='photo'>
            {langCode == 'hi' ?
              <div className='title'>{t('our_story_forth_para')} <span className='colored'>{t('our_story_forth_para_2')}</span> {t('our_story_forth_para_3')}</div> :
              langCode == 'mr' ?
                <div className='title'>{t('our_story_forth_para')} <span className='colored'>{t('our_story_forth_para_2')}</span> {t('our_story_forth_para_3')}</div> :
                <div className='title'>{t('our_story_forth_para')} <span className='colored'>{t('our_story_fifth_para')}</span></div> }
            <img src={DesktopImg} />
          </div>
        </div>
        <div className='founder_section section_padding'>
          <div className='title'>
            {t('our_story_title_two')}
          </div>
          <div className='founder_wrapper'>
            <div className='founder_img'>
              <img src={Amay}
                alt='Amay Shah' />
            </div>
            <div className='founder_desc'>
              {t('our_story_seventh_para')}
            </div>
          </div>
          <div className='founder_wrapper revert'>
            <div className='founder_img'>
              <img src={Nidhi}
                alt='Nidhi Joshi' />
            </div>
            <div className='founder_desc'>
              {t('our_story_eight_para')}
            </div>
          </div>
          <div className='founder_wrapper'>
            <div className='founder_img'>
              <img src={Roshan}
                alt='Roshan Kulkarni' />
            </div>
            <div className='founder_desc'>
              {t('our_story_tenth_para')}
            </div>
          </div>
        </div>
        <div className='team_section section_padding'>
          <div className='title'>
            {t('our_story_ninth_para')}
          </div>
          <div className='bootstrap-wrapper'>
            <div className='row'>
              <div className='col-md-4'>
                <div className='team_member'>
                  <div className='img'>
                    <img src={Yash}
                      alt='Yash Ketkar' />
                  </div>
                  <div className='details'>
                    <div className='name'>
                      Yash Ketkar
                    </div>
                    <div className='desc'>
                      Research & Development
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-md-4'>
                <div className='team_member'>
                  <div className='img'>
                    <img src={Pratiksha}
                      alt='Pratiksha Nair' />
                  </div>
                  <div className='details'>
                    <div className='name'>
                      Pratiksha Nair
                    </div>
                    <div className='desc'>
                      Architecture & Research
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-md-4'>
                <div className='team_member'>
                  <div className='img'>
                    <img src={Rucha}
                      alt='Rucha Ghayal' />
                  </div>
                  <div className='details'>
                    <div className='name'>
                      Rucha Ghayal
                    </div>
                    <div className='desc'>
                      Architecture & Research
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-md-4'>
                <div className='team_member'>
                  <div className='img'>
                    <img src={Mark}
                      alt='Mark Jacob' />
                  </div>
                  <div className='details'>
                    <div className='name'>
                      Mark Jacob
                    </div>
                    <div className='desc'>
                      Architecture & Research
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-md-4'>
                <div className='team_member'>
                  <div className='img'>
                    <img src={Vijaylaxmi}
                      alt='Vijaylaxmi' />
                  </div>
                  <div className='details'>
                    <div className='name'>
                      Vijaylaxmi Wandimali
                    </div>
                    <div className='desc'>
                      Product Design
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-md-4'>
                <div className='team_member'>
                  <div className='img'>
                    <img src={Seema}
                      alt='Seema Ghule' />
                  </div>
                  <div className='details'>
                    <div className='name'>
                      Seema Ghule
                    </div>
                    <div className='desc'>
                      Product Development
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-md-4'>
                <div className='team_member'>
                  <div className='img'>
                    <img src={Omkar}
                      alt='Omkar Deshpande' />
                  </div>
                  <div className='details'>
                    <div className='name'>
                      Omkar Deshpande
                    </div>
                    <div className='desc'>
                      3D Visualiser
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-md-4'>
                <div className='team_member'>
                  <div className='img'>
                    <img src={Viraj}
                      alt='Viraj Rane' />
                  </div>
                  <div className='details'>
                    <div className='name'>
                      Viraj Rane
                    </div>
                    <div className='desc'>
                      Content Writing
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-md-4'>
                <div className='team_member'>
                  <div className='img'>
                    <img src={Sushma}
                      alt='Sushama Joshi' />
                  </div>
                  <div className='details'>
                    <div className='name'>
                      Sushama Joshi
                    </div>
                    <div className='desc'>
                      Localisation
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='advisor_section section_padding'>
          <div className='title'>
            {t('our_story_tenth_para_one')}
          </div>
          <div className='bootstrap-wrapper'>
            <div className='row'>
              {/* <div className='col-md-4'>
                <div className='team_member'>
                  <div className='img'>
                    <img src={Roshan}
                      alt='Roshan Kulkarni' />
                  </div>
                  <div className='details'>
                    <div className='name'>
                      Roshan Kulkarni
                    </div>
                    <div className='desc'>
                      Technology & Engineering
                    </div>
                  </div>
                </div>
              </div> */}
              <div className='col-md-4'>
                <div className='team_member'>
                  <div className='img'>
                    <img src={Rajeev}
                      alt='Rajeev Shah' />
                  </div>
                  <div className='details'>
                    <div className='name'>
                      Rajeev Shah
                    </div>
                    <div className='desc'>
                      Architecture & Business
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-md-4'>
                <div className='team_member'>
                  <div className='img'>
                    <img src={Anuj}
                      alt='Anuj Kundarap' />
                  </div>
                  <div className='details'>
                    <div className='name'>
                      Anuj Kundarap
                    </div>
                    <div className='desc'>
                      Finance & Business
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='build-house-container'>
          <div className='build-house-wrapper'>
            <div className='build-house-txt'>{t('our_story_title_three')}</div>
            <div className='btn-wrapper'>
              <div className='button visava-btn'
                onClick={this.gotoVisavaWeb}>{t('vendor_page_button_label')}</div>
              <div className='or-txt'>{t('or')}</div>
              <div className='google-img'>
                <a href={GOOGLE_PLAY_URL}>
                  <img src={langCode == 'hi' ? GooglePlayBadgeHi : langCode == 'mr' ? GooglePlayBadgeMa : GooglePlayBadgeEn} />
                </a>
              </div>
            </div>
          </div>
        </div>
        {breakpoint == 'sm' && <ContactUs {...this.props} />}
        <GetInTouch />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  breakpoint : getBreakpoint(state),
  langCode: getLangCodeSelector(state)
});

export default withTranslation()(connect(mapStateToProps, null)(withRouter(OurStory)));