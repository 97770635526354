import {
  INITIATE_PAYMENT,
} from '../actions/initiatePayment';

const INITIATE_STATE = {};

const homeDetailsState = (state = INITIATE_STATE, action) => {
  switch (action.type) {
    case INITIATE_PAYMENT:
      return Object.assign({}, state, { payParams: action.payload });
      // return{
      //   ...state,
      //   initiatePayment: action && action.data,
      // };
    default:
      return { ...state };
  }
};

export default homeDetailsState;

