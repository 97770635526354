/* eslint-disable react/jsx-pascal-case */
import React, { Component } from 'react';
import FAQ from '../../client/components/faq';
import Header from '../../client/shared-ui/header';

class FrequentlyAskedQuestions extends Component {
  render () {
    return (
      <><Header /><FAQ /></>
    );
  }
}

export default FrequentlyAskedQuestions;