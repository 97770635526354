import React, { Component } from 'react';
import MyProfile from '../../client/components/myProfile';
import Header from '../../client/shared-ui/header/index';

class Profile extends Component {
  render () {
    return (
      <><Header /><MyProfile /></>
    );
  }
}

export default Profile;