import React, { Component } from 'react';
import ViewFullScreen from '../../client/components/viewFullScreen';

class FullSCreen extends Component {
  render () {
    return (
      <ViewFullScreen />
    );
  }
}

export default FullSCreen;