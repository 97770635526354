/* eslint-disable no-console */
import React from 'react';
import './budgetScreens.scss';
import { withRouter } from 'react-router-dom';
import { setDMHRedirection } from '../../../utility/common';
import BudgetImg from '../../../../src/asset/images/insufficient_budget.svg';

// eslint-disable-next-line react/prop-types
function BudgetScreen ({ t, history, editHouse, agreedClick, actualHouseCost }) {
  window.scrollTo({ top: 0 });
  
  const reDirectToDMH = () => {
    setDMHRedirection();
    // eslint-disable-next-line react/prop-types
    history.push('/design-my-house', { editHouse });
  };
  

  const costFormat = (val) => {
    val = !val ? 0 : val;
    val = val.toFixed(0);
    val = parseInt(val);
    return val.toLocaleString('en-IN');
  };

  return (
    <div className='budget-container'>
      <div className='banner-image-container'>
        <div className='info_container'>
          <div className='txt'>{t('budgetScreenTitle')}</div>
          <div className='img_area'>
            <img src={BudgetImg}
              alt='Visava Budget' />
          </div>
          <div className='title_area'>
            <div className='title'>{t('budgetScreenTitle1')}</div>
            <div className='sub_title'>{t('budgetScreenTitle2')}</div>
          </div>
          <div className='btn_area'>
            <div className='visava-btn trasparent'
              onClick={reDirectToDMH}>{t('budgetScreenTitle3')}</div>
          </div>
        </div>
      </div>
      <div className='or_container'>{t('or')}</div>
      <div className='bottom_info_container'>
        <div className='description'>
          {t('budgetScreenTitle4')} <span className='budget'>₹{costFormat(actualHouseCost)}</span> {t('budgetScreenTitle_4_1')}
        </div>
        <div className='continue-wrapper'>
          <div className='continue visava-btn'
            onClick={agreedClick}>{t('budgetScreenTitle5')}</div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(BudgetScreen);