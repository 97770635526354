/*
  arg - error code, traslation func ref
  returns - error msg
*/
export const errorMsgHandler = (errorCode='FAILED', t) => {
  let errorMsg = '';

  switch(errorCode) {
    // sign up
    case 1007:
    case 1106:
      errorMsg = t('auth_account_already_exists');
      break;

    // login codes
    case 1403:
    case 1502:
      errorMsg = t('auth_account_not_exist');
      break;

    // mobile validation codes
    case 1006:
    case 1202:
    case 1402:
    case 1602:
      errorMsg = t('enter_valid_phone_number');
      break;
    // verification via mobile
    case 1203:
    case 1603:
      errorMsg = t('mobile_otp_verifier_failed');
      break;
    case 1204:
      errorMsg = t('mobile_already_verified');
      break;
    case 1205:
      errorMsg = t('mobile_num_not_reg'); // ### n
      break;
    case 1206:
      errorMsg = t('failed_mobile_otp_verification'); // ### n
      break;
    case 1604:
      errorMsg = t('mobile_num_not_reg_verified'); // ### n
      break;
    case 1605:
      errorMsg = t('failed_mobile_login'); // ### n
      break;
    // verification via email
    case 1701:
    case 1301:
      errorMsg = t('failed_email_ver'); // ### n
      break;
    case 1702:
    case 1302:
      errorMsg = t('failed_email_otp_value'); // ### n
      break;
    case 1303:
      errorMsg = t('email_already_ver'); // ### n
      break;
    case 1304:
      errorMsg = t('email_not_reg'); // ### n
      break;
    case 1305:
      errorMsg = t('failed_email_otp_ver'); // ### n
      break;
    case 1703:
      errorMsg = t('email_not_reg_verified'); // ### n
      break;
    case 1704:
      errorMsg = t('failed_email_login'); // ### n
      break;
    // email validation codes
    case 1105:
    case 1501:
      errorMsg = t('enter_valid_email_id');
      break;
    // generic validation
    case 1004:
    case 1104:
      errorMsg = t('enter_valid_last_name');
      break;
    case 1003:
    case 1103:
      errorMsg = t('enter_valid_first_name');
      break;
    // catch all codes
    case 'FAILED':
    case 1000:
    case 1001:
    case 1002:
    case 1005:
    case 1100:
    case 1101:
    case 1102:
    case 1200:
    case 1201:
    case 1400:
    case 1401:
    case 1500:
    case 1600:
    case 1601:
    default:
      errorMsg = t('something_went_wrong');
      break;
  }

  return errorMsg;
};