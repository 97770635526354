/* eslint-disable react/prop-types */
import React, { Component } from 'react';
//import { constants } from '../../../../../utility/constants';
import { withTranslation } from 'react-i18next';

class VendorRegisteration extends Component {
  constructor (props) {
    super(props);
    this.goToVisavaVendors=this.goToVisavaVendors.bind(this);
  }

  goToVisavaVendors () {
    const { history } = this.props;
    history && history.push('/visava-vendors');
  }

  render () {
    // eslint-disable-next-line react/prop-types
    const { t }=this.props;
    return (
      <div className='register-vender-container'>
        <div className='register-vender-wrapper'>
          <div className='intrested-vendor'>{t('intrested_in_register_as_vendor')}</div>
          <div className='sign-up-button visava-btn'
            onClick={this.goToVisavaVendors}>{t('contact')}</div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(VendorRegisteration);