/* eslint-disable no-undef */
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import './i18n/i18n';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
//import reducers from './api/reducers/';
import reducers from './client/redux/reducers';
import './client/index.scss';
import App from './pages/index.js';


const store = createStore(
  reducers,
  composeWithDevTools(applyMiddleware(thunk))
);

const basePath = process.env.REACT_APP_BASE_URL || '/';

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter basename={basePath}>
        <App />
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);
