/* eslint-disable no-console */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import CustomerImage from '../../../../../../src/asset/images/customerImage.png';
import CustomerImageMobile from '../../../../../../src/asset/images/customer_image_mobile.png';
import PlayStore from '../playstoreWrapper/playStoreButton/index.js';
import YoutubeIcon from '../../../../../../src/asset/images/youtube_icon.svg';
import ChevronIcon from '../../../../../../src/asset/images/right_chevron.svg';
import FeedbackIcon from '../../../../../../src/asset/images/feedback_icon.svg';
//import { breakPoint_L } from '../../../../../utility/breakPoints';
//import { constants } from '../../../../../utility/constants';
import { withTranslation } from 'react-i18next';
//import YouTubeModal from '../../../youTubeModal';
import { YOUTUBE_LINKS, FEED_BACK_FORMS } from '../../../../../api/api-config';
import { getBreakpoint } from '../../../../../selectors/commonSelectors';
import { windowOpenHandler } from '../../../../../utility/common';


class BannerImage extends Component {
  constructor (props) {
    super(props);
    this.howToUse = this.howToUse.bind(this);
    this.powerUserFeedBackForm = this.powerUserFeedBackForm.bind(this);
    this.endUserFeedBackForm = this.endUserFeedBackForm.bind(this);
    this.state = {
      showModal: false,
    };
  }

  howToUse () {
    // eslint-disable-next-line react/prop-types
    //this.props.history.push(YOUTUBE_LINKS.how_to_use_visava_english);
    const langCode = localStorage.getItem('lang');
    if( langCode === 'en') {
      windowOpenHandler(YOUTUBE_LINKS.how_to_use_visava_english);
    } else {
      windowOpenHandler(YOUTUBE_LINKS.how_to_use_visava_hindi);
    }
  }

  powerUserFeedBackForm () {
    windowOpenHandler(FEED_BACK_FORMS.power_user_form);
  }

  endUserFeedBackForm () {
    windowOpenHandler(FEED_BACK_FORMS.end_user_form);
  }

  render () {
    // eslint-disable-next-line react/prop-types
    const { breakpoint, t }= this.props;
    return (
      <div className='banner-container-top'>
        <div className='join_community_strip'
          onClick={this.powerUserFeedBackForm}>
          {t('feedback_title')}
          <img src={ChevronIcon} />
        </div>
        <div className='feedback_btn'
          onClick={this.endUserFeedBackForm}>
          <img src={FeedbackIcon} />
          <span className='lbl'>{t('share_feedback')}</span>
        </div>
        <div
          id='dashboardImage'
          className='dashboardImageContainer'>
          <img
            className='customerImage'
            src={CustomerImage}
            alt={'customerImage'} />
          <img
            className='customerImage_mobile'
            src={CustomerImageMobile}
            alt={'customerImage'} />
          <div className='dashboardImageLink'
            // onClick={() => this.setState({ showModal: true })}
            onClick={this.howToUse}>
            <img className='youtubeIcon'
              src={YoutubeIcon}
              alt='' />
            {breakpoint == 'lg' && <div className='how-to-use'
              onClick={this.howToUse}>{t('how_to_use')}</div>}
          </div>
          {breakpoint == 'lg' && <div className='googlePlayContainer'>
            <PlayStore />
          </div>}
        </div>
        {/* {this.state.showModal ? (
          <YouTubeModal
            shown={this.state.showModal}
            close={() =>
              this.setState({
                showModal: false,
              })
            }
            youTubeLink={YOUTUBE_LINKS.how_to_use} />
        ) : null} */}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  breakpoint : getBreakpoint(state)
});

export default withTranslation()(connect(mapStateToProps, null)(BannerImage));