import React, { Component } from 'react';
import Details from '../../client/components/details';
// import Header from '../../client/shared-ui/header';

class HomeDetails extends Component {
  render () {
    return (
      <>
        {/* <Header /> */}
        <Details />
      </>
    );
  }
}

export default HomeDetails;