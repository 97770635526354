/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import LogoWithText from '../../components/logoWithText';
import { CONTACT_DETAILS } from '../../../api/api-config';

class TermsConditions extends Component {
  constructor (props) {
    super(props);
    this.mailTo = this.mailTo.bind(this);
    this.onMobile = this.onMobile.bind(this);
  }

  mailTo () {
    window.open('mailto:enquiry@visava.in');
  }

  onMobile () {
    window.open('tel:+16502096560');
  }

  render () {
    return (
      <div>
        <div className='tnc-container english_font'
          onClick={this.props.onClose}>
          <div className='tnc-inner-container'
            onClick={(e) => e.stopPropagation()}>
            <div className='close_icon_wrapper'>
              <div className='close_icon'
                onClick={this.props.onClose} />
            </div>
            <div><LogoWithText /></div>
            <div className='modal-body'>
              <div className='modal-header'>
                Terms and Conditions
              </div>
              <p className='paragraph'>
                Visava Platform [refer definition 1(f)] is owned by Visava Labs Private Limited (hereinafter referred as ‘Owner’ or ‘Company’). The Company through its platform provides architectural design solutions wherein a Registered User by providing his/her/their specifications like area, location of the plot etc. and preferences like number of rooms, number of floors etc., can virtually visualize how Registered User’s building may look like, on a 3D environment. The Registered User can also acquire the 2D blueprint of the building, the material selection according to the plot location and the cost involved in its construction (hereinafter referred to as ‘Services’).
              </p>
              <p className='paragraph'>
                Visava Platform helps the User [refer definition 1(c)] to avail said services by creating his/her/their login by providing predefined credentials and thereafter becoming Registered User [refer definition 1(d)]. Thereafter Registered Users can avail service of designing their own buildings. To avail such services the Registered User has to pay a onetime fee per building the Registered User wants to get designed as mentioned on the website.
              </p>
              <p className='paragraph'>
                By accessing Visava Platform, the Registered User agrees to abide by and bound by the Terms and Conditions of Visava Platform. The Terms and Conditions contained herein along with the Privacy Policy form an Agreement regulating our relationship with regard to the use of the Visava Platform by the Registered User.
              </p>
              <p className='paragraph'>
                Please read this Agreement carefully. By singing in or logging on the Visava Platform the Registered User accepts the terms and conditions and by default follows the terms and conditions of Visava Platform. The Registered User is advised to regularly check the said terms for any amendments or updates to the terms and conditions.
              </p>
              <p className='paragraph'>
                The Visava Platform reserves the right to modify the terms and conditions of the Service Agreement ("Terms of SERVICE") and Disclaimer without any prior notification. The Registered User is advised to regularly review the Terms of Service. If the Registered User does not agree with any of the Terms and Conditions and any amendments thereto, the Registered User shall refrain from using the Visava Platform and/or Services.
              </p>
              <p className='paragraph'>
                These Terms and Conditions constitute a binding legal agreement between the Owner and Registered User/s; and govern legal rights, remedies, obligations and various other terms like limitations, jurisdiction, applicable law etc.
              </p>
              <p className='paragraph'>
                The Agreement between the Registered User and the Visava Platform shall be subject to following Terms and Conditions: -
              </p>
              <ol type='1'>
                <li>
                  <div className='title highlight'>Definitions:</div>
                  The terms wherever used shall unless contrary, carry the meaning as given under:
                  <ol type='a'>
                    <li><span className='highlight'>“Visava Platform”</span> means and includes the Visava’s Website or Mobile Application owned by Visava Labs Private Limited on Internet and respective stores.</li>
                    <li><span className='highlight'>“Agreement”</span> means the Terms and Conditions contained herein along with any Privacy Policy of any other Website/Application/portals whose links are provided on Visava Platform. It shall also include reference to this Agreement as and when amended, novated, supplemented varied or replaced.</li>
                    <li><span className='highlight'>“User”</span> means and includes any person who visits the Visava Platform to see the various services provided by the Company through its Visava Platform.</li>
                    <li><span className='highlight'>“Registered User”</span> means and includes any person who registers with the Visava Platform by accessing the Visava Platform through Visava’s Website and/or registering on the Visava’s Mobile Application by providing his/her/their required predefined details.</li>
                  </ol>
                </li>
                <li>
                  <div className='title highlight'>Eligibility, Registration and Termination:</div>
                  <ol type='a'>
                    <li>Visava Platform can be accessed by going on www.visava.in and/or can be downloaded from Google Play Store or Apple Play Store as per the requirements of the User. Visava Platform can be used/accessed only by persons who can form a legally binding contract under the Indian Contract Act, 1872. If Registered User is a minor i.e., under the age of 18 years, you may use Visava Platform only with the involvement of a parent or guardian. The User may use Visava Platform and shall have access to the platform while a User can also register on the Visava Platform and create an account to become a Registered User and get complete access and avail the paid services listed on the Visava Platform.</li>
                    <li>While creating an account and to become a Registered User, the User has to provide his/her/their personal information such as full name, e-mail ID, mobile number, property specifications, OTP and Location. The User may register by providing his/her/their Mobile Number and email and thereafter the Owner through its backend shall send a system generated email and OTP on the provided email address and/or mobile number of the user for verification. Only upon completing the said verification by the User, the User’s Visava account will be created, and User will become a Registered User.</li>
                    <li>After completion of registration, Registered User can avail the services listed on the Visava Platforms viz., 3D (three dimensional) models, 2D (two dimensional) drawings, costing of the building, bill of materials etc. based on user parameters.</li>
                    <li>The Registered User agrees that the Information provided by him/her/them at the time of registration is true, complete and correct and that any change in the Information will be updated to the Owner with immediate effect of the change. If the Information details are found not to be true (wholly or partly), the Owner has the right in its sole discretion to reject the registration and debar the Registered User from using the Services of the Visava Platform and/or other affiliated Website/Websites without prior intimation whatsoever.</li>
                    <li>The Registered User agrees that he/she are using the Visava Platform services on their own will and that the Owner shall not be responsible.</li>
                    <li>The Owner reserves the right at its sole discretion to terminate, refuse, restrict or suspend any Registered User’s account at any time, for any reason, without notice, including when a Registered User fails to abide by these Terms and Conditions, or any other policy or terms posted anywhere on the Visava Platform.</li>
                    <li>The Owner reserves the right at its sole discretion to terminate, refuse, restrict or suspend any Registered User’s account at any time if any Registered User –
                      <ol type='i'>
                        <li>uploads pornographic contents/photos/videos/links on Visava Platform.</li>
                        <li>uploads any detrimental or offensive contents/photos/videos/links or offensive comments/photos/videos/links pertaining to religion on Visava Platform.</li>
                        <li>Any content that promotes or incites violence, terrorism, illegal acts, or hatred on the grounds of race, ethnicity, cultural identity, religious belief, disability, gender, identity or sexual orientation, or is otherwise objectionable.</li>
                      </ol>
                    </li>
                    <li>The Registered User or may be prohibited from creating a new account if he/she/they has/have been previously terminated as per the above- mentioned circumstances or for any other reason whatsoever objectionable by the Owner.</li>
                    <li>If any Registered User misuses the details of any other Registered User, then the Owner reserves the right at its sole discretion to terminate that Registered User account and the owner reserves the right at its sole discretion to initiate appropriate legal action against the Registered User(s).</li>
                  </ol>
                </li>
                <li>
                  <div className='title highlight'>Facilities for Users:</div>
                  The facilities for user are as follows-
                  <ol type='a'>
                    <li>Browse the Visava Platform;</li>
                  </ol>
                </li>
                <li>
                  <div className='title highlight'>Facilities for Registered Users:</div>
                  The facilities for Registered Users are as follows-
                  <ol type='a'>
                    <li>To access and use customised designs in the form of interactive 3D models and 2D plans;</li>
                    <li>To access material specifications;</li>
                    <li>To calculate the costing as per Registered User’s budget;</li>
                    <li>Can contact the listed Vendors for fulfilment of material requirements and any other similar needs.</li>
                  </ol>
                </li>
                <li>
                  <div className='title highlight'>Use of Contents and restrictions on Registered User:</div>
                  <ol type='a'>
                    <li>The Registered User agrees that the Visava Platform may contain whether directly or indirectly graphics designs, materials, ideas, plans, information’s, and any such contains available useful for building Registered User’s virtual specimen home.</li>
                    <li>The Registered User agrees not to display, upload, modify, publish, transmit, update, share or sell, reproduce, modify any content available on the Visava platform.</li>
                    <li>The Registered User agrees that the specimen house displayed on the Visava Platform after providing his/her/their property description is just a sample design provided for the Registered User’s reference. If in case the Registered User implements and/or uses the said specimen house for building his/her/their house, the owner shall not be held liable or responsible for the final construction of his/her/their house.</li>
                    <li>The Registered User agrees that the owner of the Visava Platform shall not be liable and/or responsible for any arrangements, agreements, disputes etc., between the Registered User and the Vendors listed on the Visava Platform.</li>
                    <li>The Registered User agrees not to display, upload, modify, publish, transmit, update, share or otherwise make available any content and/or material which is in violation of patents, trade mark, copyright or any other proprietary rights of other person/people.</li>
                    <li>The Registered User can only access the graphics, designs, materials, ideas, plans, information, photographs, etc., and such other similar contents available on the Visava Platform only for his/her/their purpose and shall not copy/modify/broadcast/share the content from the Visava Platform for anybody else.</li>
                  </ol>
                </li>
                <li>
                  <div className='title highlight'>Notification Settings:</div>
                  A User or Registered User agrees to receive messages or emails from VISAVA for notifications purpose.
                </li>
                <li>
                  <div className='title highlight'>Linking to sites:</div>
                  For convenience and enjoyment of the Registered User, Visava may provide links to other web sites on the world wide web that are not operated by Visava Platform. The said links are neither endorsed or, approved or sponsored by Visava Platform nor Visava Platform is liable if in case any information available at the linked site or any products that may be sold their results in any damage from such use of information.
                </li>
                <li>
                  <div className='title highlight'>Conduct of Registered Users:</div>
                  The Registered User agrees that the specimen house displayed on the Visava Platform after providing his/her/their property description is just a sample design provided for the Registered User’s reference. If in case the Registered User wishes to
                  implement and/or use the said specimen house for building his/her/their house, the Registered User shall be entirely responsible for all the compliance of all the laws, rules or regulations etc., obtaining requisite permission from the concern local authorities etc.
                </li>
                <li>
                  <div className='title highlight'>Applicable laws and Jurisdiction:</div>
                  These terms shall be governed, interpreted, and construed in accordance with the laws of the Republic of India applicable from time to time. The Visava Platform and the Registered User agrees to submit to the exclusive jurisdiction of courts located in Pune, Maharashtra, India for any claims, actions, demands, suits, disputes arising out of and/or in connection with Services provided by Visava.
                </li>
                <li>
                  <div className='title highlight'>Limitation of Liability:</div>
                  <ol type='a'>
                    <li>To the fullest extent permissible by law, in no event shall the Owner or its employees, group companies or agents be liable for any direct, indirect, special, consequential damages or damages of any other kind incurred by the Registered User arising out of the Registered User’s access or use or inability to access or use Visava Platform and/or its Services.</li>
                    <li>The Registered User agrees that the specimen house displayed on the Visava Platform after providing his/her/their property description and using graphics, designs, materials, ideas, plans, information, photographs, etc. and such other similar contents to support and help user in building his/her/their home is just a sample design provided for the Registered User’s reference and if in case the Registered User wishes to implement and/or use the said specimen house for building his/her/their house, then the owner of Visava platform assumes no liability with respect to the design, graphic, structure of the final constructed house.</li>
                    <li>The Owner shall not be held liable and/or responsible for any fake identity, fake calls, frauds caused through people through the help of Visava Platform.</li>
                    <li>In any event, the Owner will not be held liable for any loss or damage including without limitation, indirect or consequential loss or damage or any loss or damage whatsoever arising from loss of data arising out of, or in connection with, the use of Visava Platform.</li>
                    <li>The Registered User expressly agrees that the Owner is not responsible for the information posted and/or shared by the Registered User.</li>
                    <li>The Registered User expressly agrees that Owner is not responsible and/or liable for any threatening, defamatory, obscene, unlawful, harmful, offensive, or illegal content or conduct of any other party or any infringement of another’s rights, including intellectual property rights. The Registered User specifically agrees that the Owner will not be held responsible and/or liable for any content/data sent using and/or included on /Visava Platform or Service by any third party/parties.</li>
                    <li> NOTWITHSTANDING anything contained herein, in any event, the liability of financial or otherwise, suffered to the Owner of the Visava Platform shall be limited to the amount paid by the Registered User and shall not exceed the same.</li>
                  </ol>
                </li>
                <li>
                  <div className='title highlight'>Disclaimer:</div>
                  Every effort is made to keep the Visava Platform up and running smoothly. However, the Owner takes no responsibility for, and will not be held liable for the Visava Platform being temporarily unavailable due to technical issues beyond our control.
                </li>
                <li>
                  <div className='title highlight'>Warranty and Indemnification:</div>
                  <ol type='a'>
                    <li>Visava Platform is provided by the Owner, on “AS IS” and “AS AVAILABLE” basis without express or implied warranty or condition of any kind. The Registered User agrees that he/she/they are using the services provided on the Visava Platform at his/her/their own risk. The Visava Platform disclaims all warranties, express or implied, including but not limited to, implied warranties of merchantability.</li>
                    <li>The details and/or information provided by the Owner make no representations or warranties of any kind, express or implied, about the completeness, accuracy, reliability, suitability, or availability with respect to the Visava Platform and/or the information contained on the Visava Platform for any purpose. Any relation the Registered User places on such information are therefore strictly at their own risk.</li>
                    <li>The Registered User agrees that the Owner is not held responsible or held liable for any transaction between the Registered User and the vendors listed on the Visava Platform or any third-party providers (including payment gateways etc.) of Visava Platform or Services provided through Visava Platform. No advice or information whether oral or in writing received from the Registered User of the Visava Platform shall create any warranty on behalf of the Visava Platform in this regard.</li>
                    <li>The Registered User agrees to always indemnify, defend, and hold harmless the Owner from any suits, claims, losses, fines, penalties, damages, liabilities, demands, expenses including, but not limited to, reasonable legal expenses and accounting fees arising out of and/or in connection with any violation and/or act and/or omission committed and/or alleged to be committed by the Registered User.</li>
                  </ol>
                </li>
                <li>
                  <div className='title highlight'>Intellectual Property:</div>
                  The User and Registered User acknowledges and accepts that unless otherwise expressly stated, all the content on the Visava Platform including, but not limited to, images, graphics, designs, materials, videos, ideas, plans, information, photographs, trade names, trademarks, logos, labels, service marks and/or any other proprietary
                  marks are either owned by the Owner or licensed thereto. No information, content or portion uploaded on the Visava Platform shall be reproduced, duplicated, copied, sold, or otherwise exploited for any commercial purposes not expressly permitted by Owner. It is the policy of the Visava Platform and/or Owner to terminate in its sole discretion, accounts of Registered User or in appropriate circumstances found repeatedly infringing the rights of Owner of copyright, trademark or other intellectual property rights. Visava Platform, its Services and all the content is protected by copyright, trademark and other relevant laws of India. In case of any violation user or registered user is liable to appropriate legal action.
                </li>
                <li>
                  <div className='title highlight'>Payment Terms:</div>
                  <ol type='a'>
                    <li>The Registered User shall avail services by paying the subscription fees by selecting the appropriate payment option viz., UPI, Internet banking, Credit/Debit Cards, Third Party payment gateways. The Registered User after selecting any of the aforesaid payment options shall be redirected to an external payment gateway on which the Registered User is required to follow the instructions for the effecting the online payment. The payment gateway is not under the owner’s control and the owner shall not be in any case responsible and/or liable to any extent whatsoever for any payment related or internet related issues faced by the Registered User while initiating the said payment for example payment failure and debit to the Registered User’s account etc.</li>
                    <li>Visava Platform reserves the right to use third party platform to co-ordinate such payment transaction by the Registered Users. The Owner shall not be held responsible for loss or damages cost to the Registered User by such third party/parties.</li>
                  </ol>
                </li>
                <li>
                  <div className='title highlight'>Cancellation and Refund:</div>
                  The price paid for the any paid services availed by the Registered User are non-refundable and non-transferable.
                </li>
                <li>
                  <div className='title highlight'>Entire Agreement and Severability Clause:</div>
                  These Terms of Use contain the entire agreement between the parties in relation to its subject matter. If at any time any part of these Terms of Use is held to be or becomes void or otherwise unenforceable for any reason under any applicable law, the same shall be deemed omitted from these Terms of Use and the validity and/or enforceability of the remaining provisions of these Terms of Use shall not in any way be affected or impaired as a result of that omission.
                </li>
                <li>
                  Google Play and the Google Play logo are trademarks of Google LLC.
                </li>
                <li>
                  <div className='title highlight'>Contact:</div>
                  Any questions regarding this Terms of Use should be sent to  <a href='mailto:enquiry@visava.in'
                    className='highlight'>{CONTACT_DETAILS.email}</a>.
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default TermsConditions;