/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import Slider from 'react-slick';
import { connect } from 'react-redux';
//import MobileImg1 from '../../../../../../src/asset/images/mobile_carousal_1.svg';
// import MobileImg2 from '../../../../../../src/asset/images/mobile_carousal_2.svg';
// import MobileImg3 from '../../../../../../src/asset/images/mobile_carousal_3.svg';
import MobileImagefloorPlan from '../../../../../asset/images/MobileImagefloorPlan.png';
import MobileImageHouse from '../../../../../asset/images/MobileImageHouse.png';
import MobileImageBoM from '../../../../../asset/images/MobileImageBoM.png';
import ForwardArrow from '../../../../../../src/asset/images/forward_arrow.svg';
import ForwardArrowThin from '../../../../../../src/asset/images/forword_arrow_thin.svg';
import { withRouter } from 'react-router';
import { userAgent } from '../../../../../api/api-config';
//import { breakPoint_L } from '../../../../../utility/breakPoints';
//import SampleDocument from '../../../sampleDocument';
//import { constants } from '../../../../../utility/constants';
import { withTranslation } from 'react-i18next';
import { getBreakpoint } from '../../../../../selectors/commonSelectors';
//import { windowOpenHandler } from '../../../../../utility/common';

class VisawaCarousal extends Component {

  constructor (props) {
    super(props);
    this.OnButtonClick=this.OnButtonClick.bind(this);
    this.onGetStartedClick=this.onGetStartedClick.bind(this);
    this.closePopUp=this.closePopUp.bind(this);
    this.state = {
      showSampleDocuments: false
    };
  }

  OnButtonClick () {
    //this.setState({ showSampleDocuments:true });
    const { history } = this.props;
    const langCode = localStorage.getItem('lang');
    history && history.push(`/sample-document?preferred_language=${langCode}&user_agent=${userAgent}`);
    //windowOpenHandler(`/sample-document?preferred_language=${langCode}&user_agent=${userAgent}`);
  }

  closePopUp () {
    this.setState({
      showSampleDocuments: false
    });
  }

  onGetStartedClick () {
    const { history } = this.props;
    const jwtToken = localStorage.getItem('jwtToken');
    if (jwtToken) {
      history.push('/homes');
    } else {
      history.push('/login');
    }
  }

  render () {
    const settings = {
      dots: true,
      lazyLoad: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      initialSlide: 0,
      arrows: true,
      //infinite: false
    };
    const { t, breakpoint }=this.props;
    //const { showSampleDocuments } = this.state;

    return (
      breakpoint == 'sm' ? <div className='carousal-container'>
        {/* {showSampleDocuments && <SampleDocument closePopUp={this.closePopUp} />} */}
        <div className='carousal-wrapper'>
          <Slider {...settings}>
            <div>
              <div className='text-box'>
                <div className='offer_tag'>
                  <div>{t('offer_key')}</div>
                </div>
                <div className='visava-web'>
                  {t('use_visava_on_web')}
                </div>
                <div className='visava-web-costing'>
                  {t('get_design_costing_starting')} <span className='original_price'> {t('amount')}</span> {t('get_design_costing_ending')}
                </div>
              </div>
              <div className='carousal-image-wrapper'>
                <img className='carousal-image'
                  //src={MobileImg1}
                  src={MobileImagefloorPlan}
                  alt='' />
              </div>
            </div>
            <div>
              <div className='text-box'>
                <div className='offer_tag'>
                  <div>{t('offer_key')}</div>
                </div>
                <div className='visava-web'>
                  {t('use_visava_on_web')}
                </div>
                <div className='visava-web-costing'>
                  {t('get_design_costing_starting')} <span className='original_price'>{t('amount')}</span> {t('get_design_costing_ending')}
                </div>
              </div>
              <div className='carousal-image-wrapper'>
                <img className='carousal-image'
                  //src={MobileImg2}
                  src={MobileImageHouse}
                  alt='' />
              </div>
            </div>
            <div>
              <div className='text-box'>
                <div className='offer_tag'>
                  <div>{t('offer_key')}</div>
                </div>
                <div className='visava-web'>
                  {t('use_visava_on_web')}
                </div>
                <div className='visava-web-costing'>
                  {t('get_design_costing_starting')} <span className='original_price'> {t('amount')}</span> {t('get_design_costing_ending')}
                </div>
              </div>
              <div className='carousal-image-wrapper'>
                <img className='carousal-image'
                  //src={MobileImg3}
                  src={MobileImageBoM}
                  alt='' />
              </div>
            </div>
          </Slider>
        </div>
        <div className='carousal-buttons'>
          <div className='sample-docs'
            onClick={this.OnButtonClick} >{t('view_sample_doc')}</div>
          <div className='get-started-wrapper'
            onClick={this.onGetStartedClick}>
            <div className='get-started'>
              {t('get_started')}
            </div>
            <div className='arrow-wrapper'>
              <img className='forward-arrow'
                src={ForwardArrow}
                alt='' />
            </div>
          </div>
        </div>
      </div> : <div className='carousal-container-l'>
        {/* {showSampleDocuments && <SampleDocument closePopUp={this.closePopUp} />} */}
        <Slider {...settings}>
          <div className='carousal-wrapper-l'>
            <div className='text-container-l r'>
              <div className='offer_tag'>
                <div>{t('offer_key')}</div>
              </div>
              <div className='text-box'>
                <div className='visava-web'>
                  {t('use_visava_on_web')}
                </div>
                <div className='visava-web-costing'>
                  {t('get_design_costing_starting')} <span className='original_price'> {t('amount')}</span> {t('get_design_costing_ending')}
                </div>
                <div className='carousal-buttons'>
                  <div className='sample-docs'
                    role='button'
                    onClick={this.OnButtonClick} >{t('view_sample_doc')}</div>
                  {/* {showSampleDocuments && <SampleDocument closePopUp={this.closePopUp} />} */}
                  <div className='get-started-wrapper'
                    onClick={this.onGetStartedClick}>
                    <div className='get-started'>
                      {t('get_started')}
                    </div>
                    <div className='arrow-wrapper'>
                      <img className='forward-arrow'
                        src={ForwardArrowThin}
                        alt='' />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='image-container-l'>
              <img className='carousal-image'
                //src={MobileImg1}
                src={MobileImagefloorPlan}
                alt='' />
            </div>
          </div>
          <div className='carousal-wrapper-l'>
            <div className='text-container-l'>
              <div className='offer_tag'>
                <div>{t('offer_key')}</div>
              </div>
              <div className='text-box'>
                <div className='visava-web'>
                  {t('use_visava_on_web')}
                </div>
                <div className='visava-web-costing'>
                  {t('get_design_costing_starting')} <span className='original_price'> {t('amount')}</span> {t('get_design_costing_ending')}
                </div>
                <div className='carousal-buttons'>
                  <div className='sample-docs'
                    role='button'
                    onClick={this.OnButtonClick} >{t('view_sample_doc')}</div>
                  {/* {showSampleDocuments && <SampleDocument closePopUp={this.closePopUp} />} */}
                  <div className='get-started-wrapper'
                    onClick={this.onGetStartedClick}>
                    <div className='get-started'>
                      {t('get_started')}
                    </div>
                    <div className='arrow-wrapper'>
                      <img className='forward-arrow'
                        src={ForwardArrow}
                        alt='' />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='image-container-l'>
              <img className='carousal-image'
                //src={MobileImg2}
                src={MobileImageHouse}
                alt='' />
            </div>
          </div>
          <div className='carousal-wrapper-l'>
            <div className='text-container-l'>
              <div className='offer_tag'>
                <div>{t('offer_key')}</div>
              </div>
              <div className='text-box'>
                <div className='visava-web'>
                  {t('use_visava_on_web')}
                </div>
                <div className='visava-web-costing'>
                  {t('get_design_costing_starting')} <span className='original_price'> {t('amount')}</span> {t('get_design_costing_ending')}
                </div>
                <div className='carousal-buttons'>
                  <div className='sample-docs'
                    role='button'
                    onClick={this.OnButtonClick} >{t('view_sample_doc')}</div>
                  {/* {showSampleDocuments && <SampleDocument closePopUp={this.closePopUp} />} */}
                  <div className='get-started-wrapper'
                    onClick={this.onGetStartedClick}>
                    <div className='get-started'>
                      {t('get_started')}
                    </div>
                    <div className='arrow-wrapper'>
                      <img className='forward-arrow'
                        src={ForwardArrow}
                        alt='' />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='image-container-l'>
              <img className='carousal-image'
                //src={MobileImg3}
                src={MobileImageBoM}
                alt='' />
            </div>
          </div>
        </Slider>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  breakpoint : getBreakpoint(state)
});


export default withTranslation()(withRouter(connect(mapStateToProps, null)(VisawaCarousal)));