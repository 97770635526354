/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PuneHome from '../../../../../../src/asset/images/pune-home.png';
import AssamHome from '../../../../../../src/asset/images/assam-home.png';
import RanchiHome from '../../../../../../src/asset/images/ranchi-home.png';
import GoaHome from '../../../../../../src/asset/images/goa-home.png';
import ManaliHome from '../../../../../../src/asset/images/Manali.png';
import IndoreHome from '../../../../../../src/asset/images/Indore.png';
import ManipurHome from '../../../../../../src/asset/images/Manipur.png';
import SolapurHome from '../../../../../../src/asset/images/Solapur.png';
import ShimlaHome from '../../../../../../src/asset/images/Shimla.png';
//import { breakPoint_L } from '../../../../../utility/breakPoints';
import Carousel from 'react-multi-carousel';
//import { constants } from '../../../../../utility/constants';
import { withTranslation } from 'react-i18next';
import { getBreakpoint } from '../../../../../selectors/commonSelectors';

class HomesByVisawa extends Component {

  constructor (props) {
    super(props);
    this.viewMore=this.viewMore.bind(this);
    this.state = {
      showAll: false
    };
  }

  viewMore () {
    this.setState({
      showAll: true
    });
  }

  render () {
    // eslint-disable-next-line react/prop-types
    const { t, breakpoint }=this.props;
    const { showAll } = this.state;
    const settings = {
      dots: true,
      lazyLoad: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      initialSlide: 0,
      arrows: true,
      //infinite: false
    };

    const allPlaces = [
      {
        img: PuneHome,
        place:t('pune')
      },
      {
        img: AssamHome,
        place: t('assam')
      },
      {
        img: RanchiHome,
        place: t('ranchi')
      },
      {
        img: GoaHome,
        place: t('goa')
      },
      {
        img: ManaliHome,
        place: t('manali')
      },
      {
        img: IndoreHome,
        place: t('indore')
      },
      {
        img: ManipurHome,
        place: t('manipur')
      },
      {
        img: SolapurHome,
        place: t('solapur')
      },
      {
        img: ShimlaHome,
        place: t('shimla')
      },
    ];

    const places = [
      {
        img: PuneHome,
        place: t('pune')
      },
      {
        img: AssamHome,
        place: t('assam')
      },
      {
        img: RanchiHome,
        place: t('ranchi')
      },
      {
        img: GoaHome,
        place: t('goa')
      },
    ];

    const responsive = {
      superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000,
          min: 1920 },
        items: 5,
        partialVisibilityGutter: -10
      },
      desktopLg: {
        breakpoint: { max: 1919,
          min: 1200 },
        items: 4,
        partialVisibilityGutter: -30
      },
      desktop: {
        breakpoint: { max: 1199,
          min: 992 },
        items: 3,
        partialVisibilityGutter: -20
      },
      tablet: {
        breakpoint: { max: 991,
          min: 768 },
        items: 3,
        partialVisibilityGutter: -90
      }
    };
    return (
      <div className='homes-container'>
        <div className='homes-by-visava'>
          {t('homes_by_visava')}
        </div>
        {breakpoint == 'lg' ? <div style={{ width: '100%' }}>
          <Carousel
            responsive={responsive}
            showDots={false}
            additionalTransfrom={0}
            draggable
            partialVisible>
              
            {allPlaces && allPlaces.map((element) => (
              <div className='homes-wrapper'
                key={element && element.place}>
                <div className='image-wrapper'>
                  <img className='home-image'
                    src={element && element.img}
                    alt='' />
                </div>
                <div className='town-wrapper'>
                  {element && element.place}
                </div>
              </div>
            ))}
          </Carousel>

        </div> : <div>
          {!showAll ? places && places.map((element) => (
            <div className='homes-wrapper'
              key={element && element.place}>
              <div className='image-wrapper'>
                <img className='home-image'
                  src={element && element.img}
                  alt='' />
              </div>
              <div className='town-wrapper'>
                {element && element.place}
              </div>
            </div>
          )) : allPlaces && allPlaces.map((element) => (
            <div className='homes-wrapper'
              key={element && element.place}>
              <div className='image-wrapper'>
                <img className='home-image'
                  src={element && element.img}
                  alt='' />
              </div>
              <div className='town-wrapper'>
                {element && element.place}
              </div>
            </div>
          ))}
          {!showAll && <div className='view-more-wrapper'
            onClick={this.viewMore}>{t('view_more').toUpperCase()}</div>}
        </div>}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  breakpoint : getBreakpoint(state)
});

export default withTranslation()(connect(mapStateToProps, null)(HomesByVisawa));