import React, { Component } from 'react';
import GoogleMaps from '../../client/components/googleMaps';

class Maps extends Component {
  render () {
    return (
      <GoogleMaps />
    );
  }
}

export default Maps;