import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import healthCheckReducer from './healthCheckReducer';
import designHouse from './designMyHouseReducer';
import homeDetailsState from './initiatePayment';
import headerState from './headerState';

export default combineReducers({
  routing: routerReducer,
  healthCheck: healthCheckReducer,
  designMyHouseData: designHouse,
  homeDetailsState: homeDetailsState,
  headerState: headerState
});