/* eslint-disable react/prop-types */
/* eslint-disable no-console */
import React, { Component } from 'react';
import { BASE_URL_V4, API_URL } from '../../../api/api-config';
//import { constants } from '../../../utility/constants';
import Loader from '../../shared-ui/Loader';
import { withTranslation } from 'react-i18next';
import { getBreakpoint } from '../../../selectors/commonSelectors';
import { withRouter } from 'react-router';
import ErrorPopup from '../errorPopup';
import OtpInput from 'react-otp-input-visio';
import ContactUs from '../dashboard/home/contactUs/index.js';
import GetInTouch from '../dashboard/home/getInTouch/index.js';
import { axiosInstance } from '../../../api/coreApi';
import LargeEcosystem from '../../../asset/images/large_ecosystem.svg';
import BusinessGrowth from '../../../asset/images/business_growth.svg';
import FlexibleModels from '../../../asset/images/flexible_models.svg';
import UserIcon from '../../../../src/asset/images/user_icon.svg';
import EmailIcon from '../../../../src/asset/images/email-open.svg';
import MobileIcon from '../../../../src/asset/images/phonelink.svg';
import CompanyIcon from '../../../../src/asset/images/company_icon.svg';
import { connect } from 'react-redux';

class VisavaVendor extends Component {

  constructor (props) {
    super(props);
    this.onFullNameChange=this.onFullNameChange.bind(this);
    this.onCompanyNameChange=this.onCompanyNameChange.bind(this);
    this.onEmailChange=this.onEmailChange.bind(this);
    //this.onPhoneChange=this.onPhoneChange.bind(this);
    this.onSubmit=this.onSubmit.bind(this);
    //this.onDescriptionChange=this.onDescriptionChange.bind(this);
    //this.isNumeric=this.isNumeric.bind(this);
    this.inputNumber=this.inputNumber.bind(this);
    this.gotoDesignMyHouse=this.gotoDesignMyHouse.bind(this);
    this.state = {
      FullName: '',
      CompanyName: '',
      email: '',
      PhoneNumber: '',
      Description:'',
      errorMessage: '',
      allReq: false,
      emailError: false,
      phoneError: false,
      isLoading: false,
      showErrorPopUp: false,
    };
  }

  componentDidMount () {
    window.scrollTo({ top: 0 });
  }
  
  // isNumeric (value) {
  //   return /^-?\d+$/.test(value);
  // }

  onFullNameChange (e) {
    this.setState({
      FullName: e && e.target && e.target.value,
    });
  }

  onCompanyNameChange (e) {
    this.setState({
      CompanyName: e && e.target && e.target.value
    });
  }

  onEmailChange (e) {
    this.setState({
      email: e && e.target && e.target.value,
      emailError: false
    });
  }

  // onPhoneChange (e) {
  //   this.setState({
  //     PhoneNumber: e && e.target && e.target.value,
  //     phoneError: false
  //   });
  // }

  // onDescriptionChange (e) {
  //   this.setState({
  //     Description: e && e.target && e.target.value,
  //   });
  // }

  gotoDesignMyHouse () {
    const { history } = this.props;
    const jwtToken = localStorage.getItem('jwtToken');
    if (jwtToken) {
      history.push('/homes');
    } else {
      history.push('/login');
    }
  }

  inputNumber (e) {
    this.setState({
      PhoneNumber: e,
      phoneError: false
    });
  }

  onSubmit () {
    const { t } = this.props;
    const { FullName, CompanyName, email, PhoneNumber } = this.state;

    if(FullName == '' || CompanyName == '') {
      this.setState({ allReq:true });
      return;
    }else{
      this.setState({ allReq:false });
    }

    const isEmailValid = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email) ? true : false;
    const isPhoneValid = PhoneNumber && PhoneNumber.length == 10 ? true : false;
    const url = BASE_URL_V4 + API_URL.vendorContactUs;

    const obj = (PhoneNumber == '') ? {
      full_name: FullName,
      company_name: CompanyName,
      email: email
    }:{
      full_name: FullName,
      company_name: CompanyName,
      //email: email,
      mobile_number: PhoneNumber,
      //message_content: Description,
    };

    // const obj = {
    //   full_name: FullName,
    //   company_name: CompanyName,
    //   email: email,
    //   mobile_number: PhoneNumber,
    //   message_content: Description,
    // };
    
    // if (email == '' && isPhoneValid == true || isEmailValid == true && PhoneNumber == '' ) {
    //   this.setState({
    //     emailError: false,
    //     phoneError: false
    //   });
    // } 
    if (isEmailValid == false && PhoneNumber == '') {
      this.setState({
        emailError: true,
        phoneError: false
      });
    } else if (isPhoneValid == false && email == '') {
      this.setState({
        phoneError: true,
        emailError: false,
      });
    } else {
      this.setState({
        isLoading: true
      });
      axiosInstance.post(url, obj).then((res) => {
        const data = res && res.data;
        if (data && data.status && data.status.toLowerCase() == 'success') {
          this.setState({
            FullName: '',
            CompanyName: '',
            email: '',
            PhoneNumber: '',
            //Description:'',
            emailError: false,
            phoneError: false,
            isLoading: false,
            showErrorPopUp: true,
            errorMessage: t('form_submit_thank_you')
          });
        }
      }).catch((err) => {
        this.setState({
          isLoading: false
        });

        console.log(err);
      });
    }
  }

  render () {
    const { t, breakpoint } = this.props;
    const { FullName, CompanyName, email, PhoneNumber, emailError, phoneError, isLoading, allReq } = this.state;
    
    return (
      <div className='banner-container-top'>
        <div className='vendor_banner'>
          <div className='inner_wrapper'>
            <div className='vendor_container'>
              <div className='coming_soon'>
                <div className='txt'>{t('coming')} <span className='bolder'>{t('soon')}</span></div>
              </div>
              <div className='title'>{t('vendor_page_title')}</div>
              <div className='sub_title'>{t('vendor_page_title_desc')}</div>
            </div>
            <div className='vendor-form-container'>
              <Loader
                isLoading={isLoading} />
              <div className='vendor-form-wrapper'>
                <form className='form-wrapper'>
                  {(allReq) && <div className='error-text'>{t('all_fields_required')}</div>}
                  {(emailError || phoneError) && <div className='error-text'>{t('enter_valid_inputs')}</div>}
                  <label className='form-label'>
                    <div className='img-wrapper'>
                      <img className='img'
                        src={UserIcon}
                        alt='' />
                    </div>
                    {t('full_name')}
                  </label>
                  <input className='form-input'
                    type='text'
                    id='fullname'
                    placeholder={t('input_text')}
                    maxLength={250}
                    value={FullName}
                    onChange={(e) => this.onFullNameChange(e)} />
                  <label className='form-label'>
                    <div className='img-wrapper'>
                      <img className='img'
                        src={CompanyIcon}
                        alt='' />
                    </div>
                    {t('company_name')}
                  </label>
                  <input className='form-input'
                    type='text'
                    id='companyname'
                    placeholder={t('input_text')}
                    value={CompanyName}
                    maxLength={250}
                    onChange={(e) => this.onCompanyNameChange(e)} />
                  <label className='form-label'>
                    <div className='img-wrapper'>
                      <img className='img'
                        src={MobileIcon}
                        alt='' />
                    </div>
                    {t('phone_number')}
                  </label>
                  <OtpInput
                    value={PhoneNumber}
                    onChange={this.inputNumber}
                    numInputs={10}
                    isInputNum={true}
                    hasErrored={true}
                    inputStyle='phone-input-container' />
                  <div className='vendor-or-container'>
                    <div className='or-sideline-wrapper left_line'><div className='or-sideline' /></div>
                    <div>{t('or')}</div>
                    <div className='or-sideline-wrapper right_line'><div className='or-sideline' /></div>
                  </div>
                  <label className='form-label'>
                    <div className='img-wrapper'>
                      <img className='img'
                        src={EmailIcon}
                        alt='' />
                    </div>
                    {t('email_address')}
                  </label>
                  <input className='form-input'
                    type='email'
                    id='email'
                    placeholder={t('input_text')}
                    value={email}
                    onChange={(e) => this.onEmailChange(e)} />
                  {/* <label className='form-label'>{t('description')}</label>
                  <input className='form-input'
                    type='text'
                    id='Description'
                    placeholder={t('input_text')}
                    value={Description}
                    onChange={(e) => this.onDescriptionChange(e)} /> */}
                  <div className='form-submit button-pointer'
                    onClick={ this.onSubmit }>{t('submit')}</div>
                </form>
              </div>
            </div>
            {this.state.showErrorPopUp &&
              <ErrorPopup shown={this.state.showErrorPopUp}
                close={() => this.setState({ showErrorPopUp:false })}
                closeOk={() => this.props.history.push('/')}
                buttonLabel='ok'
                message={this.state.errorMessage}
                googleMaps={false}
                feedBackButton={false}
                showPayment={false} />}
          </div>
        </div>
        <div className='visava-vendor-container'>
          <div className='vendor_inner_wrapper'>
            <div className='visava-vendor-label'>{t('vendor_help_section_title')}</div>
            <div className='mobile_viw'>
              <div className='item'>
                <div className='visava-steps-wrapper'>
                  <div className='step-image'>
                    <img className='image'
                      src={LargeEcosystem}
                      alt='' />
                  </div>
                </div>
              </div>
              <div className='item'>
                <div className='step-details'>
                  <div className='heading'>{t('vendor_heading_one')}</div>
                  <div className='description'>{t('vendor_heading_desc_one')}</div>
                </div>
              </div>
              <div className='item'>
                <div className='business-image'>
                  <img className='image'
                    src={BusinessGrowth}
                    alt='' />
                </div>
              </div>
              <div className='item'>
                <div className='visava-steps-wrapper'>
                  <div className='step-details'>
                    <div className='heading'>{t('vendor_heading_two')}</div>
                    <div className='description'>{t('vendor_heading_desc_two')}</div>
                  </div>
                </div>
              </div>
              <div className='item'>
                <div className='visava-steps-wrapper'>
                  <div className='model-image'>
                    <img className='image'
                      src={FlexibleModels}
                      alt='' />
                  </div>
                </div>
              </div>
              <div className='item'>
                <div className='step-details'>
                  <div className='heading'>{t('vendor_heading_three')}</div>
                  <div className='description'>{t('vendor_heading_desc_three')}</div>
                </div>
              </div>
            </div>
            <div className='desktop_view'>
              <div className='bootstrap-wrapper'>
                <div className='row'>
                  <div className='col-md-6'>
                    <div className='item'>
                      <div className='visava-steps-wrapper'>
                        <div className='step-image'>
                          <img className='image'
                            src={LargeEcosystem}
                            alt='' />
                        </div>
                      </div>
                    </div>
                    <div className='item'>
                      <div className='visava-steps-wrapper'>
                        <div className='step-details'>
                          <div className='heading'>{t('vendor_heading_two')}</div>
                          <div className='description'>{t('vendor_heading_desc_two')}</div>
                        </div>
                      </div>
                    </div>
                    <div className='item'>
                      <div className='visava-steps-wrapper'>
                        <div className='model-image'>
                          <img className='image'
                            src={FlexibleModels}
                            alt='' />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <div className='item'>
                      <div className='step-details'>
                        <div className='heading'>{t('vendor_heading_one')}</div>
                        <div className='description'>{t('vendor_heading_desc_one')}</div>
                      </div>
                    </div>
                    <div className='item'>
                      <div className='visava-steps-wrapper'>
                        <div className='business-image'>
                          <img className='image'
                            src={BusinessGrowth}
                            alt='' />
                        </div>
                      </div>
                    </div>
                    <div className='item'>
                      <div className='step-details'>
                        <div className='heading'>{t('vendor_heading_three')}</div>
                        <div className='description'>{t('vendor_heading_desc_three')}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='build-house-container'>
          <div className='build-house-wrapper'>
            <div className='build-house-txt'>{t('vendor_page_lead_title')}</div>
            <div className='button visava-btn'
              onClick={this.gotoDesignMyHouse}>{t('vendor_page_button_label')}
            </div>
          </div>
        </div>
        {breakpoint == 'sm' && <ContactUs {...this.props} />}
        <GetInTouch {...this.props} />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  breakpoint : getBreakpoint(state)
});

export default withTranslation()(withRouter(connect(mapStateToProps, null)(VisavaVendor)));