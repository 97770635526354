/* eslint-disable no-console */
import { API_URL } from '../../../api/api-config';
import { axiosInstance } from '../../../api/coreApi';

export const FETCH_HEALTH_CHECK = 'FETCH_HEALTH_CHECK';

export const fetchHealthCheck = () => {
  const healthCkeck = `${API_URL.healthCheck}`;

  return async (dispatch) => {
    await axiosInstance
      .get(healthCkeck)
      .then(({ data }) => {
        dispatch({ type: FETCH_HEALTH_CHECK,
          data });
      })
      .catch((err) => {
        console.log(err && err.response);
      });
  };
};