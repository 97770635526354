import React, { Component } from 'react';
import Welcome from '../../client/components/welcome';
import Header from '../../client/shared-ui/header/index';

class WelcomeScreen extends Component {
  render () {
    return (
      <><Header /><Welcome /></>
    );
  }
}

export default WelcomeScreen;