import React, { Component } from 'react';
import { YOUTUBE_LINKS } from '../../../../../api/api-config';

class VisawaVideo extends Component {
  
  render () {
    const langCode = localStorage.getItem('lang');
    return (
      <div className='visava-video-container'>
        {langCode === 'en' ?
          <iframe
            width='100%'
            height='100%'
            src={ YOUTUBE_LINKS.how_to_use_visava_english}
            title='YouTube video player'
            frameBorder='0'
            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
            allowFullScreen /> :
          <iframe
            width='100%'
            height='100%'
            src={ YOUTUBE_LINKS.how_to_use_visava_hindi}
            title='YouTube video player'
            frameBorder='0'
            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
            allowFullScreen /> }
      </div>
    );
  }
}

export default VisawaVideo;