/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-key */
/* eslint-disable no-console */
/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getAddress, getBreakpoint } from '../../../selectors/commonSelectors';
import BackButton from '../../../../src/asset/images/back-arrow.svg';
import LocatorImage from '../../../../src/asset/images/locator.svg';
import CurrentLocationImg from '../../../../src/asset/images/current-location.svg';
import { setDMHRedirection } from '../../../utility/common';
import { axiosInstance } from '../../../api/coreApi';
import { GoogleMap, Marker } from '@react-google-maps/api';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng
} from 'react-places-autocomplete';
import Geocode from 'react-geocode';
import { withRouter } from 'react-router';
import Loader from '../../shared-ui/Loader';
import { updateAddress } from '../../redux/actions/designMyHouse';
import { withTranslation } from 'react-i18next';
import { API_URL, BASE_URL_V4, GOOGLE_MAP_API_KEY } from '../../../api/api-config';
import ErrorPopup from '../errorPopup';
import get from 'lodash/get';


class GoogleMaps extends Component {

  constructor (props) {
    super(props);
    this.fetchCurrentLocation=this.fetchCurrentLocation.bind(this);
    this.notifyMeLocation=this.notifyMeLocation.bind(this);
    this.onMapClick=this.onMapClick.bind(this);
    this.handleChange=this.handleChange.bind(this);
    this.handleSelect=this.handleSelect.bind(this);
    this.selectLocation=this.selectLocation.bind(this);
    this.notifyPopoUpFun=this.notifyPopoUpFun.bind(this);
    this.confirmLocation=this.confirmLocation.bind(this);
    this.openLocShareBar=this.openLocShareBar.bind(this);
    this.goBackPrev=this.goBackPrev.bind(this);
    this.fetchCurrentLocationByClick=this.fetchCurrentLocationByClick.bind(this);
    this.getAddressComponent=this.getAddressComponent.bind(this);
    this.lookUpHouseTypologyOptions=this.lookUpHouseTypologyOptions.bind(this);
    this.editHouse = props && props.location && props.location.state && props.location.state.editHouse;
    this.state = {
      showGmapComponent: false,
      showGoogleMap: false,
      currentLocation: false,
      showGPS: false,
      lat: 19.9975,
      lng: 73.7898,
      address: '',
      shortAddress: '',
      longAddress: '',
      searchString: '',
      showErrorPopUp: false,
      showsearchbar: false,
      isLoading: false
    };
  }

  componentDidMount () {
    this.setState({
      isLoading: true
    });
    if(this.props.breakpoint === 'lg') {
      this.fetchCurrentLocation();
    } else {
      this.setState({
        showsearchbar: true,
        isLoading: false,
        showGoogleMap: true,
        currentLocation: true,
        showGPS: false
      });
    }
  }

  goBackPrev () {
    setDMHRedirection();
    this.props.history.replace('/design-my-house', { selectLocation: '',
      editHouse: this.editHouse,
      addressFromMap: false });
  }

  fetchCurrentLocationByClick () {
    this.setState({
      isLoading: false,
      showGoogleMap: true,
      currentLocation: false,
      showGPS: true,
      showsearchbar: false
    });
    navigator.geolocation.getCurrentPosition((pos) => {
      //console.log('Welcome inside Navigator');
      this.setState({
        lat: pos.coords.latitude,
        lng: pos.coords.longitude
      }, () => {
        this.confirmLocation();
      });
    });
    //console.log('Welcome to Navigator 2');
  }

  fetchCurrentLocation () {
    this.setState({
      showsearchbar: true,
      isLoading: false,
      showGoogleMap: true,
      currentLocation: false,
      showGPS: true
    });
    navigator.geolocation.getCurrentPosition((pos) => {
      //console.log('Welcome inside Navigator');
      this.setState({
        lat: pos.coords.latitude,
        lng: pos.coords.longitude
      }, () => {
        this.confirmLocation();
      });
    });
    //console.log('Welcome to Navigator 2');
  }

  getAddressComponent (response, type) {
    for (const component of response.results[0].address_components) {
      if(component.types && component.types.includes(type)) {
        return component.long_name;
      }
    }
  }

  onMapClick (event) {
    //console.log('Welcome to 3');
    this.setState({
      lat: event.latLng.lat(),
      lng: event.latLng.lng()
    }, () => {
      this.confirmLocation();
    });
    //console.log(event.latLng.lat(), event.latLng.lng());
  }

  handleChange (searchString) {
    this.setState({
      searchString
    });
  }

  handleSelect (longAddress, placeID, autoCompleteRes) {
    let address = {};
    const shortAddress = get(autoCompleteRes, 'formattedSuggestion.mainText', get(autoCompleteRes, 'description', ''));
    this.setState({
      showsearchbar: false,
    });
    geocodeByAddress(longAddress, placeID)
      .then((response) => {
        this.setState({
          showGPS: true,
          currentLocation: false,
          shortAddress: shortAddress,
          longAddress
        });
        address = {
          shortAddress,
          longAddress
        };
        return response;
      })
      .then((response) => {
        //console.log('RESP', response);
        //console.log('address', address);

        getLatLng(response[0]);
        this.props.updateAddress({ shortAddress: address.shortAddress,
          longAddress: address.longAddress,
          lat: response[0].geometry.location.lat(),
          lng: response[0].geometry.location.lng() });

        this.setState({
          lat: response[0].geometry.location.lat(),
          lng: response[0].geometry.location.lng(),
        },
        () => {
          //this.fetchCurrentLocation();
        });
        // console.log('Successs', res);
      })
      .then((response) => {
        console.log(response);
        // this.props.updateAddress({ shortAddress: address.shortAddress,
        //   longAddress: address.longAddress,
        //   lat: res.lat,
        //   lng: res.lng });

        // this.setState({
        //   lat: res.lat,
        //   lng: res.lng,
        // },
        // () => {
        //   //this.fetchCurrentLocation();
        // });
        // console.log('Successs', res);
      }).catch((error) => console.error('Error', error));
    
  }

  selectLocation () {
    const { shortAddress } = this.state;
    // console.log('SA:', shortAddress);
    setDMHRedirection();
    this.props.history.replace('/design-my-house', { shortAddress: shortAddress,
      editHouse: this.editHouse,
      addressFromMap: true });
  }

  notifyMeLocation () {
    const { lat, lng, shortAddress, longAddress } = this.state;
    const url = BASE_URL_V4 + API_URL.notifyMeLocation;
    const object = {
      location_latitude: lat,
      location_longitude: lng,
      location_short_address: shortAddress,
      location_long_address: longAddress
    };
    axiosInstance.post(url, object).then((res) => {
      const data = res && res.data;
      // eslint-disable-next-line no-empty
      if (data && data.status && data.status.toLowerCase() == 'success') {
      }
    }).catch((err) => {
      this.setState({
        isLoading: false
      });
      //console.log(err);
    });
  }


  lookUpHouseTypologyOptions () {
    const { t } = this.props;
    const { lat, lng, shortAddress } = this.state;
    //console.log('>>>>> bodyObj.lng', lat, lng, shortAddress);
    const url = BASE_URL_V4 + API_URL.lookUpHouseTypologyOptions;
    const object = {
      location_latitude: lat, //location_latitude
      location_longitude: lng, //location_longitude
    };
    axiosInstance.post(url, object).then((res) => {
      const data = res && res.data;
      // eslint-disable-next-line no-empty
      if (data && data.status && data.status.toLowerCase() == 'success') {
        this.selectLocation();
      }
    }).catch((err) => {
      const errorCodes = [3607, 3608, 3609];
      if (errorCodes.includes(err.response.data.error_code)) {
        this.setState({ showErrorPopUp:true,
          errorMessage1: t('no_house_for_this_location_1'),
          errorMessage2: t('no_house_for_this_location_2') });
      }
      this.setState({
        isLoading: false
      });
    });
  }

  openLocShareBar () {
    if(this.props.breakpoint === 'lg') {
      this.fetchCurrentLocation();
    } else {
      this.setState({
        showsearchbar: true,
        isLoading: false,
        showGoogleMap: true,
        currentLocation: true,
        showGPS: false
      });
    }
  }

  notifyPopoUpFun () {
    this.setState({ showErrorPopUp:false });
    this.notifyMeLocation();
  }

  confirmLocation () {
    Geocode.setApiKey(GOOGLE_MAP_API_KEY);
    Geocode.fromLatLng(this.state.lat, this.state.lng).then(
      (response) => {
        //const shortAddress = response.results[0].formatted_address;
        let shortAddress = '';
        if(response.results[0] && response.results[0].address_components) {
          //console.log('RESP --->', response);
          const sublocality = this.getAddressComponent(response, 'sublocality');
          const political = this.getAddressComponent(response, 'political');
          shortAddress += sublocality ? `${sublocality} ` : `${political} `;
          
  
          const city = this.getAddressComponent(response, 'administrative_area_level_2');
          shortAddress += city ? `${city} ` : '';
          // const state = this.getAddressComponent(response, 'administrative_area_level_1');
          // shortAddress += state ? `${state} ` : '';

          // const postal = this.getAddressComponent(response, 'postal_code');
          // shortAddress += postal ? `${postal}, ` : '';

          // const country = this.getAddressComponent(response, 'country');
          // shortAddress += country ? `${country} ` : '';
        }
        const longAddress = response.results[0].formatted_address;
        this.setState({
          shortAddress,
          longAddress
        });
        this.props.updateAddress({ shortAddress,
          longAddress,
          lat: this.state.lat,
          lng: this.state.lng });

        //console.log(longAddress);
        //console.log(shortAddress);
      },
      (error) => {
        //console.error(error);
      }
    );
  }


  render () {
    const { showGoogleMap, currentLocation, showsearchbar, showGPS, shortAddress, longAddress, lat, lng, isLoading } = this.state;
    //const { address } = this.props;
    const { breakpoint } = this.props;

    //const { lat, lng, } = address;
    const { t } = this.props;
    const defaultBounds = {
      north: 19.14,
      south: 20.63,
      east: 75.30,
      west: 68.14,
    };

    const center = {
      lat: 19.9211 || this.state.lat,
      lng: 72.8255 || this.state.lng,
      //address: '',
      //shortAddress: '' || this.state.shortAddress,
      //longAddress: '' || this.state.longAddress
    };

    const options = {
      disableDefaultUI: true
    };
    return (
      <div>
        <Loader
          isLoading={isLoading} />
        <div className='design-my-house-container-location'>
          <div className='google-map'>
            <div className='google-map-container'>
              {showGoogleMap ?
                <div className='back-btn-container button-pointer'
                  onClick={this.goBackPrev}>
                  <img className='back-btn'
                    src={BackButton}
                    alt='back-button' />
                </div> : <div className='back-btn-container button-pointer'
                  onClick={this.openLocShareBar}>
                  <img className='back-btn'
                    src={BackButton}
                    alt='back-button' />
                </div> }
              {showsearchbar &&
                <PlacesAutocomplete
                  value={this.state.searchString}
                  onChange={this.handleChange}
                  onSelect={this.handleSelect}
                  searchOptions={{
                    componentRestrictions: { country: 'IN' },
                    bounds: new google.maps.LatLngBounds(
                      new google.maps.LatLng(defaultBounds.south, defaultBounds.west),
                      new google.maps.LatLng(defaultBounds.north, defaultBounds.east))
                  }}>
                  {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                    <div style={{ width: '100%' }}>
                      <div style={{
                        position:'relative' }}>
                        <div className='search_icon' />
                        <input
                          {...getInputProps({
                            placeholder: t('search_location_hint'),
                            className: 'search-location',
                          })} />
                      </div>
                      <div className='autocomplete-dropdown-container'>
                        {loading && <div>{t('locating')}</div>}
                        {suggestions.map((suggestion) => {
                          const className = 'suggestion-item';
                          // inline style for demonstration purpose
                          const style = suggestion.active
                            ? { backgroundColor: '#fafafa',
                              cursor: 'pointer' }
                            : { backgroundColor: '#ffffff',
                              cursor: 'pointer' };
                          return (
                            <div
                              {...getSuggestionItemProps(suggestion, {
                                className,
                                style,
                              })}>
                              <div className='location_icon_blue' />
                              <span>{suggestion.description}</span>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </PlacesAutocomplete> }
            </div>
            {currentLocation && <div className='current-location-container button-pointer'
              onClick={this.fetchCurrentLocationByClick}>
              <div className='cur-loc-img-container'>
                <img className='img-wrapper'
                  src={CurrentLocationImg}
                  alt='Current Location Image' />
              </div>
              <div className='current-location-wrapper'>
                <div className='current-location'>{t('current_location')}</div>
                <div className='using-gps'>{t('using_gps')}</div>
              </div>
            </div>}
          </div>
          {showGPS ?
            <div>
              <GoogleMap
                mapContainerStyle={{ width: '100vw',
                  height: 'calc(100vh - 260px)' }}
                zoom={6}
                center={center}
                options={options}
                onClick={this.onMapClick}>
                {lat != null && lng != null && <Marker
                  position={{ lat: this.state.lat,
                    lng: this.state.lng }} />}
              </GoogleMap>
              {showGPS ?
                <div className='confirm-location-container'>
                  <div className='location-name-container'>
                    { showGPS && <div style={{ display:'flex',
                      alignItems: 'center' }}>
                      <div className='image-wrapper'>
                        <img className='image'
                          src={LocatorImage}
                          alt='' />
                      </div>
                      <div className='location-name'>
                        {shortAddress}
                      </div>
                    </div> }
                    {!showsearchbar ?
                      <div className='confirm-location-wrapper-change button-pointer'
                        onClick={this.openLocShareBar}>{t('change')}
                      </div> : null }
                  </div>
                  <div className='location-detail'>{longAddress}</div>
                  <div className='confirm-location-wrapper button-pointer'
                    onClick={this.lookUpHouseTypologyOptions}>{t('confirm_location')}</div>
                  <div>
                    {this.state.showErrorPopUp &&
                      <ErrorPopup shown={this.state.showErrorPopUp}
                        paymentStatus={'FAILURE'}
                        googleMaps={true}
                        close={() => this.setState({ showErrorPopUp:false })}
                        closeOk={this.notifyPopoUpFun}
                        buttonLabel='notify_me'
                        message={this.state.errorMessage1}
                        feedBackButton={false}
                        anotherMessage={this.state.errorMessage2}
                        showPayment={false}
                        showGoogleClose={false} />}
                  </div>
                </div> : null }
            </div> : null }
        </div>
      </div>
    
    );
  }
}

const mapStateToProps = (state) => ({
  address : getAddress(state),
  breakpoint : getBreakpoint(state)
});

// eslint-disable-next-line no-unused-vars
const mapDispatchToProps = (dispatch) => ({
  updateAddress: (data) => {
    dispatch(updateAddress(data));
  },
});

export default withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(GoogleMaps)));
