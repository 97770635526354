/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import './paymentSuccessPage.scss';
import { windowOpenHandler } from '../../../utility/common';
import { withRouter } from 'react-router-dom';
import { userAgent } from '../../../api/api-config';
import PaymentSuccess from '../../../asset/images/payment_success.svg';
//import DesigningHouse from '../../../asset/images/designing_house.svg';
import { withTranslation } from 'react-i18next';

class PaymentSuccessPage extends Component {
  constructor (props) {
    super(props);
    this.gotoHomeDetails = this.gotoHomeDetails.bind(this);
    this.viewDesignDoc = this.viewDesignDoc.bind(this);
  }


  gotoHomeDetails () {
    // eslint-disable-next-line react/prop-types
    // eslint-disable-next-line react/prop-types
    this.props.continueDoc && this.props.continueDoc();
  }

  viewDesignDoc () {
    const houseId = localStorage.getItem('houseId');
    const shareToken = localStorage.getItem('shareToken');
    const langCode = localStorage.getItem('lang');
    windowOpenHandler(`/design-document?house_id=${houseId}&share_token=${shareToken}&preferred_language=${langCode}&user_agent=${userAgent}`);
  }

  render () {
    // eslint-disable-next-line react/prop-types
    const { t }=this.props;
    return (
      <div className='payment_container'>
        <div className='close_icon_wrapper'>
          <div className='close_icon'
            onClick={this.gotoHomeDetails} />
        </div>
        <div className='image'>
          <img src={PaymentSuccess} />
        </div>
        <div className='description'>
          {t('successful_payment_desc')}
        </div>
        {/* {this.props.paymentStatus == 'success' &&
          <><div className='title'>{t('successful_payment')}</div>
            <div className='description'>
              {t('successful_payment_desc')}
            </div></>}
        {this.props.paymentStatus == 'failed' &&
          <><div className='title'>{t('failed_payment')}</div>
            <div className='description'>
              {t('failure_payment_desc')}
            </div></>}
        {this.props.paymentStatus == 'pending' &&
          <><div className='title'>{t('pending_payment')}</div>
            <div className='description'>
              {t('pending_payment_desc')}
            </div></>} */}
        <div className='button'
          onClick={this.viewDesignDoc} >{t('view_design_doc')}</div>
      </div>

    // Use following component for designing house screen 4.6
    // <div className='payment_processing_container'>
    //   <div className='image'>
    //     <img src={DesigningHouse} />
    //   </div>
    //   <div className='description'>
    //     <div>DESIGNING YOUR HOUSE</div>
    //     <div>THIS MAY TAKE A FEW SECONDS</div>
    //   </div>
    // </div>
    );
  }
}

export default withTranslation()(withRouter(PaymentSuccessPage));