import React, { Component } from 'react';
import VisavaVendor from '../../client/components/visavaVendor';
import Header from '../../client/shared-ui/header/index';

class VisavaVendorPage extends Component {
  render () {
    return (
      <><Header /><VisavaVendor {...this.props} /></>
    );
  }
}

export default VisavaVendorPage;
