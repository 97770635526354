import React, { Component } from 'react';
import VerifyEmailPhoneOTP from '../../client/components/verifyEmailPhoneOTP';
import Header from '../../client/shared-ui/header/index';
import { breakPoint_L } from '../../utility/breakPoints';

class VerifyEmailPhoneOTPPage extends Component {
  render () {
    let Headers;
    if(breakPoint_L==false) {
      Headers=<Header />;
    }
    return (
      <>
        {Headers}
        <VerifyEmailPhoneOTP />
      </>
    );
  }
}

export default VerifyEmailPhoneOTPPage;