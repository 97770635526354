import DesignTheHouse from './designTheHouse';
import Home from './home';
import HomeDetails from './homeDetails';
import LoginPage from './login';
import Maps from './maps';
import Otp from './otp';
import SignUpPage from './signup';
import WelcomeScreen from './welcomeScreen';
import HomesList from './homesList';
import Story from './story';
import Profile from './profile';
import FAQ from './frequentlyQustions';
import FullSCreen from './fullScreen';
import VerifyEmailPhonePage from './verifyEmailPhone';
import VerifyEmailPhoneOTPPage from './verifyEmailPhoneOTP';
import VisavaVendorPage from './visavaVendor';
import DesignDocument from './designDocument';
//import NoPageFound from './PageNotFound';
import PrivacyPolicyDoc from './privacyPolicy/privacyPolicy';
import bomSampleDocument from './bomSampleDocument';
//import PaymentConfirmation from './PaymentConfirmation';
// import PaymentPending from './paymentPendingPage';
// import PaymentSuccess from './paymentSuccessPage';

const ROUTES = [
  {
    path: ['/'],
    component: Home,
    exact: true,
    headerData: {
      showHeader: true,
    }
  },
  {
    path: ['/privacy-policy'],
    component: PrivacyPolicyDoc,
    exact: true,
  },
  {
    path: ['/login'],
    component: LoginPage,
    exact: true,
    headerData: {
      showHeader: true,
      // showHeader: breakPoint_L ? false : true,
      btnText: 'SIGN UP'
    }
  }, {
    path: ['/signup'],
    component: SignUpPage,
    exact: true,
    headerData: {
      showHeader: true,
      // showHeader: breakPoint_L ? false : true,
      btnText: 'LOGIN'
    }
  }, {
    path: ['/otp'],
    component: Otp,
    exact: true,
    headerData: {
      showHeader: true,
      // showHeader: breakPoint_L ? false : true,
      btnText: '',
      page: 'otp'
    }
  }, {
    path: ['/design-my-house'],
    component: DesignTheHouse,
    exact: true,
    headerData: {
      showHeader: true,
      dmh: true
    }
  }, {
    path: ['/welcome'],
    component: WelcomeScreen,
    exact: true,
    headerData: {
      showHeader: false,
    }
  }, {
    path: ['/design-my-house/maps'],
    component: Maps,
    exact: true,
    headerData: {
      showHeader: false,
    }
  }, {
    path: ['/home-details'],
    component: HomeDetails,
    exact: true,
    headerData: {
      showHeader: true,
      dmh: true,
      showPayment: true
    }
  }, {
    path: ['/design-document'],
    component: DesignDocument,
    exact: true,
    headerData: {
      showHeader: true,
    },
  }, {
    path: ['/sample-document'],
    component: bomSampleDocument,
    exact: true,
    headerData: {
      showHeader: true,
    },
  }, {
    path: ['/homes'],
    component: HomesList,
    exact: true,
    headerData: {
      showHeader: true,
      dmh: false
     
    }
  }, {
    path: ['/our-story'],
    component: Story,
    exact: true,
    headerData: {
      showHeader: true,
      //dmh: true
    }
  }, {
    path: ['/my-profile'],
    component: Profile,
    exact: true,
    headerData: {
      showHeader: true,
    }
  }, {
    path: ['/faq'],
    component: FAQ,
    exact: true,
    headerData: {
      showHeader: true,
    }
  },
  {
    path: ['/full-screen'],
    component: FullSCreen,
    exact: true,
    headerData: {
      showHeader: false,
    }
  },
  {
    path: ['/my-profile/verify-email-phone'],
    component: VerifyEmailPhonePage,
    exact: true,
    headerData: {
      showHeader: true,
    }
  },
  {
    path: ['/my-profile/verify-email-phone/otp'],
    component: VerifyEmailPhoneOTPPage,
    exact: true,
    headerData: {
      showHeader: false,
    }
  },
  {
    path: ['/visava-vendors'],
    component: VisavaVendorPage,
    exact: true,
    headerData: {
      showHeader: true,
    },
  },
  // {
  //   path: ['/payment-confirmation'],
  //   component: PaymentConfirmation,
  //   exact: false,
  //   headerData: {
  //     showHeader: false,
  //   },
  // },
  // {
  //   path: ['/payment-pending'],
  //   component: PaymentPending,
  //   exact: true,
  //   headerData: {
  //     showHeader: false,
  //   },
  // },
  // {
  //   path: ['/payment-success'],
  //   component: PaymentSuccess,
  //   exact: true,
  //   headerData: {
  //     showHeader: false,
  //   },
  // },
  // {
  //   component: NoPageFound,
  //   headerData: {
  //     showHeader: true,
  //   }
  // }
  
];

export default ROUTES;