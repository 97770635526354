import React, { Component } from 'react';
import OtpPage from '../../client/shared-ui/otp';
import Header from '../../client/shared-ui/header/index';
import { breakPoint_L } from '../../utility/breakPoints';

class Otp extends Component {
  render () {
    let Headers;
    if(breakPoint_L==false) {
      Headers=<Header />;
    }
    return (
      <>
        { Headers }
        <OtpPage />
      </>
    );
  }
}

export default Otp;