import React, { Component } from 'react';
import { connect } from 'react-redux';
//import { breakPoint_L } from '../../../../../utility/breakPoints';
import Img1 from '../../../../../../src/asset/images/professional_design.gif';
//import Img2 from '../../../../../../src/asset/images/costing.svg';
import FloorPlan from '../../../../../asset/images/FloorPlan2.svg';
//import Img3 from '../../../../../../src/asset/images/under_construction.svg';
import BomImage from '../../../../../asset/images/BomImage.svg';
//import { constants } from '../../../../../utility/constants';
import { withTranslation } from 'react-i18next';
import { getBreakpoint } from '../../../../../selectors/commonSelectors';

class VisawaHelpCenter extends Component {
  render () {
    // eslint-disable-next-line react/prop-types
    const { t, breakpoint }=this.props;
    return (
      <div style={{ backgroundColor:'#F5F5F5',
        width:'100%' }}>
        <div className='visava-help-container'>
          <div className='help_inner_wrapper'>
            <div className='visava-help-label'>{t('how_does_visava_help_you')}</div>
            <div className='mobile_viw'>
              <div className='item'>
                <div className='visava-steps-wrapper'>
                  <div className='step-image'>
                    <img className='image'
                      src={Img1}
                      alt='' />
                  </div>
                </div>
              </div>
              <div className='item'>
                <div className='step-details'>
                  <div className='heading'>{t('professional_design')}</div>
                  {/* <div className='description r'>{t('professional_design_desc')}</div> */}
                  <div className='description'>{t('professional_design_desc_starting')}<span className='bold'>{t('professional_design_desc_bold')}</span><span style={{ fontWeight: 'bold' }}>{t('professional_design_desc_ending')}</span></div>
                  {/* <div className='description'>{t('professional_design_desc_starting')} <span style={{ fontWeight: 'bold' }}>{t('professional_design_desc_ending')}</span></div> */}
                </div>
              </div>
              <div className='item'>
                {breakpoint == 'sm' && <div className='costing-image'>
                  <img className='image'
                    src={FloorPlan}
                    alt='' />
                </div>}
                {breakpoint == 'lg' && <div className='costing-image'>
                  <img className='image'
                    src={FloorPlan}
                    alt='' />
                </div>}
              </div>
              <div className='item'>
                <div className='visava-steps-wrapper'>
                  <div className='step-details'>
                    <div className='heading'>{t('costing')}</div>
                    {/* <div className='description'>{t('costing_desc')}</div> */}
                    <div className='description'>{t('costing_desc_start')} <span className='bold'>{t('costing_desc_middle')} </span><span>{t('costing_desc_ending')}</span></div>
                  </div>
                </div>
              </div>
              <div className='item'>
                <div className='visava-steps-wrapper'>
                  <div className='material-image'>
                    <img className='image'
                      src={BomImage}
                      alt='' />
                  </div>
                </div>
              </div>
              <div className='item'>
                <div className='step-details'>
                  <div className='heading'>{t('materials')}</div>
                  {/* <div className='description'>{t('materials_desc')}</div> */}
                  <div className='description'>{t('materials_desc_start')} <span className='bold'>{t('materials_desc_middle')} </span><span>{t('materials_desc_ending')}</span></div>
                </div>
              </div>
            </div>
            <div className='desktop_view'>
              <div className='bootstrap-wrapper'>
                <div className='row'>
                  <div className='col-md-6'>
                    <div className='item'>
                      <div className='visava-steps-wrapper'>
                        <div className='step-image'>
                          <img className='image'
                            src={Img1}
                            alt='' />
                        </div>
                      </div>
                    </div>
                    <div className='item'>
                      <div className='visava-steps-wrapper'>

                        <div className='step-details'>
                          <div className='heading'>{t('costing')}</div>
                          {/* <div className='description'>{t('costing_desc')}</div> */}
                          <div className='description'>{t('costing_desc_start')} <span className='bold'> {t('costing_desc_middle')} </span><span>{t('costing_desc_ending')}</span></div>
                        </div>
                      </div>
                    </div>
                    <div className='item'>
                      <div className='visava-steps-wrapper'>
                        <div className='material-image'>
                          <img className='image'
                            src={BomImage}
                            alt='' />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <div className='item'>
                      <div className='step-details'>
                        <div className='heading'>{t('professional_design')}</div>
                        {/* <div className='description'>{t('professional_design_desc')}</div> */}
                        <div className='description'>{t('professional_design_desc_starting')}<span className='bold'>{t('professional_design_desc_bold')}</span>{t('professional_design_desc_ending')}</div>
                        {/* <div className='description'>{t('professional_design_desc_starting')} <span style={{ fontWeight: 'bold' }}>{t('professional_design_desc_ending')}</span></div> */}
                      </div>
                    </div>
                    <div className='item'>
                      {breakpoint == 'sm' && <div className='costing-image 1'>
                        <img className='image'
                          src={FloorPlan}
                          alt='' />
                      </div>}
                      {breakpoint == 'lg' && <div className='costing-image 2'>
                        <img className='image'
                          src={FloorPlan}
                          alt='' />
                      </div>}
                    </div>
                    <div className='item'>
                      <div className='step-details'>
                        <div className='heading'>{t('materials')}</div>
                        {/* <div className='description'>{t('materials_desc')}</div> */}
                        <div className='description'>{t('materials_desc_start')} <span className='bold'>{t('materials_desc_middle')} </span><span>{t('materials_desc_ending')}</span></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  breakpoint : getBreakpoint(state)
});

export default withTranslation()(connect(mapStateToProps, null)(VisawaHelpCenter));