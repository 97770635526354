/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import LogoWithText from '../../components/logoWithText';
import { CONTACT_DETAILS } from '../../../api/api-config';


class PrivacyPolicyDoc extends Component {
  constructor (props) {
    super (props);
    this.mailTo=this.mailTo.bind(this);
  }

  mailTo () {
    window.open('mailto:enquiry@visava.in');
  }
 
  render () {
    // eslint-disable-next-line react/prop-types
    const { onClose }=this.props;
    return (
      <div>
        <div className='tnc-container english_font'
          onClick={onClose}>
          <div className='tnc-inner-container'
            onClick={(e) => e.stopPropagation()}>
            <div className='close_icon_wrapper'>
              <div className='close_icon'
                onClick={this.props.onClose} />
            </div>
            <div className='modal-body'>
              <div><LogoWithText /></div>
              <div className='modal-header'>
                Privacy Policy
              </div>
              <p className='paragraph'>
                This Privacy Policy is for Visava Platform and the privacy policy is served by the Owner of the Visava Platform i.e., Visava Labs Private Limited. This privacy policy governs the privacy of its Users/Registered Users that choose to use it.
              </p>
              <p className='paragraph'>
                Visava Platform can be accessed by going on <a href='https://www.visava.in'
                  target='_blank'
                  rel='noreferrer'>www.visava.in</a> and/or the Visava mobile application can be downloaded from Google Play Store as required. Visava Platform provides architectural design solutions wherein a user by providing his/her/their specifications like area, location of the plot etc. and preferences like number of rooms, number of floors etc., can virtually visualize how registered user’s building may look like, on a 3D environment. The user can also acquire the 2D blueprint of the building, the material selection according to the plot location and the cost involved in its construction.
              </p>
              <p className='paragraph'>
                In order to become a Registered User, the User has to Register himself/herself on Visava Platform and create an account for themselves. To become a Registered User, a User has to create his/her/their account on Visava Platform by downloading the Mobile Application from Google Play Store. Then User has to enter his/her/their mobile number and/or email address. After confirmation of One Time Password (OTP) a User will become a Registered Use. Thereafter Registered User must provide specifications like area, location of the plot etc. and preferences like number of rooms, number of floors etc. Registered User can avail service from Visava Platform by paying a one-time fee per building, the user wants to get designed. Thereafter Registered Users can avail the services listed on the Visava Platforms viz., 3D (three dimensional) models, 2D (two dimensional) drawings, costing of the building, bill of materials etc. based on user parameters.
              </p>
              <p className='paragraph'>
                The privacy policy informs the Users and the Registered Users of policies and procedures regarding collection, use and disclosure of personal information that Visava Platform receive from Users or Registered Users. The owner of Visava Platform reserves the right to change the provisions of the privacy policy from time to time without any prior notice and restrict Users/Registered Users to access Visava Platform upon the owner’s sole discretion.
              </p>
              <ol type='1'>
                <li>
                  <div className='title highlight'>Information Collected:</div>
                  <ol type='a'>
                    <li>A User is required to provide personal information to register on Visava Platform by providing his/her/their Mobile Number or Email Address. Visava Platform may collect personal information when the User registers his/her/their details. While registering, the Visava Platform asks for the personal information of the User that is personally identifiable like name, address, mobile number, e-mail address, specifications like area, location of the plot etc. Visava Platform may collect any information which is required to be stored and verified.</li>
                    <li>There is certain information which is also automatically collected such as Internet Protocol (IP) address, browser type, access time etc. IP Address is received automatically from the User’s/Registered User’s internet service provider and recorded into the owner’s server logs.</li>
                  </ol>
                </li>
                <li>
                  <div className='title highlight'>Information use:</div>
                  Visava Platform uses information like Internet Protocol (IP) address, browser type, access time and Registered User’s personal information to maintain the quality of its service.
                </li>
                <li>
                  <div className='title highlight'>Information Sharing and Disclosing Personal Information to Third Parties</div>
                  <ol type='a'>
                    <li>Any personal information or non-personal information may be disclosed to owner’s other companies, agents, service providers or other companies working for the owner like processing payments, provision of data storage, hosting Visava Platform, marketing the owner’s services, organizing audits, and performing web analysis.</li>
                    <li>Additionally, Visava Platform reserves the right to utilize User/Registered Users personal information, without notice for marketing Visava Platforms services. Visava further reserves right to disclose a User/Registered Users personal information, without notice, if required to do so by any law for the time being in force or if it is necessary to comply with legal process prevailing in the country or if it is necessary to protect the rights and property of Visava Platform or to protect the rights and property of other Users/Registered Users of Visava Platform.</li>
                  </ol>
                </li>
                <li>
                  <div className='title highlight'>Information Security:</div>
                  Visava Platform secures the personal information provided by the User/Registered User on computer servers in a controlled and protected environment to prevent loss, misuse, unauthorized access, disclosure, alteration, and destruction of the information. It is necessary that Users/Registered Users keep their personal information including, but not limited to, email address, password, specifications like area, location of the plot etc. and other confidential information and must not share it with anyone to prevent unauthorized use or access of their personal information. The owner will not be held responsible for any misuse of Visava Platform by the Users/ Registered Users. Misuse of Visava Platform data by the Users/Registered Users includes but not limited to sharing the data, selling data, providing incorrect information, removing/erasing records, intentional tampering with data. The owner shall not sell, trade or lease collected information without the User’s/Registered User’s consent.
                </li>
                <li>
                  <div className='title highlight'>Use of Cookies:</div>
                  A cookie is a small text file which is automatically created on the hard disk of your computer by a web page server. Cookies are not used to run programs or transmit viruses to your computer. Cookies are uniquely assigned to the User/Registered User and can only be read by a web server in the domain that issued the cookie to the User/Registered User. Visava Platform does use cookies.
                </li>
                <li>
                  <div className='title highlight'>Applicable laws and Jurisdiction:</div>
                  This Privacy Policy shall be governed, interpreted and construed in accordance with the laws of the Republic of India applicable from time to time. The Visava Platform and the Registered User agrees to submit to the exclusive jurisdiction of courts located in Pune, Maharashtra, India for any claims, actions, demands, suits, disputes arising out of and/or in connection with Services provided by Visava.
                </li>
                <li>
                  <div className='title highlight'>Contact Us:</div>
                  <div className='title'>If you have any questions about this Privacy Policy, please contact us at</div>
                  <div className='title'>
                    <span className='highlight'>Email: </span>
                    <a href='mailto:enquiry@visava.in'
                      className='highlight'>{CONTACT_DETAILS.email}</a>.
                  </div>
                  <div className='title'>
                    <span className='highlight'>Contact: </span>
                    <a href='tel:+918530698999'
                      className='highlight'>+91 8530698999</a>
                  </div>
                  <div className='title'>
                    <span className='highlight'>Address: </span>
                    1911, Phulrani Building, Sadashiv Peth, Bajirao Road, Pune - 411030.</div>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PrivacyPolicyDoc;