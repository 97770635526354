/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

class EditHouseName extends Component {
  constructor (props) {
    super(props);
  }

  render () {

    const { t } = this.props;

    return this.props.shown ? (
      <div>
        <div
          className='edit-house-name-container'
          onClick={() => {
            this.props.close();
          }}>
          <div
            className='edit-house-name-inner-container'
            onClick={(e) => {
              e.stopPropagation();
            }}>
            <p className='edit-logo'>{t('new_house_name')}</p>
            <input type='text'
              value={this.props.data.name}
              onChange={(e) => this.props.onHousenamechange(e)}
              className={`form-input ${this.props.editNameError && 'error'}`} />
            <div className='rename-button button-pointer'
              onClick={this.props.saveEditHouseName}>{t('rename')}</div>
          </div>
        </div>
      </div>) : null;
  }
}

export default withTranslation()(EditHouseName);
