/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable no-empty */
/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
//import get from 'lodash/get';
import DHM1 from '../../../../src/asset/images/step_1.svg';
import DHM2 from '../../../../src/asset/images/step_2.svg';
import DHM3 from '../../../../src/asset/images/step_3.svg';
import DHM4 from '../../../../src/asset/images/step_4.svg';
import DHM5 from '../../../../src/asset/images/step_5.svg';
import NavIcon from '../../../asset/images/nav_icon.svg';
import LeftBlueArrow from '../../../../src/asset/images/left_blue_arrow.svg';
// import HowToUseImg from '../../../../src/asset/images/how-to-use.png';
import SampleDocImg from '../../../../src/asset/images/blur_image.svg';
import LocatorImg from '../../../../src/asset/images/locator.svg';
import RupeeImg from '../../../../src/asset/images/rupee-img.svg';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { updateAddress, updateDMHState } from '../../redux/actions/designMyHouse';
import { DropdownList } from 'react-widgets';
import Loader from '../../shared-ui/Loader';
import { API_URL, YOUTUBE_LINKS, BLOG_URL, userAgent } from '../../../api/api-config';
import { axiosInstance } from '../../../api/coreApi';
//import { breakPoint_L } from '../../../utility/breakPoints';
//import { constants } from '../../../utility/constants';
import { withTranslation } from 'react-i18next';
//import SampleDocument from '../sampleDocument';
import { getBreakpoint, getAddress, getDMHState, getLangCodeSelector } from '../../../selectors/commonSelectors';
import { isValidDMHRedirection, deleteDMHRedirection, windowOpenHandler } from '../../../utility/common';
import { Stepper, Step } from 'react-form-stepper';

class DesignMyHouse extends Component {

  constructor (props) {
    super(props);
    this.changeUnit=this.changeUnit.bind(this);
    this.isLogin=this.isLogin.bind(this);
    this.backClick=this.backClick.bind(this);
    this.designMyHouseApiCall=this.designMyHouseApiCall.bind(this);
    this.houseOptionsApiCall=this.houseOptionsApiCall.bind(this);
    this.nextClick=this.nextClick.bind(this);
    this.setValuesA=this.setValuesA.bind(this);
    this.setValuesB=this.setValuesB.bind(this);
    this.selectDoor=this.selectDoor.bind(this);
    this.selectBedroom=this.selectBedroom.bind(this);
    this.selectToilet=this.selectToilet.bind(this);
    this.selectFloor=this.selectFloor.bind(this);
    this.generateOptions=this.generateOptions.bind(this);
    this.selectLocation=this.selectLocation.bind(this);
    this.onBudgetSelection=this.onBudgetSelection.bind(this);
    this.downloadPDF=this.downloadPDF.bind(this);
    this.calcToilet=this.calcToilet.bind(this);
    this.calcDoorDir=this.calcDoorDir.bind(this);
    this.calcRoomCount=this.calcRoomCount.bind(this);
    this.calcFloorCount=this.calcFloorCount.bind(this);
    this.openBlog=this.openBlog.bind(this);
    this.goToVideo=this.goToVideo.bind(this);
    //this.selectSiteLocation=this.selectSiteLocation.bind(this);
    //this.clearAll=this.clearAll.bind(this);
    //this.showSampleDocument=this.showSampleDocument.bind(this);
    //this.directBomButtons=this.DirectBomButtons.bind(this);
    //this.closePopUp=this.closePopUp.bind(this);
    // this.editHouseData = props && props.location && props.location.state && props.location.state.editHouse;
    this.editHouseData = props && props.location && props.location.state && props.location.state.editHouse;
    this.addressFromMap = props && props.location && props.location.state && props.location.state.addressFromMap;
    //const editHouseData = props.editHouseData;
    this.props.updateDMHState(this.props.DMHState, {
      showDocumentPopUp: false,
      siteLocation: false,
      mainDoor: false,
      constArea: false,
      preferences: false,
      budget: false,
      name: this.editHouseData && this.editHouseData.name || 'My Visava Home',
      unit: this.editHouseData && this.editHouseData.dimension_unit || 'feet',
      open: false,
      lat: this.editHouseData && this.editHouseData.location_latitude || null,
      lng: this.editHouseData && this.editHouseData.location_longitude || null,
      shortAddress: this.editHouseData && this.editHouseData.location_short_address|| '',
      longAddress: this.editHouseData && this.editHouseData.location_long_address|| '',
      lengthA: this.editHouseData && this.editHouseData.dimension_x || '',
      lengthB: this.editHouseData && this.editHouseData.dimension_y || '',
      mainDoorDirection: this.editHouseData ? this.calcDoorDir(this.editHouseData.direction_of_entry) : '',
      bedroomCount: this.editHouseData ? this.calcRoomCount(this.editHouseData.number_of_rooms) : '',
      floorCount: this.editHouseData ? this.calcFloorCount(this.editHouseData.number_of_floors) : '',
      toiletCount: this.editHouseData ? this.calcToilet(this.editHouseData.toilet_exist) : '',
      estimatedBudget: this.editHouseData && this.editHouseData.budget_value ? new Intl.NumberFormat('en-IN').format((this.editHouseData.budget_value)):'',
      budgetValue:this.editHouseData && this.editHouseData.budget_value ? this.editHouseData.budget_value:'',
      isLoading: false,
      lengthAError: false,
      lengthBError: false,
      siteLocationError: false,
      mainDoorError: false,
      bedroomError: false,
      floorError: false,
      toiletError: false,
      budgetError: false,
      id: this.editHouseData && this.editHouseData.id || null,
      ground: 0,
      floorsOptions: [],
      roomsOption: [],
      errorMessage: '',
      inputError: false,
      directionError: false,
      updateDMHState: true,
      address:true
      
    });

    if(!this.addressFromMap) {
      this.props.updateAddress({
        lat: this.editHouseData && this.editHouseData.location_latitude || null,
        lng: this.editHouseData && this.editHouseData.location_longitude || null,
        shortAddress: this.editHouseData && this.editHouseData.location_short_address || '',
        longAddress: this.editHouseData && this.editHouseData.location_long_address || '',

      });
    }
    // else if(this.addressFromMap && !get(this.props, 'address.shortAddress', '')) {
    //   this.props.updateAddress({
    //     shortAddress: this.editHouseData && this.editHouseData.location_address || ''
    //   });
    // }
  }

  
  goToVideo () {
    const langCode = localStorage.getItem('lang');
    if( langCode === 'en') {
      windowOpenHandler(YOUTUBE_LINKS.how_to_use_visava_english);
    } else {
      windowOpenHandler(YOUTUBE_LINKS.how_to_use_visava_hindi);
    }
  }

  // closePopUp () {
  //   this.props.updateDMHState(this.props.DMHState, {
  //     showDocumentPopUp: false
  //   });
  // }

  calcToilet (toilet_exist) {
    const { t } = this.props;
    return toilet_exist==1 ? t('yes') : t('no');
  }

  calcDoorDir (direction_of_entry) {
    const { t } = this.props;
    const mainDoor = direction_of_entry == 'NORTH' ? t('north') : direction_of_entry == 'SOUTH' ? t('south') : direction_of_entry == 'WEST' ? t('west') : t('east');
    return mainDoor;
  }

  //no of ground which is getting displayed from here logic corrected.
  calcFloorCount (number_of_floors) {
    const { t } = this.props;
    const floor = number_of_floors == 0 ? t('ground') : number_of_floors == 1 ? t('ground+1') : t('ground+2');
    return floor;
  }

  calcRoomCount (number_of_rooms) {
    const { t } = this.props;
    const room = number_of_rooms;
    return room;
  }

  downloadPDF () {
    //windowOpenHandler('/sample-document');
    const { history } = this.props;
    const langCode = localStorage.getItem('lang');
    history && history.push(`/sample-document?preferred_language=${langCode}&user_agent=${userAgent}`);
    //windowOpenHandler(`/sample-document?preferred_language=${langCode}&user_agent=${userAgent}`);
  }
 
  openBlog () {
    const langCode = localStorage.getItem('lang');
    if(langCode == 'en') {
      windowOpenHandler(`${BLOG_URL}`);
    } else {
      windowOpenHandler(`${BLOG_URL}/${langCode}`);
    }
  }
  // clearAll () {
  //   this.setState({
  //     updateDMHState:false,
  //     address:false,
  //   });
  // }

  changeUnit (unit) {
    if(this.props.DMHState.unit !== unit) {
      if(unit=='feet') {
        this.props.updateDMHState(this.props.DMHState, {
          unit: unit,
          lengthA: this.props.DMHState.lengthA !== '' ? (this.props.DMHState.lengthA*3.28084).toFixed(2) : this.props.DMHState.lengthA,
          lengthB: this.props.DMHState.lengthB !== '' ? (this.props.DMHState.lengthB*3.28084).toFixed(2) : this.props.DMHState.lengthB,
          lengthAError: false,
          lengthBError: false
        });
      } else if(unit=='meter') {
        this.props.updateDMHState(this.props.DMHState, {
          unit: unit,
          lengthA: this.props.DMHState.lengthA !== '' ? (this.props.DMHState.lengthA/3.28084).toFixed(2) : this.props.DMHState.lengthA,
          lengthB: this.props.DMHState.lengthB !== '' ? (this.props.DMHState.lengthB/3.28084).toFixed(2) : this.props.DMHState.lengthB,
          lengthAError: false,
          lengthBError: false
        });
      }
    }
  }

  componentDidMount () {
    if(isValidDMHRedirection()) {
      deleteDMHRedirection();
      this.isLogin();
    } else{
      this.props.history.push('/homes');
    }
  }
  
  isLogin () {
    const jwtToken = localStorage.getItem('jwtToken');
    if (!jwtToken) {
      this.props.history.replace('/');
    }
  }

  backClick (param) {

    if (param == 'mainDoorCheck') {
      this.props.updateDMHState(this.props.DMHState, {
        siteLocation: false,
        mainDoor: false
      });
    } else if (param == 'constAreaCheck') {
      this.props.updateDMHState(this.props.DMHState, {
        siteLocation: true,
        mainDoor: false,
        constArea: false
      });
    } else if (param == 'preferencesCheck') {
      this.props.updateDMHState(this.props.DMHState, {
        siteLocation: true,
        mainDoor: true,
        constArea: false,
        preferences: false
      });
    } else if (param == 'budgetCheck') {
      this.props.updateDMHState(this.props.DMHState, {
        siteLocation: true,
        mainDoor: true,
        constArea: true,
        preferences: false,
        budget: false
      });
    } else {
      // TODO document why this block is empty
    }
  }

  designMyHouseApiCall (object) {
    const { id } = this.props.DMHState;
    const editMyHouse = `${API_URL.house}/${id}`;
    const designMyHouse = `${API_URL.house}`;
    const request = id ? axiosInstance.put : axiosInstance.post;

    request(id ? editMyHouse : designMyHouse, object)
      .then(({ data }) => {
        this.props.updateDMHState( {}, {
          isLoading: false
        });
        this.props.updateAddress({});
        localStorage.setItem('houseId', data.data.id);
        this.props.history.push(`/home-details?house-id=${data.data.id}`, { designMyHouseData: data && data.data,
          isConf: true });
      })
      .catch((_err) => {
        this.props.updateDMHState(this.props.DMHState, {
          isLoading: false
        });
      });
  }

  houseOptionsApiCall (bodyObj) {
    const object = !bodyObj.direction_of_entry ? {
      location_latitude: this.props.address.lat,
      location_longitude: this.props.address.lng,
      dimension_x: bodyObj.dimension_x,
      dimension_y: bodyObj.dimension_y,
      dimension_unit: bodyObj.dimension_unit,
    } : {
      location_latitude: this.props.address.lat,
      location_longitude: this.props.address.lng,
      dimension_x: bodyObj.dimension_x,
      dimension_y: bodyObj.dimension_y,
      dimension_unit: bodyObj.dimension_unit,
      direction_of_entry : bodyObj.direction_of_entry,
    };
    const editMyHouse = `${API_URL.lookUpHouseTypologyOptions}`;
    const request = axiosInstance.post;
    const { t } = this.props;

    request(editMyHouse, object)
      .then(({ data }) => {
        // console.log(data);
        // console.log(data.data.typologies_floor_room_map);
        if(data.status=='SUCCESS') {
          const groundNames = [t('ground')];

          for(let i=0;i<data.data.typologies_floor_room_map.length;i++) {
            if(i > 0) {
              groundNames.push(`${t('ground')} + ${i}`);
            }
          }
          //console.log(groundNames);
          this.props.updateDMHState(this.props.DMHState, {
            isLoading: false,
            ground: data.data.length,
            floorsOptions: groundNames,
            roomsOption: data.data.typologies_floor_room_map,
            unit: bodyObj.dimension_unit
          });
        }
        
      })
      .catch((err) => {
        this.props.updateDMHState(this.props.DMHState, {
          isLoading: false,
          
        });
        if (err && err.response) {
          const errData = err.response && err.response.data;
          if (errData && errData.status && errData.status.toLowerCase() == 'failed' && this.props.DMHState.mainDoorDirection !=='') {
            this.props.updateDMHState(this.props.DMHState, {
              directionError: true,
              mainDoor: true,
              siteLocation: true,
              constArea:false,
              errorMessage: t('wrong_parameter'),
            });
          } else {
            this.props.updateDMHState(this.props.DMHState, {
              inputError: true,
              mainDoor: false,
              errorMessage: t('wrong_parameter'),
            });
  
          }
        }
      });
  }
        
  
  nextClick (param) {
    const { t } =this.props;

    const {
      lat,
      lng,
      //shortAddress,
      unit,
      lengthA,
      lengthB,
      mainDoorDirection,
      bedroomCount,
      floorCount,
      toiletCount,
      budgetValue,
      // eslint-disable-next-line no-unused-vars
      bedroomError,
      floorError,
      toiletError,
      name,
    } = this.props.DMHState;

    const floor = floorCount == t('ground') ? 0 : floorCount == t('ground+1') ? 1 : 2;
    const toilet = toiletCount == t('yes') ? 1 : 0;
    const mainDoor = mainDoorDirection == t('north') ? 'NORTH' : mainDoorDirection == t('south') ? 'SOUTH' : mainDoorDirection == t('west') ? 'WEST' : mainDoorDirection == t('east') ? 'EAST' : null ;

    const object = {
      country_iso: 'IN',
      name: name,
      location_latitude: this.props.address.lat,
      location_longitude: this.props.address.lng,
      location_short_address: this.props.address.shortAddress,
      location_long_address: this.props.address.longAddress,
      dimension_x: lengthA,
      dimension_y: lengthB,
      dimension_unit: unit,
      direction_of_entry : mainDoor,
      number_of_rooms: bedroomCount,
      number_of_floors: floor,
      toilet_exist: toilet,
      budget_value: budgetValue,
      budget_currency: 'IN'

    };
    //console.log(lat, lng );
    if (param == 'locationCheck') {
      //this.houseOptionsApiCall(object);
      if (this.props.address.lat && this.props.address.lng && this.props.address.shortAddress) {
        this.props.updateDMHState(this.props.DMHState, {
          siteLocation: true,
          siteLocationError: false,
        });
      } else {
        this.props.updateDMHState(this.props.DMHState, {
          siteLocation: false,
          siteLocationError  : true
        });
      }
    } else if (param == 'constAreaCheck') {
      if ((unit == 'feet' && (lengthA >= 6 && lengthA <= 50) && (lengthB >= 6 && lengthB <= 50)) || (unit == 'meter' && (lengthA >= 1.83 && lengthA <= 15.24) && (lengthB >= 1.83 && lengthB <= 15.24))) {
        this.props.updateDMHState(this.props.DMHState, {
          siteLocation: true,
          mainDoor: true,
          lengthAError: false,
          lengthBError: false,
          inputError: false
        });
        this.houseOptionsApiCall(object);
      } else {
        if ((unit == 'feet' && (lengthA < 6 || lengthA > 50) && (lengthB < 6 || lengthB > 50)) || (unit == 'meter' && (lengthA < 1.83 || lengthA > 15.24) && (lengthB < 1.83 || lengthB > 15.24))) {
          this.props.updateDMHState(this.props.DMHState, {
            siteLocation: true,
            mainDoor: false,
            lengthAError: true,
            lengthBError: true,
            inputError: false
          });
        } else if ((unit == 'feet' && (lengthA < 6 || lengthA > 50)) || (unit == 'meter' && (lengthA < 1.83 || lengthA > 15.24))) {
          this.props.updateDMHState(this.props.DMHState, {
            siteLocation: true,
            mainDoor: false,
            lengthAError: true,
            lengthBError: false,
            inputError: false
          });
        } else if ((unit == 'feet' && (lengthB < 6 || lengthB > 50)) || (unit == 'meter' && (lengthB < 1.83 || lengthB > 15.24))) {
          this.props.updateDMHState(this.props.DMHState, {
            siteLocation: true,
            mainDoor: false,
            lengthAError: false,
            lengthBError: true,
            inputError: false
          });
        }
      }
    } else if (param == 'mainDoorCheck') {
      if (mainDoorDirection == '') {
        this.props.updateDMHState(this.props.DMHState, {
          mainDoorError: true,
          directionError: false
        });
      } else {
        this.props.updateDMHState(this.props.DMHState, {
          siteLocation: true,
          mainDoor: true,
          constArea: true,
          directionError: false
        });
        this.houseOptionsApiCall(object, mainDoor);
      }
    } else if (param == 'preferencesCheck') {
      if (floorCount == '') {
        this.props.updateDMHState(this.props.DMHState, {
          floorError: true
        });

      } else if (toiletCount == '') {
        this.props.updateDMHState(this.props.DMHState, {
          toiletError: true
        });
      } else if (bedroomCount == '') {
        this.props.updateDMHState(this.props.DMHState, {
          bedroomError: true
        });
      } else if (!floorError && !toiletError && !bedroomError) {
        this.props.updateDMHState(this.props.DMHState, {
          siteLocation: true,
          mainDoor: true,
          constArea: true,
          preferences: true
        });
      }

    } else if (param == 'budgetCheck') {
      if (budgetValue < 50000 || budgetValue == '') {
        this.props.updateDMHState(this.props.DMHState, {
          budgetError: true
        });
      } else {
        this.props.updateDMHState(this.props.DMHState, {
          isLoading: true
        });
        this.designMyHouseApiCall(object);
          
      }

    } else {
      // TODO document why this block is empty
    
    }
  }

  selectDoor (params) {
    this.props.updateDMHState(this.props.DMHState, {
      mainDoorDirection: params,
      mainDoorError: false,
      directionError: false,
      toiletError: false,
      floorError: false,
      bedroomError: false,
      floorCount: '',
      bedroomCount: '',
      toiletCount: '',
    });
  }

  setValuesA (params) {
    this.props.updateDMHState(this.props.DMHState, {
      lengthA: params.target.value,
      mainDoorDirection: '',
      floorCount: '',
      bedroomCount: '',
      toiletCount: '',
      errorMessage: '',
      mainDoorError:'',
      toiletError: false,
      floorError: false,
      bedroomError: false
    });
  }

  setValuesB (params) {
    this.props.updateDMHState(this.props.DMHState, {
      lengthB: params.target.value,
      mainDoorDirection: '',
      floorCount: '',
      bedroomCount: '',
      toiletCount: '',
      errorMessage:'',
      mainDoorError:'',
      floorError: '',
      bedroomError: '',
      toiletError: ''
    });
  }

  // selectSiteLocation (params) {
  //   this.props.updateDMHState(this.props.DMHState, {
  //     siteLocation: params,
  //     siteLocationError: false,
  //     floorCount: '',
  //     bedroomCount: '',
  //     toiletCount: '',
  //     bedroomError: '',
  //     toiletError: '',
  //     mainDoorError: '',
  //     floorError: ''
  //   });
  // }

  selectBedroom (params) {
    this.props.updateDMHState(this.props.DMHState, {
      bedroomCount: params,
      bedroomError: false
    });
  }

  selectToilet (params) {
    this.props.updateDMHState(this.props.DMHState, {
      toiletCount: params,
      toiletError: false
    });
  }

  selectFloor (params) {
    this.props.updateDMHState(this.props.DMHState, {
      floorCount: params,
      floorError: false,
      bedroomCount: ''
    });
  }


  generateOptions (floor, param) {
    const { t } = this.props;
    const floorNum = (floor === t('ground')) ? 0 : floor == t('ground+1') ? 1 : 2;

    //const floorNum = (floor === t('ground')) ? 0 : parseInt(floorNum);

    if(param[floorNum]) {
      return param[floorNum]['rooms'];
    }
    return [];
  }

  selectLocation () {
    this.props.history.push('/design-my-house/maps', { editHouse: this.editHouseData });
  }

  onBudgetSelection (e) {
    this.props.updateDMHState(this.props.DMHState, {
      budgetValue : Number((e.target.value).replace(/\D+/g, '')),
      estimatedBudget : new Intl.NumberFormat('en-IN').format((e.target.value).replace(/\D+/g, '')),
      budgetError: false
    });
   
  }

  render () {
    this.isLogin();
    const { langCode } = this.props;
    const {
      siteLocation,
      mainDoor,
      constArea,
      preferences,
      budget,
      unit,
      lengthA,
      lengthB,
      mainDoorDirection,
      bedroomCount,
      floorCount,
      toiletCount,
      estimatedBudget,
      budgetValue,
      lengthAError,
      lengthBError,
      mainDoorError,
      siteLocationError,
      bedroomError,
      toiletError,
      floorError,
      budgetError,
      isLoading,
      showDocumentPopUp,
      ground,
      floorsOptions,
      roomsOption,
      errorMessage,
      inputError,
      directionError
    } = this.props.DMHState;
    const { t, breakpoint }=this.props;
    const locationCheck = siteLocation == false && mainDoor == false && constArea == false && preferences == false && budget == false;
    const constAreaCheck = siteLocation == true && mainDoor == false && constArea == false && preferences == false && budget == false;
    const mainDoorCheck = siteLocation == true && mainDoor == true && constArea == false && preferences == false && budget == false;
    const preferencesCheck = siteLocation == true && mainDoor == true && constArea == true && preferences == false && budget == false;
    const budgetCheck = siteLocation == true && mainDoor == true && constArea == true && preferences == true && budget == false;

    const doorOptions = [t('north'), t('east'), t('south'), t('west')];
    let bedRoomsOptions = [0];
    if(roomsOption && roomsOption.length > 0) bedRoomsOptions = this.generateOptions(floorCount, roomsOption);
    const toiletOptions = [t('yes'), t('no')];

    return (breakpoint == 'lg' ? //desktop_view
      <>
        <div className='tablet-view banner-container-top'>
          <div className='design-my-house-contaienr-L'>
            {/* {showDocumentPopUp && <SampleDocument closePopUp={this.closePopUp} />} */}
            <div className='design-my-house-container'>
              <Loader
                isLoading={isLoading} />
              {/*  */}
              <div className='steppers_area'>
                <Stepper
                  activeStep={locationCheck ? 0 : constAreaCheck ? 1 : mainDoorCheck ? 2 : preferencesCheck ? 3 : 4}
                  connectorStateColors={true}
                  connectorStyleConfig={{
                    disabledColor: '#E3E3E3',
                    activeColor: '#00B0A6',
                    completedColor: '#00B0A6',
                    size: 3,
                  }}>
                  <Step label=' ' />
                  <Step label=' ' />
                  <Step label=' ' />
                  <Step label=' ' />
                  <Step label=' ' />
                </Stepper>
              </div>
              {/*  */}
              <div className='image-container'>
                <div className='home_navigation_img'>
                  <img src={NavIcon}
                    alt='nav icon' />
                  <div className='nav_text'>North</div>
                </div>
                <img
                  className='image-wrapper'
                  src={locationCheck ? DHM1 : constAreaCheck ? DHM2 : mainDoorCheck ? DHM3 : preferencesCheck ? DHM4 : DHM5}
                  //src={DHM1}
                  alt='' />
              </div>
              <div className='dmh-second-container'>
                {locationCheck && <div className='container-1'>
                  <div className='location'>{t('enter_site_location')}</div>
                  {(siteLocationError) && <div className='error-text'>{t('enter_valid_input')}</div>}
                  <div className='location-container'>
                    <input className='search-location'
                      placeholder={t('select_location')}
                      value={this.props.address.shortAddress || this.props.DMHState.shortAddress || ''}
                      type='search'
                      onClick={this.selectLocation} />
                    <img className='location-class'
                      src={LocatorImg}
                      alt='' />
                  </div>
                  <p className='description'>{t('enter_location_to_build_house')}</p>
                  <p className='description'>{t('if_unable_to_locate')}</p>
                  <div className='button-wrapper'>
                    <div className='visava-btn location-next-btn'
                      onClick={() => this.nextClick('locationCheck')}>
                      {t('next')}
                    </div>
                  </div>
                  {/* <div className='button-container button-pointer'
                    onClick={() => this.nextClick('locationCheck')}>
                    <div>{t('next')}</div>
                  </div> */}
                </div>}
                {constAreaCheck && <div className='container-2'>
                  <div className='const-area'>{t('enter_area')}</div>
                  <div className='length'>
                    <div className={`feet ${unit == 'feet' && 'selected border-feet'} button-pointer`}
                      onClick={() => this.changeUnit('feet')}>{t('feet')}</div>
                    <div className={`meter ${unit == 'meter' && 'selected border-meter'} button-pointer`}
                      onClick={() => this.changeUnit('meter')}>{t('meter')}</div>
                  </div>
                  {(lengthAError || lengthBError) && <div className='error-text'>{unit == 'feet' ? t('error_feet') : t('error_meter')}</div>}
                  {inputError && <div className='error-text'>{errorMessage}</div>}
                  <div className='length-A-container'>
                    <div className='length-text'>{t('length_a')}</div>
                    <div className='length_input_wrapper'>
                      <input
                        // className={`length-input-container ${lengthAError && 'error'}`}
                        className={`length-input-container ${inputError && 'error'}`}
                        placeholder={unit == 'feet' ? t('feet_min_max') : t('meter_min_max')}
                        value={lengthA}
                        type='number'
                        // disabled={this.inputError}
                        onChange={(values) => this.setValuesA(values)} />
                      <span className='length_unit_lbl'>{unit == 'feet' ? t('feet') : t('meter')} :</span>
                    </div>
                    <div className='length-text'>{t('length_b')}</div>
                    <div className='length_input_wrapper'>
                      <input
                        // className={`length-input-container ${lengthBError && 'error'}`}
                        className={`length-input-container ${inputError && 'error'}`}
                        placeholder={unit == 'feet' ? t('feet_min_max') : t('meter_min_max')}
                        value={lengthB}
                        type='number'
                        onChange={(values) => this.setValuesB(values)} />
                      <span className='length_unit_lbl'>{unit == 'feet' ? t('feet') : t('meter')} :</span>
                    </div>
                  </div>
                  <div className='button-wrapper'>
                    <div className='back-button'
                      onClick={() => this.backClick('mainDoorCheck')}>
                      <div className='left_arrow'><img src={LeftBlueArrow} /></div>
                      {`${t('back')}`}
                    </div>
                    <div className='visava-btn'
                      onClick={() => this.nextClick('constAreaCheck')}>
                      {t('next')}
                    </div>
                  </div>
                  {/* <div className='button-container'>
                    <div className='back-button button-pointer'
                      onClick={() => this.backClick('mainDoorCheck')}><div className='left_arrow'><img src={LeftBlueArrow} /></div>{` ${t('back')}`}</div>
                    <div className='next-button button-pointer'
                      onClick={() => this.nextClick('mainDoorCheck')}>{t('next')}</div>
                  </div> */}
                </div>}
                {mainDoorCheck && <div className='container-3'>
                  <div className='main-door'>{t('main_door_location')}</div>
                  {mainDoorError && <div className='error-text'>{t('enter_main_door')}</div>}
                  {directionError && <div className='error-text'>{errorMessage}</div>}
                  <div className='dropdown-container'>
                    <DropdownList
                      value={mainDoorDirection}
                      placeholder={t('select')}
                      data={doorOptions}
                      filter={false}
                      onChange={(values) => this.selectDoor(values)} />
                  </div>
                  <div className='description'>{t('main_door_specify')}</div>
                  <div className='button-wrapper'>
                    <div className='back-button'
                      onClick={() => this.backClick('constAreaCheck')}>
                      <div className='left_arrow'><img src={LeftBlueArrow} /></div>
                      {`${t('back')}`}
                    </div>
                    <div className='visava-btn'
                      onClick={() => this.nextClick('mainDoorCheck')}>
                      {t('next')}
                    </div>
                  </div>
                  {/* <div className='button-container'>
                    <div className='back-button button-pointer'
                      onClick={() => this.backClick('constAreaCheck')}><div className='left_arrow'><img src={LeftBlueArrow} /></div>{` ${t('back')}`}</div>
                    <div className='next-button button-pointer'
                      onClick={() => this.nextClick('constAreaCheck')}>{t('next')}</div>
                  </div> */}
                </div>}
                {preferencesCheck && <div className='container-4'>
                  <div className='preference'>{t('enter_preference')}</div>
                  {(toiletError || floorError || bedroomError) && <div className='error-text'>{t('enter_valid_input')}</div>}
                  <div className='preference-container'>
                    <div className='preference-wrapper'>
                      <div className='text'>{t('floor_no')}</div>
                      <div className={`${floorError ? 'dropdown-error' : 'dropdown-container'}`}>
                        <DropdownList
                          value={floorCount}
                          placeholder={t('select')}
                          data={floorsOptions}
                          filter={false}
                          onChange={(values) => this.selectFloor(values)} />
                      </div>
                    </div>
                    <div className='preference-wrapper'>
                      <div className='text'>{t('bedroom_no')}</div>
                      <div className={`${bedroomError ? 'dropdown-error' : 'dropdown-container'}`}>
                        <DropdownList
                          value={bedroomCount}
                          placeholder={t('select')}
                          data={bedRoomsOptions}
                          filter={false}
                          disabled={floorCount === ''}
                          onChange={(values) => this.selectBedroom(values)} />
                      </div>
                    </div>
                    <div className='preference-wrapper'>
                      <div className='text'>{t('toilet')}</div>
                      <div className={`${toiletError ? 'dropdown-error' : 'dropdown-container'}`}>
                        <DropdownList
                          value={toiletCount}
                          placeholder={t('select')}
                          filter={false}
                          data={toiletOptions}
                          onChange={(values) => this.selectToilet(values)} />
                      </div>
                    </div>
                    <div className='description'>{t('display_text_rooms_preferences')}</div>
                  </div>
                  <div className='button-wrapper'>
                    <div className='back-button'
                      onClick={() => this.backClick('preferencesCheck')}>
                      <div className='left_arrow'><img src={LeftBlueArrow} /></div>
                      {`${t('back')}`}
                    </div>
                    <div className='visava-btn'
                      onClick={() => this.nextClick('preferencesCheck')}>
                      {t('next')}
                    </div>
                  </div>
                  {/* <div className='button-container'>
                    <div className='back-button button-pointer'
                      onClick={() => this.backClick('preferencesCheck')}><div className='left_arrow'><img src={LeftBlueArrow} /></div>{`${t('back')}`}</div>
                    <div className='next-button button-pointer'
                      onClick={() => this.nextClick('preferencesCheck')}>{t('next')}</div>
                  </div> */}
                </div>}
                {budgetCheck && <div className='container-5'>
                  <div className='house-budget'>{t('enter_budget')}</div>
                  {budgetError && <div className='error-text'>{t('enter_valid_budget')}</div>}
                  <div className='dmh-budget-container'>
                    <input
                      className={`search-location ${budgetError && 'error'}`}
                      placeholder={t('min_budget')}
                      type='search'
                      onChange={(e) => this.onBudgetSelection(e)}
                      value={estimatedBudget} />
                    <img className='image'
                      src={RupeeImg}
                      alt='' />
                  </div>
                  {/* <div>{console.log(budgetValue)}</div> */}
                  <p className='description'>{t('please_specify_funds')}</p>
                  <p className='description'>{t('generate_customised_house')}</p>
                  <div className='button-wrapper'>
                    <div className='back-button'
                      onClick={() => this.backClick('budgetCheck')}>
                      <div className='left_arrow'><img src={LeftBlueArrow} /></div>
                      {`${t('back')}`}
                    </div>
                    <div className='visava-btn'
                      onClick={() => this.nextClick('budgetCheck')}>
                      {t('next')}
                    </div>
                  </div>
                  {/* <div className='button-container'>
                    <div className='back-button button-pointer'
                      onClick={() => this.backClick('budgetCheck')}><div className='left_arrow'><img src={LeftBlueArrow} /></div>{` ${t('back')}`}</div>
                    <div className='next-button button-pointer'
                      onClick={() => this.nextClick('budgetCheck')}>{t('next')}</div>
                  </div> */}
                </div>}
              </div>
            </div>
            <div className='lower-area'>
              <div className='section-1-container'>
                <div className='section-1-wrapper'>
                  {langCode === 'en' ?
                    <div className='section-1-inner-wrapper'
                      onClick={this.goToVideo}>
                      <div className='text'>{t('need_help')}</div>
                      <iframe
                        className='iframe-class'
                        src={YOUTUBE_LINKS.how_to_use_visava_english}
                        //src={YOUTUBE_LINKS.need_help}
                        title='YouTube video player'
                        frameBorder='0'
                        allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                        allowFullScreen />
                    </div> :
                    <div className='section-1-inner-wrapper'
                      onClick={this.goToVideo}>
                      <div className='text'>{t('need_help')}</div>
                      <iframe
                        className='iframe-class'
                        src={YOUTUBE_LINKS.how_to_use_visava_hindi}
                        title='YouTube video player'
                        frameBorder='0'
                        allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                        allowFullScreen />
                    </div>
                  }
                  <div className='divider' />
                  <div className='section-1-inner-wrapper-2'>
                    <div className='save-more'>{t('save_more')}</div>
                    <div className='details'>
                      {t('iddentifies_right_govt')}<a className='read_more'
                        onClick={this.openBlog}>{t('read_more')}</a>
                    </div>
                  </div>
                </div>
              </div>
              <div className='section-1-container  right_section'>
                <div className='section-1-wrapper'>
                  <div className='section-1-inner-wrapper'>
                    <div className='text'>{t('curious_output')}</div>
                    <div style={{ position: 'relative' }}
                      className='image-wrapper button-pointer'
                      onClick={this.showSampleDocument}>
                      <img className='image'
                        src={SampleDocImg}
                        alt='' />
                      <div className='btn_area'>
                        <div className='txt'
                          onClick={this.downloadPDF}>
                          {t('view_sample_doc')}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='design-my-house-contaienr-L banner-container-top'>
          {/* {showDocumentPopUp && <SampleDocument closePopUp={this.closePopUp} />} */}
          <div className='section-1-container'>
            <div className='section-1-wrapper'>
              {langCode === 'en' ?
                <div className='section-1-inner-wrapper'
                  onClick={this.goToVideo}>
                  <div className='text'>{t('need_help')}</div>
                  <iframe
                    className='iframe-class'
                    src={YOUTUBE_LINKS.how_to_use_visava_english}
                  //src={YOUTUBE_LINKS.need_help}
                    title='YouTube video player'
                    frameBorder='0'
                    allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                    allowFullScreen />
                </div> :
                <div className='section-1-inner-wrapper'
                  onClick={this.goToVideo}>
                  <div className='text'>{t('need_help')}</div>
                  <iframe
                    className='iframe-class'
                    src={YOUTUBE_LINKS.how_to_use_visava_hindi}
                    title='YouTube video player'
                    frameBorder='0'
                    allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                    allowFullScreen />
                </div>
              }
              <div className='divider' />
              <div className='section-1-inner-wrapper-2'>
                <div className='save-more'>{t('save_more')}</div>
                <div className='details'>
                  {t('iddentifies_right_govt')}<a className='read_more'
                    onClick={this.openBlog}>{t('read_more')}</a>
                </div>
              </div>
            </div>
          </div>
          <div className='design-my-house-container'>
            <Loader
              isLoading={isLoading} />
            {/*  */}
            <div className='steppers_area'>
              <Stepper
                activeStep={locationCheck ? 0 : constAreaCheck ? 1 : mainDoorCheck ? 2 : preferencesCheck ? 3 : 4}
                connectorStateColors={true}
                connectorStyleConfig={{
                  disabledColor: '#E3E3E3',
                  activeColor: '#00B0A6',
                  completedColor: '#00B0A6',
                  size: 3,
                }}>
                <Step label=' ' />
                <Step label=' ' />
                <Step label=' ' />
                <Step label=' ' />
                <Step label=' ' />
              </Stepper>
            </div>
            {/*  */}
            <div className='image-container'>
              <div className='home_navigation_img'>
                <img src={NavIcon}
                  alt='nav icon' />
                <div className='nav_text'>North</div>
              </div>
              <img
                className='image-wrapper'
                src={locationCheck ? DHM1 : constAreaCheck ? DHM2 : mainDoorCheck ? DHM3 : preferencesCheck ? DHM4 : DHM5}
                //src={DHM1}
                alt='' />
            </div>
            <div className='dmh-second-container'>
              {locationCheck && <div className='container-1'>
                <div className='location'>{t('enter_site_location')}</div>
                {(siteLocationError) && <div className='error-text'>{t('enter_valid_input')}</div>}
                <div className='location-container'>
                  <input className='search-location'
                    placeholder={t('select_location')}
                    value={ this.props.address.shortAddress || this.props.DMHState.shortAddress || ''}
                    type='search'
                    onClick={this.selectLocation} />
                  <img className='location-class'
                    src={LocatorImg}
                    alt='' />
                </div>
                <p className='description'>{t('enter_location_to_build_house')}</p>
                <p className='description'>{t('if_unable_to_locate')}</p>
                <div className='button-wrapper'>
                  <div className='visava-btn location-next-btn'
                    onClick={() => this.nextClick('locationCheck')}>
                    {t('next')}
                  </div>
                </div>
                {/* <div className='button-container button-pointer'
                  onClick={() => this.nextClick('locationCheck')}>
                  <div>{t('next')}</div>
                </div> */}
              </div>}
              {constAreaCheck && <div className='container-2'>
                <div className='const-area'>{t('enter_area')}</div>
                <div className='length'>
                  <div className={`feet ${unit == 'feet' && 'selected border-feet'} button-pointer`}
                    onClick={() => this.changeUnit('feet')}>{t('feet')}</div>
                  <div className={`meter ${unit == 'meter' && 'selected border-meter'} button-pointer`}
                    onClick={() => this.changeUnit('meter')}>{t('meter')}</div>
                </div>
                {(lengthAError || lengthBError) && <div className='error-text'>{unit == 'feet' ? t('error_feet') : t('error_meter')}</div>}
                {inputError &&<div className='error-text'>{errorMessage}</div>}
                <div className='length-A-container'>
                  <div className='length-text'>{t('length_a')}</div>
                  <div className='length_input_wrapper'>
                    <input
                      // className={`length-input-container ${lengthAError && 'error'}`}
                      className={`length-input-container ${inputError && 'error'}`}
                      placeholder={unit == 'feet' ? t('feet_min_max') : t('meter_min_max')}
                      value={lengthA}
                      type='number'
                      // disabled={this.inputError}
                      onChange={(values) => this.setValuesA(values)} />
                    <span className='length_unit_lbl'>{unit == 'feet' ? t('feet') : t('meter')} :</span>
                  </div>
                  <div className='length-text'>{t('length_b')}</div>
                  <div className='length_input_wrapper'>
                    <input
                      // className={`length-input-container ${lengthBError && 'error'}`}
                      className={`length-input-container ${inputError && 'error'}`}
                      placeholder={unit == 'feet' ? t('feet_min_max') : t('meter_min_max')}
                      value={lengthB}
                      type='number'
                      onChange={(values) => this.setValuesB(values)} />
                    <span className='length_unit_lbl'>{unit == 'feet' ? t('feet') : t('meter')} :</span>
                  </div>
                </div>
                <div className='button-wrapper'>
                  <div className='back-button'
                    onClick={() => this.backClick('mainDoorCheck')}>
                    <div className='left_arrow'><img src={LeftBlueArrow} /></div>
                    {`${t('back')}`}
                  </div>
                  <div className='visava-btn'
                    onClick={() => this.nextClick('constAreaCheck')}>
                    {t('next')}
                  </div>
                </div>
                {/* <div className='button-container'>
                  <div className='back-button button-pointer'
                    onClick={() => this.backClick('mainDoorCheck')}><div className='left_arrow'><img src={LeftBlueArrow} /></div>{` ${t('back')}`}</div>
                  <div className='next-button button-pointer'
                    onClick={() => this.nextClick('mainDoorCheck')}>{t('next')}</div>
                </div> */}
              </div>}
              {mainDoorCheck && <div className='container-3'>
                <div className='main-door'>{t('main_door_location')}</div>
                {mainDoorError && <div className='error-text'>{t('enter_main_door')}</div>}
                {directionError &&<div className='error-text'>{errorMessage}</div>}
                <div className='dropdown-container'>
                  <DropdownList
                    value={mainDoorDirection}
                    placeholder={t('select')}
                    data={doorOptions}
                    filter={false}
                    onChange={(values) => this.selectDoor(values)} />
                </div>
                <div className='description'>{t('main_door_specify')}</div>
                <div className='button-wrapper'>
                  <div className='back-button'
                    onClick={() => this.backClick('constAreaCheck')}>
                    <div className='left_arrow'><img src={LeftBlueArrow} /></div>
                    {`${t('back')}`}
                  </div>
                  <div className='visava-btn'
                    onClick={() => this.nextClick('mainDoorCheck')}>
                    {t('next')}
                  </div>
                </div>
                {/* <div className='button-container'>
                  <div className='back-button button-pointer'
                    onClick={() => this.backClick('constAreaCheck')}><div className='left_arrow'><img src={LeftBlueArrow} /></div>{` ${t('back')}`}</div>
                  <div className='next-button button-pointer'
                    onClick={() => this.nextClick('constAreaCheck')}>{t('next')}</div>
                </div> */}
              </div>}
              {preferencesCheck && <div className='container-4'>
                <div className='preference'>{t('enter_preference')}</div>
                {(toiletError || floorError || bedroomError) && <div className='error-text'>{t('enter_valid_input')}</div>}
                <div className='preference-container'>
                  <div className='preference-wrapper'>
                    <div className='text'>{t('floor_no')}</div>
                    <div className={`${floorError ? 'dropdown-error' : 'dropdown-container'}`}>
                      <DropdownList
                        value={floorCount}
                        placeholder={t('select')}
                        data={floorsOptions}
                        filter={false}
                        onChange={(values) => this.selectFloor(values)} />
                    </div>
                  </div>
                  <div className='preference-wrapper'>
                    <div className='text'>{t('bedroom_no')}</div>
                    <div className={`${bedroomError ? 'dropdown-error' : 'dropdown-container'}`}>
                      <DropdownList
                        value={bedroomCount}
                        placeholder={t('select')}
                        data={bedRoomsOptions}
                        filter={false}
                        disabled={floorCount === ''}
                        onChange={(values) => this.selectBedroom(values)} />
                    </div>
                  </div>
                  <div className='preference-wrapper'>
                    <div className='text'>{t('toilet')}</div>
                    <div className={`${toiletError ? 'dropdown-error' : 'dropdown-container'}`}>
                      <DropdownList
                        value={toiletCount}
                        placeholder={t('select')}
                        filter={false}
                        data={toiletOptions}
                        onChange={(values) => this.selectToilet(values)} />
                    </div>
                  </div>
                  <div className='description'>{t('display_text_rooms_preferences')}</div>
                </div>
                <div className='button-wrapper'>
                  <div className='back-button'
                    onClick={() => this.backClick('preferencesCheck')}>
                    <div className='left_arrow'><img src={LeftBlueArrow} /></div>
                    {`${t('back')}`}
                  </div>
                  <div className='visava-btn'
                    onClick={() => this.nextClick('preferencesCheck')}>
                    {t('next')}
                  </div>
                </div>
                {/* <div className='button-container'>
                  <div className='back-button button-pointer'
                    onClick={() => this.backClick('preferencesCheck')}><div className='left_arrow'><img src={LeftBlueArrow} /></div>{`${t('back')}`}</div>
                  <div className='next-button button-pointer'
                    onClick={() => this.nextClick('preferencesCheck')}>{t('next')}</div>
                </div> */}
              </div>}
              {budgetCheck && <div className='container-5'>
                <div className='house-budget'>{t('enter_budget')}</div>
                {budgetError && <div className='error-text'>{t('enter_valid_budget')}</div>}
                <div className='dmh-budget-container'>
                  <input
                    className={`search-location ${budgetError && 'error'}`}
                    placeholder={t('min_budget')}
                    type='search'
                    onChange={(e) => this.onBudgetSelection(e)}
                    value={estimatedBudget} />
                  <img className='image'
                    src={RupeeImg}
                    alt='' />
                </div>
                {/* <div>{console.log(budgetValue)}</div> */}
                <p className='description'>{t('please_specify_funds')}</p>
                <p className='description'>{t('generate_customised_house')}</p>
                <div className='button-wrapper'>
                  <div className='back-button'
                    onClick={() => this.backClick('budgetCheck')}>
                    <div className='left_arrow'><img src={LeftBlueArrow} /></div>
                    {`${t('back')}`}
                  </div>
                  <div className='visava-btn'
                    onClick={() => this.nextClick('budgetCheck')}>
                    {t('next')}
                  </div>
                </div>
                {/* <div className='button-container'>
                  <div className='back-button button-pointer'
                    onClick={() => this.backClick('budgetCheck')}><div className='left_arrow'><img src={LeftBlueArrow} /></div>{` ${t('back')}`}</div>
                  <div className='next-button button-pointer'
                    onClick={() => this.nextClick('budgetCheck')}>{t('next')}</div>
                </div> */}
              </div>}
            </div>
          </div>
          <div className='section-1-container  right_section'>
            <div className='section-1-wrapper'>
              <div className='section-1-inner-wrapper'>
                <div className='text'>{t('curious_output')}</div>
                <div style={{ position:'relative' }}
                  className='image-wrapper button-pointer'
                  onClick={this.showSampleDocument}>
                  <img className='image'
                    src={SampleDocImg}
                    alt='' />
                  <div className='btn_area'>
                    <div className='txt'
                      onClick={this.downloadPDF}>
                      {t('view_sample_doc')}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
      : <div className='design-my-house-container banner-container-top'>
        <Loader
          isLoading={isLoading} />
        {/*  */}
        <div className='steppers_area'>
          <Stepper
            activeStep={locationCheck ? 0 : constAreaCheck ? 1 : mainDoorCheck ? 2 : preferencesCheck ? 3 : 4}
            connectorStateColors={true}
            connectorStyleConfig={{
              disabledColor: '#E3E3E3',
              activeColor: '#00B0A6',
              completedColor: '#00B0A6',
              size: 3,
            }}>
            <Step label='' />
            <Step label='' />
            <Step label='' />
            <Step label='' />
            <Step label='' />
          </Stepper>
        </div>
        {/*  */}
        <div className='image-container'>
          <div className='home_navigation_img'>
            <img src={NavIcon}
              alt='nav icon' />
            <div className='nav_text'>North</div>
          </div>
          <img
            className='image-wrapper'
            src={locationCheck ? DHM1 : constAreaCheck ? DHM2 : mainDoorCheck ? DHM3 : preferencesCheck ? DHM4 : DHM5}
            //src={locationCheck ? DHM1 : mainDoorCheck ? DHM2 : constAreaCheck ? DHM3 : preferencesCheck ? DHM4 : DHM5}
            //src={DHM1}
            alt='' />
        </div>
        <div className='dmh-second-container'>
          {locationCheck && <div className='container-1'>
            <div className='location'>{t('enter_site_location')}</div>
            {(siteLocationError) && <div className='error-text'>{t('enter_valid_input')}</div>}
            <div className='location-container'>
              <input className='search-location'
                placeholder={t('select_location')}
                value={this.props.address.shortAddress|| this.props.DMHState.shortAddress || ''}
                type='search'
                onClick={this.selectLocation} />
              <img className='location-class'
                src={LocatorImg}
                alt='' />
            </div>
            <p className='description'>{t('enter_location_to_build_house')}</p>
            <p className='description'>{t('if_unable_to_locate')}</p>
            <div className='button-wrapper'>
              <div className='visava-btn location-next-btn'
                onClick={() => this.nextClick('locationCheck')}>
                {t('next')}
              </div>
            </div>
            {/* <div className='button-container button-pointer'
              onClick={() => this.nextClick('locationCheck')}><div>{t('next')}</div>
            </div> */}
          </div>}
          {constAreaCheck && <div className='container-2'>
            <div className='const-area'>{t('enter_area')}</div>
            <div className='length'>
              <div className={`feet ${unit == 'feet' && 'selected border-feet'} button-pointer`}
                onClick={() => this.changeUnit('feet')}>{t('feet')}</div>
              <div className={`meter ${unit == 'meter' && 'selected border-meter'} button-pointer`}
                onClick={() => this.changeUnit('meter')}>{t('meter')}</div>
            </div>
            {(lengthAError || lengthBError) && <div className='error-text'>{unit == 'feet' ? t('error_feet') : t('error_meter')}</div>}
            {inputError &&<div className='error-text'>{errorMessage}</div>}
            <div className='length-A-container'>
              <div className='length-text'>{t('length_a')}</div>
              <div className='length_input_wrapper'>
                <input
                  className={`length-input-container ${inputError && 'error'}`}
                  placeholder={unit == 'feet' ? t('feet_min_max') : t('meter_min_max')}
                  value={lengthA}
                  type='number'
                  onChange={(values) => this.setValuesA(values)} />
                <span className='length_unit_lbl'>{unit == 'feet' ? t('feet') : t('meter')} :</span>
              </div>
              <div className='length-text'>{t('length_b')}</div>
              <div className='length_input_wrapper'>
                <input
                  className={`length-input-container ${inputError && 'error'}`}
                  placeholder={unit == 'feet' ? t('feet_min_max') : t('meter_min_max')}
                  value={lengthB}
                  type='number'
                  onChange={(values) => this.setValuesB(values)} />
                <span className='length_unit_lbl'>{unit == 'feet' ? t('feet') : t('meter')} :</span>
              </div>
            </div>
            <div className='button-wrapper'>
              <div className='back-button'
                onClick={() => this.backClick('mainDoorCheck')}>
                <div className='left_arrow'><img src={LeftBlueArrow} /></div>
                {`${t('back')}`}
              </div>
              <div className='visava-btn'
                onClick={() => this.nextClick('constAreaCheck')}>
                {t('next')}
              </div>
            </div>
            {/* <div className='button-container'>
              <div className='back-button button-pointer'
                onClick={() => this.backClick('mainDoorCheck')}><div className='left_arrow'><img src={LeftBlueArrow} /></div>{`${t('back')}`}</div>
              <div className='next-button button-pointer'
                onClick={() => this.nextClick('mainDoorCheck')}>{t('next')}</div>
            </div> */}
          </div>}
          {mainDoorCheck && <div className='container-3'>
            <div className='main-door'>{t('main_door_location')}</div>
            {mainDoorError && <div className='error-text'>{t('enter_main_door')}</div>}
            {directionError &&<div className='error-text'>{errorMessage}</div>}
            <div className='dropdown-container'>
              <DropdownList
                value={mainDoorDirection}
                placeholder={t('select')}
                data={doorOptions}
                filter={false}
                onChange={(values) => this.selectDoor(values)} />
            </div>
            <div className='description'>{t('main_door_specify')}</div>
            <div className='button-wrapper'>
              <div className='back-button'
                onClick={() => this.backClick('constAreaCheck')}>
                <div className='left_arrow'><img src={LeftBlueArrow} /></div>
                {`${t('back')}`}
              </div>
              <div className='visava-btn'
                onClick={() => this.nextClick('mainDoorCheck')}>
                {t('next')}
              </div>
            </div>
            {/* <div className='button-container'>
              <div className='back-button button-pointer'
                onClick={() => this.backClick('constAreaCheck')}><div className='left_arrow'><img src={LeftBlueArrow} /></div>{`${t('back')}`}</div>
              <div className='next-button button-pointer'
                onClick={() => this.nextClick('constAreaCheck')}>{t('next')}</div>
            </div> */}
          </div>}
          {preferencesCheck && <div className='container-4'>
            <div className='preference'>{t('enter_preference')}</div>
            {(bedroomError || toiletError || floorError) && <div className='error-text'>{t('enter_valid_input')}</div>}
            <div className='preference-container'>
              <div className='preference-wrapper'>
                <div className='text'>{t('floor_no')}</div>
                <div className={`${floorError ? 'dropdown-error' : 'dropdown-container'}`}>
                  <DropdownList
                    value={floorCount}
                    placeholder={t('select')}
                    data={floorsOptions}
                    filter={false}
                    onChange={(values) => this.selectFloor(values)} />
                </div>
              </div>
              <div className='preference-wrapper'>
                <div className='text'>{t('bedroom_no')}</div>
                <div className={`${bedroomError ? 'dropdown-error' : 'dropdown-container'}`}>
                  <DropdownList
                    value={bedroomCount}
                    placeholder={t('select')}
                    data={bedRoomsOptions}
                    filter={false}
                    disabled={floorCount === ''}
                    onChange={(values) => this.selectBedroom(values)} />
                </div>
              </div>
              <div className='preference-wrapper'>
                <div className='text'>{t('toilet')}</div>
                <div className={`${toiletError ? 'dropdown-error' : 'dropdown-container'}`}>
                  <DropdownList
                    value={toiletCount}
                    placeholder={t('select')}
                    filter={false}
                    data={toiletOptions}
                    onChange={(values) => this.selectToilet(values)} />
                </div>
              </div>
              <div className='description'> {t('display_text_rooms_preferences')} </div>
            </div>
            <div className='button-wrapper'>
              <div className='back-button'
                onClick={() => this.backClick('preferencesCheck')}>
                <div className='left_arrow'><img src={LeftBlueArrow} /></div>
                {`${t('back')}`}
              </div>
              <div className='visava-btn'
                onClick={() => this.nextClick('preferencesCheck')}>
                {t('next')}
              </div>
            </div>
            {/* <div className='button-container'>
              <div className='back-button button-pointer'
                onClick={() => this.backClick('preferencesCheck')}><div className='left_arrow'><img src={LeftBlueArrow} /></div>{`${t('back')}`}</div>
              <div className='next-button button-pointer'
                onClick={() => this.nextClick('preferencesCheck')}>{t('next')}</div>
            </div> */}
          </div>}
          {budgetCheck && <div className='container-5'>
            <div className='house-budget'>{t('enter_budget')}</div>
            {budgetError && <div className='error-text'>{t('enter_valid_budget')}</div>}
            <div className='dmh-budget-container'>
              <input
                className={`search-location ${budgetError && 'error'}`}
                placeholder={t('min_budget')}
                type='search'
                onChange={(e) => this.onBudgetSelection(e)}
                value={estimatedBudget} />
              <img className='image'
                src={RupeeImg}
                alt='' />
            </div>
            <p className='description'>{t('please_specify_funds')}</p>
            <p className='description'>{t('generate_customised_house')}</p>
            <div className='button-wrapper'>
              <div className='back-button'
                onClick={() => this.backClick('budgetCheck')}>
                <div className='left_arrow'><img src={LeftBlueArrow} /></div>
                {`${t('back')}`}
              </div>
              <div className='visava-btn'
                onClick={() => this.nextClick('budgetCheck')}>
                {t('next')}
              </div>
            </div>
            {/* <div className='button-container'>
              <div className='back-button button-pointer'
                onClick={() => this.backClick('budgetCheck')}><div className='left_arrow'><img src={LeftBlueArrow} /></div>{`${t('back')}`}</div>
              <div className='next-button button-pointer'
                onClick={() => this.nextClick('budgetCheck')}>{t('next')}</div>
            </div> */}
          </div>}
        </div>
      </div>
    );
  }
}

//const mapStateToProps = ({ designMyHouseData }) => designMyHouseData;

//todo

const mapDispatchToProps = (dispatch) => ({
  updateAddress: (data) => {
    dispatch(updateAddress(data));
  },
  updateDMHState: (DMHState, data) => {
    const result = Object.assign({}, DMHState, data);
    dispatch(updateDMHState(result));
  }
});

const mapStateToProps = (state) => ({
  breakpoint : getBreakpoint(state),
  address : getAddress(state),
  DMHState : getDMHState(state),
  langCode: getLangCodeSelector(state)
});

export default withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(DesignMyHouse)));

