export const breakPoint_M = window && window.matchMedia('(min-width: 575px)').matches;
export const breakPoint_L = window && window.matchMedia('(min-width: 768px)').matches;


import React from 'react';
import PropTypes from 'prop-types';

/** Constants **/
const breakpoints = {
  xs: 600, // mobile
  sm: 768, // tablet
  md: 992, //  medium
  smD: 1200, // small desktops
  lg: 1440 // large desktop
};

const getInitialBreakpoint = () => {
  if (typeof window !== 'undefined') {
    return window.initialBreakpoint || 'xs';
  }
//   else {
//     return global.initialBreakpoint || 'xs';
//   }
};

export default class Breakpoint extends React.PureComponent {
  constructor (props) {
    super(props);
    this.state = {
      currWidth: null,
      breakpoint: props.defaultBreakpoint || getInitialBreakpoint()
    };
    this.resizeHandler = this.resizeHandler.bind(this);
    this.getBreakpoint = this.getBreakpoint.bind(this);
    this.resizeThrottler = this.resizeThrottler.bind(this);
    this.resizeTimeout = null;
  }

  componentDidMount () {
    // eslint-disable-next-line no-console
    if (typeof window !== 'undefined') {
      const width = window.innerWidth;
      const breakpoint = this.getBreakpoint(width);
      window.addEventListener('resize', this.resizeThrottler, false);

      // Enable this check back when all instances of Breakpoints are remoed except from Footer
      // if (breakpoint !== this.state.breakpoint) {
      this.props.onBreakpointChange(breakpoint);
      // }
      this.setState({
        currWidth: width,
        breakpoint: breakpoint
      });
    }
  }

  componentWillUnmount () {
    if (typeof window !== 'undefined') {
      window.removeEventListener('resize', this.resizeThrottler, false);
      clearTimeout(this.resizeTimeout);
    }
  }

  resizeThrottler () {
    if (!this.resizeTimeout) {
      this.resizeTimeout = setTimeout(() => {
        this.resizeTimeout = null;
        this.resizeHandler();
      }, 100);
    }
  }

  resizeHandler () {
    if (typeof window !== 'undefined') {
      const currWidth = window.innerWidth;
      const breakpoint = this.getBreakpoint(currWidth);

      if (this.state.breakpoint !== breakpoint && this.props.onBreakpointChange) {
        this.setState({
          breakpoint: breakpoint
        });
        this.props.onBreakpointChange(breakpoint);
      }
    }
  }

  getBreakpoint () {
    // return width <= breakpoints.xs ? 'xs' : width <= breakpoints.sm ? 'sm' : width <= breakpoints.md ? 'md' : 'lg';
    //return window.matchMedia(`(max-width: ${breakpoints.xs}px)`).matches ? 'xs' : window.matchMedia(`(max-width: ${breakpoints.sm}px)`).matches ? 'sm' : window.matchMedia(`(max-width: ${breakpoints.md}px)`).matches ? 'md' : window.matchMedia(`(max-width: ${breakpoints.smD}px)`).matches ? 'smD' : 'lg';
    return window.matchMedia(`(max-width: ${breakpoints.sm}px)`).matches ? 'sm' : window.matchMedia(`(max-width: ${breakpoints.sm}px)`).matches ? 'sm' : 'lg';

  }

  render () {
    return (null);
  }
}

/** Props **/
Breakpoint.defaultProps = {
  onBreakpointChange: () => {
    // TODO document why this method 'onBreakpointChange' is empty
  
  }
};

Breakpoint.propTypes = {
  onBreakpointChange: PropTypes.func, //send breakpoint as parameter to callback
  // eslint-disable-next-line react/require-default-props
  defaultBreakpoint: PropTypes.oneOf(['xs', 'sm', 'md', 'lg'])
};