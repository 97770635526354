/* eslint-disable no-console */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import get from 'lodash/get';
import { setDMHRedirection, windowOpenHandler } from '../../../utility/common';
//import EditHouseImg from '../../../../src/asset/images/edit.svg';
import HouseDetailsImg from '../../../../src/asset/images/house_details_icon.svg';
import FullScreenImg from '../../../../src/asset/images/full-screen.svg';
//import ShowRoofImg from '../../../../src/asset/images/show-roof.svg';
import DesignDrawingImg from '../../../../src/asset/images/design-drawing.svg';
import DetailedCostingImg from '../../../../src/asset/images/money-bag.png';
import MaterialQualityImg from '../../../../src/asset/images/brick.png';
import ShareIconImg from '../../../../src/asset/images/share-icon.svg';
import ShareIconGreen from '../../../../src/asset/images/share_green.png';
import ForwardArrowImg from '../../../../src/asset/images/forward-vector-blue.svg';
import SaveMoreIcon from '../../../../src/asset/images/save-more.png';
import AddAnotherImg from '../../../../src/asset/images/add-another.svg';
//import Offer_tag_img from '../../../asset/images/offer_tag_img.svg';
import betaOffer from '../../../asset/images/beta_offer.svg';
//import FacebookIcon from '../../../asset/images/facebook.svg';
//import TwitterIcon from '../../../asset/images/twitter.svg';
//import InstagramIcon from '../../../asset/images/instagram.svg';
//import LinkedinIcon from '../../../asset/images/linkedin.svg';
import FeedbackIcon from '../../../asset/images/feedback_icon.svg';
import TapToTouch from '../../../asset/images/TapToInteract.svg';
//import GoogleIcon from '../../../asset/images/google_icon.svg';
import SampleDocument from '../sampleDocument';
import ConfirmationScreen from '../confirmation';
import SharePopUp from '../sharePopup';
import Header from '../../shared-ui/header';
import { BASE_URL_V4, API_URL, BLOG_URL, SOCIAL_LINKS, userAgent, FEED_BACK_FORMS } from '../../../api/api-config';
import Loader from '../../shared-ui/Loader';
import { axiosInstance } from '../../../api/coreApi';
import { withTranslation } from 'react-i18next';
import ErrorPopup from '../errorPopup';
import { setInitialPayment } from '../../redux/actions/initiatePayment';
//import DirectBomButtons from '../directBomButtons';
import { getBreakpoint, getLangCodeSelector } from '../../../selectors/commonSelectors';
import PaymentSuccessPage from '../paymentSuccessPage';
import HouseDetailsPopup from '../houseDetailsPopup';
import BudgetScreen from '../budgetScreens';

class Details extends Component {

  constructor (props) {
    super(props);
    this.fetchHouseDetails=this.fetchHouseDetails.bind(this);
    this.viewSharePopUp=this.viewSharePopUp.bind(this);
    //this.editHouse=this.editHouse.bind(this);
    this.showFullScreen=this.showFullScreen.bind(this);
    this.showSampleDocument=this.showSampleDocument.bind(this);
    this.closePopUp=this.closePopUp.bind(this);
    this.continue=this.continue.bind(this);
    this.closeSharePopUp=this.closeSharePopUp.bind(this);
    this.submitForm=this.submitForm.bind(this);
    //this.checkPaymentStatus=this.checkPaymentStatus.bind(this);
    this.viewdesignDoc=this.viewDesignDoc.bind(this);
    this.goToDMH=this.goToDMH.bind(this);
    this.continueDoc=this.continueDoc.bind(this);
    this.goToHomeListing=this.goToHomeListing.bind(this);
    //this.initiatePayment=this.initiatePayment.bind(this);
    this.redirectLoginPage = this.redirectLoginPage.bind(this);
    this.paymentProcess = this.paymentProcess.bind(this);
    this.editHousePopUp = this.editHousePopUp.bind(this);
    this.useragreed = this.useragreed.bind(this);
    this.goToBlog=this.goToBlog.bind(this);
    this.endUserFeedBackForm=this.endUserFeedBackForm.bind(this);
    this.redirectPayment=this.redirectPayment.bind(this);
    this.skipPayment=this.skipPayment.bind(this);

    const isConfirm = props && props.location && props.location.state && props.location.state.isConf;
    this.state = {
      showDocumentPopUp: false,
      confirmation: isConfirm || false,
      showSharePopUp: false,
      payment: false,
      houseData: null,
      isLoading: false,
      budgetSaved:'',
      paymentStatus: null,
      bomCost:'999',
      shareUrl:'',
      errorMessage: '',
      showErrorPopUp: false,
      showSuccess: false,
      openEditPopUp: false,
      agreed:false,
      openHouse: false,
      openHouseTotal: false,
      paymentStarted: false,
      houseID: '',
      paymentURL: '',
      totalHouse: null,
      permittedHouse: null
    };
  }

  endUserFeedBackForm () {
    windowOpenHandler(FEED_BACK_FORMS.end_user_form);
    this.setState({
      showErrorPopUp:false
    });
  }

  useragreed () {
    this.setState({
      agreed:true
    });
  }

  redirectLoginPage () {
    const { history } = this.props;
    history.push('/login');
  }

  goToBlog () {
    const langCode = localStorage.getItem('lang');
    if(langCode == 'en') {
      windowOpenHandler(`${BLOG_URL}/housing-schemes-in-india/`);
    } else {
      windowOpenHandler(`${BLOG_URL}/${langCode}/housing-schemes-in-india/`);
    }
  }

  editHousePopUp () {
    this.setState({
      openEditPopUp: true
    });
  }

  componentDidMount () {
    const { location } = this.props;
    const prevPage = location && location.state && location.state.from;
    if(prevPage==='homes' || prevPage==='home-details') {
      localStorage.removeItem('orderId');
      localStorage.removeItem('isPayment');
      this.setState({
        confirmation:false,
        isLoading: true,
      });
    }
    const houseNumber = new URLSearchParams(window.location.search).get('house-id');
    if(houseNumber) {
      localStorage.setItem('houseId', houseNumber);
    }
    this.setState({
      houseID : houseNumber
    });
    this.fetchHouseDetails();
  }

  fetchHouseDetails () {
    this.setState({
      isLoading: true
    });
    const houseId = localStorage.getItem('houseId');
    const url = `${API_URL.house}/${houseId}`;

    axiosInstance.get(url).then((resp) => {
      const data = resp && resp.data && resp.data.data;
      const payStatus = (data.payment == true ? 'SUCCESS' : 'FAILURE');
      if (data) {
        this.setState({
          houseData: data,
          isLoading: false,
          paymentStatus: payStatus,
          bomCost: data.iap_house_bom_cost,
          budgetSaved:data.house_cost_savings,
          actualHouseCost: data.house_cost,
          userBudget: data.budget_value,
          shareUrl:data.model_url,
          shareToken:data.share_token,
          openHouse:data.open_house,
          model:data.model_url,
          totalHouse:data.total_house_count,
          permittedHouse:data.maximum_house_count
        });
        localStorage.setItem('shareToken', data.share_token);
        const { location } = this.props;
        const prevPage = location && location.state && location.state.from;
        if(!data.payment && prevPage !=='homes'|| prevPage !== 'design-my-house' ) {
          const tempOrderId = localStorage.getItem('orderId');
          const isPaymentStarted = localStorage.getItem('isPaymentStarted');
          if(data.payment && prevPage ==='homes') {
            this.viewdesignDoc();
          } else if(data.payment) {
            this.goToHomeListing();
          }
          if(tempOrderId && isPaymentStarted) {
            //this.checkPaymentStatus(tempOrderId);
          }else if(payStatus==='FAILURE') {
            //this.initiatePayment(); 
          }else{
            localStorage.removeItem('orderId');
            localStorage.removeItem('isPaymentStarted');
          }
        }

      }
    }).catch((err) => {
      if(err.response.data.status == 'Token is Expired' || err.response.data.status == 'Token is Invalid' || err.response.data.status == 'Authorization Token not found' || err.response.data.status == 'FAILED') {
        localStorage.clear();
        this.props.history.push('/login', { expiredError: true });
      }
      this.setState({
        isLoading: false
      });
    });
  }

  viewSharePopUp () {
    this.setState({
      showSharePopUp: true
    });
  }

  // editHouse () {
  //   const { t } = this.props;
  //   const { history, location } = this.props;
  //   const { paymentStatus, houseData } = this.state;
  //   if(paymentStatus !== 'SUCCESS') {
  //     setDMHRedirection();
  //     localStorage.removeItem('orderId');
  //     history.push('/design-my-house', { editHouse: houseData });
  //   } else {
  //     this.setState({
  //       errorMessage: t('cannot_edit_house'),
  //       showErrorPopUp: true,
  //     });
  //   }
   
  // }

  showFullScreen () {
    const { history } = this.props;
    const { houseData } = this.state;
    history.push('/full-screen', { modelUrl : houseData?.model_url });
  }
  

  showSampleDocument () {
    //windowOpenHandler('/sample-document');
    const { history } = this.props;
    const langCode = localStorage.getItem('lang');
    history && history.push(`/sample-document?preferred_language=${langCode}&user_agent=${userAgent}`);
    //windowOpenHandler(`/sample-document?preferred_language=${langCode}&user_agent=${userAgent}`);
  }

  closePopUp () {
    this.setState({
      showDocumentPopUp: false
    });
  }

  continue () {
    this.setState({
      confirmation: false
    });
  }

  continueDoc () {
    this.setState({
      paymentStatus: this.state.paymentStatus,
      showSuccess: false
    });
    this.goToHomeListing();
  }

  closeSharePopUp () {
    this.setState({
      showSharePopUp: false
    });
  }

  submitForm (payParams) {
    <html>
      <head>
        <title>Show Payment Page</title>
      </head>
      <body>
        <center>
          <h1>Please do not refresh this page...</h1>
        </center>
        <div>
          <form method='post'
            //action={payParams.url}
            name='paytm'>
            <table border='1'>
              <tbody>
                <tr>
                  <td><input type='hidden'
                    name='mid'
                    value={payParams.mid} /></td>
                  <td><input type='hidden'
                    name='orderId'
                    value={payParams.order_id} /></td>
                  <td><input type='hidden'
                    name='txnToken'
                    value={payParams.paytm_txn_token} /></td>
                </tr>
              </tbody>
            </table>
            {/* <script type="text/javascript">{document && document.paytm && document.paytm.submit()}</script> */}
          </form>
        </div>
      </body>
    </html>;
  }

  // initiatePayment () {
  //   const url = BASE_URL_V4 + API_URL.payment;
  //   const object = {
  //     os_type: 'WEB',
  //   };

  //   axiosInstance.post(url, object).then((response) => {
  //     if (response && response.data && response.data.data) {
  //       const data = response && response.data && response.data.data;
  //       const formData = {
  //         url: data.url,
  //         mid: data.mid,
  //         orderId: data.order_id,
  //         transactionToken: data.paytm_txn_token
  //       };
  //       this.setState({
  //         paymentURL: data.url
  //       });
  //       localStorage.setItem('orderId', data.order_id);
  //       this.props.setInitialPayment(formData);
  //     }
  //   }).catch((err) => {
  //     //console.log('\n\nError in payment------>>', err && err.response && err.response.data);
  //     if (err && err.response) {
  //       const errData = err.response && err.response.data;
  //       if (errData && errData.status && errData.status.toLowerCase() == 'failed' && errData.error_code === 5001) {
  //         const { t }=this.props;
  //         const { location } = this.props;
  //         const prevPage = location && location.state && location.state.from;
  //         if(prevPage ==='homes'|| prevPage ==='design-my-house') {
  //           this.setState({
  //             showErrorPopUp: false
  //           });
  //         }else{
  //           this.setState({
  //             errorMessage: t('cannot_make_payment'),
  //             buttonLabel: t('close_button'),
  //             showErrorPopUp: true,
  //           });
  //         }
  //         localStorage.removeItem('orderId');
  //       }
  //     }
  //   });
  // }

  // checkPaymentStatus (tempOrderId) {
  //   const url = BASE_URL_V4 + API_URL.paymentStatus;
  //   const object = {
  //     order_id: tempOrderId,
  //   };
  //   axiosInstance.post(url, object).then((response) => {
  //     if (response && response.data) {
  //       const paymentStatus = get(response, 'data.data.payment_status', 'FAILURE');
  //       this.setState({
  //         paymentStatus : paymentStatus,
  //         showSuccess: paymentStatus === 'SUCCESS',
  //         showErrorPopUp: paymentStatus !== 'SUCCESS',
  //       });
  //       const { location } = this.props;
  //       const prevPage = location && location.state && location.state.from;
  //       if(prevPage=='homes' || prevPage=='design-my-house') {
  //         localStorage.removeItem('orderId');
  //         localStorage.removeItem('isPaymentStarted');
  //         this.setState({
  //           confirmation:false,
  //           showErrorPopUp: false,
  //           showSuccess: false,
  //         });
  //       }
  //       if(paymentStatus =='SUCCESS') {
  //         localStorage.removeItem('orderId');
  //         localStorage.removeItem('isPaymentStarted');
  //         //this.fetchHouseDetails();
  //       }else if(paymentStatus =='FAILURE') {
  //         localStorage.removeItem('isPaymentStarted');
  //       }
  //     }
  //   }).catch((err) => {
  //     //this.initiatePayment();
  //     //console.log('\n\nError in payment------>>', err && err.response && err.response.data);
  //   });
  // }

  skipPayment () {
    const url = BASE_URL_V4 + API_URL.payment;
    axiosInstance.put(url).then((response) => {
      if (response && response.data && response.data.data) {
        const data = response && response.data && response.data.data;
        this.setState({
          //showSuccess: true,
          paymentStatus: 'SUCCESS'
        });
        this.viewDesignDoc();
      }
    }).catch((err) => {
      //console.log('\n\nError in payment------>>', err && err.response && err.response.data);
      if (err && err.response) {
        const errData = err.response && err.response.data;
        if (errData && errData.status && errData.status.toLowerCase() == 'failed' && errData.error_code === 5401) {
          const { t }=this.props;
          this.setState({
            errorMessage: t('cannot_make_payment_three'),
            buttonLabel: t('close_button'),
            showErrorPopUp: true,
            openHouseTotal: true
          });
          localStorage.removeItem('orderId');
        }
      }
    });
  }

  paymentProcess () {
    const { t } = this.props;
    //this.initiatePayment();
    this.setState({
      showErrorPopUp: true,
      paymentStarted: true,
      errorMessage: t('noFurtherEdit'),
      buttonLabel: t('view_design_doc')
    });
  }

  redirectPayment () {
    //const { paymentURL } = this.state;
    localStorage.setItem('isPaymentStarted', true);
    if(!this.state.openHouseTotal) {
      this.skipPayment();
    } else {
      this.setState({
        showErrorPopUp: false,
        openHouseTotal: false });
    }
    //window.open(paymentURL);
  }

  viewDesignDoc () {
    //localStorage.setItem('isValid', true);
    //this.goToHomeListing();
    const houseId = localStorage.getItem('houseId');
    const shareToken = localStorage.getItem('shareToken');
    const langCode = localStorage.getItem('lang');
    const { history } = this.props;
    history && history.push(`/design-document?house_id=${houseId}&share_token=${shareToken}&preferred_language=${langCode}&user_agent=${userAgent}`);
    //windowOpenHandler(`/design-document?house_id=${houseId}&share_token=${shareToken}&preferred_language=${langCode}&user_agent=${userAgent}`);
  }


  goToDMH () {
    localStorage.removeItem('orderId');
    const { history, t } = this.props;
    const { openHouse } = this.state;
    if(!openHouse) {
      setDMHRedirection();
      history.push('/design-my-house');
    } else {
      this.setState({
        errorMessage: 'you have one unpaid house',
        buttonLabel: t('close_button'),
        showErrorPopUp: true,
      });
    }
  }

  goToHomeListing () {
    //this.viewDesignDoc();
    const { history } = this.props;
    history.push('/homes');
  }

  render () {
    const { t, breakpoint, langCode } = this.props;
    const { showDocumentPopUp, showSuccess, model, openHouse, paymentStarted, actualHouseCost, confirmation, openEditPopUp, showSharePopUp, payment, isLoading, budgetSaved, bomCost, paymentStatus, houseData, agreed, totalHouse, permittedHouse } = this.state;
    const { payParams } = this.props;

    return (
      <div>
        <Loader
          isLoading={isLoading} />
        {!confirmation ?
          <Header /> : null }
        {(confirmation && budgetSaved > 0) || (confirmation && agreed) ? <ConfirmationScreen continue={this.continue}
          amount={budgetSaved} /> :
          confirmation && !agreed && <BudgetScreen t={t}
            agreedClick={this.useragreed}
            continue={this.continue}
            editHouse={houseData}
            actualHouseCost={actualHouseCost} />}
        {openEditPopUp && <HouseDetailsPopup
          close={() => this.setState({ openEditPopUp :false })} />}
        {!confirmation ?
          <>
            <div className='top-correction' />
            <div className='details-container banner-container-top'>
              <Loader
                isLoading={isLoading} />
              {breakpoint == 'sm' ?
                <div>
                  <div className='feedback_btn'
                    onClick={this.endUserFeedBackForm}>
                    <img src={FeedbackIcon} />
                    <span className='lbl'>{t('share_feedback')}</span>
                  </div>
                  {this.state.paymentStarted &&
                    <ErrorPopup shown={this.state.showErrorPopUp}
                      paymentStatus={'FAILURE'}
                      close={() => this.setState({ showErrorPopUp:false })}
                      closeOk={() => this.setState({ showErrorPopUp:false }, this.redirectPayment)}
                      message={this.state.errorMessage}
                      showPayment={false}
                      googleMaps={false}
                      feedBackButton={false}
                      buttonLabel={this.state.buttonLabel}
                      showGoogleClose={false} />}
                  {this.state.showSharePopUp &&
                    <SharePopUp closeSharePopUp={this.closeSharePopUp}
                      text={t('share_design_desc')}
                      shareUrl={this.state.shareUrl}
                      shareText={true} />}
                  {showDocumentPopUp && <SampleDocument closePopUp={this.closePopUp} />}
                  <div className='image-wrapper-3d'
                    style={{ height: '50vh' }}>
                    <iframe src={`${model}&interactive=false`}
                      height='100%'
                      width='100%'
                      loading='lazy' />
                    <div className='tap_to_touch_overlay'
                      onClick={this.showFullScreen}>
                      <img src={TapToTouch} />
                    </div>
                  </div>
                  <div className='option-container'>
                    <div className='option-wrapper'>
                      <div className='image-wrapper'>
                        <img className='image'
                          // src={paymentStatus === 'SUCCESS' ? ShareIconImg : HouseDetailsImg}
                          src={HouseDetailsImg}
                          alt='3d-img' />
                      </div>
                      <div className='text button-pointer'
                        onClick={this.editHousePopUp}>
                        {/* onClick={paymentStatus === 'SUCCESS' ? this.viewSharePopUp : this.editHousePopUp}> */}
                        {/* {paymentStatus === 'SUCCESS' ? t('share_design'):t('houseDetailsTitle')} */}
                        {t('houseDetailsTitle')}
                      </div>
                    </div>
                    <div className='vertical-line' />
                    <div className='option-wrapper'>
                      <div className='image-wrapper'>
                        <img className='image'
                          src={FullScreenImg}
                          alt='3d-img' />
                      </div>
                      <div className='text button-pointer'
                        onClick={this.showFullScreen}>
                        {t('full_screen')}
                      </div>
                    </div>
                    {/* <div className='vertical-line' />
                <div className='option-wrapper'>
                  <div className='image-wrapper'>
                    <img className='image'
                      src={ShowRoofImg}
                      alt='3d-img' />
                  </div>
                  <div className='text'>
                    {t('show_roof')}
                  </div>
                </div> */}
                  </div>
                  {totalHouse < permittedHouse ?
                    <div className='doc_btn_container'>
                      { paymentStatus !== 'SUCCESS' ?
                        <div style={{ position: 'relative',
                          width: '233px',
                          margin: 'auto' }}>
                          {/* <div className='offer_text'>{t('offer_key')}</div>
                        <div className='offer_tag_img'>
                          <img src={Offer_tag_img}
                            alt='offer' />
                        </div> */}
                          {langCode == 'en' ?
                            <><div className='offer_text'>{t('offer_key')}</div><div className='offer_tag_img'>
                              <img src={betaOffer}
                                alt='offer' />
                            </div></> : <><div className='offer_text'>{t('offer_key')}</div><div className='offer_tag_img_hi_mi'>
                              <img src={betaOffer}
                                alt='offer' />
                            </div></> }
                          <div className='get-document'
                            onClick={this.paymentProcess}>
                            {langCode == 'en' ?
                              <button className='get-document-submit button-pointer'
                                onClick={this.paymentProcess}>{ t('get_document_starting')} <span className='original_price'>₹{bomCost}</span></button > :
                              <button className='get-document-submit button-pointer'
                                onClick={this.paymentProcess}><span className='original_price'>₹{bomCost}</span> { t('get_document_starting')} </button >
                                  }
                          </div>
                          <div className='sample-document button-pointer'
                            onClick={this.showSampleDocument}>
                            {t('view_sample_doc')}
                          </div>
                        </div> :
                        <div className='doc_btn_container'>
                          {/* <div className='get-document button-pointer'
                            onClick={this.viewDesignDoc}>
                            {t('view_design_doc')}
                          </div> */}
                          <div className='sample-document button-pointer'
                            onClick={this.viewSharePopUp}>
                            <img className='image share_icon'
                              src={ ShareIconGreen }
                              alt='3d-img' />
                            <div> {t('share_design')} </div>
                          </div>
                        </div>
                    }
                    </div> :
                    <div className='max-house-limit-msg'>
                      {t('maximumHouseLimit')}
                    </div>}
                  {/* add UI status of limiting house for mobile view */}
                  {paymentStatus === 'SUCCESS' || totalHouse > permittedHouse - 1 ? null : <>
                    <div className='include-text'>
                      {t('visava_doc_will_include')}
                    </div>
                    <div className='info-container'>
                      <div className='info-wrapper'>
                        <div className='image-wrapper'>
                          <img className='image'
                            src={DesignDrawingImg}
                            alt='detailed Drawings' />
                        </div>
                        <div className='text'>{t('design_drawing')}</div>
                      </div>
                      <div className='info-wrapper'>
                        <div className='image-wrapper'>
                          <img className='image'
                            src={MaterialQualityImg}
                            alt='Material Quality' />
                        </div>
                        <div className='text'>{t('material_quality')}</div>
                      </div>
                      <div className='info-wrapper'>
                        <div className='image-wrapper'>
                          <img className='image'
                            src={DetailedCostingImg}
                            alt='Detailed Costing' />
                        </div>
                        <div className='text'>{t('detailed_costing')}</div>
                      </div>
                    </div>
                  </> }
                  <div className='share-house-container button-pointer'
                    onClick={paymentStatus === 'SUCCESS' ? this.goToDMH:this.viewSharePopUp}>
                    <div className='share-image-wrapper'>
                      <img className='share-image'
                        src={paymentStatus === 'SUCCESS' ? AddAnotherImg:ShareIconImg}
                        alt='' />
                    </div>
                    <div className='share-text'>{paymentStatus === 'SUCCESS' ? t('design_another_house'):t('share_new_design')}</div>
                    <div className='forward-wrapper'>
                      <img className='forward-image'
                        src={ForwardArrowImg}
                        alt='' />
                    </div>
                  </div>
                  <div className='save-more-wrapper'>
                    <div className='image-container'>
                      <div className='image-wrapper'>
                        <img className='image'
                          src={SaveMoreIcon}
                          alt='' />
                      </div>
                    </div>
                    <div className='text'>{t('save_more_money')}<a className='read-more'
                      onClick={this.goToBlog}> {t('read_more')}</a></div>
                  </div>
                </div> :
                <div className='detail-wrapper-l'>
                  <div className='feedback_btn'
                    onClick={this.endUserFeedBackForm}>
                    <img src={FeedbackIcon} />
                    <span className='lbl'>{t('share_feedback')}</span>
                  </div>
                  {this.state.showSharePopUp &&
                    <SharePopUp closeSharePopUp={this.closeSharePopUp}
                      text={t('share_design_desc')}
                      shareUrl={this.state.shareUrl}
                      shareText={true} />}
                  {this.state.paymentStarted &&
                    <ErrorPopup shown={this.state.showErrorPopUp}
                      paymentStatus={'FAILURE'}
                      feedBackButton={false}
                      close={() => this.setState({ showErrorPopUp:false })}
                      closeOk={() => this.setState({ showErrorPopUp:false }, this.redirectPayment)}
                      buttonLabel={this.state.buttonLabel}
                      googleMaps={false}
                      message={this.state.errorMessage} />}
                  {showDocumentPopUp && (
                    <SampleDocument closePopUp={this.closePopUp} />
                  )}
                  <div className='section-1'>
                    <div className='sub-section-1'>
                      {paymentStatus === 'SUCCESS' ? <div className='house-details-l'>
                        <div className='image-wrapper'>
                          <img className='image'
                            src={HouseDetailsImg}
                            alt='3d-img' />
                        </div>
                        <div className='text button-pointer'
                          onClick={this.editHousePopUp}>
                          {t('houseDetailsTitle')}
                        </div>
                      </div> :
                      <div className='edit-house-l'>
                        <div className='image-wrapper'>
                          <img className='image'
                            src={HouseDetailsImg}
                            alt='3d-img' />
                        </div>
                        <div className='text button-pointer'
                          onClick={this.editHousePopUp}>
                          {t('houseDetailsTitle')}
                        </div>
                      </div>}
                      <div className='full-screen-l'>
                        <div className='image-wrapper'>
                          <img className='image'
                            src={FullScreenImg}
                            alt='3d-img' />
                        </div>
                        <div className='text button-pointer'
                          onClick={this.showFullScreen}>
                          {t('full_screen')}
                        </div>
                      </div>
                      {/* <div className='show-roof-l'>
                        <div className='image-wrapper'>
                          <img className='image'
                          src={ShowRoofImg}
                          alt='3d-img' />
                        </div>
                        <div className='text'>
                          {t('show_roof')}
                        </div>
                        </div> */}
                      <div className='share-design-l'>
                        <div className='share-top-section'>
                          <div className='image-wrapper'>
                            <img className='image'
                              src={ShareIconImg}
                              alt='3d-img' />
                          </div>
                          <div className='text button-pointer'
                            onClick={this.viewSharePopUp}>
                            {t('share_design')}
                          </div>
                        </div>
                        {/* <div className='social_icon_wrapper'>
                          <div className='icon'>
                            <a href={SOCIAL_LINKS.facebook}
                              className='icon'>
                              <img src={FacebookIcon} />
                            </a>
                          </div>
                          <div className='icon'>
                            <a href={SOCIAL_LINKS.twitter}
                              className='icon'>
                              <img src={TwitterIcon} />
                            </a>
                          </div>
                          <div className='icon'>
                            <a href={SOCIAL_LINKS.instagram}
                              className='icon'>
                              <img src={InstagramIcon} />
                            </a>
                          </div>
                          <div className='icon'>
                            <a href={SOCIAL_LINKS.linkedin}
                              className='icon'>
                              <img src={LinkedinIcon} />
                            </a>
                          </div>
                          <div className='icon'>
                            <img src={GoogleIcon} />
                          </div>
                        </div> */}
                      </div>
                      {paymentStatus === 'SUCCESS' || totalHouse > permittedHouse - 1 ? null : <>
                        <div className='include-text'>
                          {t('visava_doc_will_include')}
                        </div>
                        <div className='info-container'>
                          <div className='info-wrapper'>
                            <div className='image-wrapper'>
                              <img className='image'
                                src={DesignDrawingImg}
                                alt='detailed Drawings' />
                            </div>
                            <div className='text'>{t('design_drawing')}</div>
                          </div>
                          <div className='info-wrapper'>
                            <div className='image-wrapper'>
                              <img className='image'
                                src={MaterialQualityImg}
                                alt='Material Quality' />
                            </div>
                            <div className='text'>{t('material_quality')}</div>
                          </div>
                          <div className='info-wrapper'>
                            <div className='image-wrapper'>
                              <img className='image'
                                src={DetailedCostingImg}
                                alt='Detailed Costing' />
                            </div>
                            <div className='text'>{t('detailed_costing')}</div>
                          </div>
                        </div>
                      </> }
                      {/* add the required class for sample doc button in desktop view */}
                      <div className='sample-btn-area'>
                        {paymentStatus !== 'SUCCESS'?
                          <div className='btn'
                            onClick={this.showSampleDocument}>{t('view_sample_doc')}
                          </div>: null }
                      </div>
                      {/* <div>
                        <DirectBomButtons />
                        </div> */}
                    </div>
                    <div className='sub-section-2' />
                    <div className='sub-section-3'>
                      <div className='image-container'>
                        <div className='image-wrapper'>
                          <img className='image'
                            src={SaveMoreIcon}
                            alt='' />
                        </div>
                      </div>
                      <div className='text' >{t('save_more_money')}</div>
                      <a className='read-more'
                        onClick={this.goToBlog}>{t('read_more')}</a>
                    </div>
                  </div>
                  <div className='section-2'>
                    <div className='d-modal-container'>
                      <div className='d-image-wrapper'>
                        <iframe src={model}
                          height='100%'
                          width='100%'
                          loading='lazy' />
                      </div>
                    </div>
                    <div className='document-container'>
                      {/* {paymentStatus !== 'SUCCESS'?
                        <div className='sample-documents button-pointer'
                          onClick={this.showSampleDocument}>{t('view_sample_doc')}
                        </div>: null } */}
                      
                      <div>
                        { totalHouse < permittedHouse ?
                        // paymentStatus !== 'SUCCESS'?
                          <div style={{ position: 'relative' }}>
                            {langCode == 'en' ?
                              <><div className='offer_text'>{t('offer_key')}</div><div className='offer_tag_img'>
                                <img src={betaOffer}
                                  alt='offer' />
                              </div></> : <><div className='offer_text'>{t('offer_key')}</div><div className='offer_tag_img_hi_mi'>
                                <img src={betaOffer}
                                  alt='offer' />
                              </div></> }
                            <div className='get-document'
                              onClick={this.paymentProcess}>
                              {langCode == 'en' ?
                                <button className='get-document-submit button-pointer'
                                  onClick={this.paymentProcess}>{ t('get_document_starting')} <span className='original_price'>₹{bomCost}</span></button > :
                                <button className='get-document-submit button-pointer'
                                  onClick={this.paymentProcess}><span className='original_price'>₹{bomCost}</span> { t('get_document_starting')} </button >
                                  }
                            </div>
                          </div> :
                          <div className='max-house-limit-msg'>{t('maximumHouseLimit')}</div>
                          // add UI status of limiting house for Desktop view **********
                          // <div className='doc_btn_container'>
                          //   <div className='get-document'
                          //     style={{
                          //       marginTop: '5px' }} >
                          //     <div className='get-document-submit button-pointer'
                          //       onClick={this.viewDesignDoc}>{t('view_design_doc')} </div>
                          //   </div>
                          // </div>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              }
              {showSuccess && paymentStatus === 'SUCCESS' ? <PaymentSuccessPage continueDoc={this.continueDoc} />:
                (paymentStatus === 'PENDING' || paymentStatus === 'FAILURE' && !paymentStarted) ? <ErrorPopup shown={this.state.showErrorPopUp}
                  paymentStatus={this.state.paymentStatus}
                  close={() => this.setState({ showErrorPopUp:false })}
                  closeOk={() => this.setState({ showErrorPopUp:false })}
                  buttonLabel={t('close_button')}
                  googleMaps={false}
                  message={paymentStatus === 'PENDING' ? t('payment_status_pending') : t('payment_status_failure')} /> : null}
            </div>
          </> : <>
          </>
        }
        { this.redirectLoginPage}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  setInitialPayment: (data) => {
    dispatch(setInitialPayment(data));
  },
});

const mapStateToProps = (state) => ({
  payParams: (state.homeDetailsState && state.homeDetailsState.payParams) || {},
  breakpoint : getBreakpoint(state),
  langCode: getLangCodeSelector(state)
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withRouter(Details)));