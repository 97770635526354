import {
  UPDATE_BREAKPOINT,
  UPDATE_LANG_CODE
} from '../actions/headerState';

const INITIATE_STATE = {
  langCode: 'en'
};

const headerState = (state = INITIATE_STATE, action) => {
  switch (action.type) {
    case UPDATE_BREAKPOINT:
      return Object.assign({}, state, { breakpoint: action.payload });
    case UPDATE_LANG_CODE:
      return Object.assign({}, state, { langCode: action.payload });
    default:
      return { ...state };
  }
};

export default headerState;

