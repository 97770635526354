/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Route,
  Switch,
  Link
} from 'react-router-dom';
import Routes from './routes';
import PageNotFound from '../client/components/pageNotFound';
import { changeLang } from '../i18n/i18n';
import { createBrowserHistory } from 'history';
import { SetupInterceptors } from '../api/coreApi';
import { getLangCode } from '../utility/common';
import { getLangCodeSelector } from '../selectors/commonSelectors';
import { updateLangCode } from '../client/redux/actions/headerState';

const NotFound = () => (
  <div
    style={{
      height: '70vh',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    }}>
    <h1>Not Found</h1>
    <Link to='/'> Go Home </Link>
  </div>
);

const App = ({ state, dispatch, ...rest }) => {
  const history = createBrowserHistory();
  SetupInterceptors(history);


  useEffect(() => {
    const tempLang = localStorage.getItem('lang');

    if (tempLang) {
      dispatch(updateLangCode(getLangCode(tempLang)));
      changeLang(tempLang);
    } else {
      dispatch(updateLangCode('hi'));
      changeLang('hi');
    }
  });

  const hours = 0.01;
  const saved = localStorage.getItem('jwtToken');
  if (saved && (new Date().getTime() - saved > hours * 60 * 60 * 1000)) {
    localStorage.clear();
  }

  const renderRoutes = () => Routes.map((route, i) => (
    <Route
      exact={route.exact}
      key={i}
      path={route.path}
      component={route.component}
      {...rest}
      {...route} />
  ));

  return (
    <div className={`main-wrapper ${rest.langCode}`}>
      <Switch>
        {renderRoutes()}
        <Route component={PageNotFound} />
      </Switch>
    </div>
  );
};

const mapStateToProps = (state) => ({
  langCode : getLangCodeSelector(state)
});

export default (connect(mapStateToProps, null)(App));
