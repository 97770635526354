import React, { Component } from 'react';
import Login from '../../client/components/login';
import Header from '../../client/shared-ui/header/index';
//import { breakPoint_L } from '../../utility/breakPoints';

class LoginPage extends Component {
  render () {
    // let Headers;
    // if(breakPoint_L==false) {
    //   Headers=<Header />;
    // }
    return (
      <>
        <Header />
        <Login />
      </>
    );
  }
}

export default LoginPage;