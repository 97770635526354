/* eslint-disable react/prop-types */
/* eslint-disable no-console */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import LocationIcon from '../../../../../../src/asset/images/location.svg';
import MobileIcon from '../../../../../../src/asset/images/phone.svg';
import EmailIcon from '../../../../../../src/asset/images/mail.svg';
import FacebookIcon from '../../../../../../src/asset/images/facebook.svg';
import TwitterIcon from '../../../../../../src/asset/images/twitter.svg';
import InstagramIcon from '../../../../../../src/asset/images/instagram.svg';
import LinkedinIcon from '../../../../../../src/asset/images/linkedin.svg';
import GooglePlayBadgeEn from '../../../../../../src/asset/images/GooglePlayBadgeEn.png';
import GooglePlayBadgeHi from '../../../../../../src/asset/images/GooglePlayBadgeHi.png';
import GooglePlayBadgeMa from '../../../../../../src/asset/images/GooglePlayBadgeMa.png';
import CopyRightIcon from '../../../../../../src/asset/images/copyright.svg';
import ContactUs from '../contactUs/index.js';
import { withRouter } from 'react-router';
import { withTranslation } from 'react-i18next';
import { GOOGLE_PLAY_URL, CONTACT_DETAILS, SOCIAL_LINKS } from '../../../../../api/api-config';
import PrivacyPolicyDoc from '../../../../shared-ui/privacyPolicy/privacyPolicy';
import TermsConditions from '../../../../shared-ui/t&c';
import { getBreakpoint, getLangCodeSelector } from '../../../../../selectors/commonSelectors';

class GetInTouch extends Component {
  constructor (props) {
    super (props);
    this.goToLogin=this.goToLogin.bind(this);
    this.goToOurStory=this.goToOurStory.bind(this);
    this.gotoHomes=this.gotoHomes.bind(this);
    this.goToVisavaVendors=this.goToVisavaVendors.bind(this);
    //this.goToPrivacyPolicy=this.goToPrivacyPolicy.bind(this);
    this.showTC=this.showTC.bind(this);
    this.showPP=this.showPP.bind(this);
    this.state = {
      tcPopUp: false,
      PPPopUp: false
    };
  }

  goToLogin () {
    const { history } = this.props;
    history.push('/login');
  }

  goToOurStory () {
    const { history } = this.props;
    history && history.push('/our-story');
    // window.open('/our-story');
  }

  gotoHomes () {
    const { history } = this.props;
    history && history.push('/login');
  }

  goToVisavaVendors () {
    const { history } = this.props;
    history && history.push('/visava-vendors');
  }

  // goToPrivacyPolicy () {
  //   // window && window.open('/privacy-policy');
  //   //windowOpenHandler('/privacy-policy');
  //   const { history } = this.props;
  //   history && history.push('/privacy-policy');
  // }

  showPP () {
    this.setState({
      PPPopUp: !this.state.PPPopUp
    }, () => {
      const header = document.getElementsByClassName('header-container-1');
      if (typeof document !== 'undefined' && document.body && header && header[0]) {
        if (document.documentElement && window && window.innerWidth && this.state.PPPopUp) {
          const scrollBarGap = window.innerWidth - document.documentElement.clientWidth;
          if (scrollBarGap > 0) {
            document.body.style.paddingRight = `${scrollBarGap}px`;
          }
          document.body.style.overflow = 'hidden';
          document.body.style.position = 'absolute';
          document.body.style.width = '100%';

          header[0].style.filter = 'none';
          header[0].style.width = `calc(100% - ${scrollBarGap}px)`;
        } else {
          document.body.style.overflow = 'auto';
          document.body.style.position = 'static';
          document.body.style.paddingRight = 'inherit';
          document.body.style.removeProperty('width');

          header[0].style.filter = '';
          header[0].style.width = '';
        }
      }
    });

  }

  showTC () {
    this.setState({
      tcPopUp: !this.state.tcPopUp
    }, () => {
      const header = document.getElementsByClassName('header-container-1');
      if (typeof document !== 'undefined' && document.body && header && header[0]) {
        if (document.documentElement && window && window.innerWidth && this.state.tcPopUp) {
          const scrollBarGap = window.innerWidth - document.documentElement.clientWidth;
          if (scrollBarGap > 0) {
            document.body.style.paddingRight = `${scrollBarGap}px`;
          }
          document.body.style.overflow = 'hidden';
          document.body.style.position = 'absolute';
          document.body.style.width = '100%';

          header[0].style.filter = 'none';
          header[0].style.width = `calc(100% - ${scrollBarGap}px)`;
        } else {
          document.body.style.overflow = 'auto';
          document.body.style.position = 'static';
          document.body.style.paddingRight = 'inherit';
          document.body.style.removeProperty('width');

          header[0].style.filter = '';
          header[0].style.width = '';
        }
      }
    });
  }

  render () {
    const { t, breakpoint, langCode }=this.props;
    const { tcPopUp, PPPopUp } = this.state;
    const jwtToken = localStorage.getItem('jwtToken');
    return (
      <div className='get-in-touch-container'>
        {tcPopUp && <TermsConditions onClose={this.showTC} />}
        {PPPopUp && <PrivacyPolicyDoc onClose={this.showPP} />}
        <div className='get-in-touch'>{t('get_in_touch')}</div>
        <div className='firm-name'>{t('visava_labs_pvt_ltd').toUpperCase()}</div>
        <div className='location-wrapper'>
          <div className='image-wrapper'>
            <img className='location-icon'
              src={LocationIcon}
              alt='location' />
          </div>
          <div style={{ marginTop: '1px' }}>{t('pune_India')}</div>
        </div>
        <div className='link'
          onClick={this.gotoHomes}>{t('design_my_house')}</div>
        <div className='link'
          onClick={this.goToOurStory}>{t('our_story_footer')}</div>
        <div className='link'
          onClick={this.goToVisavaVendors}>{t('vendor_portal')}</div>
        {!jwtToken && <div className='link'
          onClick={this.goToLogin}>{t('log_in')}</div>}
        <div id='TnC'
          className='link'
          onClick={this.showTC}>{t('otp_verifier_terms_link')}</div>
        <div id='privacy_policy'
          className='link login'
          onClick={this.showPP}>{t('privacy_policy')}</div>
        <a href='tel:+918530698999'
          className='contact-container'>
          <div className='mobile-icon-wrapper'>
            <img className='mobile-icon'
              src={MobileIcon}
              alt='mobilePhone' />
          </div>
          <div className='contact-text'>{CONTACT_DETAILS.mobile}</div>
        </a>
        <a href='mailto:enquiry@visava.in'
          className='contact-container'>
          <div className='email-icon-wrapper'>
            <img className='email-icon'
              src={EmailIcon}
              alt='EmailIcon' />
          </div>
          <div className='contact-text'>{CONTACT_DETAILS.email}</div>
        </a>
        <div className='stay-connected'>
          {t('stay_connected')}
        </div>
        <div className='social-icon-wrapper'>
          <a href={SOCIAL_LINKS.facebook}
            target='_blank'
            rel='noreferrer'>
            <img className='icon'
              src={FacebookIcon}
              alt='' />
          </a>
          <a href={SOCIAL_LINKS.twitter}
            target='_blank'
            rel='noreferrer'>
            <img className='icon'
              src={TwitterIcon}
              alt='' />
          </a>
          <a href={SOCIAL_LINKS.instagram}
            target='_blank'
            rel='noreferrer'>
            <img className='icon'
              src={InstagramIcon}
              alt='' />
          </a>
          <a href={SOCIAL_LINKS.linkedin}
            target='_blank'>
            {/* rel='noreferrer'> */}
            <img className='icon'
              src={LinkedinIcon}
              alt='' />
          </a>
        </div>
        <div className='play-store-wrapper'>
          <a href={GOOGLE_PLAY_URL}
            target='_blank'
            rel='noopener noreferrer'><img className='play-store'
              src={langCode == 'hi' ? GooglePlayBadgeHi : langCode == 'mr' ? GooglePlayBadgeMa : GooglePlayBadgeEn}
              alt='' /></a></div>
        <div className='copyright-container'>
          <div style={{ display:'flex',
            flexDirection:'row',
            lineHeight:'normal',
            alignItems:'flex-strat'
          }}>
            <div className='copyright-image-wrapper'>
              <img className='copyright-image'
                src={CopyRightIcon}
                alt='copy-right-icon' />
            </div>
            <div className='copyright-text'>{new Date().getFullYear()}{t('right_reserved')}</div>
          </div>
          <div className='play-store-wrapper'>
            <a href={GOOGLE_PLAY_URL}
              target='_blank'
              rel='noopener noreferrer'><img className='play-store'
                src={langCode == 'hi' ? GooglePlayBadgeHi : langCode == 'mr' ? GooglePlayBadgeMa : GooglePlayBadgeEn}
                alt='' /></a>
          </div>
        </div>
        {breakpoint == 'lg' && <div><div className='contact-us-container-L'>
          <ContactUs />
        </div></div>}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  breakpoint : getBreakpoint(state),
  langCode: getLangCodeSelector(state)
});

export default withTranslation()(connect(mapStateToProps, null)(withRouter(GetInTouch)));