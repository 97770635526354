import { API_URL } from '../../../api/api-config';
import { axiosInstance } from '../../../api/coreApi';

export const INITIATE_PAYMENT = 'INITIATE_PAYMENT';

export const initiatePayment = (object) => {
  const payment = `${API_URL.payment}`;
  return async (dispatch) => {
    await axiosInstance
      .post(payment, object)
      .then(({ data }) => {
        dispatch({ type: INITIATE_PAYMENT,
          data });
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.log('\n\nerr---->>', err);
      });
  };
};

export const setInitialPayment = (data) => ({
  type: INITIATE_PAYMENT,
  payload: data
});