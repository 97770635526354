import React, { Component } from 'react';
import DesignMyHouse from '../../client/components/designMyHouse';
import Header from '../../client/shared-ui/header';

class DesignTheHouse extends Component {
  render () {
    return (
      <><Header /><DesignMyHouse /></>
    );
  }
}

export default DesignTheHouse;