/* eslint-disable no-console */
/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import GmailImg from '../../../../src/asset/images/gmail.svg';
//import CloseIcon from '../../../../src/asset/images/cancel-icon.svg';
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  WhatsappShareButton,
  TwitterShareButton
} from 'react-share';
import CopyImg from '../../../../src/asset/images/content_copy.svg';
import TwitterIcon from '../../../../src/asset/images/twitter.svg';
import FacebookIcon from '../../../../src/asset/images/facebook.svg';
import LinkedInIcon from '../../../../src/asset/images/linkedin.svg';
import WhatsappIcon from '../../../../src/asset/images/whatsapp.svg';
import { withTranslation } from 'react-i18next';

class SharePopUp extends Component {
  constructor (props) {
    super(props);
    this.onOutSideClick=this.onOutSideClick.bind(this);
    this.handleCopy = this.handleCopy.bind(this);
    this.state = {
      isCopied: false,
    };
  }

  onOutSideClick () {
    const { closeSharePopUp } = this.props;
    closeSharePopUp && closeSharePopUp();
  }

  async copyText (text) {
    if ('clipboard' in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand('copy', true, text);
    }
  }
  
  //add a function to handle preview img to add with share doc content
  handleCopy () {
    this.copyText(`${this.props.text}\n${this.props.shareUrl}`)
      .then(() => {
        this.setState({ isCopied: true });
        setTimeout(() => {
          this.setState({ isCopied: false });
        }, 2000);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  render () {

    const { t } = this.props;

    return (
      <div className='overlay'
        onClick={this.onOutSideClick}>
        <div className='share-container'>
          <div className='share-wrapper'
            onClick={(e) => e.stopPropagation()}>
            <div className='share-text'>{this.props.shareText? t('share_text') : t('invite_text')}</div>
            <div className='share-icon-wrapper'>
              <div className='image-wrapper'>
                <EmailShareButton url={this.props.shareUrl}
                  body={this.props.text}>
                  <img className='image'
                    src={GmailImg}
                    alt='' /></EmailShareButton>
                <div className='text-container'>
                  <div className='text-wrapper'>
                    Gmail
                  </div>
                </div>
              </div>
 

              <div className='image-wrapper'>
                <TwitterShareButton
                  url={this.props.shareUrl}
                  title={this.props.text}>
                  <img className='image'
                    src={TwitterIcon}
                    alt='' /></TwitterShareButton>
                <div className='text-container'>
                  <div className='text-wrapper'>
                    Twitter
                  </div>
                </div>
              </div>

              <div className='image-wrapper'>
                <FacebookShareButton
                  url={this.props.shareUrl}
                  quote={this.props.text}>
                  <img className='image'
                    src={FacebookIcon}
                    alt='' /></FacebookShareButton>
                <div className='text-container'>
                  <div className='text-wrapper'>
                    Facebook
                  </div>
                </div>
              </div>

            </div>


            <div className='share-icon-wrapper'>
              <div className='image-wrapper button-pointer'
                onClick={this.handleCopy}>
                <img className='image'
                  src={CopyImg}
                  alt='' />
                <div className='text-container'>
                  <div className='text-wrapper'>
                    {this.state.isCopied ? 'Copied!' : 'copy'}
                  </div>
                </div>
              </div>

              <div className='image-wrapper'>
                <LinkedinShareButton
                  url={this.props.shareUrl}
                  title={this.props.text}>
                  <img className='image'
                    src={LinkedInIcon}
                    alt='' /></LinkedinShareButton>
                <div className='text-container'>
                  <div className='text-wrapper'>
                    LinkedIn
                  </div>
                </div>
              </div>

              <div className='image-wrapper'>
                <WhatsappShareButton
                  url={this.props.shareUrl}
                  title={this.props.text}>
                  <img className='image'
                    src={WhatsappIcon}
                    alt='' />
                </WhatsappShareButton>
                <div className='text-container'>
                  <div className='text-wrapper'>
                    WhatsApp
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(SharePopUp);