import React, { Component } from 'react';
import PrivacyPolicyDoc from '../../client/shared-ui/privacyPolicy/privacyPolicy';

class PrivacyPolicyPage extends Component {
  render () {
    return (
      <PrivacyPolicyDoc />
    );
  }
}

export default PrivacyPolicyPage;