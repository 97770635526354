/* eslint-disable no-console */
/* eslint-disable no-undef */

export const API_HOST = process.env.REACT_APP_API_BASE_URL || 'https://www.visava.in';
export const BASE_URL_V4 = `${API_HOST}/api/v4/`;
export const SAVING_LIMIT = 1000;
//export const WEB_APP_URL = 'https://www.visava.in/';  use this url once the app is released or for production env.
//export const WEB_APP_URL = 'https://dev1.visava.in/web/'; // to be used only for dev. env.
export const APP_HOST = process.env.REACT_APP_HOST_URL || 'https://www.visava.in';
//export const GOOGLE_PLAY_URL = 'https://play.google.com/store/apps/details?id=com.visava.app';
export const GOOGLE_PLAY_URL = 'https://play.google.com/store/apps/details?id=com.visava.android';
export const SHARE_URL = `${APP_HOST}/`;
export const BLOG_URL = 'https://www.visava.in/blog/';
export const userAgent = 'VisavaWebApp';
export const SAMPLE3DMODEL = 'https://dev1.visava.in/model/?model=TM_T3A_1_5_Remodelled_v3.json&material_master=TM_Materials_Master.json&material_dag=TM_F_Materials_DAG_v4.json&a=8&b=8&uom=METER&ed=SOUTH&bv=200000&bc=IN&debug=false&optimize=false';


//google-maps-api-key for GPS feature
export const GOOGLE_MAP_API_KEY = 'AIzaSyBP3QcpjBXMY2LqhidfGGxxILENlM9rKbU';

//Client mail and number
export const CONTACT_DETAILS = {
  mobile: '+918530698999',
  email: 'enquiry@visava.in'
};

//beta release Feed-back form links
export const FEED_BACK_FORMS = {
  power_user_form: 'https://docs.google.com/forms/d/e/1FAIpQLScKdta631WEbRVJeJg7pLASo9pN-m_LSdi9B2rdnOQGQd1ECw/viewform',
  end_user_form: 'https://docs.google.com/forms/d/e/1FAIpQLSf1d_XKDywtrKlWMRmrFJp0otXatmKcL90XoHqsNU0c-5mD8A/viewform'
};

export const House_ID = 1;
export const Share_Token = 'ABC1234567890';

// API end-points
export const API_URL = {
  healthCheck: 'health-check',
  signUpMobile: 'sign-up-mobile-initiate',
  signUpMobileOtp: 'sign-up-mobile-verify',
  signUpEmail: 'sign-up-email-initiate',
  signUpEmailOtp: 'sign-up-email-verify',
  loginMobile: 'login-mobile-initiate',
  loginMobileOtp: 'login-mobile-verify',
  loginEmail: 'login-email-initiate',
  loginEmailOtp: 'login-email-verify',
  //userProfileImage: 'user-profile-image',
  addEmailToProfile:'user-profile/add-email-to-profile-initiate',
  addEmailToProfileOtp:'user-profile/add-email-to-profile-verify',
  addMobileToProfile:'user-profile/add-mobile-to-profile-initiate',
  addMobileToProfileOtp:'user-profile/add-mobile-to-profile-verify',
  house: 'house',
  plan: 'plan',
  invite: 'invite',
  payment: 'payment',
  paymentStatus: 'payment-status-check',
  sampleBluePrint: 'sample-blueprint',
  bluePrint: 'blueprint',
  contactUs: 'contact-us-general',
  vendorContactUs: 'contact-us-vendor',
  visavaHouseBom: 'visava-house-bom/',
  houseOptions:'house-options',
  lookUpGeo:'house/lookup-geo',
  NotifyGeo:'/house/lookup-typologies',
  lookUpHouseTypology:'/house/lookup-typologies',
  lookUpHouseTypologyOptions:'house/lookup-typology-options',
  notifyMeLocation:'contact-us-user',
  getUserProfile:'user-profile',
  getUserProfileImage: 'user-profile-image',
  logout: 'logout',
  userHouseProfile: 'house/house-user-info',
  houseCosting: 'house/costing',
  houseBom: 'house/bom',
  sampleBomCosting: 'house/costing',
  sampleBom: 'house/bom',
  feedBackStatus: 'user-feedback/'
};

//error-codes-generic
export const ERROR_CODES = {
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  SERVER_DOWN: 504,
};

//Client youtube links
export const YOUTUBE_LINKS = {
  how_to_use_visava_english: 'https://www.youtube.com/embed/4HWF_6fFcw0',
  how_to_use_visava_hindi: 'https://www.youtube.com/embed/3TnX1-2eWPQ',
  // how_to_use: 'https://www.youtube.com/embed/tFVpFFYI3OQ',
  // home_page: 'https://www.youtube.com/embed/tFVpFFYI3OQ',
  //need_help: 'https://www.youtube.com/embed/tFVpFFYI3OQ',
  // how_visava_works: 'https://www.youtube.com/embed/tFVpFFYI3OQ'
};

//Social-sites links for share
export const SOCIAL_LINKS = {
  facebook: 'https://www.facebook.com/VisavaLabs/',
  twitter: 'https://twitter.com/LabsVisava',
  instagram: 'https://www.instagram.com/visavalabs/',
  linkedin: 'https://www.linkedin.com/company/visava-labs/'
};
