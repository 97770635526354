/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable no-console */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import OtpInput from 'react-otp-input-visio';
import { connect } from 'react-redux';
import { BASE_URL_V4, API_URL } from '../../../api/api-config';
import Loader from '../../shared-ui/Loader';
import LogoWithText from '../../components/logoWithText';
//import { breakPoint_L } from '../../../utility/breakPoints';
import { withTranslation } from 'react-i18next';
import { getBreakpoint } from '../../../selectors/commonSelectors';
import { axiosInstance } from '../../../api/coreApi';

//import EmailIcon from '../../../../src/asset/images/email-open.svg';
//import MobileIcon from '../../../../src/asset/images/phonelink.svg';
//import { withTranslation } from 'react-i18next';
//import OtpInput from 'react-otp-input-visio';

class VerifyEmailPhoneOTP extends Component {
  constructor (props) {
    super(props);
    this.isNumeric=this.isNumeric.bind(this);
    this.inputNumber=this.inputNumber.bind(this);
    this.validateOtp=this.validateOtp.bind(this);
    this.loading=this.loading.bind(this);
    // this.validateOtpApi=this.validateOtpApi.bind(this);
    // this.resendOtp=this.resendOtp.bind(this);
    const { t } = this.props;
    this.state = {
      email:'',
      mobile:'',
      showEmail:'',
      showMobile:'',
      otpNumber: '',
      inputError: false,
      otpValidation: true,
      tcPopUp: false,
      verifyText: t('verify_text'),
      showError: false,
      isLoading: false,
      enterOtpError: false,
    };
  }
  
  inputNumber (e) {
    this.setState({
      otpNumber: e,
      enterOtpError:false,
    });
  }

  isNumeric (value) {
    return /^-?\d+$/.test(value);
  }

  loading (value) {
    this.setState({
      isLoading: value
    });
  }

  validateOtpApi ( emailOrPhone) {
    const { history, t } = this.props;
    const { otpNumber } = this.state;
    const jwtToken=localStorage.getItem('jwtToken');
    if (!this.isNumeric(emailOrPhone)) {
      const URL = BASE_URL_V4 + API_URL.addEmailToProfileOtp;
      //console.log(URL, 'url...1');
      const requestBody = {
        email: emailOrPhone,
        otp: otpNumber,
      };
      const axiosConfig = {
        headers:  { 'Authorization':  ` Bearer  ${jwtToken}`
        }
      };
      axiosInstance.post(URL, requestBody, axiosConfig).then((res) => {
        this.loading(false);
        if (res && res.data) {
          const response = res.data;
          history.push('/my-profile');
        }
      }).catch((err) => {
        if (err && err.response) {
          const errData = err.response && err.response.data;
          if (errData.status.toLowerCase() == 'failed') {
            this.setState({
              otpValidation: false,
              showError: true,
              verifyText: t('otp_verifier_resend_code'),
              isLoading: false
            });
          }
          console.log('err-------->>>>', errData);
        }
        
      });
    } else{
      const URL = BASE_URL_V4 + API_URL.addMobileToProfileOtp;
      //console.log(URL, 'url...2');
      const requestBody = {
        country_code: '91',
        mobile: emailOrPhone,
        otp: otpNumber,
      };
      const axiosConfig = {
        headers:  { 'Authorization':  ` Bearer  ${jwtToken}`
        }
      };
      axiosInstance.post(URL, requestBody, axiosConfig).then((res) => {
        this.loading(false);
        if (res && res.data) {
          const response = res.data;
          history.push('/my-profile');
        }
      }).catch((err) => {
        if (err && err.response) {
          const errData = err.response && err.response.data;
          if (errData.status.toLowerCase() == 'failed') {
            this.setState({
              otpValidation: false,
              showError: true,
              verifyText: t('otp_verifier_resend_code'),
              isLoading: false
            });
          }
          if (errData && errData.dev_response_code == '1001') {
            this.setState({
              alreadyRegistered: true,
              errMsg: t('auth_account_already_exists'),
            });
          }
          console.log('err-------->>>>', errData);
        }
      });
    }
  }

  resendOtp (emailOrPhone) {
    const { t } = this.props;
    this.loading(true);
    const userId = localStorage.getItem('userId');
    const jwtToken=localStorage.getItem('jwtToken');
    if (!this.isNumeric(emailOrPhone)) {
      const URL = `${BASE_URL_V4 + API_URL.addEmailToProfile}/${userId}`;
      //console.log(URL);
      const requestBody = {
        email: emailOrPhone,
      };
      const axiosConfig = {
        headers:  { 'Authorization':  ` Bearer  ${jwtToken}`
        }
      };
      axiosInstance.post(URL, requestBody, axiosConfig).then(() => {
        this.setState({
          showError: false,
          verifyText: t('verify_text'),
          otpNumber: '',
          isLoading: false
        });
      }).catch((err) => {
        this.loading(false);
        if (err && err.response) {
          const errData = err.response && err.response.data;
          console.log('err-------->>>>', errData);
        }
      });

    } else {
      const URL = `${BASE_URL_V4 + API_URL.addMobileToProfile}/${userId}`;
      //console.log(URL);
      const requestBody = {
        country_code: '91',
        mobile: emailOrPhone,
      };
      const axiosConfig = {
        headers:  { 'Authorization':  ` Bearer  ${jwtToken}`
        }
      };
      axiosInstance.post(URL, requestBody, axiosConfig).then(() => {
        this.setState({
          showError: false,
          verifyText: t('verify_text'),
          otpNumber: '',
          isLoading: false
        });
      }).catch((err) => {
        this.loading(false);
        if (err && err.response) {
          const errData = err.response && err.response.data;
          console.log('err-------->>>>', errData);
        }
      });
    }
  }

  validateOtp (emailOrPhone) {
    const { showError, otpNumber } = this.state;
    if (otpNumber && otpNumber.length == 4) {
      this.loading(true);
      if (showError) {
        this.resendOtp(emailOrPhone);
      } else {
        this.validateOtpApi(emailOrPhone);
      }
    }else {
      this.setState({
        enterOtpError:true
      });
    }
  }

  render () {
    const { location, t, breakpoint } = this.props;
    const emailOrPhone = location && location.state && location.state.mobile ? location.state.mobile : location && location.state && location.state.email;
    const { otpNumber, otpValidation, showError, isLoading, enterOtpError } = this.state;
    //console.log(emailOrPhone);
    return (
      <div className='otp-container banner-container-top'>
        <Loader
          isLoading={isLoading} />
        {breakpoint == 'lg' && <LogoWithText />}
        {/* {tcPopUp && <TermsConditions onClose={this.showTC} />} */}
        {/* {!tcPopUp && <div>{!breakPoint_L && <div className='initial-text'>{signup ? 'Sign Up' : 'Login'}</div>} */}
        <div className='description'>{t('otp_verifier_sms_email_message')}  {`${emailOrPhone}`}</div>
        {enterOtpError ? <div className='error-text'>{t('please_enter_otp')}</div>:null}
        <div className='otp-wrapper'>
          <OtpInput
            value={otpNumber}
            onChange={this.inputNumber}
            numInputs={4}
            isInputNum={true}
            hasErrored={this.state.showError}
            inputStyle='input-container'
            errorStyle={this.state.showError && 'input-error'} />
        </div>
        <div className={`resend-code-wrapper ${!otpValidation ? 'otp-failed' : undefined}`}>
          <div className={!otpValidation ? 'invalid-code' : undefined}>{otpValidation ? t('didn\'t_receive_the_code') : (!otpValidation && showError) ? t('code_is_invalid') : ''}</div>
          {otpValidation && <div className='resend-code button-pointer'
            onClick={() => this.resendOtp(emailOrPhone)}>{t('otp_verifier_resend_code')}</div>}
        </div>
        <div className='otp-validation-wrapper'
          role='button'>
          <div
            className={'otp-validation-button button-pointer'}
            onClick={() => this.validateOtp(emailOrPhone)}>
            {this.state.verifyText}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  breakpoint : getBreakpoint(state)
});

export default withTranslation()(withRouter(connect(mapStateToProps, null)(VerifyEmailPhoneOTP)));