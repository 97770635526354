export const setDMHRedirection = () => localStorage.setItem('isValidDMH', 'true');

export const isValidDMHRedirection = () => localStorage.getItem('isValidDMH') === 'true';

export const deleteDMHRedirection = () => localStorage.removeItem('isValidDMH');

export const windowOpenHandler = (link) => {
  let linkWdBasePath = '';

  if (link.indexOf('http') > -1) { // complete URL
    linkWdBasePath = link;
  } else { // relative path
    // eslint-disable-next-line no-undef
    const basePath = process.env.REACT_APP_BASE_URL || '';
    linkWdBasePath = (basePath && basePath !== '/') ? basePath + link : link;
  }
  window && window.open(linkWdBasePath);
};

export const getLangCode = (langName='en') => {
  const langCodeMap = {
    'en': 'en',
    'hi': 'hi',
    'mr': 'mr'
  };

  return langCodeMap[langName] || 'en';
};