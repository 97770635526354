/* eslint-disable no-console */
/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { API_URL, } from '../../../api/api-config';
import './houseDetailsPopup.scss';
import { axiosInstance } from '../../../api/coreApi';
import { setDMHRedirection } from '../../../utility/common';
import CloseIcon from '../../../asset/images/close_icon.svg';

class HouseDetailsPopup extends Component {

  constructor (props) {
    super(props);
    this.gotoDMH=this.gotoDMH.bind(this);
    this.preferToilet=this.preferToilet.bind(this);
    this.preferFloor=this.preferFloor.bind(this);
    this.fetchHouseDetails=this.fetchHouseDetails.bind(this);
    this.capitalize=this.capitalize.bind(this);
    this.preferDirection=this.preferDirection.bind(this);
    this.unitLocalization=this.unitLocalization.bind(this);
    this.unitAreaLocalization=this.unitAreaLocalization.bind(this);
    this.state = {
      houseData: null,
      budget: '',
      location: '',
      lengthA: '',
      lengthB: '',
      mainDoor: '',
      floor: '',
      rooms: '',
      toilet: '',
      isLoading: false,
      budgetSaved: '',
      unit: '',
      //unitLength: ''

    };
  }

  componentDidMount () {
    this.fetchHouseDetails();
  }
 
  gotoDMH () {
    setDMHRedirection();
    const { history } = this.props;
    const { houseData } = this.state;
    history.push('/design-my-house', { editHouse: houseData });
  }

  preferToilet (toilet) {
    const { t } = this.props;
    const result = toilet == 1 ? t('yes') : t('no');
    return result;
  }

  unitLocalization (unit) {
    const { t } = this.props;
    const result = unit == 'feet' ? t('feet') : t('meter');
    return result;
  }

  unitAreaLocalization (unitlength) {
    const { t } = this.props;
    const result = unitlength == 'square feet' ? t('square_feet') : t('square_meter');
    return result;
  }
  
  preferDirection (mainDoor) {
    const { t } = this.props;
    const result = mainDoor == 'NORTH' ? t('north') : mainDoor == 'EAST' ? t('east') : mainDoor == 'WEST' ? t('west') : t('south');
    return result;
  }

  preferFloor (floor) {
    const { t } = this.props;
    return floor === 0 ? t('ground') : `${t('ground')} + ${floor}`;
  }

  capitalize (str) {
    str = str?.toLowerCase()?.split(' ');
    for (let i = 0; i < str.length; i++) {
      str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
    }
    return str.join(' ');
  }

  fetchHouseDetails () {

    this.setState({
      isLoading: true
    });

    const houseId = localStorage.getItem('houseId');
    const url = `${API_URL.house}/${houseId}`;
    axiosInstance.get(url).then((resp) => {
      const data = resp && resp.data && resp.data.data;
      const payStatus = (data.payment == true ? 'SUCCESS' : 'FAILURE');
      if (data) {
        this.setState({
          houseData: data,
          paymentStatus: payStatus,
          budget: data.budget_value,
          location: data.location_long_address,
          lengthA: data.dimension_x,
          lengthB: data.dimension_y,
          mainDoor: data.direction_of_entry,
          floor: data.number_of_floors,
          rooms: data.number_of_rooms,
          toilet: data.toilet_exist,
          isLoading: false,
          budgetSaved:data.house_cost_savings,
          unit: data.dimension_unit,
          //unitLength: data.dimension_unit
        });
      }
    });
  }

  render () {
    const { t } = this.props;
    const { budget, location, lengthA, lengthB, mainDoor, floor, rooms, toilet, unit, paymentStatus } = this.state;
    return (
      <div className='overlay'>
        <div className='details_popup_container'>
          <div className='inner_wrapper'>
            <div className='header'>
              <div className='title'>{t('houseDetailsTitle')}</div>
              <div className='icon'>
                <img src={CloseIcon}
                  onClick={() => {
                    this.props.close();
                  }} />
              </div>
            </div>
            <div className='middle_table'>
              <div className='row'>
                <div className='label'>{t('location')}</div>
                <div className='txt'>{location}</div>
              </div>
              <div className='row'>
                <div className='label'>{t('construction_area')}</div>
                <div className='mobile_txt txt'>
                  {t('lengthA')} = {lengthA} {this.unitLocalization(unit)},<br />
                  {t('lengthB')} = {lengthB} {this.unitLocalization(unit)},<br />
                  {t('plinth')} = {(lengthA*lengthB).toFixed(2)} {t('square')} {this.unitLocalization(unit)}
                </div>
                <div className='desktop_txt txt'> {t('lengthA')} = {lengthA} {this.unitLocalization(unit)},  {t('lengthB')} = {lengthB} {this.unitLocalization(unit)}, {t('plinth')} = {(lengthA*lengthB).toFixed(2)} {t('square')} {this.unitLocalization(unit)} </div>
              </div>
              <div className='row'>
                <div className='label'>{t('budget')}</div>
                <div className='txt'>₹{(budget).toLocaleString('en-IN')}</div>
              </div>
              <div className='row'>
                <div className='label'>{t('main_door_direction')}</div>
                <div className='txt'>{this.preferDirection(mainDoor)}</div>
              </div>
              <div className='row'>
                <div className='label'>{t('preferences')} </div>
                <div className='mobile_txt txt'>
                  {t('floors')} = {this.preferFloor(floor)},<br />
                  {t('rooms')} = {rooms}, {t('toiletreq')} = {this.preferToilet(toilet)}
                </div>
                <div className='desktop_txt txt'>{t('floors')} = {this.preferFloor(floor)}, {t('rooms')} = {rooms}, {t('toiletreq')} = {this.preferToilet(toilet)}</div>
              </div>
            </div>
            { paymentStatus !== 'SUCCESS' ?
              <div className='footer'>
                <div className='visava-btn btn'
                  onClick={this.gotoDMH}>{t('editHouseButton')} </div>
              </div> : null }
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(withRouter(HouseDetailsPopup));