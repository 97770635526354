/* eslint-disable no-console */
/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import UserIcon from '../../../../src/asset/images/user_icon.svg';
import EmailIcon from '../../../../src/asset/images/email-open.svg';
import MobileIcon from '../../../../src/asset/images/phonelink.svg';
import { withRouter } from 'react-router-dom';
import OtpInput from 'react-otp-input-visio';
import { BASE_URL_V4, API_URL } from '../../../api/api-config';
import Loader from '../../shared-ui/Loader';
//import { breakPoint_L } from '../../../utility/breakPoints';
import LogoWithText from '../logoWithText';
//import { constants } from '../../../utility/constants';
import { withTranslation } from 'react-i18next';
import { getBreakpoint } from '../../../selectors/commonSelectors';
import { errorMsgHandler } from '../../../utility/errorHandling';
import { getLangCode } from '../../../utility/common';
import { axiosInstance } from '../../../api/coreApi';

class SignUp extends Component {

  constructor (props) {
    super(props);
    this.redirectToLogin=this.redirectToLogin.bind(this);
    this.inputNumber=this.inputNumber.bind(this);
    this.inputEmail=this.inputEmail.bind(this);
    this.inputFirstName=this.inputFirstName.bind(this);
    this.inputLastName=this.inputLastName.bind(this);
    this.mobileSignUpApi=this.mobileSignUpApi.bind(this);
    this.emailSignUpApi=this.emailSignUpApi.bind(this);
    this.formValidation=this.formValidation.bind(this);
    this.enterButtonFunction=this.enterButtonFunction.bind(this);
    this.submitForm=this.submitForm.bind(this);
    this.state = {
      phoneNumber: '',
      emailId: '',
      errorPhone: false,
      errorEmail: false,
      firstName: '',
      lastName: '',
      customError: false,
      errMsg: '',
      firstNameError: false,
      lastNameError: false,
      isLoading: false,
      bothError:false,
    };
  }

  componentDidMount () {
    const jwtToken = localStorage.getItem('jwtToken');
    if (jwtToken) {
      this.props.history.replace('/homes');
    }
  }

  redirectToLogin () {
    const { history } = this.props;
    history.push('/login');
  }

  inputNumber (e) {
    this.setState({
      phoneNumber: e,
      errorPhone: false,
      errorEmail: false,
      customError: false,
      firstNameError: false,
      lastNameError: false,
      bothError:false
    });
  }

  inputEmail (e) {
    this.setState({
      emailId: e.target.value,
      errorPhone: false,
      errorEmail: false,
      customError: false,
      firstNameError: false,
      lastNameError: false,
      bothError:false
    });
  }

  inputFirstName (e) {
    this.setState({
      firstName: e.target.value,
      errorPhone: false,
      errorEmail: false,
      customError: false,
      errMsg: '',
      firstNameError: false,
      lastNameError: false,
      bothError:false
    });
  }

  inputLastName (e) {
    this.setState({
      lastName: e.target.value,
      errorPhone: false,
      errorEmail: false,
      customError: false,
      errMsg: '',
      firstNameError: false,
      lastNameError: false,
      alreadyRegistered: false,
      bothError:false
    });
  }

  mobileSignUpApi () {
    const { firstName, lastName, phoneNumber } = this.state;
    const { history, t } = this.props;
    const mobileSignUp = BASE_URL_V4 + API_URL.signUpMobile;

    const mobileRequestPayload = {
      preferred_language: getLangCode(localStorage.getItem('lang')) || 'en',
      first_name: firstName,
      last_name: lastName,
      country_iso: 'IN',
      mobile_country_code: '91',
      mobile_number: phoneNumber,
    };

    axiosInstance.post(mobileSignUp, mobileRequestPayload).then(() => {
      this.setState({
        isLoading: false
      });
      history.push('/otp', { from: 'signup',
        mobile: phoneNumber,
        email: null,
        firstName: firstName,
        lastName: lastName });
    }).catch((err) => {
      this.setState({
        isLoading: false
      });
      if (err && err.response) {
        const errData = err.response && err.response.data;
        if (errData && errData.status && errData.status.toLowerCase() == 'failed') {
          this.setState({
            alreadyRegistered: errData.error_code == 1007,
            errMsg: errorMsgHandler(errData.error_code, t),
          });
        }
      } else {
        this.setState({
          alreadyRegistered: false,
          errMsg: errorMsgHandler(1000, t) // error_code - 1000 - catch all
        });
      }
    });
  }

  emailSignUpApi () {
    const { firstName, lastName, emailId } = this.state;
    const { history, t } = this.props;
    const emailSignUp = BASE_URL_V4 + API_URL.signUpEmail;

    const emailRequestPayload = {
      preferred_language: getLangCode(localStorage.getItem('lang')) || 'en',
      first_name: firstName,
      last_name: lastName,
      country_iso: 'IN',
      email: emailId,
    };

    axiosInstance.post(emailSignUp, emailRequestPayload).then(() => {
      this.setState({
        isLoading: false
      });
      history.push('/otp', { from: 'signup',
        mobile: null,
        email: emailId,
        firstName: firstName,
        lastName: lastName });
    }).catch((err) => {
      this.setState({
        isLoading: false
      });
      if (err && err.response) {
        const errData = err.response && err.response.data;
        if (errData && errData.status && errData.status.toLowerCase() == 'failed') {
          this.setState({
            alreadyRegistered: errData.error_code == 1106,
            errMsg: errorMsgHandler(errData.error_code, t),
          });
        }
      } else {
        this.setState({
          alreadyRegistered: false,
          errMsg: errorMsgHandler(1100, t) // error_code - 1100 - catch all
        });
      }
    });
  }

  formValidation () {
    const { t }=this.props;
    const { phoneNumber, emailId, firstName, lastName } = this.state;
    const isPhoneValid = phoneNumber && phoneNumber.length == 10 ? true : false;
    const isEmailValid = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(emailId) ? true : false;

    if (firstName == '' && phoneNumber == '' && emailId == '' && lastName == '') {
      this.setState({
        customError: true,
        errMsg: t('enter_details_to_proceed'),
      });
      return false;
    } else if (firstName == '') {
      this.setState({
        firstNameError: true,
        errMsg: t('enter_valid_first_name')
      });
      return false;
    } else if (lastName == '') {
      this.setState({
        lastNameError: true,
        errMsg: t('enter_valid_last_name')
      });
      return false;
    } else if (isPhoneValid && emailId == '') {
      return true;
    } else if (isEmailValid && (phoneNumber == '')) {
      return true;
    } else if (isPhoneValid && isEmailValid) {
      return true;
    } else if ((phoneNumber == '' && emailId == '')) {
      this.setState({
        bothError:true
      });
    } else if ((phoneNumber != '' && phoneNumber.length < 10) && (emailId != '' && !isEmailValid)) {
      this.setState({
        errorPhone: true,
        errorEmail: true
      });
    } else if (phoneNumber != '' && phoneNumber.length < 10) {
      this.setState({
        errorPhone: true
      });
      return false;
    } else if (emailId != '' && !isEmailValid) {
      this.setState({
        errorEmail: true
      });
      return false;
    }
  }

  submitForm (e) {
    e.preventDefault();
    this.setState({ alreadyRegistered: false });
    const { phoneNumber } = this.state;
    const validation = this.formValidation();
    if (validation) {
      if (phoneNumber && phoneNumber.length == 10) {
        this.setState({
          isLoading: true
        });
        this.mobileSignUpApi();
      } else {
        this.setState({
          isLoading: true
        });
        this.emailSignUpApi();
      }
    }
  }

  // call the function of next click on enter-key-press click
  enterButtonFunction (e) {
    if (e.key === 'Enter') {
      e.preventDefault();
      this.setState({ alreadyRegistered: false });
      const { phoneNumber } = this.state;
      const validation = this.formValidation();
      if (validation) {
        if (phoneNumber && phoneNumber.length == 10) {
          this.setState({
            isLoading: true
          });
          this.mobileSignUpApi();
        } else {
          this.setState({
            isLoading: true
          });
          this.emailSignUpApi();
        }
      }
      console.log('works well');
    }
  }

  render () {
    const { t }=this.props;
    const { breakpoint } = this.props;
    const { phoneNumber, emailId, errorPhone, errorEmail, bothError, firstName, lastName, customError, errMsg, firstNameError, lastNameError, isLoading } = this.state;
    return (
      /***** mobile view ****/
      breakpoint == 'sm' ?
        <div className='sign-up-container banner-container-top'>
          <Loader
            isLoading={isLoading} />
          <div className='sign-up-text'>{t('sign_up')}</div>
          <form className='form-wrapper'
            onSubmit={this.submitForm}>
            {(customError || (errMsg && errMsg.length > 0)) && <div className='custom-error'>{errMsg}</div>}
            <div className='label-wrapper'>
              <div className='img-wrapper'>
                <img className='img'
                  src={UserIcon}
                  alt='' />
              </div>
              <label className='label-text'>{t('first_name')}</label>
            </div>
            <input
              className={`form-input ${(customError || firstNameError) && 'input-error'}`}
              type='text'
              value={firstName}
              id='first-name'
              placeholder={t('input_text')}
              maxLength={250}
              onChange={(e) => this.inputFirstName(e)} />
            <div className='label-wrapper'>
              <div className='img-wrapper'>
                <img className='img'
                  src={UserIcon}
                  alt='' />
              </div>
              <label className='label-text'>{t('last_name')}</label>
            </div>
            <input
              className={`form-input ${(customError || lastNameError) && 'input-error'}`}
              value={lastName}
              type='text'
              id='last-name'
              placeholder={t('input_text')}
              maxLength={250}
              onChange={(e) => this.inputLastName(e)} />
            <div style={{ position:'relative' }}>
              {bothError && <div className='error-container'>{t('signup_phone_email_error')}</div>}
              {errorPhone && <div className='error-container'>{t('enter_valid_phone_number')}</div>}
              <div className='label-wrapper'>
                <div className='img-wrapper'>
                  <img className='img'
                    src={MobileIcon}
                    alt='' />
                </div>
                <label className='label-text'>{t('user_profile_screen_phone_number')}</label>
              </div>
            </div>
            <OtpInput
              value={phoneNumber}
              onChange={this.inputNumber}
              numInputs={10}
              isInputNum={true}
              hasErrored={true}
              inputStyle='input-container'
              errorStyle={(errorPhone || customError || bothError) && 'input-error'} />
            <div className='or-container'>
              <div className='or-sideline-wrapper left_line'><div className='or-sideline' /></div>
              <div>{t('or')}</div>
              <div className='or-sideline-wrapper right_line'><div className='or-sideline' /></div>
            </div>
            <div style={{ position:'relative' }}>
              {errorEmail && <div className='error-container'>{t('enter_valid_email_id')}</div>}
          
              <div className='label-wrapper'>
                <div className='img-wrapper'>
                  <img className='img'
                    src={EmailIcon}
                    alt='' />
                </div>
                <label className='label-text'>{t('email_address')}</label>
              </div>
            </div>
            <input
              className={`form-input ${(errorEmail || customError || bothError) && 'input-error'}`}
              type='email'
              id='email'
              placeholder={t('input_text')}
              value={emailId}
              onChange={(e) => this.inputEmail(e)}
              onKeyDown={this.enterButtonFunction} />
            <div className='btn-container'>
              <input className='next-btn-container'
                type='submit'
                value={t('next')} />
            </div>
          </form>
          <div className='existing-user-container'>
            <div className='existing-user'>
              {t('already_member')}
            </div>
            <div className='login-wrapper button-pointer'
              onClick={this.redirectToLogin}>
              {t('login').toUpperCase()}
            </div>
          </div>
        </div> :
        /***** desktop view ****/
        <div className='sign-up-container banner-container-top'>
          <Loader
            isLoading={isLoading} />
          <LogoWithText title={t('sign_up')} />
          <div className='form-wrapper'>
            {(customError || (errMsg && errMsg.length > 0)) && <div className='custom-error'>{errMsg}</div>}
            <div className='feild-container left'>
              <div>
                <div className='label-wrapper'>
                  <div className='img-wrapper'>
                    <img className='img'
                      src={UserIcon}
                      alt='' />
                  </div>
                  <label className='label-text'>{t('first_name')}</label>
                </div>
                <input
                  className={`form-input ${(customError || firstNameError) && 'input-error'}`}
                  type='text'
                  value={firstName}
                  id='first-name'
                  maxLength={250}
                  placeholder={t('input_text')}
                  onChange={(e) => this.inputFirstName(e)} />
              </div>
              <div style={{ position:'relative' }}>
                {errorPhone && <div className='error-container'>{t('enter_valid_phone_number')}</div>}
                {bothError && <div className='error-container'>{t('signup_phone_email_error')}</div>}
                {errorEmail && <div className='top-space' />}
                <div className='label-wrapper'>
                  <div className='img-wrapper'>
                    <img className='img'
                      src={MobileIcon}
                      alt='' />
                  </div>
                  <label className='label-text'>{t('user_profile_screen_phone_number')}</label>
                </div>
                <OtpInput
                  value={phoneNumber}
                  onChange={this.inputNumber}
                  isDisabled={emailId !== ''}
                  numInputs={10}
                  isInputNum={true}
                  onKeyDown={this.enterButtonFunction}
                  hasErrored={true}
                  inputStyle='input-container'
                  errorStyle={(errorPhone || customError || bothError) && 'input-error'} />
              </div>
            </div>
            <div className='or-container-l-signup'>
              <div className='or-sideline-wrapper-l-signup'><div className='or-sideline-l-signup' /></div>
              <div className='or-text-signup'>{t('or')}</div>
              <div className='or-sideline-wrapper-l-signup'><div className='or-sideline-l-signup' /></div>
            </div>
            <div className='feild-container right'>
              <div>
                <div className='label-wrapper'>
                  <div className='img-wrapper'>
                    <img className='img'
                      src={UserIcon}
                      alt='' />
                  </div>
                  <label className='label-text'>{t('last_name')}</label>
                </div>
                <input
                  className={`form-input ${(customError || lastNameError) && 'input-error'}`}
                  value={lastName}
                  type='text'
                  id='last-name'
                  maxLength={250}
                  placeholder={t('input_text')}
                  onChange={(e) => this.inputLastName(e)} />
              </div>
              <div style={{ position:'relative' }}>
                {errorEmail && <div className='error-container'>{t('enter_valid_email_id')}</div>}
                {(bothError || errorPhone) && <div className='top-space' />}
                <div className='label-wrapper'>
                  <div className='img-wrapper'>
                    <img className='img'
                      src={EmailIcon}
                      alt='' />
                  </div>
                  <label className='label-text'>{t('email_address')}</label>
                </div>
                <input
                  className={`form-input ${(errorEmail || customError || bothError) && 'input-error'}`}
                  type='email'
                  id='email'
                  placeholder={t('input_text')}
                  value={emailId}
                  onKeyDown={this.enterButtonFunction}
                //disabled={phoneNumber !== ''}
                  onChange={this.inputEmail} />
              </div>
            </div>
          </div>
          <form className='form-wrapper'
            onSubmit={this.submitForm}>
            <div className='btn-container'>
              <input className='next-btn-container'
                type='submit'
                value={t('next')} />
            </div>
          </form>
          <div className='existing-user-container'>
            <div className='existing-user'>
              {t('already_member')}
            </div>
            <div className='login-wrapper button-pointer'
              onClick={this.redirectToLogin}>
              {t('login').toUpperCase()}
            </div>
          </div>
        </div>
    );
  }
}

const mapStateToProps = (state) => ({
  breakpoint : getBreakpoint(state),
});


//export default withTranslation()(withRouter(SignUp));
export default withTranslation()(withRouter(connect(mapStateToProps, null)(SignUp)));