/* eslint-disable no-console */
/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import WelcomeImgBg from '../../../../src/asset/images/welcome_img_bg.svg';
// import WelcomeImgH from '../../../../src/asset/images/welcomeH.png';
// import WelcomeImgM from '../../../../src/asset/images/welcomeM.png';
import VisavaLogo from '../../../../src/asset/images/VisavaLogo.svg';
import { withTranslation } from 'react-i18next';
//import { breakPoint_L } from '../../../utility/breakPoints';
// import welcomeMob from '../../../../src/asset/images/welcome-mobile.png';
// import welcomeMobH from '../../../../src/asset/images/welcome-mobileH.png';
// import welcomeMobM from '../../../../src/asset/images/welcome-mobileM.png';
//import { setDMHRedirection } from '../../../utility/common';
import { getBreakpoint } from '../../../selectors/commonSelectors';


class Welcome extends Component {
  constructor (props) {
    super(props);
    this.prevPage=this.prevPage.bind(this);
    this.getStarted=this.getStarted.bind(this);
    this.state = {
      langUsed: ''
    };
  }

  componentDidMount () {
    this.prevPage();
    //console.log('b', breakPoint_L);
    this.setState({
      langUsed: localStorage.getItem('lang')
    });
    
  }

  prevPage () {
    const { history, location } = this.props;
    const prevPage = location && location.state && location.state.from && location.state.from == 'signup';
    //console.log('prv', prevPage);
    if (prevPage) {
      history.replace('/');
    }
  }

  getStarted () {
    const { history } = this.props;
    //setDMHRedirection();
    history && history.replace('/design-my-house');
  }

  render () {

    // const { t, breakpoint } = this.props;
    // const { langUsed } = this.state;
    const { t } = this.props;

    this.prevPage();
    return (
      <div className='welcome-container'>
        <div className='welcome_img_container'>
          <div className='info_container'>
            <div className='img-area'>
              <img src={WelcomeImgBg}
                alt='welcome starts' />
            </div>
            <div className='welcome_text'>
              <div className='title'>{t('thank_you')}</div>
              <div className='sub_title'>{t('first_time_sign_up_beta').toUpperCase()}</div>
              <div className='txt'>{t('first_time_sign_up_beta_desc')}</div>
            </div>
          </div>
        </div>
        <div className='simple-steps-container'>
          <div className='visava-logo-wrapper'>
            <img src={VisavaLogo}
              alt='Visava'
              className='visava-logo' />
          </div>
          <div className='simple-steps-wrapper'>{t('welcome_page_heading')}</div>
          <div className='get-started-contaier button-pointer'
            onClick={this.getStarted}>
            <div className='get-started-wrapper'>{t('get_started')}</div>
          </div>
        </div>
        
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  breakpoint : getBreakpoint(state)
});

export default withTranslation()(withRouter(connect(mapStateToProps, null)(Welcome)));