/* eslint-disable no-console */
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { BASE_URL_V4 } from './api-config';
// import Cookies from 'universal-cookie';

// const cookies = new Cookies();

function getToken () {
  const jwtToken = localStorage.getItem('jwtToken');
  return jwtToken;
}

export const axiosInstance = axios.create({
  baseURL: BASE_URL_V4,
  headers: {
    Authorization: `Bearer ${getToken()}`,
    'Content-Type': 'application/json'
  }
});

export const SetupInterceptors = () => {
  const history = useHistory();

  axiosInstance.interceptors.response.use(function (response) {
    return response;
  }, function (error) {
    const status = error.response.status;
    const resBaseURL = error.response.config.baseURL;

    if (resBaseURL === BASE_URL_V4 && status === 401) {
      localStorage.removeItem('jwtToken');
      localStorage.removeItem('userId');
      history.push('/login?exp');
    }
    return Promise.reject(error);
  });
};
