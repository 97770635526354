import React, { Component } from 'react';
//import { constants } from '../../../../../../utility/constants';
//import PlayStoreImage from '../../../../../../../src/asset/images/playStore.png';
import GooglePlayBadgeEn from '../../../../../../../src/asset/images/GooglePlayBadgeEn.png';
import GooglePlayBadgeHi from '../../../../../../../src/asset/images/GooglePlayBadgeHi.png';
import GooglePlayBadgeMa from '../../../../../../../src/asset/images/GooglePlayBadgeMa.png';
import { withTranslation } from 'react-i18next';
import { GOOGLE_PLAY_URL } from '../../../../../../api/api-config';
//import websiteQRCode from '../../../../../../asset/images/websiteQRCode.svg';
import qrCode from '../../../../../../asset/images/visava-android-app-qr.svg';
import { connect } from 'react-redux';
import { getLangCodeSelector } from '../../../../../../selectors/commonSelectors';

class PlayStore extends Component {
  render () {
    // eslint-disable-next-line react/prop-types
    const { t, langCode } = this.props;
    return (
      <div className='googlePlayWrapper'>
        <div className='description1'>
          {t('finger_tips')}
        </div>
        <div className='description2'>
          {t('first_tech')}
        </div>
        <div className='row'>
          <span className='googlePlayImgWrapper'>
            <a href={GOOGLE_PLAY_URL}
              target='_blank'
              rel='noopener noreferrer'><img
                className='googlePlay'
                src={langCode == 'hi' ? GooglePlayBadgeHi : langCode == 'mr' ? GooglePlayBadgeMa : GooglePlayBadgeEn}
                alt='' /></a>
          </span>
          <span className='or-option'>
            {t('or')}
          </span>
          <span className='qrcode'>
            <a href={GOOGLE_PLAY_URL}
              target='_blank'
              rel='noopener noreferrer'><img
                src={qrCode}
                alt='' /></a>
            <span className='txt'>{t('scan_key')}</span>
          </span>
        </div>
       
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  langCode : getLangCodeSelector(state)
});

export default connect(mapStateToProps, null) (withTranslation()(PlayStore));