import React, { Component } from 'react';
import HomeListing from '../../client/components/homes';
import Header from '../../client/shared-ui/header';

class HomesList extends Component {
  render () {
    return (
      <><Header /><HomeListing /></>
    );
  }
}

export default HomesList;