//import { API_URL } from '../api-config';
import { API_URL } from '../../../api/api-config';
import { axiosInstance } from '../../../api/coreApi';

export const DESIGN_MY_HOUSE = 'DESIGN_MY_HOUSE';
export const UPDATE_ADDRESS = 'UPDATE_ADDRESS';
export const UPDATE_DMH_STATE = 'UPDATE_DMH_STATE';

export const designMyHouseApi = (object, props) => {
  const history = props && props.history;
  const designMyHouse = `${API_URL.house}`;

  return async (dispatch) => {
    await axiosInstance
      .post(designMyHouse, object)
      .then(({ data }) => {
        dispatch({ type: DESIGN_MY_HOUSE,
          data });
        history.push('/homeDetails');
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.log('\n\nerr---->>', err);
      });
  };
};

export const updateAddress = (data) => ({
  type: UPDATE_ADDRESS,
  payload: data
});

export const updateDMHState = (data) => ({
  type: UPDATE_DMH_STATE,
  payload: data
});