/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { axiosInstance } from '../../../api/coreApi';
import { BASE_URL_V4, API_URL } from '../../../api/api-config';
import EmailIcon from '../../../../src/asset/images/email-open.svg';
import MobileIcon from '../../../../src/asset/images/phonelink.svg';
import { withTranslation } from 'react-i18next';
import OtpInput from 'react-otp-input-visio';
import Loader from '../../shared-ui/Loader';
import LogoWithText from '../logoWithText';

class VerifyEmailPhone extends Component {
  constructor (props) {
    super(props);
    this.inputEmail=this.inputEmail.bind(this);
    this.submitForm=this.submitForm.bind(this);
    this.formValidation=this.formValidation.bind(this);
    this.inputNumber=this.inputNumber.bind(this);
    this.loginApi=this.loginApi.bind(this);
    this.isNumeric=this.isNumeric.bind(this);
    this.toProfile=this.toProfile.bind(this);
    this.state = {
      email: '',
      mobile: '',
      loading: false,
      showMobile: false,
      showEmail: false,
      inputError: false,
      errText: '',
      no:'',
      emailVerified:'',
      mobileVerified:'',
    };
  }

  toProfile () {
    const { history } = this.props;
    history.push('/my-profile');
  }

  componentDidMount () {
    const { history } = this.props;
    const jwtToken = localStorage.getItem('jwtToken');
    const userId = localStorage.getItem('userId');

    if (!jwtToken) {
      history.push('/');
    } else {
      this.setState({
        loading: false
      });
      const url = BASE_URL_V4 + API_URL.userProfile + userId;
      axiosInstance.get(url).then((resp) => {
        const profileData = resp && resp.data && resp.data.data;
        if (profileData) {
          this.setState({
            loading: false,
            //email: profileData.email,
            //mobile: profileData.mobile,
            emailVerified: profileData.email_verifed,
            mobileVerified: profileData.mobile_verifed
          });
        }
        if(profileData.mobile=='NULL') {
          this.setState({
            showEmail:true
          });
        }
        if(profileData.email=='NULL') {
          this.setState({
            showMobile:true
          });
        }
      }).catch((err) => {
        this.setState({
          loading: false
        });
        console.log('err---->>>>', err.response);
      });
    }
  }

  inputEmail (e) {
    this.setState({
      email: e.target.value,
      inputError: false
    });
  }

  inputNumber (e) {
    this.setState({
      no:e,
      mobile: e,
      inputError: false
    });
  }

  isNumeric (value) {
    return /^-?\d+$/.test(value);
  }


  formValidation () {
    const { props } = this.props;
    const { mobile, email, showEmail, showMobile } = this.state;
    const a=(this.props.location.state.emailorphone);
    if (!(this.isNumeric(a))) {
      return (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email) ? true : false);
    }
    if ((this.isNumeric(a))) {
      return (mobile && mobile.length == 10 ? true : false);
    }
  }

  loginApi () {
    const { history, t } = this.props;
    const { mobile, email, showMobile, showEmail, emailVerified, mobileVerified } = this.state;
    const a=(this.props.location.state.emailorphone);
    //console.log(a, email);
    const userId = localStorage.getItem('userId');
    const jwtToken=localStorage.getItem('jwtToken');
    //const URL = (this.isNumeric(email)) ? `${BASE_URL_V4 + API_URL.addMobileToProfile}/${userId}` : `${BASE_URL_V4 + API_URL.addEmailToProfile}/${userId}`;
    
    let URL ='';
    if(emailVerified==1) {
      URL = `${BASE_URL_V4 + API_URL.addMobileToProfile}/${userId}`;
    } else {
      URL = `${BASE_URL_V4 + API_URL.addEmailToProfile}/${userId}`;
    }
    const payloadObject = (emailVerified == 1)? {
      country_code: '91',
      mobile: mobile
    } : {
      email: email
    };

    //console.log('po', payloadObject);

    const axiosConfig = {
      headers:  { 'Authorization':  ` Bearer  ${jwtToken}`
      }
    };
    //console.log(URL, payloadObject);
    axiosInstance.post(URL, payloadObject, axiosConfig).then(() => {
      this.setState({
        isLoading: false
      });
      //console.log('showmobile:', showMobile, 'showEmail:', showEmail, a);
      if((this.isNumeric(a))) {
        history.push('/my-profile/verify-email-phone/otp', { from: 'verifyEmailPhone',
          mobile: mobile != '' ? mobile : undefined,
          // email: email != '' ? email : undefined
        });
      } else {
        history.push('/my-profile/verify-email-phone/otp', { from: 'verifyEmailPhone',
          // mobile: mobile != '' ? mobile : undefined,
          email: email != '' ? email : undefined
        });
      }
    }).catch((err) => {
      this.setState({
        isLoading: false
      });
      if (err && err.response) {
        const errData = err.response && err.response.data;
        console.log('\n\n\nError----------->>>>', errData);
        if (errData && errData.status && errData.status.toLowerCase() == 'failed') {
          this.setState({
            inputError: true,
            errText: t('mobile_already_registered')
          });
        }
      }
    });
  }

  submitForm () {
    const { t }=this.props;
    const { showEmail, showMobile } = this.state;
    //console.log('mobile', showMobile, 'email', showEmail);
    const validation = this.formValidation();
    //console.log('va', validation);
    if (validation) {
      this.setState({
        isLoading: true
      });
      this.loginApi();
    } else {
      this.setState({
        no: '',
        inputError: true,
        errText: (this.isNumeric(this.props.location.state.emailorphone)) ? t('enter_valid_phone_number') : t('enter_valid_email_id')
      });
    }
  }

  render () {
    const { t } =this.props;
    const { showEmail, inputError, errText, loading } = this.state;
    const a=(this.props.location.state.emailorphone);
    //console.log('emailorphone', a, showEmail, this.isNumeric(a));
    //const emailOrPhone = location && location.state && location.state.mobile ? location.state.mobile : location && location.state && location.state.email;
    return (
      <>
        {!showEmail && !(this.isNumeric(a))?
          <div className='login-container-email'>
            <Loader
              isLoading={loading} />
            <div className='back-mobile'
              onClick={this.toProfile}>&lt;</div>
            <div className='logo-with-text'><LogoWithText /></div>
            {inputError && <div className='error-text2'>{errText}</div>}
            <div className='form-wrapper-input'>
              <div className='label-wrapper1'>
                <div className='img-wrapper'>
                  <img className='img'
                    src={EmailIcon}
                    alt='' />
                </div>
                <label className='label-text'>{t('user_profile_screen_email')}</label>
              </div>
              <input
                className='form-input1'
                id='email'
                placeholder={t('input_text')}
                onChange={(e) => this.inputEmail(e)} /></div>
            {/* <button onClick={this.submitForm}>Verify</button> */}
            
            <div className='form-wrapper-button'>
              <div className='btn-container'>
                <div className={'next-btn-container button-pointer'}
                  onClick={this.submitForm}>{t('verify_text')}</div>
              </div>
            </div>
  
          </div>:
          <div className='login-container-otp'>
            <div className='back-mobile'
              onClick={this.toProfile}>&lt;</div>
            <div className='logo-with-text'><LogoWithText /></div>
            <Loader
              isLoading={loading} />
            <div className='form-wrapper-input'>
              <div className='label-wrapper1'>
                {inputError && <div className='error-text2'>{errText}</div>}
                <div className='img-wrapper'>
                  <img className='img'
                    src={MobileIcon}
                    alt='' />
        
                </div>
                <label className='label-text'>{t('user_profile_screen_phone_number')}</label>
              </div>
              <div >
                <OtpInput
                  value={this.state.no}
                  onChange={this.inputNumber}
                  numInputs={10}
                  isInputNum={true}
            // hasErrored={false}
            // isDisabled={true}
                  inputStyle='input-container1'
                  errorStyle={inputError && 'input-error'} />
              </div>
            </div>
            <div className='form-wrapper-button'>
              <div className='btn-container'>
                <div className={'next-btn-container button-pointer'}
                  onClick={this.submitForm}>{t('verify_text')}</div>
              </div>
            </div>
          </div>
        }
      </>
    );
  }
}

export default withTranslation()(withRouter(VerifyEmailPhone));