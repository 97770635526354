/* eslint-disable react/jsx-key */
/* eslint-disable react/prop-types */
/* eslint-disable no-console */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import ForwardImage from '../../../asset/images/forward-vector-blue.svg';
import SaveMoreIcon from '../../../../src/asset/images/save-more.png';
import FeedbackIcon from '../../../asset/images/feedback_icon.svg';
import AddAnotherImg from '../../../../src/asset/images/add-another.svg';
import EditIcon from '../../../asset/images/green_edit_icon.svg';
import TickIcon from '../../../asset/images/white_tick_icon.svg';
import HouseIcon from '../../../asset/images/white_house_icon.svg';
import { API_URL, BLOG_URL, userAgent, FEED_BACK_FORMS } from '../../../api/api-config';
//import { updateLangCode } from '../../redux/actions/headerState';
import { axiosInstance } from '../../../api/coreApi';
import moment from 'moment';
import { connect } from 'react-redux';
//import { constants } from '../../../utility/constants';
import { withTranslation } from 'react-i18next';
import EditHouseName from '../editHouseName';
import Loader from '../../shared-ui/Loader';
import ErrorPopup from '../errorPopup';
import { getLangCodeSelector } from '../../../selectors/commonSelectors';
import { setDMHRedirection, windowOpenHandler } from '../../../utility/common';

class HomeListing extends Component {

  constructor (props) {
    super(props);
    this.gotoDMH=this.gotoDMH.bind(this);
    this.housesApiCall=this.housesApiCall.bind(this);
    this.gotoHouseDetails=this.gotoHouseDetails.bind(this);
    this.renderHouses=this.renderHouses.bind(this);
    this.editHouseName=this.editHouseName.bind(this);
    this.onHousenamechange=this.onHousenamechange.bind(this);
    //this.fetchProfileDetails=this.fetchProfileDetails.bind(this);
    this.saveEditHouseName=this.saveEditHouseName.bind(this);
    this.continueDesign=this.continueDesign.bind(this);
    this.goToBlog=this.goToBlog.bind(this);
    this.viewDesignDoc=this.viewDesignDoc.bind(this);
    this.endUserFeedBackForm=this.endUserFeedBackForm.bind(this);
    this.state = {
      isLoading: false,
      houseData: [],
      editName: null,
      openEditModal: false,
      editNameError: false,
      payment: 0,
      totalHouses: 0,
      paymentDone: false,
      errorMessage: '',
      showErrorPopUp: false,
      purchasedHouses: null,
      permittedHouse: null
    };
  }

  componentDidMount () {
    //this.fetchProfileDetails();
    this.housesApiCall();
  }

  viewDesignDoc () {
    const { history } = this.props;
    const houseId = localStorage.getItem('houseId');
    const shareToken = localStorage.getItem('shareToken');
    const langCode = localStorage.getItem('lang');
    history.push(`/design-document?house_id=${houseId}&share_token=${shareToken}&preferred_language=${langCode}&user_agent=${userAgent}`);
  }

  endUserFeedBackForm () {
    windowOpenHandler(FEED_BACK_FORMS.end_user_form);
    this.setState({
      showErrorPopUp:false
    });
  }

  housesApiCall () {
    const { history } = this.props;
    const url = API_URL.house;

    this.setState({
      isLoading: true
    });
    axiosInstance.get(url).then((resp) => {
      const data = resp && resp.data && resp.data.data;
      if (data) {
        this.setState({
          purchasedHouses: resp.data.purchased_house_count,
          permittedHouse: resp.data.maximum_house_count
        });
      }
      if (data && data.length>0 ) {
        this.setState({
          houseData: data,
          payment: data[0].payment,
          totalHouses: data.length,
        });
        for (let i = 0; i < this.state.totalHouses; i++) {
          if(data[i].payment==0 ) {
            this.setState({
              paymentDone:false,
            });
            break;
          } else {
            this.setState({
              paymentDone:true,
            });
          }
        }
      }else{
        setDMHRedirection();
        history.push('/design-my-house');
      }
      this.setState({
        isLoading: false,
      });
    }).catch((err) => {
      console.log('=====', err);
      if(err.response.data.status == 'Token is Expired' || err.response.data.status == 'Token is Invalid' || err.response.data.status == 'Authorization Token not found') {
        localStorage.clear();
        this.props.history.push('/login', { expiredError: true });
      }
      this.setState({
        isLoading: false
      });
    });
  }
  

  // fetchProfileDetails () {
  //   const { history } = this.props;
  //   const jwtToken = localStorage.getItem('jwtToken');

  //   if (!jwtToken) {
  //     history.push('/');
  //   } else {
  //     this.setState({
  //       loading: true,
  //     });
  //     const URL = BASE_URL_V4 + API_URL.getUserProfile;
  //     axiosInstance
  //       .get(URL)
  //       .then((resp) => {
  //         const profileData = resp && resp.data && resp.data.data;
  //         if (profileData) {
  //           this.setState({
  //             loading: false,
  //             preferredLanguage: profileData.preferred_language
  //           });
  //           localStorage.setItem('lang', profileData.preferred_language);
  //         }
  //         this.housesApiCall();
  //       })
  //       .catch(() => {
  //         this.setState({
  //           loading: false,
  //         });
  //       });
  //   }

  // }
  
  goToBlog () {
    const langCode = localStorage.getItem('lang');
    if(langCode == 'en') {
      windowOpenHandler(`${BLOG_URL}/housing-schemes-in-india/`);
    } else {
      windowOpenHandler(`${BLOG_URL}/${langCode}/housing-schemes-in-india/`);
    }
  }

  gotoHouseDetails (id, payment, share_token) {
    const { history } = this.props;
    localStorage.setItem('houseId', id);
    localStorage.setItem('shareToken', share_token);
    localStorage.removeItem('orderId');

    if(payment) {
      this.viewDesignDoc();
    } else {
      history.push(`/home-details?house-id=${id}`, { from:'homes' });
    }
  }

  editHouseName ( id ) {
    this.setState({ editName:id });
  }

  onHousenamechange (e) {
    this.setState((prevState) => ({
      editName: {
        ...prevState.editName,
        name: e && e.target && e.target.value
      }
    }));
  }

  saveEditHouseName () {
    const { editName } = this.state;

    if(editName.name=='') {
      this.setState({ editNameError: true });
      return;
    }

    const object = {
      name: editName.name,
    };
    const url = `${API_URL.house}/${this.state.editName.id}`;

    this.setState({
      isLoading: true
    });

    axiosInstance.patch(url, object).then((resp) => {
      const data = resp && resp.data && resp.data.data;
      this.housesApiCall();
      if (data) {
        this.setState({
          isLoading: false,
          openEditModal: false,
          editNameError: false,
        });
      }
    }).catch((err) => {
      console.log('Error----->>>>', err.response.data);
      this.setState({
        isLoading: false,
        editNameError: false,
      });
    });
  }

  renderHouses () {
    const { houseData } = this.state;
    const { t } = this.props;
    //const { paymentDone } = this.state;
    //console.log(houseData);
    return houseData && houseData.map((element) => {
      const date = moment(element && element.created_timestamp).format('DD.MM.YYYY');
      return (
        <div className='house-card button-pointer'
          onClick={() => this.gotoHouseDetails(element.id, element.payment, element.share_token)}
          key={element.id}>
          <div className='left-area'>
            <div className='top-area'
              onClick={() => this.gotoHouseDetails(element.id)}>
              <div className='house-icon-area'>
                {element.payment ? <img src={TickIcon}
                  alt='' />
                  : <img src={HouseIcon}
                      alt='' />
                }
              </div>
              <div className='house-name'>
                <div className='name'>{element && element.name}</div>
                <div className='date'>{date}</div>
              </div>
            </div>
            <div className='bottom-area'>
              {element.payment ?
                <div className='house-status purchased'>{t('paidHouseStatus')}</div>
                : <div className='house-status in-progress'>{t('unpaidHouseStatus')}</div>
              }
            </div>
          </div>
          <div className='edit-icon'
            onClick={(e) => {
              e.stopPropagation();
              this.setState({ openEditModal: true });
              this.editHouseName(element);
            }}>
            <img src={EditIcon}
              alt='' />
          </div>
        </div>
      );
    });
  }

  gotoDMH () {
    const { t } = this.props;
    const { history } = this.props;
    const { paymentDone, totalHouses } = this.state;
    if(totalHouses==0 || paymentDone) {
      setDMHRedirection();
      history.push('/design-my-house');
    } else{
      this.setState({ showErrorPopUp: true,
        errorMessage: t('cannot_create_house') });
    }
  }

  continueDesign () {
    const { history } = this.props;
    setDMHRedirection();
    history.push('/design-my-house', { editHouse: this.state.houseData[0] });
  }

  render () {
    const { t, langCode }=this.props;
    // eslint-disable-next-line no-unused-vars
    const { isLoading, houseData, purchasedHouses, permittedHouse } = this.state;
  
    return (
      <div className='banner-container-top'>
        {!this.state.isLoading && !this.state.paymentDone && <div className='continue-design-house button-pointer'
          onClick={this.continueDesign}>
          {langCode == 'en'?
            <div className='continue-text'>{t('continue_designing')} {houseData[0]?.name}</div> :
            <div className='continue-text'>{houseData[0]?.name} {t('continue_designing')}</div>}
        </div> }
        <div className='houses-container'>
          <Loader isLoading={isLoading} />
          <div className='houses-wrapper'>
            <div className='my-houses'>{t('my_visava_houses')}</div>
            <div className='house-count-wrapper'>
              {purchasedHouses === 1 ?
                <div className='count-area'>
                  <div className='house-count disabled'>{/* Add disabled class with house-count class to make it grayed out */}
                    <img src={HouseIcon} />
                  </div>
                  <div className='house-count'>
                    <img src={HouseIcon} />
                  </div>
                  <div className='house-count'>
                    <img src={HouseIcon} />
                  </div>
                </div> :
                purchasedHouses === 2 ?
                  <div className='count-area'>
                    <div className='house-count disabled'>{/* Add disabled class with house-count class to make it grayed out */}
                      <img src={HouseIcon} />
                    </div>
                    <div className='house-count disabled'>
                      <img src={HouseIcon} />
                    </div>
                    <div className='house-count'>
                      <img src={HouseIcon} />
                    </div>
                  </div> :
                  <div className='count-area'>
                    <div className='house-count disabled'>{/* Add disabled class with house-count class to make it grayed out */}
                      <img src={HouseIcon} />
                    </div>
                    <div className='house-count disabled'>
                      <img src={HouseIcon} />
                    </div>
                    <div className='house-count disabled'>
                      <img src={HouseIcon} />
                    </div>
                  </div> }
              {langCode === 'en' ?
                <div className='details'>{purchasedHouses} {t('zeroOfThree')}</div>
                : <div className='details'>{t('zeroOfThree')} {purchasedHouses} {t('zeroOfThreeHalf')}</div> }
            </div>
            {this.renderHouses()}
            {this.state.totalHouses < permittedHouse ?
              <div className='houses-card-container button-pointer'
                onClick={this.gotoDMH}>
                <div className='houses-card-wrappper'>
                  <div className='image-wrapper'>
                    <img className='image'
                      src={AddAnotherImg}
                      alt='' />
                  </div>
                  <div className='house-name-wrapper'>
                    <div className='text'>{t('design_another_house')}</div>
                  </div>
                  <div className='proceed-forward'>
                    <img className='forward-image'
                      src={ForwardImage}
                      alt='' />
                  </div>
                </div>
              </div> : null }
            <div className='save-more-wrapper'>
              <div className='image-wrapper'>
                <img className='image'
                  src={SaveMoreIcon}
                  alt='' />
              </div>
              <div className='text'>{t('save_more_money')}<a className='read-more'
                onClick={this.goToBlog}> {t('read_more')}</a></div>
            </div>
          </div>
        </div>
        <div className='feedback_btn'
          onClick={this.endUserFeedBackForm}>
          <img src={FeedbackIcon} />
          <span className='lbl'>{t('share_feedback')}</span>
        </div>
        {this.state.openEditModal ? (
          <EditHouseName
            shown={this.state.openEditModal}
            close={() =>
              this.setState({
                openEditModal: false,
              })
            }
            data={this.state.editName}
            onHousenamechange={this.onHousenamechange}
            saveEditHouseName={this.saveEditHouseName}
            editNameError={this.state.editNameError} />
        ) : null}
        {this.state.showErrorPopUp &&
          <ErrorPopup shown={this.state.showErrorPopUp}
            close={() => this.setState({ showErrorPopUp:false })}
            closeOk={() => this.setState({ showErrorPopUp:false })}
            paymentStatus={'FAILURE'}
            message={t('DesignOtherHouse')}
            paymentDone={this.state.paymentDone}
            buttonLabel={t('ok')}
            googleMaps={false}
            feedBackButton={false}
            showPayment={false} />}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  langCode: getLangCodeSelector(state)
});

export default connect(mapStateToProps, '')(withTranslation()(withRouter(HomeListing)));
