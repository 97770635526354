import {
  UPDATE_ADDRESS,
  DESIGN_MY_HOUSE,
  UPDATE_DMH_STATE
} from '../actions/designMyHouse';
    
const INITIAL_STATE = {};
      
const designHouse = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_ADDRESS:
      return Object.assign({}, state, { address: action.payload });
    case UPDATE_DMH_STATE:
      return Object.assign({}, state, { DMHState: action.payload });
    case DESIGN_MY_HOUSE:
      return {
        ...state,
        designMyHouse: action && action.data
      };
    default:
      return { ...state };
  }
};
      
export default designHouse;