import React, { Component } from 'react';
import { connect } from 'react-redux';
import BannerImage from './bannerImage/index.js';
import PlayStoreWrapper from './playstoreWrapper/index.js';
import VisawaCarousal from './visawaCarousal/index.js';
import VisawaHelpCenter from './helpCenter/index.js';
import VendorRegisteration from './vendorRegisteration/index.js';
import VisawaVideo from './visawaVideo/index.js';
import HomesByVisawa from './homesByVisawa/index.js';
import ContactUs from './contactUs/index.js';
import GetInTouch from './getInTouch/index.js';
//import Features from './features/index.js';
//import { breakPoint_L } from '../../../../utility/breakPoints';
import { getBreakpoint } from '../../../../selectors/commonSelectors';

class Home extends Component {

  componentDidMount () {
    window.scrollTo({ top: 0 });
  }


  render () {
    // eslint-disable-next-line react/prop-types
    const { breakpoint } = this.props;
    return (
      <div>
        
        <BannerImage {...this.props} />
        {breakpoint == 'sm' && <PlayStoreWrapper {...this.props} />}
        <VisawaCarousal {...this.props} />
        {/* <Features {...this.props} /> */}
        <VisawaHelpCenter {...this.props} />
        <VisawaVideo {...this.props} />
        <HomesByVisawa {...this.props} />
        <VendorRegisteration {...this.props} />
        {breakpoint == 'sm' && <ContactUs {...this.props} />}
        <GetInTouch {...this.props} />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  breakpoint : getBreakpoint(state)
});

export default (connect(mapStateToProps, null)(Home));