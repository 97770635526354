/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import AttachImg from '../../../../src/asset/images/attach-icon.svg';
import ForwardArrow from '../../../../src/asset/images/forward-vector-blue.svg';
import { userAgent } from '../../../api/api-config';
import { withTranslation } from 'react-i18next';
//import { windowOpenHandler } from '../../../utility/common';

class SampleDocument extends Component {
  constructor (props) {
    super(props);
    this.onOutsideClick=this.onOutsideClick.bind(this);
    this.insideClick=this.insideClick.bind(this);
    this.downloadPDF=this.downloadPDF.bind(this);
  }

  onOutsideClick () {
    const { closePopUp } = this.props;
    closePopUp && closePopUp();
  }

  insideClick (e) {
    e.stopPropagation();

  }

  componentDidMount () {
    //localStorage.setItem( 'userAgent', 'VisavaWebApp');
  }

  downloadPDF () {
    const { history } = this.props;
    const langCode = localStorage.getItem('lang');
    history && history.push(`/sample-document?preferred_language=${langCode}&user_agent=${userAgent}`);
    //windowOpenHandler(`/sample-document?preferred_language=${langCode}&user_agent=${userAgent}`);
  }

  render () {
    const { t } = this.props;
    return (
      <div className='sample-doc-container'
        onClick={this.onOutsideClick}>
        <div className='sample-house-container'
          onClick={this.insideClick}>
          {/* <div className='house-types'>{t('type_of_houses')}</div> */}
          <div className='document-wrapper button-pointer'
            onClick={this.downloadPDF}>
            <div className='image-wrapper'>
              <img className='image'
                src={AttachImg}
                alt='' />
            </div>
            <div className='text'>{t('view_sample_doc')}</div>
            <div className='forward-arrow-wrapper'>
              <img className='forward-image'
                src={ForwardArrow}
                alt='' />
            </div>
          </div>
          {/* <div className='seperator' />
          <div className='document-wrapper button-pointer'
            onClick={this.downloadPDF}>
            <div className='image-wrapper'>
              <img className='image'
                src={AttachImg}
                alt='' />
            </div>
            <div className='text'>{t('rural_sample_house')}</div>
            <div className='forward-arrow-wrapper'>
              <img className='forward-image'
                src={ForwardArrow}
                alt='' />
            </div>
          </div> */}
        </div>
      </div>
    );
  }
}

export default withTranslation()(SampleDocument);