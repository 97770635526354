import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationEN from './locales/en.json';
import translationHN from './locales/hi.json';
import translationMAR from './locales/mar.json';

const resources={
  en:{
    translation:translationEN
  },
  hi:{
    translation:translationHN
  },
  mr:{
    translation:translationMAR
  }
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng:'hi',
    fallbackLng:'hi',
    keySeparator:false,
    interpolation:{
      escapeValue:false
    }
  });
export default i18n;

export const changeLang = (lang) => {
  //changeLang(lang);
  i18n.changeLanguage(lang);
  localStorage.setItem('lang', lang);
};