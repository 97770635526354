import React, { Component } from 'react';
import UpArrowImg from '../../../../src/asset/images/up-arrow.svg';
import DownArrowImg from '../../../../src/asset/images/down-arrow.svg';
import { withTranslation } from 'react-i18next';

class FAQ extends Component {

  constructor (props) {
    super(props);

    this.state = {
      showDesc: undefined
    };
  }

  showDescription (value) {
    const { showDesc } = this.state;

    this.setState({
      showDesc: value == showDesc ? undefined : value
    });
  }

  render () {
    const { showDesc } = this.state;
    // eslint-disable-next-line react/prop-types
    const { t } = this.props;
    return (
      <div className='banner-container-top'>
        <div className='faq-container'>
          <div className='faq-text'>{t('user_profile_f_a_q')}</div>
          <div className='faq-wrapper'>
            <div className='faq-combined-wrapper'>
              <div className='text'
                onClick={() => this.showDescription(1)}>{t('how_does_visava_work_que')}</div>
              <div className='icon-image-wrapper button-pointer'
                onClick={() => this.showDescription(1)}>
                <img className='icon'
                  src={showDesc != 1 ? DownArrowImg : UpArrowImg}
                  alt='' />
              </div>
            </div>
            {showDesc == 1 && <div className='faq-description-wrapper'>
              <div className='faq-description-text'>
                {t('how_does_visava_work_ans')}
              </div>
            </div>}
          </div>


          <div className='faq-wrapper'>
            <div className='faq-combined-wrapper'>
              <div className='text'
                onClick={() => this.showDescription(2)}>{t('when_do_i_use_visava_que')}</div>
              <div className='icon-image-wrapper button-pointer'
                onClick={() => this.showDescription(2)}>
                <img className='icon'
                  src={showDesc != 2 ? DownArrowImg : UpArrowImg}
                  alt='' />
              </div>
            </div>
            {showDesc == 2 && <div className='faq-description-wrapper'>
              <div className='faq-description-text'>
                {t('when_do_i_use_visava_ans')}
              </div>
            </div>}
          </div>


          <div className='faq-wrapper'>
            <div className='faq-combined-wrapper'>
              <div className='text'
                onClick={() => this.showDescription(3)}>{t('do_i_reach_out_que')}</div>
              <div className='icon-image-wrapper button-pointer'
                onClick={() => this.showDescription(3)}>
                <img className='icon'
                  src={showDesc != 3 ? DownArrowImg : UpArrowImg}
                  alt='' />
              </div>
            </div>
            {showDesc == 3 && <div className='faq-description-wrapper'>
              <div className='faq-description-text'>
                {t('do_i_reach_out_ans')}
              </div>
            </div>}
          </div>


          <div className='faq-wrapper'>
            <div className='faq-combined-wrapper'>
              <div className='text'
                onClick={() => this.showDescription(4)}>{t('do_after_receive_que')}</div>
              <div className='icon-image-wrapper button-pointer'
                onClick={() => this.showDescription(4)}>
                <img className='icon'
                  src={showDesc != 4 ? DownArrowImg : UpArrowImg}
                  alt='' />
              </div>
            </div>
            {showDesc == 4 && <div className='faq-description-wrapper'>
              <div className='faq-description-text'>
                {t('do_after_receive_ans')}
              </div>
            </div>}

          </div>

          <div className='faq-wrapper'>
            <div className='faq-combined-wrapper'>
              <div className='text'
                onClick={() => this.showDescription(5)}>{t('is_visava_going_to_build_que')}</div>
              <div className='icon-image-wrapper button-pointer'
                onClick={() => this.showDescription(5)}>
                <img className='icon'
                  src={showDesc != 5 ? DownArrowImg : UpArrowImg}
                  alt='' />
              </div>
            </div>
            {showDesc == 5 && <div className='faq-description-wrapper'>
              <div className='faq-description-text'>
                {t('is_visava_going_to_build_ans')}
              </div>
            </div>}
          </div>


          <div className='faq-wrapper'>
            <div className='faq-combined-wrapper'>
              <div className='text'
                onClick={() => this.showDescription(6)}>{t('how_long_access_que')}</div>
              <div className='icon-image-wrapper button-pointer'
                onClick={() => this.showDescription(6)}>
                <img className='icon'
                  src={showDesc != 6 ? DownArrowImg : UpArrowImg}
                  alt='' />
              </div>
            </div>
            {showDesc == 6 && <div className='faq-description-wrapper'>
              <div className='faq-description-text'>
                {t('how_long_access_ans')}
              </div>
            </div>}
          </div>


          <div className='faq-wrapper'>
            <div className='faq-combined-wrapper'>
              <div className='text'
                onClick={() => this.showDescription(7)}>{t('can_i_edit_que')}</div>
              <div className='icon-image-wrapper button-pointer'
                onClick={() => this.showDescription(7)}>
                <img className='icon'
                  src={showDesc != 7 ? DownArrowImg : UpArrowImg}
                  alt='' />
              </div>
            </div>
            {showDesc == 7 && <div className='faq-description-wrapper'>
              <div className='faq-description-text'>
                {t('can_i_edit_ans')}
              </div>
            </div>}
          </div>


          <div className='faq-wrapper'>
            <div className='faq-combined-wrapper'>
              <div className='text'
                onClick={() => this.showDescription(8)}>{t('will_i_loose_que')}</div>
              <div className='icon-image-wrapper button-pointer'
                onClick={() => this.showDescription(8)}>
                <img className='icon'
                  src={showDesc != 8 ? DownArrowImg : UpArrowImg}
                  alt='' />
              </div>
            </div>
            {showDesc == 8 && <div className='faq-description-wrapper'>
              <div className='faq-description-text'>
                {t('will_i_loose_ans')}
              </div>
            </div>}
          </div>

          <div className='faq-wrapper'>
            <div className='faq-combined-wrapper'>
              <div className='text'
                onClick={() => this.showDescription(9)}>{t('what_if_dont_have_enough_funds_que')}</div>
              <div className='icon-image-wrapper button-pointer'
                onClick={() => this.showDescription(9)}>
                <img className='icon'
                  src={showDesc != 9 ? DownArrowImg : UpArrowImg}
                  alt='' />
              </div>
            </div>
            {showDesc == 9 && <div className='faq-description-wrapper'>
              <div className='faq-description-text'>
                {t('what_if_dont_have_enough_funds_ans')}
              </div>
            </div>}
          </div>

        </div>
      </div>
    );
  }
}

export default withTranslation()(FAQ);