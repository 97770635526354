import React, { Component } from 'react';
import PropTypes from 'prop-types';

/**
 * Loader Component
 *
 * @namespace Loader
 */
class Loader extends Component {
  constructor (props) {
    super (props);
    this.preventClick=this.preventClick.bind(this);
  }

  preventClick (e) {
    e.preventDefault();
  }

  render () {
    const { isLoading, isInlineLoading } = this.props;
    if (isLoading || isInlineLoading) {
      return (
        <div className={`spinner-container ${isInlineLoading ? 'sm' : ''}`}
          role='presentation'
          onClick={!this.props.isButton && this.preventClick}>
          <div className='icon spinner align-middle' />
        </div>
      );
    } else {
      return null;
    }
  }
}

Loader.propTypes = {
  isButton: PropTypes.bool,
  isLoading: PropTypes.bool,
  isInlineLoading: PropTypes.bool
};

Loader.defaultProps = {
  isButton: false,
  isLoading: false,
  isInlineLoading: false
};

export default Loader;