import React from 'react';
import Dashboard from '../../client/components/dashboard/index.js';
import Header from '../../client/shared-ui/header';

class Home extends React.Component {
  render () {
    return (
    //enablehumburgerheader={true/false.}
      <><Header /><Dashboard {...this.props} /></>
    );
  }
}

export default Home;