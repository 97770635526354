/* eslint-disable no-console */
/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import OtpInput from 'react-otp-input-visio';
import EmailIcon from '../../../../src/asset/images/email-open.svg';
import MobileIcon from '../../../../src/asset/images/phonelink.svg';
import { BASE_URL_V4, API_URL } from '../../../api/api-config';
import Loader from '../../shared-ui/Loader';
//import { breakPoint_L } from '../../../utility/breakPoints';
import LogoWithText from '../logoWithText';
//import { constants } from '../../../utility/constants';
import { withTranslation } from 'react-i18next';
import { getBreakpoint } from '../../../selectors/commonSelectors';
import { errorMsgHandler } from '../../../utility/errorHandling';
import { axiosInstance } from '../../../api/coreApi';


class Login extends Component {
  
  constructor (props) {
    super(props);
    this.formValidation=this.formValidation.bind(this);
    this.goToSignUp=this.goToSignUp.bind(this);
    this.inputNumber=this.inputNumber.bind(this);
    this.loginApi=this.loginApi.bind(this);
    this.inputEmail=this.inputEmail.bind(this);
    this.submitForm=this.submitForm.bind(this);
    this.enterButtonFunction=this.enterButtonFunction.bind(this);
    const expiredErrorBool = props && props.location && props.location.state && props.location.state.expiredError;
    this.state = {
      phoneNumber: '',
      emailId: '',
      emailDisabled: false,
      phoneDisabled: false,
      inputError: false,
      isLoading: false,
      errText: '',
      expiredError: expiredErrorBool || false,
    };
  }

  componentDidMount () {
    const jwtToken = localStorage.getItem('jwtToken');
    if (jwtToken) {
      this.props.history.replace('/homes');
    }

    // redirection to login on token expired
    const queryParams = this.props.location.search;
    if (queryParams.indexOf('exp') > -1) {
      this.setState({ expiredError: true });
    }
  }

  formValidation () {
    const { phoneNumber, emailId, emailDisabled, phoneDisabled } = this.state;
    if (emailDisabled) {
      return (phoneNumber && phoneNumber.length == 10 ? true : false);
    } else if (phoneDisabled) {
      return (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(emailId) ? true : false);
    }
    return false;
  }

  goToSignUp () {
    const { history } = this.props;
    history.push('/signup');
  }

  // call the function of next click on enter-key-press click
  enterButtonFunction (e) {
    if (e.key === 'Enter') {
      this.submitForm();
      console.log('works well');
    }
  }

  inputNumber (e) {
    this.setState({
      phoneNumber: e,
      emailDisabled: e != '' ? true : false,
      inputError: false
    });
  }

  loginApi () {
    const { phoneNumber, emailId, emailDisabled } = this.state;
    const { history, t } = this.props;
    const URL = emailDisabled ? BASE_URL_V4 + API_URL.loginMobile : BASE_URL_V4 + API_URL.loginEmail;
    const payloadObject = emailDisabled ? {
      mobile_country_code: '91',
      mobile_number: phoneNumber
    } : {
      email: emailId
    };

    axiosInstance.post(URL, payloadObject).then(() => {
      this.setState({
        isLoading: false
      });
      history.push('/otp', { from: 'login',
        mobile: phoneNumber != '' ? phoneNumber : undefined,
        email: emailId != '' ? emailId : undefined });
    }).catch((err) => {
      this.setState({
        isLoading: false
      });
      if (err && err.response) {
        const errData = err.response && err.response.data;
        // console.log('\n\n\nError----------->>>>', errData);
        if (errData && errData.status && errData.status.toLowerCase() == 'failed') {
          this.setState({
            inputError: true,
            errText: errorMsgHandler(errData.error_code, t),
          });
        }
      } else {
        this.setState({
          inputError: true,
          errText: errorMsgHandler(emailDisabled ? 1400 : 1500, t) // catch all scenario
        });
      }
    });
  }

  inputEmail (e) {
    this.setState({
      emailId: e.target.value,
      phoneDisabled: e.target.value != '' ? true : false,
      inputError: false
    });
  }

  submitForm () {
    this.setState({ expiredError:false });
    const { t }=this.props;
    const { emailDisabled, phoneDisabled } = this.state;
    const validation = this.formValidation();
    if (validation) {
      this.setState({
        isLoading: true
      });
      this.loginApi();
    } else {
      this.setState({
        inputError: true,
        errText: !emailDisabled && !phoneDisabled ? t('signup_phone_email_error') : emailDisabled ? t('enter_valid_phone_number') : t('enter_valid_email_id')
      });
    }
  }

  render () {
    const { t }=this.props;
    const { phoneNumber, emailId, emailDisabled, phoneDisabled, inputError, isLoading, errText, expiredError } = this.state;
    const { breakpoint } = this.props;
    //const disabled = phoneNumber == '' && emailId == '';
    return (breakpoint == 'sm' ?
    /***** mobile view ****/
      <div className='login-container banner-container-top mobile'>
        <Loader
          isLoading={isLoading} />
        <div className={`login-text ${inputError && 'login-text-error-margin'}`}>{t('login')}</div>
        {expiredError && <div className='error-text'>{t('session_expired')}</div>}
        <form className='form-wrapper'
          style={{ position:'relative' }}>
          {inputError && <div className='error-text'>{errText}</div>}
          <div className='label-wrapper'>
            <div className='img-wrapper mobile_img'>
              <img className='img'
                src={MobileIcon}
                alt='' />
            </div>
            <label className='label-text'>{t('user_profile_screen_phone_number')}</label>
          </div>
          <OtpInput
            value={phoneNumber}
            onChange={this.inputNumber}
            numInputs={10}
            isInputNum={true}
            hasErrored={true}
            isDisabled={phoneDisabled}
            inputStyle='input-container' />
          <div className='or-container'>
            <div className='or-sideline-wrapper left_line'><div className='or-sideline' /></div>
            <div>{t('or')}</div>
            <div className='or-sideline-wrapper right_line'><div className='or-sideline' /></div>
          </div>
          <div style={{ position: 'relative' }}>
            {/* {inputError && <div className='error-text'>{errText}</div>} */}
            <div className='label-wrapper'>
              <div className='img-wrapper'>
                <img className='img'
                  src={EmailIcon}
                  alt='' />
              </div>
              <label className='label-text'>{t('email_address')}</label>
            </div>
          </div>
          <input
            className={`form-input ${inputError && phoneDisabled && 'input-error'}`}
            type='email'
            id='email'
            placeholder={t('input_text')}
            value={emailId}
            disabled={emailDisabled}
            onChange={(e) => this.inputEmail(e)}
            onKeyDown={this.enterButtonFunction} />
          <div className='btn-container'>
            <div className='next-btn-container button-pointer'
              onClick={this.submitForm}>{t('next')}</div>
          </div>
        </form>
        <div className='new-to-visava-container'>
          {t('login_new_to_visava_message')}
          <div className='sign-up'
            onClick={this.goToSignUp}>{t('otp_verifier_signup_btn_2')}</div>
        </div>
      </div> :
      /***** Desktop view ****/
      <div className='login-container banner-container-top desktop'>
        <Loader
          isLoading={isLoading} />
        <LogoWithText title={t('log_in')} />
        <div style={{ position:'relative ' }}>
          {inputError && <div className='error-text'>{errText}</div>}
          {expiredError && <div className='error-text'>{t('session_expired')}</div>}
          <div className='form-wrapper-l'>
            <div className='input-wrapper'>
              <div className='label-wrapper'>
                <div className='img-wrapper mobile_img'>
                  <img className='img'
                    src={MobileIcon}
                    alt='' />
                </div>
                <label className='label-text'>{t('user_profile_screen_phone_number')}</label>
              </div>
              <div className='digit_wrapper'>
                <OtpInput
                  value={phoneNumber}
                  onChange={this.inputNumber}
                  numInputs={10}
                  isInputNum={true}
                  hasErrored={true}
                  isDisabled={phoneDisabled}
                  inputStyle='input-container'
                  errorStyle={inputError && emailDisabled && 'input-error'} />
              </div>
            </div>
            <div className='or-container-l'>
              <div className='or-sideline-wrapper-l'><div className='or-sideline-l' /></div>
              <div className='or-text'>{t('or')}</div>
              <div className='or-sideline-wrapper-l'><div className='or-sideline-l' /></div>
            </div>
            <div className='input-wrapper'>
              <div className='label-wrapper'>
                <div className='img-wrapper'>
                  <img className='img'
                    src={EmailIcon}
                    alt='' />
                </div>
                <label className='label-text'>{t('email_address')}</label>
              </div>
              <input
                className={`form-input ${inputError && phoneDisabled && 'input-error'}`}
                type='email'
                id='email'
                placeholder={t('input_text')}
                value={emailId}
                disabled={emailDisabled}
                onChange={(e) => this.inputEmail(e)}
                onKeyDown={this.enterButtonFunction} />
            </div>
          </div>
        </div>
        <div className='form-wrapper'>
          <div className='btn-container'>
            <div className='next-btn-container button-pointer'
              onClick={this.submitForm}>{t('next')}</div>
          </div>
        </div>
        <div className='new-to-visava-container'>
          {t('login_new_to_visava_message')}
          <div className='sign-up button-pointer'
            onClick={this.goToSignUp}>{t('otp_verifier_signup_btn_2')}</div>
        </div>
      </div>
    );
  }
}

/***** reading value of breakpoint from redux ****/
const mapStateToProps = (state) => ({
  breakpoint : getBreakpoint(state)
});

export default withTranslation()(withRouter(connect(mapStateToProps, null)(Login)));